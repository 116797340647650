@import 'src/utilities/variables.scss';

.sourceInfoList {
	list-style: none;
	margin: 0;
	padding: 0;
	width: 450px;
	max-height: 70vh;
	overflow: auto;

	li {
		text-transform: capitalize;
	}

	.ant-checkbox-checked .ant-checkbox-inner {
		background-color: $checkbox-color;
		border-color: #ffffff;

		&::after {
			border-color: #ffffff;
		}
	}

	.ant-checkbox-inner {
		border: 1px solid $checkbox-color;
	}

	.ant-checkbox-wrapper:hover .ant-checkbox-inner,
	.ant-checkbox:hover .ant-checkbox-inner,
	.ant-checkbox-input:focus + .ant-checkbox-inner {
		border-color: $checkbox-color;
	}

	.ant-checkbox-checked::after {
		border: 1px solid $checkbox-color;
	}

	.clear-all {
		float: right;
		border: none;
		box-shadow: none;
		color: $checkbox-color;
		border-bottom: 1px solid;
		padding: 0px;
		height: 18px;
		margin-right: 3px;
		font-family: $primay-font, Roboto, Arial, Helvetica, sans-serif;
	}
}
