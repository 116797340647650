@import 'src/utilities/variables.scss';

a.ant-typography.pddButton,
.ant-typography.pddButton {
	padding: 11px 10px;
	color: $button-color-primary;
	background: #ffffff;
	border: 1px solid #dddddd;
	display: inline-block;
	font-weight: bold;
}

.ant-layout-sider {
	background-color: #ffffff;
}
.ant-layout-sider-collapsed .ant-layout-sider-children {
	visibility: hidden;
}
.ant-layout-sider-collapsed .ant-layout-sider-children .ant-checkbox {
	display: none;
}
.ant-layout-sider-collapsed {
	flex: 0 0 0px !important;
	max-width: 0px !important;
	min-width: 0px !important;
	width: 0px !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
	height: 30px;
}
.ant-select:not(.ant-select-customize-input)
	.ant-select-selector
	.ant-select-selection-search-input {
	height: 30px;
}
.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
	line-height: 30px;
}

.filters {
	margin-bottom: 10px;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
	background-color: #fde5e1;
}
.ant-select-item-group {
	color: $button-color-primary;
	font-size: 12px;
	font-weight: bold;
}

.datepicker {
	padding-left: 0px !important;
	padding-right: 20px !important;

	& .euiFlexGroup--directionRow {
		position: relative;
		z-index: 8;
	}
}
.query-wrapper {
	.queryList {
		margin-top: 5px;
	}
}

.queryList {
	margin-bottom: 0;
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	& li {
		display: inline-flex;
		align-items: center;
		margin-bottom: 5px;
		padding: 3px;
		background: #f0f0f0;
		border: 1px solid #ccc9c9;
		box-sizing: border-box;
		border-radius: 4px;

		& .queryBlock {
			display: flex;
			align-items: baseline;
			background: $button-color-primary;
			border-radius: 2px;
			padding: 1px;
			position: relative;
		}

		& .operandOption {
			margin: -1px 0 0 5px;
			padding: 0;
		}

		& .query {
			margin: 0 5px;
			cursor: default;
			color: #ffffff;
		}

		& .ant-checkbox-wrapper {
			margin-left: 2px;
			font-size: 12px;
		}

		& .anticon {
			font-size: 10px;
			background-color: #ffffff;
			color: #242323;
			padding: 2px;
			margin-left: 0px;
		}
	}

	& .checkedAllButton {
		background: $button-color-primary;
		padding: 5px;
		border-radius: 3px;
		margin-right: 5px;

		& label {
			color: #ffffff;
			font-weight: bold;
		}

		& .ant-checkbox-checked .ant-checkbox-inner {
			background-color: #ffffff;
			border-color: #ffffff;

			&::after {
				border-color: $button-color-primary;
			}
		}
	}
}

.queryList li.startBraces .operandOption {
	background: #ffffff;
	padding: 5px 5px 3px;
	margin-top: -6px;
	margin-left: -1px;
	position: relative;
	top: 3px;
}

.queryBlock .ant-checkbox-checked .ant-checkbox-inner {
	background-color: #ffffff !important;
	border-color: #ffffff !important;

	&::after {
		border-bottom-color: #242323;
		border-right-color: #242323;
	}
}

.queryList .checkedAllButton .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.queryList .checkedAllButton .ant-checkbox:hover .ant-checkbox-inner,
.queryList .checkedAllButton .ant-checkbox-input:focus + .ant-checkbox-inner {
	border-color: #ffffff;
}
.queryList li.operandOption {
	background: none;
}

.queryList li .ant-switch {
	height: 20px;
	line-height: 20px;
	min-width: 57px;
}
.queryList li .ant-switch-checked .ant-switch-handle {
	left: calc(100% - 16px - 2px);
	width: 16px;
	height: 16px;
}
.queryList li .ant-switch-handle {
	width: 16px;
	height: 16px;
}
.queryList li .ant-switch-inner {
	margin: 0 16px 0 25px;
}

.queryList li .ant-switch-checked .ant-switch-inner {
	margin: 0 25px 0 11px;
}
.queryList .queryButton {
	background: none;
	padding: 0;
	margin-right: 5px;
}
.queryList .queryButton button {
	padding: 2px 10px;
	width: 104px;
}
.finalQuery {
	border: 1px solid #e9e9e9;
	background: #f0f0f0;
	padding: 5px 10px;
	margin-bottom: 5px;
	line-height: 20px;
}
.showQuery {
	display: block;
	opacity: 1;
	transition: all 0.8s;
}
.hideQuery {
	display: none;
	opacity: 0;
	transition: all 0.8s;
}
.editModal {
	width: 620px !important;
}
.editModal .ant-modal-body {
	padding-bottom: 5px;
}
.editModal .ant-modal-footer {
	padding: 0;
	border-top: 0;
}
#editQueryform .ant-form-item-label {
	text-align: left;
}
#editQueryform .ant-form-item {
	margin-bottom: 8px;
}
#editQueryform .ant-form-item-control-input-content button {
	margin-right: 10px;
}
#editQueryform .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
	height: 32px;
}
#editQueryform {
	.ant-select:not(.ant-select-customize-input)
		.ant-select-selector
		.ant-select-selection-search-input {
		height: 32px;
	}
}
#editQueryform .ant-select-single .ant-select-selector .ant-select-selection-item,
#editQueryform .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
	line-height: 32px;
}
#editQueryform .ant-form-item-label > label::after {
	content: '';
}
#editQueryform .customValidator .ant-form-item-label > label::before {
	display: inline-block;
	margin-right: 4px;
	color: #ff4d4f;
	font-size: 14px;
	font-family: $primay-font;
	line-height: 1;
	content: '*';
}
#editQueryform .rangeGroup label {
	display: inline-block;
	margin-right: 10px;
}
.rangeFields,
#editQueryform .rangeFields .ant-form-item {
	display: inline-block;
}
.rangeFields {
	border: 1px solid #d9d9d9;
	margin-bottom: 10px;
	background-color: #ffffff;
}
.rangeFields .ant-col-16 {
	max-width: 100%;
}
.rangeFields .rangeField.ant-form-item {
	margin-bottom: 0;
}
.rangeFields .rangeField .ant-input,
.rangeFields .rangeField .ant-input:focus,
.ant-input-focused {
	border: none;
	box-shadow: none;
	background-color: transparent !important;
	border-radius: 0;
}
.rangeFields .rangeIcon {
	text-align: center;
	padding-top: 5px;
}
.rangeFields .anticon svg {
	color: #a5a4a4;
}
// #editQueryform .errorField{
//     border: 1px solid red;
// }
#editQueryform .errorMessage {
	color: red;
	margin-top: -5px;
	margin-bottom: 5px;
}
#editQueryform .ant-form-item textarea.ant-input {
	height: 100px;
}

.align-content {
	display: flex;
	align-items: center;
	justify-content: center;
	border-left: 1px solid #e2dfdf;
}

.align-content .anticon {
	margin: 5px;
}

.flex-end {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	border-left: 1px solid #e2dfdf;
}

.vl {
	border-left: 1px solid #e2dfdf;
	height: 33px;
}

.ant-popover-content {
	border: 1px solid #cccccc;
	box-sizing: border-box;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	border-radius: 2px;
}

.overflow-hidden {
	overflow: hidden;
	font-size: 14px;
	cursor: pointer;
}

.hide-text .euiButton__text {
	font-size: 0px;
	margin-left: 0px;
}

.hide-text .euiSuperUpdateButton {
	min-width: 30px;
}

.active {
	font-weight: 600;
}

.pop-filters {
	width: 850px;
	max-height: 450px;
	overflow-y: auto;
	position: relative;
	.editLoaderClass {
		position: absolute;
		top: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.25);
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 1;
		.loaderIcon {
			text-align: center;
			background: #ffffff;
			padding: 15px 15px 10px;
			border-radius: 5px;
		}
	}
}

.queryList .startFilter {
	border-right: none;
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
}

.queryList .endFilter {
	border-left: none;
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
}

.queryList .midFilter {
	border-left: none;
	border-right: none;
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
}

.query-wrapper {
	margin-bottom: 60px;
}

.noFilters {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 12px;
}

.query-content-wrapper {
	margin-bottom: 10px;
}

.showFilters {
	display: block;
	opacity: 1;
	transition: all 0.8s;
}

.hideFilters {
	display: none;
	opacity: 0;
	transition: all 0.8s;
}

.ant-modal-wrap {
	z-index: 1030;
}

.filters-footer {
	border-top: 1px solid #e9e9e9;
}

.filters-footer > div {
	margin-top: 10px;
}

.filters-footer .add-filter-btn {
	display: flex;
	justify-content: flex-end;
	align-self: baseline;
	gap: 10px;
}

.filters-footer .add-filter-btn .ant-btn {
	margin-left: 15px;
}

a.ant-typography.btnPDD {
	width: 50px;
	height: 35px;
	padding: 2px 0;
	font-size: 21px;
	border-radius: 2px;
	background: $button-color-primary;
	text-align: center;
	color: #ffffff;
}
.exportOptions {
	.ant-btn-icon-only.ant-btn-lg.btnExport,
	.ant-btn-icon-only.ant-btn-lg,
	.btnExport {
		width: 50px;
		height: 35px;
		background-color: $button-color-primary;
		border-color: $button-color-primary;
		font-size: 21px;
		padding: 0;
		margin-left: 15px;

		&:hover {
			opacity: 0.8;
		}
	}

	.btnExport .ant-btn-icon-only.ant-btn-lg {
		margin-left: 0;
	}
}

.add-filters-section {
	border: 1px solid #cccccc;
	background: #f3f3f3;
	border-radius: 0px 0px 1px 1px;
	margin-top: 10px;
	.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		background: #ffffff;
	}
	#editQueryform {
		padding-top: 15px;
		padding-left: 10px;
	}
}

.filters-badge {
	margin-left: 8px;
	margin-right: 5px;
	margin-bottom: 2px;

	.ant-badge-count {
		background: $button-color-primary;
	}
}

.highlight-wrapper {
	padding-bottom: 13px;
}

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
	border-top-color: #cccccc;
	border-left-color: #cccccc;
}

.disable-popup {
	cursor: not-allowed;
}

.pointer-events-none {
	pointer-events: none;
}

.btnExport:hover {
	color: $color-white;
	border-color: $button-color-primary;
	opacity: 0.8;
}

.search-header {
	.noBucketMessage {
		text-align: center;
		padding-top: 7px;
		font-weight: bold;
	}
	.ant-col {
		margin-top: 12px;
	}
	.exportOptions {
		border-left: none;
	}
	.graphOption {
		border-right: 1px solid #e2dfdf;

		.graph-slide {
			font-size: 14px;
			background: $button-color-primary;
			margin-left: 5px;
		}
	}
}
.highlight-field::before {
	content: '';
	position: absolute;
	top: -12px;
	left: 0;
	width: 100%;
	height: 3px;
	border-top: 3px solid $old-primary-color;
}
.renderViewList {
	width: 100%;
	margin-bottom: 0;
	li {
		padding: 5px 15px;
		cursor: pointer;
		&:hover {
			background: #f2f2f2;
		}
		span {
			width: 13px;
			height: 13px;
			border: 2px solid #242323;
			border-radius: 100%;
			margin-right: 10px;
			display: inline-block;
		}
		&.active {
			span {
				background: $button-color-primary;
			}
		}
	}
}
