@import 'src/utilities/variables.scss';

$lable-color: #4f4f4f;

.regex-container {
	border: '1px solid #d9d9d9';
	border-radius: '4px';
	padding: '20px 15px';
	font-size: 20px;
	margin-bottom: '10px';

	.regex-form-header {
		span {
			color: $lable-color;
		}
	}

	.checkbox-wrapper {
		color: #121212;
		display: flex;
		flex-direction: column;
		row-gap: 10px;
		padding: 4px;

		.ant-radio-inner {
			border-color: $button-color-primary;
		}

		.ant-radio-inner::after {
			background-color: $button-color-primary;
		}
		label {
			width: 120px;
		}
	}

	.btn-container {
		display: flex;
		column-gap: 10px;
		margin: 15px 0;
	}

	.regex-error {
		border: 1px solid #ef9685;
		border-radius: 8px;
		background: #fae5e1;
		padding: 14px 15px 14px 15px;
		font-size: 0.7em;
		margin: 1em 0;
		ul {
			list-style: disc;
			margin-bottom: 0;
			margin-left: 22px;
		}
		.err-wrapper {
			display: flex;
			column-gap: 5px;
			align-items: center;
			margin-bottom: 1px;

			.err-icon {
				$size: 1.2em;
				height: $size;
				width: $size;
			}

			span {
				font-weight: 600;
				font-size: 1em;
			}
		}
	}

	.regex-header {
		display: flex;
		align-content: center;
		margin-bottom: 25px;
		border: 1px solid $border-color-dark;
		border-radius: 4px;

		.ant-select-single {
			color: #666666;
		}

		.select-before {
			&:nth-child(1) {
				width: 150px;
				border-right: 1px solid $border-color-dark;
			}

			&:nth-child(2) {
				flex: 1;
			}
		}

		input {
			color: #666666;
			flex: 1;
		}
	}
	.regexType {
		.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
			height: 30px;
			border-radius: 4px;
			padding: 0 11px 0 7px;
			.ant-select-selection-search-input {
				height: 22px;
			}
		}
		.ant-select-single {
			width: 241px;
			margin-left: 15px;

			.ant-select-selector .ant-select-selection-item {
				line-height: 30px;
				color: #4f4f4f;
				font-size: 13px;
			}
		}
	}
	.regexTypeSection {
		margin-top: 10px;
		border-color: #d9d9d9;
		.select-before:nth-child(1) {
			border-color: #d9d9d9;
		}
		.ant-form-item {
			margin-bottom: 0;
			width: 100%;
		}
		.ant-input {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			border: none;
		}
		.ant-select:not(.ant-select-customize-input) .ant-select-selector {
			border: none;
		}
	}

	.expression-wrapper {
		font-size: 16px;
		margin: 15px 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: $button-color-primary;

		.expr-text {
			font-weight: 600;
		}

		.expression-icons {
			display: flex;
			column-gap: 10px;
		}
	}
}

.regex-form {
	label {
		color: $lable-color;
		font-weight: 500;
	}
	.form-item {
		label {
			color: $lable-color;
			font-weight: 500;
		}
	}

	&-header {
		margin-bottom: 20px;
	}

	.regex-input {
		margin: 5px 0 15px 0;
	}

	.ant-form-item-explain-error {
		display: none;
	}

	.ant-col.ant-form-item-label {
		padding: 0px;
	}

	.ant-form-item:not(:last-child) {
		margin-bottom: 0px;
	}

	.ant-input {
		border-radius: 4px;
		color: $lable-color;
	}
	textarea {
		resize: none;
	}
}

//------------regex--table----------
.regex-table {
	.action-icon-box {
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		transition: all 3s ease-in;
		cursor: pointer;
		gap: 5px;

		.action-wrap {
			display: flex;
			flex: 1;
			align-items: center;

			.action-icon-g {
				background-color: green;
				color: white;
				border-radius: 100%;
				font-size: 8px;
				padding: 4px;
			}
			.action-icon-r {
				background-color: red;
				color: white;
				border-radius: 100%;
				font-size: 16px;
			}
		}

		&-abs {
			background-color: white;
			z-index: 999;
			margin-right: 15px;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100px;
			gap: 12px;

			padding: 0;
			position: absolute;
			height: 100%;
			bottom: 0;
			right: 0;
			box-shadow: -5px 1px 3px 0px $box-shadow-light;

			&:hover {
				background-color: white;
				box-shadow: -5px 1px 3px 0px $box-shadow-light;
			}
		}

		.hid {
			visibility: hidden;
		}
		.vis {
			visibility: visible;
		}
	}

	.extra-options {
		padding: 0;
		.actionIcons {
			padding: 10px;
		}
	}

	.content-ellipsis {
		word-wrap: break-word;
		white-space: normal;
		display: -webkit-box;
		-webkit-line-clamp: 5;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.default-cursor {
		cursor: default;
	}

	.hid {
		visibility: hidden;
	}
	.vis {
		visibility: visible;
	}

	.regex-table-nodata {
		display: flex;
		flex-direction: column;
		align-items: center;
		row-gap: 10px;
		padding: 2em 0;

		.nodata-icon {
			$size: 5em;

			width: $size;
			height: $size;
		}
		span {
			font-size: 1em;
		}
	}

	.ant-table-thead {
		.ant-table-cell {
			border-right: 1px solid #cccccc !important;
		}
	}

	.ant-table-tbody {
		.ant-table-row {
			.ant-table-cell {
				border-right: 1px solid transparent !important;
			}

			.ant-table-cell:last-child {
				border-right: 1px solid #f0f0f0 !important;
			}
		}
	}

	svg.three-dots {
		fill: $button-color-primary;
	}

	.ant-table-thead > tr > th {
		font-weight: 500;
	}
}
.replaceSection {
	padding-left: 24px;
	.noteForUserInput {
		padding-left: 28px;
		margin-bottom: 5px;
		font-size: 13px;
		opacity: 0.6;
		line-height: 15px;
	}
}
.replaceWordsTextareaBlock {
	margin-left: 26px;
	.replaceWordsTextarea {
		border: 1px solid #c5c5c5;
		border-radius: 5px;
		.replaceInput {
			height: 100px;
			border: none;
			resize: none;
			&:focus {
				box-shadow: none;
			}
		}
		.badge_list {
			margin-bottom: 0;
			.badge_list_label {
				max-width: 295px;
				word-break: break-all;
			}
		}
	}
	p {
		opacity: 0.6;
	}
}

//--------------regex--modal----------
.success_modal {
	.success-modal-icon {
		color: #ffcc00;
		font-size: 50px;
	}
}

//--------------regex--modal----------
.success_modal {
	.success-modal-icon {
		color: #ffcc00;
		font-size: 50px;
	}
}

//--------------regex--modal----------
.success_modal {
	.success-modal-icon {
		color: #ffcc00;
		font-size: 50px;
	}
}

//-----------info---wrapper-----------
.info-wrapper {
	display: flex;
	align-items: center;
	column-gap: 5px;
	color: #666666;
	font-size: 1em;

	svg {
		$size: 1.1em;
		height: $size;
		width: $size;
		color: $button-color-primary;
	}
}

.regex-info {
	font-size: 0.7em;
	margin-top: 10px;
}
