@import 'src/utilities/variables.scss';

.left-block-wrapper {
	display: flex;
	color: $color-white;
	column-gap: 30px;
	align-items: center;

	span {
		font-size: 1em;
		font-weight: 600;
		line-height: 17px;
		letter-spacing: 0em;
	}

	&-icon {
		margin-top: 10px;
	}
}
