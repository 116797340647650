.updatePolicyModal {
	.ant-modal-footer {
		display: none;
	}
	.updatePolicyWrapper {
		h3 {
			border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
			padding-bottom: 5px;
			margin-bottom: 15px;
		}
	}
	.updatePolicyButtons {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 15px;
		.ant-btn {
			&:first-child {
				margin-right: 15px;
			}
		}
	}
}

.policyStatusTable {
	table {
		border: 1px solid rgba($color: #000000, $alpha: 0.1);
		border-radius: 5px;
	}
	tr {
		&:last-child {
			td {
				border-bottom: 0;
			}
		}
		th,
		td {
			&:last-child {
				border-left: 1px solid rgba($color: #000000, $alpha: 0.1);
			}
		}
	}
}
