$old-primary-color: #f05742;

/* Define button colours */

$button-color-primary: #4e6b8c;
$button-color-secondary: #4e6b8c;

$border-color-light: #d9d9d9;
$border-color-extra-light: #e0e0e0;
$border-color-dark: #949494;
$spinner-color: #ee602c;
$bg-color-primary: #2d2d2d;

// Error colors
$error-border-color: rgba(239, 150, 133, 1);
$error-bg-color: rgba(250, 229, 225, 1);

$color-white: #fff;

// checkbox
$checkbox-color: #4e6b8c;

$box-shadow-light: rgba(0, 0, 0, 0.1);
$box-shadow-dark: rgba(0, 0, 0, 0.4);
// -------fonts----------
@font-face {
	font-display: swap;
	font-family: 'MuseoSans';
	font-style: normal;
	src: url('./fonts/MuseoSans_500.otf') format('truetype');
}

$primay-font: 'MuseoSans';
