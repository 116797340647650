.exportLogsHeading{
    width: 100%;
}
.timeWrapper{
    .ant-input-number{
        width: 100%;
    }
    .exportLogsExtra{
        color: #717171;
        font-size: 12px;
    }
}