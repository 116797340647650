@import 'src/utilities/variables.scss';

$white: #ffffff;

.root-conatiner {
	height: 100%;
}

.flex-container-row {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.loginWrapper {
		margin: 2% 3%;
		width: 400px;

		.disk-space-warning {
			background-color: $old-primary-color;
			color: #ffffff;
			display: flex;
			gap: 30px;
			justify-content: space-between;
			padding: 10px 20px;
			box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1);
			border-radius: 5px;
			margin-bottom: 20px;

			.disk-text {
				color: white;
				font-size: 18px;
				flex: 1;
				margin-bottom: 0;
			}

			.close-icon {
				margin-left: 18px;
				margin-right: 6px;
				cursor: pointer;
			}
		}

		.loginForm {
			padding: 20px 30px;
			box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1);
			background-color: #fff;
			border-radius: 8px;
			border: 1px solid #cfcfcf;

			&-error {
				margin-bottom: 15px;
				border: 1px solid $error-border-color;
				border-radius: 8px;
				background-color: $error-bg-color;
				padding: 12px 20px;

				&-head {
					display: flex;
					justify-content: space-between;
					align-items: center;
					font-size: 16px;
					font-weight: 900;

					gap: 10px;
				}
			}
		}

		& .ant-form-item {
			margin-bottom: 15px;
		}

		& .loginHead {
			margin-bottom: 15px;

			h1 {
				font-size: 24px;
			}
		}

		& .username,
		& .password,
		input {
			border-radius: 4px;
			border-color: $border-color-dark;
			font-size: 16px;
		}

		& .backBtn {
			color: #013d66;
			font-size: 14px;
			margin-top: 15px;

			& .backIcon {
				font-size: 25px;
			}

			&:hover,
			&:focus {
				border-color: transparent;
			}
		}
	}
}

.flex-container-column {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	overflow-y: hidden;
	overflow-x: hidden;

	& .logo-login img {
		width: 180px;
		margin-top: 1%;
		margin-left: 5%;
	}
}

.login-label .ant-form-item-label label {
	font-size: 12px;
}

.ant-form-item-explain-error {
	margin-bottom: 4%;
	margin-top: 2%;
}

.btnLogin {
	margin-top: 10px;
	width: 100%;
}

.form-loading {
	opacity: 0.4;
}

.btn-loading {
	background-color: grey !important;
}
