@import 'src/utilities/variables.scss';

.uploadText {
	border: 1px solid;
	padding: 7px;
	border-top-right-radius: 5px;
	border-left: none;
	border-bottom-right-radius: 5px;
	opacity: 0.5;
	min-width: 190px;
	display: inline-block;
	padding-top: 4px;
	padding-bottom: 4px;
}

.btn_add {
	border-top: 1px solid #e0e0e0;
	display: flex;
	justify-content: flex-end;
	padding: 10px 10px;
	column-gap: 10px;
}

.successMessage {
	color: green;
}

.upload_wrapper {
	display: flex;
	align-items: center;
	position: relative;

	.ant-upload-list {
		display: none;
	}
}

.uploaded_file {
	color: #033e67;
	font-weight: 600;
	border-radius: 4px;
	padding: 0 4px;
	margin-left: 1px;
	height: 22px;
	display: flex;
	align-items: center;
	justify-content: center;
	.file_icon {
		color: #033e67;
		font-size: 16px;
	}
	img {
		width: 18px;
		height: 18px;
	}
}

.modal_wrapper {
	.ant-modal-footer {
		text-align: center;
		border: none;
		padding: 0px 16px 25px 16px;
	}
}

.success_modal {
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;

	&_header {
		font-size: 22px;
		margin: 10px;
	}

	&_sub {
		color: #808080;
		padding: 0 40px;
		text-align: center;
	}
}

.hideBtn {
	display: none;
}

.upload-spinner-wrapper {
	display: flex;
	width: 100px;
	justify-content: space-between;
	margin-right: 70px;

	.upload-spinner {
		$size: 15px;
		width: $size;
		height: $size;
		margin-top: 2px;
	}
}
