.reset-form-wrapper {
  .form-header {
    font-size: 1.8em;
    text-align: center;
    padding: 12px 0 22px 0;
  }

  .reset-field-container {
    label {
      height: 40px;
      margin-right: 10px !important;
    }
  }

  .reset-input-field {
    height: 40px;
    padding: 0px 11px !important;
  }

  .reset-btn-wrapper {
    display: flex;
    justify-content: right;
    column-gap: 8px;
    padding: 10px 0 30px 0;

    .close-btn {
      font-weight: 300;
    }
  }
}
