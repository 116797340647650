@import 'src/utilities/variables.scss';

.ant-menu.profile-settings {
	padding: 1px 20px;
	border: 1px solid #cccccc;
	box-sizing: border-box;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	border-radius: 2px;

	.menu-item {
		border: none;
		box-shadow: none;
		padding: 0px 15px 0px 3px;

		&-val {
			color: #a3a2a1;
			margin-left: 4px;
		}

		&-wrapper {
			padding: 0 25px 0 0 !important;
			margin: 0px !important;
		}
	}

	.ant-menu-item {
		&:hover {
			color: $old-primary-color;
		}

		&:active {
			background-color: transparent !important;
			color: #000000 !important;
		}

		&-selected {
			background-color: transparent !important;
			color: inherit;
		}
	}

	.ant-menu-submenu-title {
		&:active {
			background-color: transparent !important;
			color: #000000 !important;
		}
	}

	.menu-item-wrapper .edit-indices {
		float: right;
		position: absolute;
		top: 20px;
		right: -10px;
	}

	.header-ver {
		cursor: text;

		.version-wrapper {
			font-size: 13px;
		}

		&:hover {
			color: unset;
		}
	}

	.bottomBorder {
		border-bottom: 1px solid #efeded;
	}

	.disabled-item {
		pointer-events: none;
		color: inherit;
	}

	.edit-indices-wrapper {
		position: absolute !important;
		top: -5px;
		right: 18px;
	}

	.editAlias {
		top: 39px;
	}

	.editAliasMenu {
		top: 74px;
	}
}

.ant-menu-vertical > .ant-menu-item.aliasName {
	height: auto;
}

.aliasIndxes {
	max-height: 50px;
	overflow-y: auto;
	width: 300px;
}
.ant-menu-vertical .ant-menu-submenu-title {
	padding-left: 0;
	padding-right: 46px;
}

.ant-menu-submenu {
	&-title {
		&:hover {
			color: #000000;
		}
	}

	&-popup {
		max-height: 190px;
		overflow-y: auto;
		overflow-x: hidden;
		background: #ffffff;
		border: 1px solid #cccccc;
	}

	&-expand-icon {
		right: 23px;
	}

	&-arrow {
		right: 23px;
	}
}

.ant-menu-submenu-popup > .ant-menu {
	padding: 5px 0;
	box-shadow: none;
}
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
	color: unset;
}

.ant-menu-vertical.ant-menu-sub .ant-menu-item {
	height: 30px;
	line-height: 30px;
	margin: 1px 0;
	cursor: default;

	&:not(:last-child) {
		border-bottom: 1px solid #efeded;
	}
}

.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
	color: #000000;
}
.aliasValue {
	color: #a3a2a1;
}

.peg_tabs {
	.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
		color: #033e67;
		font-weight: 600;
	}

	.ant-tabs-ink-bar {
		background: $old-primary-color;
		height: 3px !important;
	}
}

.link_color {
	color: black !important;

	&:hover {
		color: $old-primary-color !important;
	}
}

.svg_styles {
	svg {
		fill: #033e67;
	}

	&:hover {
		svg {
			fill: #f05742;
		}
	}
}
