@import 'src/utilities/variables.scss';

.Block_wrapper,
.Pass_wrapper {
	height: 22px;
}
.readaction-wrapper {
	width: 100%;
	// border: 1px solid #cccccc;

	.ant-collapse-item {
		min-height: 45px;
	}

	.ant-collapse-header {
		font-family: $primay-font;
		font-size: 1.3em;
	}

	// .wordsForm {
	// 	display: flex;
	// }

	// .real-header {
	// 	display: flex;
	// 	justify-content: flex-end;
	// 	align-items: center;

	// }

	.import-btns {
		display: flex;
		justify-content: flex-end;
		padding: 3px 0;
		position: absolute;
		top: 17px;
		right: 51px;

		.gray-btn {
			display: flex;
			align-items: center;
			justify-content: center;
			font-weight: 300;
			height: 24px;
			width: 28px;
			padding: 0;
			border: none;
			.anticon {
				font-size: 16px;
			}
			img {
				width: 18px;
				height: 18px;
			}
		}
		.exp {
			img {
				width: 20px;
			}
		}
	}

	.readaction-body {
		display: flex;
		flex-direction: column;

		.divider {
			width: 1px;
			background-color: #cccccc;
		}

		.dual-container {
			border-top: unset !important;
			margin-top: 15px !important;
		}

		.list-container {
			display: flex;
			flex-direction: column;
			column-gap: 15px;
			// border-top: 1px solid #cccccc;
			// margin-top: 12px;

			.red-wrapper {
				width: 100%;

				.list-title {
					display: flex;
					justify-content: space-between;
					align-items: baseline;
					margin-bottom: 12px;
					font-weight: 600;

					.sub-title {
						font-weight: 600;

						.tool-icon {
							color: rgba(0, 0, 0, 0.45);
						}

						span {
							margin-right: 10px;
						}
					}
					.listBlock {
						display: flex;
						flex-direction: row;
						.ant-btn {
							height: 22px;
							width: 28px;
							padding: 0px 4px;
							display: flex;
							align-items: center;
							justify-content: center;
							border: none;
							img {
								width: 18px;
								height: 17px;
							}
						}
					}
				}

				.passlist-container {
					display: flex;
					column-gap: 15px;
					flex-direction: column;

					.passlist-item {
						width: 100%;
					}
				}
			}
		}

		.dual-row {
			display: flex;
			column-gap: 15px;
			width: 100%;
			margin-top: 10px;

			.form-field {
				width: 50%;

				.ant-select-selector,
				.field-input {
					border-radius: 6px;
					height: 40px;
					align-items: center;
				}
			}
		}

		.ant-form-item {
			margin-bottom: 10px;
		}

		.btn_add {
			margin-top: 15px;
			//justify-content: space-between;
			padding: 9px 0 0 0;
			justify-content: flex-start;

			.other-btns {
				display: flex;
				align-items: center;

				.btn-set1 {
					column-gap: 10px;
					display: flex;
				}

				.btn-set2 {
					display: flex;

					.dvider {
						width: 1px;
						background-color: #cccccc;
						margin: 0 15px;
					}

					button {
						padding: 0 25px;
					}
				}
			}
		}

		.red-info {
			margin-top: 12px;
			justify-content: flex-end;
		}

		.clear-btn {
			align-items: center;
		}
	}

	.ant-collapse-borderless
		> .ant-collapse-item
		> .ant-collapse-content
		> .ant-collapse-content-box {
		padding: 12px;
	}
}

.ant-layout-sider-collapsed {
	.import-btns {
		display: none;
	}
}

.words_wrapper {
	border: 1px solid #e0e0e0;
	padding: 2px;
	border-radius: 5px;
	background-color: #fafafa;
}

.list-words {
	word-wrap: break-word;
}

.grow-wrap::after {
	content: attr(data-replicated-value) ' ';
	white-space: pre-wrap;
	visibility: hidden;
}

.grow-wrap > textarea {
	resize: none;
	overflow: hidden;
	border: none;
	resize: none;
	margin: 0 5px;
	width: 97.5%;

	&:focus {
		outline: none;
	}
}
.grow-wrap > textarea,
.grow-wrap::after {
	padding: 0.5rem;
	font: inherit;
	grid-area: 1 / 1 / 2 / 2;
}

.highlight-box {
	border: 1px solid $old-primary-color;
}

#file-input {
	display: none;
}

.validationMessage {
	color: #747474;
	margin-top: -3px;
	margin-bottom: 0;
	font-size: 11px;
}
.wordsForm {
	position: relative;
	.wordActionItem {
		.ant-form-item-label {
			padding-bottom: 0;
		}
	}
	.listNameField {
		position: relative;
	}
	.scopeItem {
		margin-bottom: 10px;
	}
	.uploadSection {
		position: absolute;
		top: 0;
		right: 0;
		display: flex;
		z-index: 1;
		.ant-btn {
			height: 22px;
			width: 28px;
			padding: 0px 4px;
			display: flex;
			align-items: center;
			justify-content: center;
			border: none;
			img {
				width: 18px;
				height: 17px;
			}
		}
		.download-spinner {
			width: 14px;
			height: 14px;
			margin-top: 5px;
			margin-left: 13px;
		}
		.deleteListBtn {
			color: #033e67;
			margin-top: 1px;
		}
	}
	.searchWords {
		display: flex;
		flex-direction: column;
		label {
			margin-bottom: 2px;
			.anticon {
				margin-left: 3px;
				opacity: 0.5;
			}
		}
		.inputWordSection {
			display: flex;
			.inputWord {
				margin-right: 15px;
			}
		}
		p {
			margin: 2px;
		}
	}
	.wordsTable {
		max-height: 200px;
		overflow: auto;
		margin: 10px 0;
		border: 1px solid #ddd;
		ul {
			margin-bottom: 0;
			li {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 5px 10px;
				border-bottom: 1px solid #ddd;
				&:last-child {
					border-bottom: none;
				}
				p {
					margin-bottom: 0;
				}
				svg {
					width: 14px;
					height: 11px;
					cursor: pointer;
				}
			}
		}
	}
	.notFoundMessage {
		margin-top: 2px;
		font-size: 13px;
		margin-bottom: 0;
	}
	.newWords {
		margin-top: 10px;
		ul {
			li {
				display: inline-flex;
				background: #4e6b8c;
				color: #fff;
				padding: 0 5px;
				align-items: center;
				margin-right: 10px;
				margin-bottom: 5px;
				p {
					margin-bottom: 0;
					margin-right: 10px;
				}
				svg {
					width: 14px;
					height: 11px;
					cursor: pointer;
				}
				&.duplicateWord {
					background: red;
				}
			}
		}
		&.deletedWordsList {
			h3 {
				font-size: 13px;
				border-bottom: 1px solid #ddd;
				margin-bottom: 5px;
			}
			ul {
				li {
					background: red;
				}
			}
		}
	}
	.ant-form-item-explain.ant-form-item-explain-error {
		margin-top: 1px;
		margin-bottom: 0;
	}
	.error {
		border: 1px solid red;
	}
}

.wordSearchTooltip {
	.ant-tooltip-inner {
		text-transform: none;
	}
}
