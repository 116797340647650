.errorField {
	border: 1px solid red;
}
.menu-username {
	display: inline-block;
	max-width: 150px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	position: relative;
	top: 2px;
	font-weight: bold;
}
.ant-input.invalidField,
.ant-input:focus.invalidField {
	border: 1px solid red;
}
.fieldError {
	color: red;
	margin-top: -20px;
}
.validateError {
	color: red;
}
body {
	.ant-tooltip-inner {
		border-radius: 5px;
		box-shadow: 0 6px 8px -4px rgba(0, 0, 0, 0.5), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
			0 9px 28px 8px rgba(0, 0, 0, 0.05);
		text-transform: capitalize;
	}
}
