@import 'src/utilities/variables.scss';

.row_settings {
	display: flex;
	justify-content: space-evenly;
}

.general_wrapper {
	.ant-input {
		height: 38px;
	}
	.date_margin {
		width: 100%;
	}
	.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
		height: 38px;
	}
	.ant-select-single .ant-select-selector .ant-select-selection-item,
	.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
		line-height: 36px;
	}
}

.proxy-checkbox-wrapper {
	padding-top: 15px;
	padding-bottom: 8px;
	border-top: 1px solid #f0f0f0;

	.ant-checkbox {
		border-color: $button-color-primary;
	}

	.ant-checkbox-checked .ant-checkbox-inner {
		background-color: $button-color-primary;
		border-color: $button-color-primary;
	}

	.proxy-tooltip {
		color: rgba(0, 0, 0, 0.45);
	}
}

.pegConfigButton {
	border-top: 1px solid #ccc;
	padding-top: 15px;
	.ant-col {
		position: unset;
	}
	p {
		margin-bottom: 10px;
	}
	.deployBtn {
		position: absolute;
		top: 0;
		right: 0;
	}
}
.success_modal_header {
	text-align: center;
	&.warningHeader {
		font-size: 15px;
	}
	&.successHeader {
		font-size: 18px;
	}
}

//----update---ant----styles------

.ant-picker-now {
	a {
		color: $button-color-primary;
	}
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
	background: $button-color-primary;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
	border: 1px solid $button-color-primary;
}

.ant-picker-time-panel-column
	> li.ant-picker-time-panel-cell-selected
	.ant-picker-time-panel-cell-inner {
	background: #e6f0f8;
}
