@import 'src/utilities/variables.scss';

.filterdropdown {
	padding: 6px;
}

.dropdown {
	margin: 20px auto;

	& select {
		width: 160px;
		height: 30px;
		line-height: 32px;
	}

	& .euiComboBox {
		width: 170px;
		height: 30px;
		line-height: 32px;
		display: inline-block;
	}

	& label {
		color: $button-color-primary;
		font-size: 14px;
	}
}

.filters .euiFlexGroup--wrap {
	margin-left: -6px;
}

.filters .euiButtonEmpty--primary {
	font-size: 15px;
	color: $button-color-primary;
	background-color: #f0f0f0;
	border: 1px solid #dddddd;
	padding: 5px;
}
.filters .euiButtonEmpty.euiButtonEmpty--xSmall {
	height: 37px;
}

.euiPopover__panel.euiPopover__panel-isOpen {
	z-index: 1033 !important;
}

.paddingError {
	padding-left: 15px;
}

.btnAddFilter {
	color: white;
	background: $button-color-primary;
	border: 2px solid $button-color-primary;
	box-sizing: border-box;
	border-radius: 2px;
}

.add-filters-wrapper {
	background: #f3f3f3;
	border-radius: 0px 0px 1px 1px;
}

.add-filters-footer {
	justify-content: flex-end;
	margin: 16px;
}

.editQuery {
	padding: 10px;
}

.edit-filters-footer .ant-form-item-control-input-content {
	display: flex;
	justify-content: flex-end;
}

.queryBlock .anticon {
	margin: 5px;
}

.updateSignature {
	& .updateCol {
		padding-left: 15px;
		padding-right: 15px;
	}

	& .checkConflict {
		flex-direction: row;
		label {
			&::after {
				content: '' !important;
			}
		}

		.ant-form-item-control-input {
			place-self: flex-start;
			bottom: 5px;
		}
	}

	.ant-checkbox-checked .ant-checkbox-inner {
		background-color: $button-color-primary;
		border-color: #ffffff;

		&::after {
			border-color: #ffffff;
		}
	}

	.ant-checkbox-wrapper:hover .ant-checkbox-inner,
	.ant-checkbox:hover .ant-checkbox-inner,
	.ant-checkbox-input:focus + .ant-checkbox-inner {
		border-color: $button-color-primary;
	}

	.ant-checkbox-checked::after {
		border: 1px solid $button-color-primary;
	}

	& h3 {
		margin-top: 10px;
		margin-bottom: 15px;
		padding-bottom: 5px;
		border-bottom: 1px solid #cccccc;
	}

	& .ant-form-item-control-input-content {
		text-align: right;

		.ant-btn {
			margin-left: 15px;
		}
	}

	& .ant-form-item-label {
		label {
			&::after {
				content: '*';
				color: $button-color-primary;
				margin-left: 5px;
				display: block;
			}
		}
	}
}

.add-filters-footer {
	display: flex;
	gap: 10px;
}

.filterdropdown {
	.euiFormControlLayout,
	.euiFieldText {
		height: 32px;
	}
}
