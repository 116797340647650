@import 'src/utilities/variables.scss';

%loader {
	$size: 28px;
	width: $size;
	height: $size;
	border-radius: 50%;
	display: inline-block;
	border-top: 3px solid $spinner-color;
	border-right: 3px solid transparent;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;

	@keyframes rotation {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
}

.loader-wrapper {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	height: 100%;
	background-color: rgb(255 242 242 / 25%);
	z-index: 1031;
	display: flex;
	flex-direction: column;
	justify-content: center;

	.loader-contents {
		padding: 12px;
		display: flex;
		justify-content: center;

		.loader-og {
			@extend %loader;

			$size: 38px;
			width: $size;
			height: $size;
		}
	}
}

//----styles---for--spinner------
.spinner-wrapper {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	.loader-sp {
		@extend %loader;
	}
}
