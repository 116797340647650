.redaction-header {
	color: #033e67;
	font-size: 1.2em;
	font-weight: 700;
	display: flex;
	margin-bottom: 20px;
	align-items: center;
	column-gap: 10px;
	justify-content: space-between;

	.saprater {
		width: 85%;
		background: #e0e0e0;
		height: 1px;
		border-radius: 6px;
	}
}
.redaction-wrapper {
	width: 50%;
	.redaction-form {
		border: 1px solid #e0e0e0;
		border-radius: 6px;
		padding: 10px;

		.dual-row {
			display: flex;
			column-gap: 10px;
		}

		.form-field {
			width: 50%;

			.ant-select-selector,
			.field-input {
				border-radius: 6px;
				width: 100%;
				border-radius: 6px;
				height: 40px;
			}
			.ant-select-single .ant-select-selector .ant-select-selection-item,
			.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
				line-height: 38px;
			}
			.ant-switch {
				font-weight: bold;
				&.ant-switch-checked {
					background: #4e6b8c;
					&:focus {
						box-shadow: 0 0 0 2px rgba($color: #4e6b8c, $alpha: 0.2);
					}
				}
			}
		}
		.btn-wrapper {
			padding-top: 10px;
			border-top: 1px solid #e0e0e0;
			display: flex;
			justify-content: right;
			.btn_wrapper {
				padding: 20px 0;
				.ant-btn {
					margin: 0;
				}
			}
		}
	}
}
