.updateUserModal {
	.ant-modal-footer {
		display: none;
	}
	.updateUserWrapper {
		h3 {
			border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
			padding-bottom: 5px;
			margin-bottom: 15px;
		}
	}
	.updateUserButtons {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 15px;
		.ant-btn {
			&:first-child {
				margin-right: 15px;
			}
		}
	}
}

.userScopeSection {
	display: flex;
	justify-content: space-between;
	p {
		margin-bottom: 0;
		margin-right: 5px;
	}
	.anticon {
		margin-top: 2px;
		svg {
			width: 14px;
			height: 14px;
		}
	}
}

.createUserPage {
	.policyStatusWrapper {
		position: relative;
		border-top: 0;
		margin-top: -15px;
		.createUser {
			position: absolute;
			top: 15px;
			right: 15px;
			cursor: pointer;
			z-index: 1;
		}
	}
	.user_wrapper {
		.createBtn {
			width: 90px;
		}
		.scopeBtn {
			margin-top: 5px;
			margin-bottom: 15px;
		}
		.userBtnGroup {
			button {
				margin-right: 15px;
			}
		}
		.globalScopeSwitcher {
			flex-direction: row;
			align-items: center;
			.ant-form-item-label {
				padding: 0 15px 0 0;
			}
		}
	}

	.userForm {
		h2 {
			font-size: 16px;
		}
		.userFormWrapper {
			width: 320px;
		}
	}
	.disable {
		opacity: 0.5;
		cursor: default;
	}
	.usernameCol {
		margin: 0;
		span {
			display: inline-flex;
			align-items: center;
			background: #033e67;
			color: #fff;
			padding: 0 4px;
			height: 20px;
			margin-left: 5px;
			border-radius: 4px;
		}
	}
}
