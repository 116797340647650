.addScope {
	margin-bottom: 20px;
}
.addScopeModal {
	.ant-modal-content {
		border-radius: 10px;
		overflow: hidden;
	}
	.ant-modal-body {
		padding-right: 36px;
		padding-bottom: 20px;
	}
	.ant-modal-header {
		.ant-modal-title {
			p {
				margin-bottom: 0;
			}
		}
	}
	.ant-modal-footer {
		display: none;
	}
	.fieldsRow {
		position: relative;
		.ant-form-item {
			margin-bottom: 10px;
		}
		.operator {
			margin-top: 5px;
			margin-bottom: -7px;
		}

		.anticon-minus-circle {
			position: absolute;
			top: 37px;
			right: -25px;
		}
		.ant-switch,
		.ant-switch-checked {
			background-color: #000;
		}
		.euiFormControlLayout {
			height: 32px;
			box-shadow: none;
			background-color: #fff;
			border: 1px solid #d9d9d9;
			&.rangeError {
				border-color: red;
			}
		}
		.ant-row-middle {
			align-items: baseline;
		}
	}
	.operator,
	.addScopeBtn,
	.scopeFooter {
		.ant-form-item-control-input-content {
			display: inline-flex;
			justify-content: end;
		}
	}

	.ant-form-item.addScopeBtn {
		margin-top: 10px;
		.bat-sec {
			height: 28px;
			display: inline-flex;
			align-items: center;
		}
	}

	.scopeFooter {
		margin-bottom: 0;
	}
	.duplicateFilter {
		text-align: right;
		color: red;
	}
}
.scopeQueryString {
	font-size: 14px;
	margin-top: 5px;
	.queryBlock {
		background: #033e67;
		border-radius: 3px;
		color: #fff;
		position: relative;
		padding: 10px 26px 10px 10px;
		font-size: 15px;
		.anticon {
			position: absolute;
			top: 10px;
			right: 5px;
			font-size: 13px;
		}
	}
}
