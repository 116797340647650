@import 'src/utilities/variables.scss';

.deleteDataForm {
	.policy_wrapper {
		display: flex;
		flex-direction: column;
		.policyFormWrapper {
			display: flex;
			flex-direction: column;
			border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
			margin-bottom: 10px;
			.policyFormBlock {
				width: 380px;
			}
		}
		.bat-primary {
			width: 70px;
		}
	}

	.checkbox_wrapper {
		flex-direction: column;
		align-items: baseline;
		.optionWrapper {
			display: flex;
			align-items: center;
			margin-bottom: 15px;
		}
		label {
			margin-right: 15px;
		}
		.dayOption {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-right: 40px;
			.ant-select {
				width: 80px;
				margin-right: 15px;
			}
			p {
				font-size: 15px;
				margin-bottom: 0;
			}
		}
		.weekOption {
			.ant-select {
				width: 150px;
				&:first-child {
					width: 100px;
					margin-right: 15px;
				}
			}
		}
	}

	.ant-form-item {
		margin-bottom: 15px;
	}

	.bucketsWarning {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		font-size: 11px;
		position: relative;
		top: -10px;
		color: #000000;
		margin-bottom: 0;
		img {
			width: 15px;
			margin-right: 2px;
		}
	}
	.preserveWrapper {
		display: flex;
		align-items: center;
		p {
			margin-bottom: 0;
		}
		.ant-select {
			width: 60px;
			margin: 0 8px;
		}
	}
	.inlineFields {
		display: flex;
		justify-content: space-between;
		.ant-form-item {
			&:first-child {
				width: 200px;
			}
		}
	}
}

.policyActions {
	.anticon {
		margin-right: 10px;
	}
}

.policyStatusTable {
	.ant-table-cell:last-child {
		padding: 0;
	}
}

.policyStatusWrapper {
	position: relative;
	color: #033e67;
	border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
	margin-top: 30px;
	.policyRefreshWrapper {
		position: absolute;
		top: 11px;
		right: 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		z-index: 1;
	}

	&_update {
		font-size: 15px;
		display: flex;
		align-items: center;
	}

	.btnRefresh {
		border-radius: 4px;
		margin-left: 5px;
	}

	.action-icon-box {
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		transition: all 3s ease-in;
		cursor: pointer;
		gap: 5px;
		padding: 10px;

		&-abs {
			background-color: white;
			z-index: 999;
			margin-right: 15px;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100px;
			gap: 12px;

			padding: 0;
			position: absolute;
			height: 100%;
			bottom: 0;
			right: 0;
			box-shadow: -5px 1px 3px 0px $box-shadow-light;

			&:hover {
				background-color: white;
				box-shadow: -5px 1px 3px 0px $box-shadow-light;
			}
		}
	}

	.hide {
		visibility: hidden;
	}
	.visible {
		visibility: visible;
	}
}

.tableInlineImgText {
	display: flex;
	align-items: center;
	.anticon,
	img {
		margin-right: 3px;
	}
}

.ocrWrapper {
	.ant-radio-group {
		display: flex;
	}
	.langDropdown {
		width: 180px;
	}
	.ant-radio-wrapper {
		display: flex;
		align-items: center;
	}
	.paddleRadioSection {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 10px;
		width: 353px;
		p {
			margin-bottom: 0;
			small {
				position: relative;
				top: -7px;
			}
		}
	}
}
