@import 'src/utilities/variables.scss';

$filter-label-color: #2d2d2d;

.full_width {
	width: 100%;
	height: 100%;
	min-height: 85vh;

	.events_heading {
		font-size: 25px;
		font-weight: 900;
	}

	.filters-wrapper {
		display: flex;
		column-gap: 12px;
		margin: 12px 0px 15px 0px;

		.date-filter-con {
			width: 35%;
			max-width: 420px;

			.ant-picker {
				padding: 4px 5px 4px;
				input {
					font-size: 13px;
				}
			}

			.events_select_status-pre-box {
				max-width: unset;
				width: 20%;
			}
		}

		.filter-range {
			border: none;
			width: 80%;

			.ant-picker-input > input {
				color: $filter-label-color;
			}

			.ant-picker-active-bar {
				background: $button-color-primary;
			}

			&:focus {
				box-shadow: none;
			}
		}
	}

	.status-info-row-wrapper {
		display: flex;
		gap: 5px;

		.status-item {
			display: flex;
			justify-content: flex-start;
			column-gap: 3px;
			align-items: center;
			font-size: 12px;

			.title {
				color: $button-color-primary;
				font-weight: 600;
				display: flex;
				align-items: center;

				span.anticon {
					margin-top: -3px;
					margin-right: 0px;
					svg {
						width: 23px;
						height: 23px;
					}
				}
			}
			.sub-title {
				font-size: 1em;
				color: $border-color-dark;
			}
		}

		@media (max-width: 1338px) {
			flex-direction: column;
		}
	}
}

//------override---ant-range-pikcers---styles---------

.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
	background: $button-color-primary;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner {
	background: $button-color-primary;
}

.ant-picker-header-view button:hover {
	color: $button-color-primary;
}

//----------------------------------------------

.event-text {
	font-size: 14px;
}
.full_width .filters-wrapper {
	align-items: baseline;
	.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
		height: 30px;
	}
	.ant-select-single .ant-select-selector .ant-select-selection-item {
		line-height: 30px;
	}
	.dateFilter {
		display: flex;
		flex-direction: column;
		width: 100%;
		.date-filter-con {
			width: 100%;
		}
		p {
			margin: 3px 0 0;
			color: #666666;
			.anticon {
				margin-top: 3px;
			}
		}
	}
}

.events_select_status {
	&_container {
		display: flex;
		width: 100%;
		max-width: 350px;
		border-left: 1px solid $border-color-dark;
		border-top: 1px solid $border-color-dark;
		border-bottom: 1px solid $border-color-dark;
		border-right: 1px solid $border-color-dark;
		border-radius: 4px;
	}

	&-select {
		flex: 1;
		color: $filter-label-color;
		border: none;
	}

	&-pre-box {
		flex: 1;
		max-width: 60px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-right: 1px solid $border-color-dark;

		& > span {
			color: $filter-label-color;
		}
	}
}

.events_select_status_container {
	&.searchBar {
		max-width: 450px;
		.events_select_status-pre-box {
			max-width: 142px;
		}
	}
}

//-----disable---filter---row----
.disable-row {
	&-select {
		color: $border-color-dark;
	}

	.events_select_status-pre-box {
		& > span {
			color: $border-color-dark;
		}
	}

	.filter-range {
		.ant-picker-input > input {
			color: $border-color-dark !important;
		}
	}
}

//------end----disabled-----

.forwardStatus {
	margin-right: 5px;
	position: relative;
	top: 2px;
}

.table {
	border-radius: 5px;
	overflow: hidden;
	margin-top: 8px;
}

.ant-table-thead > tr > th {
	background-color: #e0e0e0;
	padding: 10px 8px 9px 8px;
	font-weight: 900;
	color: black;

	&:hover {
		background-color: #cccccc;
	}
}

.ant-table-tbody > tr > td {
	padding: 10px;
	vertical-align: middle;
}

#action-disabled {
	opacity: 0.6;
	cursor: not-allowed;
}

.action-icon-box {
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	transition: all 3s ease-in;
	cursor: pointer;

	&-abs {
		padding: 0;
		position: absolute;
		margin-right: 80px;
		width: 60px;
		height: 100%;
		bottom: 0;
		background-color: white;
		box-shadow: -5px 1px 3px 0px $box-shadow-light;

		&:hover {
			background-color: white;
			box-shadow: -5px 1px 3px 0px $box-shadow-dark;
		}
	}
}

.no-border {
	&:hover,
	&:active,
	&:visited,
	&:focus {
		border-color: transparent;
	}
}

.link-box {
	display: flex;
	align-items: center;
	gap: 5px;
	color: inherit;

	&:hover {
		cursor: pointer;
	}
}

.ant-pagination-item {
	border: none;

	& > a {
		padding: 0px;

		&:hover,
		&:focus,
		&:active,
		.ant-pagination-item-active {
			background-color: #cde5f5;
		}
	}
}

.ant-pagination-item-active {
	background-color: #cde5f5;
}

li.ant-pagination-next,
li.ant-pagination-prev {
	display: flex;
	justify-content: center;
	align-items: center;

	& > a {
		display: inline-flex;
	}
}

.site-drawer-render-in-current-wrapper {
	position: relative;
	height: 200px;
	padding: 48px;
	overflow: hidden;
	text-align: center;
	background: #fafafa;
	border: 1px solid #ebedf0;
	border-radius: 2px;
}

.text-box {
	display: flex;
	width: 95%;
	column-gap: 8px;
	align-items: center;

	.ant-typography {
		min-width: 31%;
	}

	& > span:nth-child(2) {
		font-weight: 100;
	}
}

.keylog-table {
	width: 100%;
}
.text-box.keylog-container {
	flex-direction: column;
	align-items: flex-start;
	gap: 11px;
}

.button-ghost {
	border-radius: 4px;
	border: 1px solid #4e6b8c;
	color: #4e6b8c;

	&:hover,
	&:focus {
		color: #2d5c91;
		border: 1px solid #2d5c91;
	}
}

.button-filled {
	background: #4e6b8c;
	border-radius: 4px;
	border: 1px solid #4e6b8c;

	&:hover,
	&:focus {
		background: #2d5c91;
		border: 1px solid #2d5c91;
	}
}

.img-box {
	margin-top: 30px;
}

.ant-table-wrapper.table > div > div > ul > li.ant-pagination-options > div > div {
	border: none;
	border-radius: 0;
	border-left: 1px solid #cccccc;
	border-right: 1px solid #cccccc;
	color: #121212;
}

.ant-pagination-options::after {
	padding-left: 12px;
	color: #666666;
	content: 'per page';
}
.table-options-wrapper {
	display: flex;
	justify-content: space-between;

	.table-options {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-items: flex-end;
		div {
			flex: 1 1 0;
		}
		.retry-forwarding-header-container {
			padding: 0 10px;

			.ant-btn-link[disabled] {
				opacity: 0.3;
				font-size: 14px;
				padding: 0;
				height: 20px;
			}
			.btn-grp {
				border-right: 1px solid #666666;
				font-size: 14px;
				padding: 0;
				height: 20px;
				&:last-child {
					border: none;
					margin-right: -10px;
				}
			}
		}
	}
}

.total-selected-events {
	color: $button-color-primary;
	font-weight: bold;
	display: flex;
	border-radius: 3px;
	column-gap: 10px;
	margin-bottom: 8px;
}

.ant-table-tbody > tr > td.actionCell {
	padding: 0;
	.action-icon-box {
		padding: 10px;
	}
}

.highlighted_row {
	background-color: #fffced;
}
