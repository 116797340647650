.defaultAccessScope {
	border: 1px solid #ddd;
	padding: 5px;
	background: #fbfbfb;
	margin-top: 5px;
	margin-bottom: 10px;
	border-radius: 4px;
	h4 {
		font-weight: bold;
	}
	.queryList li .queryBlock {
		background: #7c7e80;
		padding: 1px 5px;
	}
	.queryList li .ant-switch-disabled {
		background: #7c7e80 !important;
		opacity: 1;
	}
}
