.viewGraph{
    height: 200px;
    transition: height 1s;
    overflow: hidden;
}
/* .viewGraph .echChart{
    transition: height 1s;
    height: 200px !important;
} */
.collapseGraph{
    height: 0px;
    transition: height 1s;
    overflow: hidden;
}

div[id^="echTooltipPortalMainTooltip__"] {
    transform: translate(1000px, 150px)!important;
}