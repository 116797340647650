.pod_detail {
	flex-grow: 1;
	padding-right: 30px;
	max-width: 100%;
	width: 100%;

	.active-row {
		background-color: #efefef;
		color: #033e67;
		font-weight: bold;
	}

	.coll-title {
		color: #033e67;
		font-weight: bold;
		font-size: 0.8em;
	}

	.ant-collapse-content-box {
		padding: 1px;
	}

	&_data {
		.ant-table-title {
			color: #033e67;
			font-weight: bold;
		}

		table {
			border: 1px solid #f0f0f0;

			thead tr th {
				font-weight: bold;
				padding: 7px 16px;
				div {
					height: 15px;
				}
			}

			tbody tr td {
				padding: 7px 16px;
			}
		}
	}
}

.status_wapper {
	display: flex;
	align-items: center;

	span {
		margin-right: 5px;
	}
}
