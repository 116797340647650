@import 'src/utilities/variables.scss';

.progress-modal {
	position: fixed;
	padding: 10px 20px;
	min-width: 400px;
	background-color: white;
	box-shadow: 2px 7px 10px rgba(0, 0, 0, 0.2);
	border-radius: 3px;
	z-index: 999;
	bottom: 50px;
	right: 100px;
	color: black;
}

.progress-modal-box {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
}

.underline {
	text-decoration: underline;
}

.small-text {
	font-size: 12px;
	font-weight: 800;
}

.delete-modal {
	padding: 7px 10px;
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.delete-modal-heading {
	display: block;
	font-size: 20px;
	margin-bottom: 0px;
}

.delete-modal-date-box {
	flex: 3;
	text-align: end;
	margin-right: 20px;
}
.delete-modal-date {
	font-weight: 900;
	flex: 7;
}
.space-between {
	padding: 0 10px;
}
.space-bottom {
	margin-bottom: 10px;
}

.delete-modal-container {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.delete-modal-btns {
	display: flex;
	justify-content: flex-end;
	gap: 10px;
}

.delete-modal-input {
	font-weight: 900;
	flex: 7;
	padding: 10px 20px;
}

.delete-modal-sub-heading {
	flex: 3;
	text-align: end;
	margin-right: 20px;
}
.delete-modal-content-box {
	display: flex;
	justify-content: center;
	margin-bottom: 10px;
}

.delete-modal-filter {
	background-color: $button-color-primary;
	color: white;
	border-radius: 2px;
	padding: 5px 8px;
	margin: 2px 5px;
	width: 100%;

	&-container {
		background-color: $button-color-primary;
		color: white;
		border-radius: 2px;
		padding: 5px 8px;
		margin: 2px 5px;
		width: 80%;
	}
}

.flex {
	display: flex;
	justify-content: center;
	align-items: center;
}

.warning-flex {
	display: flex;
	justify-content: center;
	gap: 5px;
	margin: 10px 0 20px 0;
	padding: 4px 10px;
	background-color: bisque;

	font-size: 14px;
}

.delete-modal-filter-head-box {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-bottom: 10px;
}
.delete-modal-filter-head {
	flex: 3;
	text-align: end;
	margin-right: 20px;
	align-self: center;
}
.delete-modal-filter-box {
	max-width: 600px;
	flex: 7;
	display: flex;
	justify-content: flex-start;
	gap: 5px;
	flex-wrap: wrap;
}

.delete-modal-filter-value {
	font-weight: 900;
	margin-left: 1px;
}

.delete-modal-filter-key {
	text-transform: capitalize;
	margin-right: 1px;
}
.flex {
	display: flex;
	justify-content: center;
}

.delete-cta-btn {
	padding: 3px 10px;
	border: 1px solid $button-color-primary;
	color: $button-color-primary;
	font-weight: 900;
	cursor: pointer;
}

.delete-modal-content {
	margin-top: 10px;
	display: flex;
	flex-direction: column;
	gap: 20px;
	align-items: stretch;

	&-child-one {
		position: absolute;
		top: 0;
		bottom: 150px;
		overflow-y: scroll;
		overflow-x: hidden;
		width: inherit;
		padding: 20px 30px;
	}

	&-child-two {
		width: 600px;
		position: absolute;
		background-color: white;
		bottom: 5px;
		padding: 10px 30px;
		border-top: 1px solid rgba(0, 0, 0, 0.1);

		&-content {
			display: flex;
			flex-direction: column;
			gap: 5px;
			justify-content: flex-end;
			margin-bottom: 20px;
		}
	}
}
