.ImportFileRow {
    padding-top: 10px;

    & .importFileCol{
        padding-left: 15px;
        padding-right: 15px;
    }

    & h3{
        margin-top: 10px;
        margin-bottom: 15px;
        padding-bottom: 5px;
        border-bottom: 1px solid #CCCCCC;
    }

    & .importFooter {
        margin-top: 15px;
        margin-bottom: 15px;

        & .ant-btn{
            margin-right: 15px;
        }
    }

    & label{
        margin-bottom: 5px;
        display: block;

        &::after{
            content: "*";
            color: red;
            margin-left: 5px;
        }
    }

    & .edit-filters-footer{
        margin-bottom: 15px;
    }

    &.errorMessage, &.successMessage {
        margin-top: 5px;
    }
}