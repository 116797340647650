@import '~antd/dist/antd.css';
@import 'src/utilities/variables.scss';

.ant-layout {
	background: none;
}

.dashboardBg {
	background: $bg-color-primary;
	font-family: $primay-font;
	height: auto;
	min-height: 100%;
}

.height100 {
	height: 100%;
}

.heightVh {
	height: 97vh;
}

.ant-layout-header {
	padding: 0 17px;
	background: none;
	line-height: normal;
	height: auto;

	.ant-row-middle {
		height: 65px;
	}
}

.ant-notification {
	z-index: 1032;
}

.ant-btn {
	font-weight: bold;

	&-primary {
		background-color: $button-color-primary;
		border-color: $button-color-primary;
		color: #ffffff;

		&:hover,
		&:focus {
			background-color: $button-color-primary;
			border-color: $button-color-primary;
			color: #ffffff !important;
		}
	}

	&:hover,
	&:focus {
		color: $button-color-primary;
		border-color: $button-color-primary;
	}
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
	background-color: #d8d8d8;
	border-color: #d9d9d9;
	color: #969696;
}

.drawBoundingBox {
	margin-top: 16px;
}

.ant-btn-dashed.drawBoundingBox {
	background-color: #1890ff;
	color: #ffffff;
	border-color: #1890ff;
	border-style: solid;
}

.export {
	& select {
		width: 160px;
		height: 30px;
		line-height: 32px;
	}

	& .euiComboBox {
		width: 170px;
		height: 30px;
		line-height: 32px;
		display: inline-block;
	}
}

.imageKeyBlock {
	margin-top: 11px;

	& label {
		color: $button-color-primary;
		font-size: 14px;
		font-weight: bold;
		margin-right: 10px;
	}
}

.img-resp {
	width: 100%;
	height: 100%;
}

.links {
	margin: 7px 0;

	& a {
		color: $button-color-primary;
		margin-left: 10px;
	}
}

.color-red {
	color: #ff1f00;
	font-size: 13px;
}

.errorMessage {
	color: #ff1f00;
	font-size: 13px;
}
.successMessage {
	color: #189100;
	font-size: 13px;
}

@keyframes getWidth {
	100% {
		width: 100%;
	}
}

.btn {
	background-color: $button-color-primary;
	margin: 0px 10px;
	height: 28px;
	padding: 3px 9px;
	border-color: $button-color-primary;

	&-primary {
		&:hover {
			background-color: $button-color-primary;
		}
	}
}

.carousel {
	margin: 0 auto;

	& img {
		object-fit: unset;
	}
}

.awssld__content {
	display: block !important;
}

.dialog {
	box-shadow: 0 8px 6px -6px black;
	position: static;
	top: 22%;
	z-index: 10;
}

.expanded-image {
	height: 550px;
	width: 100%;
}

.save-modal {
	width: 400px;

	& p {
		color: #69707d;
		line-height: 1.2;
		margin-bottom: 15px;
	}

	& .title {
		font-size: 13px;
		margin: 5px 0;
		font-weight: 550;
	}
}

.img-wrapper {
	position: relative !important;
	overflow-x: auto !important;
	overflow-y: auto !important;
	max-height: 900px !important;
	&.regexViewWrapper {
		max-height: 800px !important;
	}
}

.transition {
	width: 200px;
	transform: translateX(0);
	transition: transform 0.5s;
	float: left;
	margin-left: 10px;
}

.width-83 {
	width: 83.33%;
	float: left;
}

.width-100 {
	transition: width 0.5s;
	width: 100%;
	float: left;
}

.modal {
	display: none; /* Hidden by default */
	position: fixed; /* Stay in place */
	z-index: 1; /* Sit on top */
	left: 0;
	top: 0;
	width: 42%;
	height: 100%; /* Full height */

	&-overlay {
		background-color: rgb(0, 0, 0); /* Fallback color */
		background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
		padding-top: 100px; /* Location of the box */
		width: 100%;
		z-index: 100;
	}

	&-content {
		background-color: #fefefe;
		margin: auto;
		padding: 12px;
		border: 1px solid #888;
		width: 80%;
	}
}

/* The Close Button */
.close {
	color: #aaaaaa;
	float: right;
	font-size: 28px;
	font-weight: bold;
}

.save-span {
	margin-bottom: 5px;
	font-size: 12px;
}

.euiPopover__panel {
	max-height: 400px;
	overflow: auto;
}

/* width */
::-webkit-scrollbar {
	width: 5px;

	&-track {
		background: #f1f1f1;
	}

	&-thumb {
		background: rgb(161, 161, 161);

		&:hover {
			background: rgb(161, 161, 161);
		}
	}
}

.index-select {
	width: 50%;
	display: inline-block;
}

.circle-picker {
	margin: 10px auto !important;
	justify-content: center;
	background-color: #f3f3f3;
	padding-top: 10px;
	height: 250px;
	overflow-y: auto;
}

.logout-link {
	margin-left: 10px;
	color: #ffffff !important;
}
.rightBlock {
	display: flex;
	justify-content: flex-end;
	.logout-wrapper {
		justify-content: flex-end;
		display: inline-flex;
		align-items: center;
		padding-right: 10px;
		&.disableProfile {
			opacity: 0.5;
			cursor: not-allowed;
		}
	}

	.bucketWrapper {
		padding-right: 20px;
		border-right: 1px solid #ffffff;
		min-height: 25px;
		margin-right: 20px;
		color: #ffffff;
		.ant-select {
			min-width: 200px;
			top: 1px;
		}
		.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
			height: 32px;
		}
		.ant-select-single .ant-select-selector .ant-select-selection-item {
			line-height: 32px;
		}
		.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
			line-height: 32px;
		}
		.ant-select:not(.ant-select-customize-input)
			.ant-select-selector
			.ant-select-selection-search-input {
			height: 32px;
		}
		.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
			color: rgba(0, 0, 0, 0.9);
			background: #eceaea;
		}
		.ant-form-item-label {
			font-weight: bold;
		}
		.bucketInfo {
			position: relative;
			.bucketLabel {
				font-weight: bold;
				display: inline-block;
				margin-right: 10px;
				position: relative;
				top: 7px;
				small {
					display: block;
				}
			}
			.bucketName {
				font-weight: bold;
				margin: 0 15px 0 5px;
				display: inline-block;
			}
			.addBucketBtn {
				margin-left: 5px;
				padding: 4px 7px;
			}
			.bucketList {
				position: relative;
				display: inline-block;
				margin-left: 15px;
			}
			.changeBucketBtn {
				position: absolute;
				bottom: -1px;
				right: 0px;
			}
			.ant-btn.changeBucketBtn:hover,
			.ant-btn.changeBucketBtn:focus {
				color: #000000;
				border-color: #d9d9d9;
			}
		}
		.changeBucketWrapper,
		.configureBucketWrapper {
			display: inline-block;
			padding: 5px 0;
		}

		.ant-btn-circle.ant-btn-icon-only {
			width: 27px;
			height: 27px;
			min-width: 27px;
			line-height: 21px;
		}
		.ant-btn-icon-only {
			vertical-align: -2px;
		}
		.ant-btn-icon-only > * {
			font-size: 17px;
		}
		.footerButtons {
			margin-right: 0;
			.ant-btn {
				&:last-child {
					margin-left: 5px;
				}
			}
			.ant-btn-icon-only > * {
				font-size: 13px;
				font-weight: bold;
			}
		}
		.bucketFormWrapper {
			label {
				color: #ffffff;
			}
		}
	}
}

.bucketModal {
	.ant-modal-header {
		display: none;
	}
	.ant-modal-body {
		padding-top: 10px;
	}
	.ant-modal-content {
		width: 580px;
	}
	.ant-form-item {
		margin-bottom: 5px;
	}
	.ant-form-item-control-input-content .ant-btn {
		margin-right: 15px;
	}
	.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
		height: 32px;
	}
	.ant-select:not(.ant-select-customize-input)
		.ant-select-selector
		.ant-select-selection-search-input {
		height: 32px;
	}
	.ant-select-single .ant-select-selector .ant-select-selection-item,
	.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
		line-height: 32px;
	}
	.ant-form-item-label
		> label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
		content: '';
		margin-right: 0;
	}
	#errorDiv,
	#update_errorDiv {
		margin-bottom: 5px;
	}
	.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
		color: $bg-color-primary;
		font-weight: bold;
	}
	.ant-tabs-tab {
		&:hover {
			color: $bg-color-primary;
		}
		&:focus {
			color: #000000;
		}
	}
	.ant-tabs-ink-bar {
		background: $button-color-primary;
	}
}

.user-icon {
	background: #f2f2f2;
	padding: 7px;
	border-radius: 50%;
}

.settings-icon {
	background: #f2f2f2;
	padding: 7px;
	border-radius: 50%;
}

.loading {
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1;
	flex-direction: column;
}

.ant-spin-dot-item {
	background-color: #00476c !important;
}

.ant-card {
	height: 100%;
	display: flex;
	flex-direction: column;
	margin: 3px 20px;

	&-head {
		min-height: 65px;
		padding: 0 15px;
		box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
		border-bottom: 1px solid $border-color-extra-light;

		&-title {
			overflow: inherit;
			padding: 0 !important;
		}
	}

	&-body {
		justify-content: center;
		align-items: center;
		display: flex;
		flex-direction: column;
		padding: 15px;
	}
}

.no-records .ant-card-body {
	height: 85vh;
}

.no-records-wrapper {
	display: contents;

	& .no-records-head {
		font-size: 22px;
		color: $old-primary-color;
	}

	& .no-records-subhead {
		font-size: 18px;
	}
}

.logo-dashboard {
	max-width: 180px;
}

.gridContent {
	width: 100%;
	margin: 5px;
	padding: 0px;
	border: 1px solid $border-color-extra-light;
	box-sizing: border-box;
	border-radius: 4px;
	box-shadow: -3px 0 5px -5px #333, 5px 0 5px -7px #333;
}
