@import 'src/utilities/variables.scss';

.savedQueriesInput .export {
	padding-left: 10px;
	padding-top: 10px;
}
.export {
	width: 350px;
	margin-bottom: 10px;

	& .load-icon-wrapper {
		padding: 12%;
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: center;
		column-gap: 25px;

		& .loader-icon {
			margin-right: 5%;
			margin-top: 2%;
		}
	}

	h3 {
		font-size: 14px;
		border-bottom: 1px solid #cccccc;
		padding-bottom: 5px;
		font-weight: bold;
	}
}
.imageCountExtra {
	font-size: 13px;
	text-align: right;
	color: #737070;
}

.imgCount {
	float: right;
	color: #070000;
	position: absolute;
	right: 7px;
}
.radioWrapper {
	margin-top: 20px;
}

.exportButton {
	margin-top: 5px;
}

.exportModal {
	& .ant-modal-footer {
		text-align: center;
		padding-bottom: 14px;

		a {
			margin-left: 15px;
		}
	}

	& .ant-modal-body {
		padding: 0;
	}
}

.ant-slider-rail {
	background-color: #d5d4d4;
}
.ant-slider-track {
	background-color: $button-color-primary;
}
.ant-slider:hover .ant-slider-track {
	background-color: $button-color-primary;
}

.ant-slider-handle {
	border-color: $button-color-primary;
	background-color: $button-color-primary;

	& .ant-tooltip-open {
		border-color: $button-color-primary;
	}

	&:focus {
		border-color: $button-color-primary;
	}
}

.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
	border-color: $button-color-primary;
}

.ant-radio-checked .ant-radio-inner {
	border-color: $button-color-primary;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
	border-color: $button-color-primary;
}

.ant-radio-inner::after {
	background-color: $button-color-primary;
}

.download-disable {
	pointer-events: none;
	background-color: #d8d8d8;
	border-color: #d9d9d9;
	color: #969696;
}

.btnSave {
	margin-right: 10px;
}

.export-footer {
	border-top: 1px solid #cccccc;
	margin-top: 10px;
	padding-top: 10px;
}

.export-buttons {
	display: flex;
	justify-content: flex-end;
}

.loading-label {
	font-style: normal;
	font-weight: 300;
	font-size: 22px;
}

.slotWrapper {
	margin-top: 15px;
	label {
		line-height: 32px;
	}
	.ant-select:not(.ant-select-customize-input)
		.ant-select-selector
		.ant-select-selection-search-input,
	.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
		height: 32px;
	}
	.ant-select-single .ant-select-selector .ant-select-selection-item,
	.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
		line-height: 32px;
	}
	.ant-select {
		width: 100%;
	}
}
.danger {
	color: red;
	font-weight: bold;
}
