@import url(//fonts.googleapis.com/css?family=Roboto);
@import 'src/utilities/variables.scss';

body {
	margin: 0;
	/* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
	font-family: $primay-font, 'Roboto', Arial, Helvetica, sans-serif !important;
}

code {
	font-family: $primay-font, source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ant-modal-wrap {
	z-index: 1033 !important;
}

//-----button---styles----------
.ok-btn {
	@extend %button-basic;

	background: $button-color-primary;

	&:hover {
		background: $button-color-primary;
	}

	&:focus {
		background: $button-color-primary;
	}
}

%button-basic {
	font-weight: 300;
	font-family: $primay-font;
	border: 1px solid $button-color-primary;
	border-radius: 4px;

	&:hover {
		color: $button-color-primary;
		border-color: $button-color-primary;
		opacity: 0.8;
	}

	&:focus {
		color: unset;
		border-color: $button-color-primary;
	}
}

.bat-primary {
	@extend %button-basic;

	background: $button-color-primary;

	&:hover {
		background: $button-color-primary;
	}

	&:focus {
		background: $button-color-primary;
	}
}

.bat-sec {
	@extend %button-basic;

	color: $button-color-secondary;

	&:focus {
		color: $button-color-secondary;
	}
}
//-------end--button--styles--------

:root {
	--primary-font: #{$primay-font};
}
