.no-page-found-wrapper {
	padding: 50px !important;
	height: 87vh;

	& .noPageFound {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		height: 100%;

		& .no-page-head {
			font-size: 22px;
			width: 700px;
			text-align: center;
			margin-bottom: 26px;
			margin-top: 30px;
		}
	}

	& .ant-card-body {
		height: 100%;
	}
}

a.ant-typography.btnHome {
	height: 35px;
	padding: 8px 12px;
	font-size: 14px;
	border-radius: 2px;
	background: #4e6b8c;
	text-align: center;
	color: #ffffff;
}

.not-found-header {
	min-height: 4em;
	display: flex;
	align-items: center;

	.ant-row {
		width: 100%;
	}
}

.no-page-icon > svg {
	height: 110px;
	width: 110px;
}
