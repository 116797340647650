@import './kbn-ui-datepicker.css';
@import 'src/utilities/variables.scss';

.search-header {
	font-family: $primay-font;
	& .euiDatePopoverButton {
		font-size: 12px;
	}

	& .euiDatePickerRange__delimeter {
		padding: 8px;
	}
}

.euiFlexItem.euiFlexItem--flexGrowZero {
	button {
		border: 1px solid #e3e6ef;

		background-color: $color-white;
		color: $button-color-primary;

		span {
			svg {
				$size: 17px;

				height: $size;
				width: $size;
				stroke: $button-color-primary;
				stroke-width: 1px;
			}
		}

		&:hover {
			background: $color-white;
			border: 1px solid #e3e6ef;
		}
	}

	& > button {
		border: 1px solid $button-color-primary;

		&:hover,
		&:focus {
			opacity: 0.8;
			border: 1px solid $button-color-primary;
			text-decoration: none;
		}
	}
}

.euiButton--primary {
	color: $button-color-primary;
	border-color: $button-color-primary;
	background-color: $color-white;
}

.euiButton--primary.euiButton--fill {
	background-color: $button-color-primary;
	border-color: $button-color-primary;
	color: $color-white;
}

.euiButton:enabled:hover,
.euiButton:enabled:focus {
	background-color: $color-white;
}

.euiButton--primary.euiButton--fill,
.euiButton--primary.euiButton--fill {
	&:hover {
		background: $button-color-primary;
		border-color: $button-color-primary;
		opacity: 0.8;
	}

	&:focus {
		color: unset;
		border-color: $border-color-light;
	}
}

.euiButtonEmpty--primary {
	color: $button-color-primary;
}

.euiLink.euiLink--primary {
	color: $button-color-primary;
}

.euiButtonIcon--primary {
	color: $button-color-primary;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range {
	background-color: $button-color-primary;
	border: solid 2px $button-color-primary;

	&:hover {
		background-color: $button-color-primary;
	}
}

.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list
	li.react-datepicker__time-list-item--selected {
	background-color: $button-color-primary;

	&:hover {
		color: $color-white;
		background-color: $button-color-primary;
	}
}

.euiSuperDatePicker__prettyFormatLink {
	color: $button-color-primary;
}

.euiSwitch .euiSwitch__body {
	background: $button-color-primary;
}

.react-datepicker__year-option--selected_year.react-datepicker__year-option--preselected,
.react-datepicker__month-option--selected_month.react-datepicker__month-option--preselected {
	background: $button-color-primary;
}

.react-datepicker__day--today {
	color: $button-color-primary;
}
