@-webkit-keyframes euiAnimFadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes euiAnimFadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes euiGrow {
	0% {
		opacity: 0;
	}
	1% {
		opacity: 0;
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	100% {
		opacity: 1;
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@keyframes euiGrow {
	0% {
		opacity: 0;
	}
	1% {
		opacity: 0;
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	100% {
		opacity: 1;
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@-webkit-keyframes focusRingAnimate {
	0% {
		-webkit-box-shadow: 0 0 0 6px rgba(0, 107, 180, 0);
		box-shadow: 0 0 0 6px rgba(0, 107, 180, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 3px rgba(0, 107, 180, 0.3);
		box-shadow: 0 0 0 3px rgba(0, 107, 180, 0.3);
	}
}

@keyframes focusRingAnimate {
	0% {
		-webkit-box-shadow: 0 0 0 6px rgba(0, 107, 180, 0);
		box-shadow: 0 0 0 6px rgba(0, 107, 180, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 3px rgba(0, 107, 180, 0.3);
		box-shadow: 0 0 0 3px rgba(0, 107, 180, 0.3);
	}
}

@-webkit-keyframes focusRingAnimateLarge {
	0% {
		-webkit-box-shadow: 0 0 0 10px rgba(0, 107, 180, 0);
		box-shadow: 0 0 0 10px rgba(0, 107, 180, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 4px rgba(0, 107, 180, 0.3);
		box-shadow: 0 0 0 4px rgba(0, 107, 180, 0.3);
	}
}

@keyframes focusRingAnimateLarge {
	0% {
		-webkit-box-shadow: 0 0 0 10px rgba(0, 107, 180, 0);
		box-shadow: 0 0 0 10px rgba(0, 107, 180, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 4px rgba(0, 107, 180, 0.3);
		box-shadow: 0 0 0 4px rgba(0, 107, 180, 0.3);
	}
}

@-webkit-keyframes euiButtonActive {
	50% {
		-webkit-transform: translateY(1px);
		transform: translateY(1px);
	}
}

@keyframes euiButtonActive {
	50% {
		-webkit-transform: translateY(1px);
		transform: translateY(1px);
	}
}

.eui-alignBaseline {
	vertical-align: baseline !important;
}

.eui-alignBottom {
	vertical-align: bottom !important;
}

.eui-alignMiddle {
	vertical-align: middle !important;
}

.eui-alignTop {
	vertical-align: top !important;
}

.eui-displayBlock {
	display: block !important;
}

.eui-displayInline {
	display: inline !important;
}

.eui-displayInlineBlock {
	display: inline-block !important;
}

.eui-fullWidth {
	display: block !important;
	width: 100% !important;
}

.eui-textCenter {
	text-align: center !important;
}

.eui-textLeft {
	text-align: left !important;
}

.eui-textRight {
	text-align: right !important;
}

.eui-textNoWrap {
	white-space: nowrap !important;
}

.eui-textInheritColor {
	color: inherit !important;
}

.eui-textBreakWord {
	overflow-wrap: break-word !important;
	word-wrap: break-word !important;
	word-break: break-word;
}

.eui-textBreakAll {
	overflow-wrap: break-word !important;
	word-break: break-all !important;
}

.eui-textBreakNormal {
	overflow-wrap: normal !important;
	word-wrap: normal !important;
	word-break: normal !important;
}

.eui-textOverflowWrap {
	overflow-wrap: break-word !important;
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
	.eui-textOverflowWrap {
		word-break: break-all !important;
	}
}

.eui-textTruncate {
	max-width: 100%;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
	white-space: nowrap !important;
	word-wrap: normal !important;
}

/**
   *  Responsive
   *
   *  1. Be sure to hide the element initially
   */
[class*='eui-showFor'] {
	display: none !important;
	/* 1 */
}

@media only screen and (max-width: 574px) {
	.eui-hideFor--xs {
		display: none !important;
	}
}

@media only screen and (max-width: 574px) {
	.eui-showFor--xs {
		display: inline !important;
	}
}

@media only screen and (max-width: 574px) {
	.eui-showFor--xs--block {
		display: block !important;
	}
}

@media only screen and (max-width: 574px) {
	.eui-showFor--xs--inlineBlock {
		display: inline-block !important;
	}
}

@media only screen and (max-width: 574px) {
	.eui-showFor--xs--flex {
		display: -webkit-box !important;
		display: -ms-flexbox !important;
		display: flex !important;
	}
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
	.eui-hideFor--s {
		display: none !important;
	}
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
	.eui-showFor--s {
		display: inline !important;
	}
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
	.eui-showFor--s--block {
		display: block !important;
	}
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
	.eui-showFor--s--inlineBlock {
		display: inline-block !important;
	}
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
	.eui-showFor--s--flex {
		display: -webkit-box !important;
		display: -ms-flexbox !important;
		display: flex !important;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.eui-hideFor--m {
		display: none !important;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.eui-showFor--m {
		display: inline !important;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.eui-showFor--m--block {
		display: block !important;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.eui-showFor--m--inlineBlock {
		display: inline-block !important;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.eui-showFor--m--flex {
		display: -webkit-box !important;
		display: -ms-flexbox !important;
		display: flex !important;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.eui-hideFor--l {
		display: none !important;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.eui-showFor--l {
		display: inline !important;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.eui-showFor--l--block {
		display: block !important;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.eui-showFor--l--inlineBlock {
		display: inline-block !important;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.eui-showFor--l--flex {
		display: -webkit-box !important;
		display: -ms-flexbox !important;
		display: flex !important;
	}
}

@media only screen and (min-width: 1200px) {
	.eui-hideFor--xl {
		display: none !important;
	}
}

@media only screen and (min-width: 1200px) {
	.eui-showFor--xl {
		display: inline !important;
	}
}

@media only screen and (min-width: 1200px) {
	.eui-showFor--xl--block {
		display: block !important;
	}
}

@media only screen and (min-width: 1200px) {
	.eui-showFor--xl--inlineBlock {
		display: inline-block !important;
	}
}

@media only screen and (min-width: 1200px) {
	.eui-showFor--xl--flex {
		display: -webkit-box !important;
		display: -ms-flexbox !important;
		display: flex !important;
	}
}

/**
    * IE doesn't properly wrap groups if it is within a flex-item of a flex-group.
    * Adding the following styles to the flex-item that contains the wrapping group, will fix IE.
    * https://github.com/philipwalton/flexbugs/issues/104
    */
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
	.euiIEFlexWrapFix {
		-webkit-box-flex: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		-ms-flex-negative: 1;
		flex-shrink: 1;
		-ms-flex-preferred-size: 0%;
		flex-basis: 0%;
	}
}

/**
    * Overflow scrolling
    */
.eui-yScroll {
	scrollbar-width: thin;
	height: 100%;
	overflow-y: auto;
}
.eui-yScroll::-webkit-scrollbar {
	width: 16px;
	height: 16px;
}
.eui-yScroll::-webkit-scrollbar-thumb {
	background-color: rgba(105, 112, 125, 0.5);
	border: 6px solid transparent;
	background-clip: content-box;
}
.eui-yScroll::-webkit-scrollbar-corner,
.eui-yScroll::-webkit-scrollbar-track {
	background-color: transparent;
}

.eui-xScroll {
	scrollbar-width: thin;
	overflow-x: auto;
}
.eui-xScroll::-webkit-scrollbar {
	width: 16px;
	height: 16px;
}
.eui-xScroll::-webkit-scrollbar-thumb {
	background-color: rgba(105, 112, 125, 0.5);
	border: 6px solid transparent;
	background-clip: content-box;
}
.eui-xScroll::-webkit-scrollbar-corner,
.eui-xScroll::-webkit-scrollbar-track {
	background-color: transparent;
}

/**
    * Overflow scrolling with shadows
    */
.eui-yScrollWithShadows {
	scrollbar-width: thin;
	height: 100%;
	overflow-y: auto;
	-webkit-mask-image: linear-gradient(
		to bottom,
		rgba(255, 0, 0, 0.1) 0%,
		red 7.5px,
		red calc(100% - 7.5px),
		rgba(255, 0, 0, 0.1) 100%
	);
	mask-image: linear-gradient(
		to bottom,
		rgba(255, 0, 0, 0.1) 0%,
		red 7.5px,
		red calc(100% - 7.5px),
		rgba(255, 0, 0, 0.1) 100%
	);
}
.eui-yScrollWithShadows::-webkit-scrollbar {
	width: 16px;
	height: 16px;
}
.eui-yScrollWithShadows::-webkit-scrollbar-thumb {
	background-color: rgba(105, 112, 125, 0.5);
	border: 6px solid transparent;
	background-clip: content-box;
}
.eui-yScrollWithShadows::-webkit-scrollbar-corner,
.eui-yScrollWithShadows::-webkit-scrollbar-track {
	background-color: transparent;
}

.eui-xScrollWithShadows {
	scrollbar-width: thin;
	overflow-x: auto;
	-webkit-mask-image: linear-gradient(
		to right,
		rgba(255, 0, 0, 0.1) 0%,
		red 7.5px,
		red calc(100% - 7.5px),
		rgba(255, 0, 0, 0.1) 100%
	);
	mask-image: linear-gradient(
		to right,
		rgba(255, 0, 0, 0.1) 0%,
		red 7.5px,
		red calc(100% - 7.5px),
		rgba(255, 0, 0, 0.1) 100%
	);
}
.eui-xScrollWithShadows::-webkit-scrollbar {
	width: 16px;
	height: 16px;
}
.eui-xScrollWithShadows::-webkit-scrollbar-thumb {
	background-color: rgba(105, 112, 125, 0.5);
	border: 6px solid transparent;
	background-clip: content-box;
}
.eui-xScrollWithShadows::-webkit-scrollbar-corner,
.eui-xScrollWithShadows::-webkit-scrollbar-track {
	background-color: transparent;
}

.euiYScrollWithShadows {
	scrollbar-width: thin;
	height: 100%;
	overflow-y: auto;
	-webkit-mask-image: linear-gradient(
		to bottom,
		rgba(255, 0, 0, 0.1) 0%,
		red 7.5px,
		red calc(100% - 7.5px),
		rgba(255, 0, 0, 0.1) 100%
	);
	mask-image: linear-gradient(
		to bottom,
		rgba(255, 0, 0, 0.1) 0%,
		red 7.5px,
		red calc(100% - 7.5px),
		rgba(255, 0, 0, 0.1) 100%
	);
}
.euiYScrollWithShadows::-webkit-scrollbar {
	width: 16px;
	height: 16px;
}
.euiYScrollWithShadows::-webkit-scrollbar-thumb {
	background-color: rgba(105, 112, 125, 0.5);
	border: 6px solid transparent;
	background-clip: content-box;
}
.euiYScrollWithShadows::-webkit-scrollbar-corner,
.euiYScrollWithShadows::-webkit-scrollbar-track {
	background-color: transparent;
}

/**
    * Forcing focus ring on non-EUI elements
    */
.eui-isFocusable:focus {
	-webkit-animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards
		focusRingAnimateLarge !important;
	animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimateLarge !important;
}

/**
   * Adapted from Eric Meyer's reset (http://meyerweb.com/eric/tools/css/reset/, v2.0 | 20110126).
   *
   */
*,
*:before,
*:after {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: none;
	vertical-align: baseline;
}

code,
pre,
kbd,
samp {
	font-family: var(--primary-font), 'Roboto Mono', Consolas, Menlo, Courier, monospace;
}

input,
textarea,
select,
button {
	font-family: var(--primary-font), 'Inter UI', -apple-system, BlinkMacSystemFont, 'Segoe UI',
		Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

em {
	font-style: italic;
}

strong {
	font-weight: 700;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

html {
	font-family: var(--primary-font), 'Inter UI', -apple-system, BlinkMacSystemFont, 'Segoe UI',
		Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
	font-weight: 400;
	letter-spacing: -0.005em;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-webkit-font-kerning: normal;
	font-kerning: normal;
	font-size: 16px;
	color: #343741;
	height: 100%;
	background-color: #fafbfd;
	scrollbar-width: thin;
	scrollbar-color: rgba(105, 112, 125, 0.5) transparent;
}

body {
	line-height: 1;
}

*:focus {
	outline: none;
}
*:focus::-moz-focus-inner {
	border: none;
}
*:focus:-moz-focusring {
	outline: none;
}

a {
	text-decoration: none;
	color: #ef5742;
}
a:hover {
	text-decoration: none;
}
a:focus {
	text-decoration: none;
	outline: none;
}

a:hover,
button,
[role='button'] {
	cursor: pointer;
}

input {
	margin: 0;
	padding: 0;
}

button {
	background: none;
	border: none;
	padding: 0;
	margin: 0;
	outline: none;
	font-size: inherit;
	color: inherit;
	border-radius: 0;
}
button:hover {
	cursor: pointer;
}

ol,
ul {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

hr {
	margin: 0;
}

fieldset {
	min-inline-size: auto;
}

svg text {
	letter-spacing: normal !important;
}

.euiScreenReaderOnly,
.euiScreenReaderOnly--showOnFocus:not(:focus) {
	position: absolute;
	left: -10000px;
	top: auto;
	width: 1px;
	height: 1px;
	overflow: hidden;
}

.euiSkipLink {
	-webkit-transition: none !important;
	transition: none !important;
}
.euiSkipLink:focus {
	-webkit-animation: none !important;
	animation: none !important;
}
.euiSkipLink.euiSkipLink--absolute:focus {
	position: absolute;
}
.euiSkipLink.euiSkipLink--fixed:focus {
	position: fixed;
	top: 4px;
	left: 4px;
	z-index: 1001;
}

.euiAccordion__triggerWrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.euiAccordion__button {
	font-size: 16px;
	font-size: 1rem;
	line-height: 1.5;
	text-align: left;
	width: 100%;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.euiAccordion__button:hover,
.euiAccordion__button:focus {
	text-decoration: underline;
	cursor: pointer;
}
.euiAccordion__button:focus .euiAccordion__iconWrapper {
	-webkit-animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
	animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
	color: #ef5742;
}

.euiAccordion__buttonReverse {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: reverse;
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
.euiAccordion__buttonReverse .euiAccordion__iconWrapper {
	margin-left: 8px;
	margin-right: 4px;
}

.euiAccordion__iconWrapper {
	width: 16px;
	height: 16px;
	border-radius: 4px;
	margin-right: 8px;
	margin-left: 4px;
	-ms-flex-negative: 0;
	flex-shrink: 0;
}
.euiAccordion__iconWrapper .euiAccordion__icon {
	vertical-align: top;
	-webkit-transition: -webkit-transform 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
	transition: -webkit-transform 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
	transition: transform 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
	transition: transform 250ms cubic-bezier(0.694, 0.0482, 0.335, 1),
		-webkit-transform 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
}
.euiAccordion__iconWrapper .euiAccordion__icon-isOpen {
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg);
}

.euiAccordion__optionalAction {
	-ms-flex-negative: 0;
	flex-shrink: 0;
}

.euiAccordion__childWrapper {
	visibility: hidden;
	height: 0;
	opacity: 0;
	overflow: hidden;
	-webkit-transform: translatez(0);
	transform: translatez(0);
	-webkit-transition: height 250ms cubic-bezier(0.694, 0.0482, 0.335, 1),
		opacity 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
	transition: height 250ms cubic-bezier(0.694, 0.0482, 0.335, 1),
		opacity 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
}

.euiAccordion__padding--xs {
	padding: 4px;
}

.euiAccordion__padding--s {
	padding: 8px;
}

.euiAccordion__padding--m {
	padding: 16px;
}

.euiAccordion__padding--l {
	padding: 24px;
}

.euiAccordion__padding--xl {
	padding: 32px;
}

.euiAccordion.euiAccordion-isOpen .euiAccordion__childWrapper {
	visibility: visible;
	opacity: 1;
	height: auto;
}

.euiAccordionForm__extraAction {
	opacity: 0;
	-webkit-transition: opacity 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
	transition: opacity 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
}
.euiAccordionForm__extraAction:focus {
	opacity: 1;
}

.euiAccordionForm__title {
	display: inline-block;
}

.euiAccordionForm__button {
	padding: 16px 16px 16px 0;
}
.euiAccordionForm__button:hover {
	text-decoration: none;
}
.euiAccordionForm__button:hover .euiAccordionForm__title {
	text-decoration: underline;
}

.euiAccordionForm {
	border-top: 1px solid #d3dae6;
	border-bottom: 1px solid #d3dae6;
}
.euiAccordionForm + .euiAccordionForm {
	border-top: none;
}
.euiAccordionForm:hover .euiAccordionForm__extraAction {
	opacity: 1;
	visibility: visible;
}

.euiAspectRatio {
	position: relative;
}
.euiAspectRatio > * {
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
	width: 100% !important;
	height: 100% !important;
}

.euiAvatar {
	-ms-flex-negative: 0;
	flex-shrink: 0;
	display: inline-block;
	background-size: cover;
	text-align: center;
	vertical-align: middle;
	overflow-x: hidden;
	font-weight: 500;
}

.euiAvatar--user {
	position: relative;
	border-radius: 50%;
}
.euiAvatar--user:after {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 50%;
	content: '';
	pointer-events: none;
	border: 1px solid rgba(52, 55, 65, 0.05);
}

.euiAvatar--space {
	position: relative;
	border-radius: 4px;
}
.euiAvatar--space:after {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 4px;
	content: '';
	pointer-events: none;
	border: 1px solid rgba(52, 55, 65, 0.05);
}

.euiAvatar--s {
	width: 24px;
	height: 24px;
	line-height: 24px;
	font-size: 12px;
}

.euiAvatar--m {
	width: 32px;
	height: 32px;
	line-height: 32px;
	font-size: 14.4px;
}

.euiAvatar--l {
	width: 40px;
	height: 40px;
	line-height: 40px;
	font-size: 19.2px;
}

.euiAvatar--xl {
	width: 64px;
	height: 64px;
	line-height: 64px;
	font-size: 25.6px;
}

/**
   * 1. Accounts for the border
   */
.euiBadge {
	font-size: 12px;
	font-weight: 500;
	line-height: 18px;
	/* 1 */
	padding: 0 8px;
	display: inline-block;
	text-decoration: none;
	border-radius: 2px;
	border: solid 1px transparent;
	background-color: transparent;
	white-space: nowrap;
	vertical-align: middle;
	cursor: default;
	max-width: 100%;
	text-align: left;
}
.euiBadge.euiBadge-isDisabled {
	color: #88888b !important;
	background-color: #c2c3c6 !important;
}
.euiBadge:focus-within {
	-webkit-animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
	animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
}
.euiBadge + .euiBadge {
	margin-left: 4px;
}
.euiBadge .euiBadge__content {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	overflow: hidden;
}
.euiBadge .euiBadge__childButton {
	max-width: 100%;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
	white-space: nowrap !important;
	word-wrap: normal !important;
	-webkit-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	text-align: inherit;
	font-weight: inherit;
	line-height: inherit;
	color: inherit;
}
.euiBadge .euiBadge__childButton:disabled {
	cursor: not-allowed;
}
.euiBadge .euiBadge__childButton:not(:disabled):hover,
.euiBadge .euiBadge__childButton:not(:disabled):focus {
	text-decoration: underline;
}
.euiBadge .euiBadge__iconButton {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	font-size: 0;
	margin-left: 4px;
}
.euiBadge .euiBadge__iconButton:focus {
	background-color: rgba(255, 255, 255, 0.8);
	color: #000;
	border-radius: 2px;
}
.euiBadge .euiBadge__iconButton:disabled {
	cursor: not-allowed;
}
.euiBadge .euiBadge__iconButton .euiBadge__icon {
	margin: 0 !important;
}
.euiBadge .euiBadge__text {
	max-width: 100%;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
	white-space: nowrap !important;
	word-wrap: normal !important;
	-webkit-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	cursor: default;
}
.euiBadge .euiBadge__icon {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	margin-left: 4px;
}
.euiBadge.euiBadge--iconLeft .euiBadge__content {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: reverse;
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse;
}
.euiBadge.euiBadge--iconLeft .euiBadge__content .euiBadge__iconButton,
.euiBadge.euiBadge--iconLeft .euiBadge__content .euiBadge__icon {
	margin-right: 4px;
	margin-left: 0;
}

.euiBadge-isClickable:not(:disabled):hover,
.euiBadge-isClickable:not(:disabled):focus {
	text-decoration: underline;
}

.euiBadge-isClickable.euiBadge-isDisabled {
	cursor: not-allowed;
}

.euiBadge-isClickable:focus {
	-webkit-animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
	animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
}

.euiBadge-isClickable .euiBadge__text {
	cursor: inherit;
}

.euiBadge--hollow {
	background-color: #fff;
	border-color: #d3dae6;
	color: #343741;
}

.euiBadgeGroup__item {
	display: inline-block;
	max-width: 100%;
}

.euiBadgeGroup--gutterExtraSmall {
	margin: -2px;
}
.euiBadgeGroup--gutterExtraSmall > .euiBadgeGroup__item {
	margin: 2px;
	max-width: calc(100% - 4px);
}

.euiBadgeGroup--gutterSmall {
	margin: -4px;
}
.euiBadgeGroup--gutterSmall > .euiBadgeGroup__item {
	margin: 4px;
	max-width: calc(100% - 8px);
}

.euiBetaBadge {
	display: inline-block;
	padding: 0 16px;
	border-radius: 24px;
	-webkit-box-shadow: inset 0 0 0 1px #d3dae6;
	box-shadow: inset 0 0 0 1px #d3dae6;
	vertical-align: super;
	font-size: 12px;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: 0.05em;
	line-height: 24px;
	text-align: center;
	white-space: nowrap;
	cursor: default;
}
.euiBetaBadge:focus {
	-webkit-animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
	animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
}

.euiBetaBadge--iconOnly {
	padding: 0;
	width: 24px;
}
.euiBetaBadge--iconOnly .euiBetaBadge__icon {
	position: relative;
	margin-top: -1px;
}

.euiNotificationBadge {
	-ms-flex-negative: 0;
	flex-shrink: 0;
	display: inline-block;
	border-radius: 4px;
	font-size: 12px;
	font-weight: 500;
	line-height: 16px;
	height: 16px;
	min-width: 16px;
	padding-left: 4px;
	padding-right: 4px;
	vertical-align: middle;
	text-align: center;
	-webkit-transition: all 150ms ease-in;
	transition: all 150ms ease-in;
	cursor: default;
	background: #dd0a73;
	color: #fff;
}

.euiNotificationBadge--medium {
	line-height: 20px;
	height: 20px;
	min-width: 24px;
}

.euiNotificationBadge--subdued {
	background: #e0e5ee;
	color: #000;
}

.euiBasicTable-loading {
	position: relative;
}
.euiBasicTable-loading tbody {
	overflow: hidden;
}
.euiBasicTable-loading tbody:before {
	position: absolute;
	content: '';
	width: 100%;
	height: 2px;
	background-color: #ef5742;
	-webkit-animation: euiBasicTableLoading 1000ms linear;
	animation: euiBasicTableLoading 1000ms linear;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}

@-webkit-keyframes euiBasicTableLoading {
	from {
		left: 0;
		width: 0;
	}
	20% {
		left: 0;
		width: 40%;
	}
	80% {
		left: 60%;
		width: 40%;
	}
	100% {
		left: 100%;
		width: 0;
	}
}

@keyframes euiBasicTableLoading {
	from {
		left: 0;
		width: 0;
	}
	20% {
		left: 0;
		width: 40%;
	}
	80% {
		left: 60%;
		width: 40%;
	}
	100% {
		left: 100%;
		width: 0;
	}
}

.euiBottomBar {
	-webkit-box-shadow: 0 0 12px -1px rgba(65, 78, 101, 0.2), 0 0 4px -1px rgba(65, 78, 101, 0.2),
		0 0 2px 0 rgba(65, 78, 101, 0.2);
	box-shadow: 0 0 12px -1px rgba(65, 78, 101, 0.2), 0 0 4px -1px rgba(65, 78, 101, 0.2),
		0 0 2px 0 rgba(65, 78, 101, 0.2);
	background: #404040;
	color: #fff;
	position: fixed;
	bottom: 0;
	right: 0;
	left: 0;
	-webkit-animation: euiBottomBarAppear 350ms cubic-bezier(0.694, 0.0482, 0.335, 1);
	animation: euiBottomBarAppear 350ms cubic-bezier(0.694, 0.0482, 0.335, 1);
	z-index: 4000;
}
.euiBottomBar.euiBottomBar--paddingSmall {
	padding: 8px;
}
.euiBottomBar.euiBottomBar--paddingMedium {
	padding: 16px;
}
.euiBottomBar.euiBottomBar--paddingLarge {
	padding: 24px;
}

@-webkit-keyframes euiBottomBarAppear {
	0% {
		-webkit-transform: translateY(100%);
		transform: translateY(100%);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0%);
		transform: translateY(0%);
		opacity: 1;
	}
}

@keyframes euiBottomBarAppear {
	0% {
		-webkit-transform: translateY(100%);
		transform: translateY(100%);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0%);
		transform: translateY(0%);
		opacity: 1;
	}
}

.euiButton {
	display: inline-block;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	cursor: pointer;
	height: 40px;
	text-align: center;
	white-space: nowrap;
	max-width: 100%;
	vertical-align: middle;
	font-family: var(--primary-font), 'Inter UI', -apple-system, BlinkMacSystemFont, 'Segoe UI',
		Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
	font-weight: 400;
	letter-spacing: -0.005em;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-webkit-font-kerning: normal;
	font-kerning: normal;
	font-size: 16px;
	font-size: 1rem;
	line-height: 1.5;
	text-decoration: none;
	border: solid 1px transparent;
	-webkit-transition: all 250ms ease-in-out;
	transition: all 250ms ease-in-out;
	-webkit-box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
	box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
	border-radius: 4px;
	min-width: 112px;
}
.euiButton:hover:not(:disabled) {
	-webkit-transform: translateY(-1px);
	transform: translateY(-1px);
}
.euiButton:focus {
	-webkit-animation: euiButtonActive 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
	animation: euiButtonActive 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
}
.euiButton:active:not(:disabled) {
	-webkit-transform: translateY(1px);
	transform: translateY(1px);
}
.euiButton:hover:not(:disabled),
.euiButton:focus {
	text-decoration: underline;
}
.euiButton .euiButton__content {
	height: 100%;
	width: 100%;
	vertical-align: middle;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 0 12px;
}
.euiButton .euiButton__content .euiButton__icon,
.euiButton .euiButton__content .euiButton__spinner {
	-ms-flex-negative: 0;
	flex-shrink: 0;
}
.euiButton .euiButton__content > * + * {
	margin-left: 8px;
}
.euiButton .euiButton__text {
	text-overflow: ellipsis;
	overflow: hidden;
}
.euiButton.euiButton--small {
	height: 32px;
	line-height: 32px;
}
.euiButton.euiButton--iconRight .euiButton__content {
	height: 100%;
	width: 100%;
	vertical-align: middle;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: reverse;
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse;
}
.euiButton.euiButton--iconRight .euiButton__content .euiButton__icon,
.euiButton.euiButton--iconRight .euiButton__content .euiButton__spinner {
	-ms-flex-negative: 0;
	flex-shrink: 0;
}
.euiButton.euiButton--iconRight .euiButton__content > * + * {
	margin-left: 0;
	margin-right: 8px;
}
.euiButton:hover,
.euiButton:active {
	-webkit-box-shadow: 0 4px 8px 0 rgba(152, 162, 179, 0.15), 0 2px 2px -1px rgba(152, 162, 179, 0.3);
	box-shadow: 0 4px 8px 0 rgba(152, 162, 179, 0.15), 0 2px 2px -1px rgba(152, 162, 179, 0.3);
}

.euiButton:disabled {
	color: #afb0b3;
	border-color: #c2c3c6;
	pointer-events: none;
}
.euiButton:disabled .euiButton__content {
	pointer-events: auto;
	cursor: not-allowed;
}
.euiButton:disabled .euiButton__spinner {
	border-color: #ef5742 currentColor currentColor currentColor;
}
.euiButton:disabled.euiButton--fill {
	color: #88888b;
	background-color: #c2c3c6;
	border-color: #c2c3c6;
}
.euiButton:disabled.euiButton--fill:hover,
.euiButton:disabled.euiButton--fill:focus {
	background-color: #c2c3c6;
	border-color: #c2c3c6;
}
.euiButton:disabled:hover,
.euiButton:disabled:focus {
	-webkit-box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
	box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
	text-decoration: none;
}

.euiButton--primary {
	color: #ef5742;
	border-color: #ef5742;
}
.euiButton--primary.euiButton--fill {
	background-color: #ef5742;
	border-color: #ef5742;
	color: #fff;
}

.euiButton--primary:enabled {
	-webkit-box-shadow: 0 2px 2px -1px rgba(54, 97, 126, 0.3);
	box-shadow: 0 2px 2px -1px rgba(54, 97, 126, 0.3);
}
.euiButton--primary:enabled:hover,
.euiButton--primary:enabled:focus {
	-webkit-box-shadow: 0 4px 8px 0 rgba(54, 97, 126, 0.15), 0 2px 2px -1px rgba(54, 97, 126, 0.3);
	box-shadow: 0 4px 8px 0 rgba(54, 97, 126, 0.15), 0 2px 2px -1px rgba(54, 97, 126, 0.3);
	background-color: rgba(0, 107, 180, 0.1);
}

.euiButton--secondary {
	color: #017d73;
	border-color: #017d73;
}
.euiButton--secondary.euiButton--fill {
	background-color: #017d73;
	border-color: #017d73;
	color: #fff;
}

.euiButton--secondary:enabled {
	-webkit-box-shadow: 0 2px 2px -1px rgba(39, 87, 83, 0.3);
	box-shadow: 0 2px 2px -1px rgba(39, 87, 83, 0.3);
}
.euiButton--secondary:enabled:hover,
.euiButton--secondary:enabled:focus {
	-webkit-box-shadow: 0 4px 8px 0 rgba(39, 87, 83, 0.15), 0 2px 2px -1px rgba(39, 87, 83, 0.3);
	box-shadow: 0 4px 8px 0 rgba(39, 87, 83, 0.15), 0 2px 2px -1px rgba(39, 87, 83, 0.3);
	background-color: rgba(1, 125, 115, 0.1);
}

.euiButton--warning {
	color: #9b6900;
	border-color: #f5a700;
}
.euiButton--warning.euiButton--fill {
	background-color: #f5a700;
	border-color: #f5a700;
	color: #000;
}
.euiButton--warning.euiButton--fill:enabled:hover,
.euiButton--warning.euiButton--fill:enabled:focus {
	background-color: #dc9600;
	border-color: #dc9600;
}
.euiButton--warning:enabled {
	-webkit-box-shadow: 0 2px 2px -1px rgba(172, 140, 74, 0.3);
	box-shadow: 0 2px 2px -1px rgba(172, 140, 74, 0.3);
}
.euiButton--warning:enabled:hover,
.euiButton--warning:enabled:focus {
	-webkit-box-shadow: 0 4px 8px 0 rgba(172, 140, 74, 0.15), 0 2px 2px -1px rgba(172, 140, 74, 0.3);
	box-shadow: 0 4px 8px 0 rgba(172, 140, 74, 0.15), 0 2px 2px -1px rgba(172, 140, 74, 0.3);
	background-color: rgba(245, 167, 0, 0.1);
}

.euiButton--danger {
	color: #bd271e;
	border-color: #bd271e;
}
.euiButton--danger.euiButton--fill {
	background-color: #bd271e;
	border-color: #bd271e;
	color: #fff;
}
.euiButton--danger.euiButton--fill:enabled:hover,
.euiButton--danger.euiButton--fill:enabled:focus {
	background-color: #a7221b;
	border-color: #a7221b;
}
.euiButton--danger:enabled {
	-webkit-box-shadow: 0 2px 2px -1px rgba(123, 97, 96, 0.3);
	box-shadow: 0 2px 2px -1px rgba(123, 97, 96, 0.3);
}
.euiButton--danger:enabled:hover,
.euiButton--danger:enabled:focus {
	-webkit-box-shadow: 0 4px 8px 0 rgba(123, 97, 96, 0.15), 0 2px 2px -1px rgba(123, 97, 96, 0.3);
	box-shadow: 0 4px 8px 0 rgba(123, 97, 96, 0.15), 0 2px 2px -1px rgba(123, 97, 96, 0.3);
	background-color: rgba(189, 39, 30, 0.1);
}

.euiButton--ghost {
	color: #fff;
	border-color: #fff;
}
.euiButton--ghost.euiButton--fill {
	background-color: #fff;
	border-color: #fff;
	color: #000;
}
.euiButton--ghost.euiButton--fill:enabled:hover,
.euiButton--ghost.euiButton--fill:enabled:focus {
	background-color: #f2f2f2;
	border-color: #f2f2f2;
}
.euiButton--ghost:enabled {
	-webkit-box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.3);
	box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.3);
}
.euiButton--ghost:enabled:hover,
.euiButton--ghost:enabled:focus {
	-webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15), 0 2px 2px -1px rgba(0, 0, 0, 0.3);
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15), 0 2px 2px -1px rgba(0, 0, 0, 0.3);
	background-color: rgba(255, 255, 255, 0.1);
}

.euiButton--text {
	color: #343741;
	border-color: #69707d;
}
.euiButton--text.euiButton--fill {
	background-color: #69707d;
	border-color: #69707d;
	color: #fff;
}
.euiButton--text.euiButton--fill:enabled:hover,
.euiButton--text.euiButton--fill:enabled:focus {
	background-color: #5d646f;
	border-color: #5d646f;
}
.euiButton--text:enabled {
	-webkit-box-shadow: 0 2px 2px -1px rgba(115, 115, 115, 0.3);
	box-shadow: 0 2px 2px -1px rgba(115, 115, 115, 0.3);
}
.euiButton--text:enabled:hover,
.euiButton--text:enabled:focus {
	-webkit-box-shadow: 0 4px 8px 0 rgba(115, 115, 115, 0.15), 0 2px 2px -1px rgba(115, 115, 115, 0.3);
	box-shadow: 0 4px 8px 0 rgba(115, 115, 115, 0.15), 0 2px 2px -1px rgba(115, 115, 115, 0.3);
	background-color: rgba(105, 112, 125, 0.1);
}

.euiButton:disabled.euiButton--ghost,
.euiButton:disabled.euiButton--ghost:hover,
.euiButton:disabled.euiButton--ghost:focus {
	-webkit-box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.3);
	box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.3);
	color: #69707d;
	border-color: #69707d;
}

.euiButton:disabled.euiButton--ghost.euiButton--fill {
	background-color: #69707d;
	color: #a1a5ae;
}

.euiButton--fullWidth {
	display: block;
	width: 100%;
}

/**
   * 1. We don't want any of the animations that come inherited from the mixin.
   *    These should act like normal links instead.
   * 2. Change the easing, quickness to not bounce so lighter backgrounds don't flash
   */
.euiButtonEmpty {
	display: inline-block;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	cursor: pointer;
	height: 40px;
	text-align: center;
	white-space: nowrap;
	max-width: 100%;
	vertical-align: middle;
	font-family: var(--primary-font), 'Inter UI', -apple-system, BlinkMacSystemFont, 'Segoe UI',
		Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
	font-weight: 400;
	letter-spacing: -0.005em;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-webkit-font-kerning: normal;
	font-kerning: normal;
	font-size: 16px;
	font-size: 1rem;
	line-height: 1.5;
	text-decoration: none;
	border: solid 1px transparent;
	-webkit-transition: all 250ms ease-in-out;
	transition: all 250ms ease-in-out;
	border-color: transparent;
	background-color: transparent;
	-webkit-box-shadow: none;
	box-shadow: none;
	-webkit-transform: none !important;
	transform: none !important;
	/* 1 */
	-webkit-animation: none !important;
	animation: none !important;
	/* 1 */
	-webkit-transition-timing-function: ease-in;
	transition-timing-function: ease-in;
	/* 2 */
	-webkit-transition-duration: 150ms;
	transition-duration: 150ms;
	/* 2 */
}
.euiButtonEmpty:hover:not(:disabled) {
	-webkit-transform: translateY(-1px);
	transform: translateY(-1px);
}
.euiButtonEmpty:focus {
	-webkit-animation: euiButtonActive 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
	animation: euiButtonActive 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
}
.euiButtonEmpty:active:not(:disabled) {
	-webkit-transform: translateY(1px);
	transform: translateY(1px);
}
.euiButtonEmpty:hover:not(:disabled),
.euiButtonEmpty:focus {
	text-decoration: underline;
}
.euiButtonEmpty .euiButtonEmpty__content {
	height: 100%;
	width: 100%;
	vertical-align: middle;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 0 8px;
}
.euiButtonEmpty .euiButtonEmpty__content .euiButton__icon,
.euiButtonEmpty .euiButtonEmpty__content .euiButton__spinner {
	-ms-flex-negative: 0;
	flex-shrink: 0;
}
.euiButtonEmpty .euiButtonEmpty__content > * + * {
	margin-left: 8px;
}
.euiButtonEmpty .euiButtonEmpty__text {
	text-overflow: ellipsis;
	overflow: hidden;
}
.euiButtonEmpty.euiButtonEmpty--small {
	height: 32px;
}
.euiButtonEmpty.euiButtonEmpty--xSmall {
	height: 30px;
	font-size: 14px;
}
.euiButtonEmpty.euiButtonEmpty--iconRight .euiButtonEmpty__content {
	height: 100%;
	width: 100%;
	vertical-align: middle;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: reverse;
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse;
}
.euiButtonEmpty.euiButtonEmpty--iconRight .euiButtonEmpty__content .euiButton__icon,
.euiButtonEmpty.euiButtonEmpty--iconRight .euiButtonEmpty__content .euiButton__spinner {
	-ms-flex-negative: 0;
	flex-shrink: 0;
}
.euiButtonEmpty.euiButtonEmpty--iconRight .euiButtonEmpty__content > * + * {
	margin-left: 0;
	margin-right: 8px;
}
.euiButtonEmpty:disabled {
	color: #afb0b3;
	pointer-events: none;
}
.euiButtonEmpty:disabled .euiButtonEmpty__content {
	pointer-events: auto;
	cursor: not-allowed;
}
.euiButtonEmpty:disabled .euiButtonEmpty__icon {
	fill: currentColor;
}
.euiButtonEmpty:disabled:focus {
	background-color: transparent;
}
.euiButtonEmpty:disabled:hover,
.euiButtonEmpty:disabled:focus {
	text-decoration: none;
}

.euiButtonEmpty--primary {
	color: #ef5742;
}
.euiButtonEmpty--primary:focus {
	background-color: rgba(0, 107, 180, 0.1);
}

.euiButtonEmpty--danger {
	color: #bd271e;
}
.euiButtonEmpty--danger:focus {
	background-color: rgba(189, 39, 30, 0.1);
}

.euiButtonEmpty--disabled {
	color: #757577;
}
.euiButtonEmpty--disabled:focus {
	background-color: rgba(175, 176, 179, 0.1);
}
.euiButtonEmpty--disabled:hover {
	cursor: not-allowed;
}

.euiButtonEmpty--ghost {
	color: #fff;
}
.euiButtonEmpty--ghost:focus {
	background-color: rgba(255, 255, 255, 0.1);
}

.euiButtonEmpty--text {
	color: #343741;
}
.euiButtonEmpty--text:focus {
	background-color: rgba(52, 55, 65, 0.1);
}

.euiButtonEmpty--flushLeft {
	margin-right: 8px;
}
.euiButtonEmpty--flushLeft .euiButtonEmpty__content {
	border-left: none;
	padding-left: 0;
	padding-right: 0;
}

.euiButtonEmpty--flushRight {
	margin-left: 8px;
}
.euiButtonEmpty--flushRight .euiButtonEmpty__content {
	border-right: none;
	padding-left: 0;
	padding-right: 0;
}

.euiButtonIcon {
	display: inline-block;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	cursor: pointer;
	text-align: center;
	white-space: nowrap;
	max-width: 100%;
	vertical-align: middle;
	font-family: var(--primary-font), 'Inter UI', -apple-system, BlinkMacSystemFont, 'Segoe UI',
		Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
	font-weight: 400;
	letter-spacing: -0.005em;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-webkit-font-kerning: normal;
	font-kerning: normal;
	font-size: 16px;
	font-size: 1rem;
	text-decoration: none;
	-webkit-transition: all 250ms ease-in-out;
	transition: all 250ms ease-in-out;
	border: none;
	background-color: transparent;
	-webkit-box-shadow: none;
	box-shadow: none;
	height: auto;
	min-height: 24px;
	min-width: 24px;
	line-height: 0;
	padding: 4px;
	border-radius: 4px;
}
.euiButtonIcon:hover:not(:disabled) {
	-webkit-transform: translateY(-1px);
	transform: translateY(-1px);
}
.euiButtonIcon:focus {
	-webkit-animation: euiButtonActive 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
	animation: euiButtonActive 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
}
.euiButtonIcon:active:not(:disabled) {
	-webkit-transform: translateY(1px);
	transform: translateY(1px);
}
.euiButtonIcon:hover:not(:disabled),
.euiButtonIcon:focus {
	text-decoration: underline;
}
.euiButtonIcon > svg {
	pointer-events: none;
}
.euiButtonIcon:disabled {
	color: #afb0b3;
	pointer-events: none;
}
.euiButtonIcon:disabled .euiButtonIcon__icon {
	pointer-events: auto;
	cursor: not-allowed;
}
.euiButtonIcon:disabled:hover,
.euiButtonIcon:disabled:focus {
	background-color: transparent;
	text-decoration: none;
}

.euiButtonIcon--danger {
	color: #bd271e;
}
.euiButtonIcon--danger:focus {
	background-color: rgba(189, 39, 30, 0.1);
}

.euiButtonIcon--disabled {
	color: #afb0b3;
}
.euiButtonIcon--disabled:focus {
	background-color: rgba(175, 176, 179, 0.1);
}
.euiButtonIcon--disabled:hover,
.euiButtonIcon--disabled:focus {
	cursor: not-allowed;
}

.euiButtonIcon--ghost {
	color: #fff;
}
.euiButtonIcon--ghost:focus {
	background-color: rgba(255, 255, 255, 0.1);
}

.euiButtonIcon--primary {
	color: #ef5742;
}
.euiButtonIcon--primary:focus {
	background-color: rgba(0, 107, 180, 0.1);
}

.euiButtonIcon--subdued {
	color: #6a717d;
}
.euiButtonIcon--subdued:focus {
	background-color: rgba(106, 113, 125, 0.1);
}

.euiButtonIcon--success {
	color: #017d73;
}
.euiButtonIcon--success:focus {
	background-color: rgba(1, 125, 115, 0.1);
}

.euiButtonIcon--text {
	color: #343741;
}
.euiButtonIcon--text:focus {
	background-color: rgba(52, 55, 65, 0.1);
}

.euiButtonIcon--warning {
	color: #f5a700;
}
.euiButtonIcon--warning:focus {
	background-color: rgba(245, 167, 0, 0.1);
}

.euiButtonToggle__wrapper {
	display: inline-block;
}
.euiButtonToggle__wrapper:not(.euiButtonToggle--isDisabled) {
	-webkit-transition: -webkit-transform 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
	transition: -webkit-transform 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
	transition: transform 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
	transition: transform 250ms cubic-bezier(0.34, 1.61, 0.7, 1),
		-webkit-transform 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
}
.euiButtonToggle__wrapper:not(.euiButtonToggle--isDisabled):hover {
	-webkit-transform: translateY(-1px);
	transform: translateY(-1px);
}
.euiButtonToggle__wrapper:not(.euiButtonToggle--isDisabled):focus {
	-webkit-animation: euiButtonActive 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
	animation: euiButtonActive 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
}
.euiButtonToggle__wrapper:not(.euiButtonToggle--isDisabled):active {
	-webkit-transform: translateY(1px);
	transform: translateY(1px);
}

.euiButtonToggle__input:enabled:hover + .euiButtonToggle,
.euiButtonToggle__input:enabled:focus + .euiButtonToggle,
.euiButtonToggle__input:enabled:active + .euiButtonToggle {
	text-decoration: underline;
}

.euiButtonToggle.euiButtonToggle--isIconOnly {
	min-width: 0;
}
.euiButtonToggle.euiButtonToggle--isIconOnly .euiButton__content {
	padding: 0 8px;
}
.euiButtonToggle.euiButtonToggle--isIconOnly .euiButton__text:empty {
	display: none;
}

.euiButtonToggle.euiButtonToggle--isEmpty {
	border-color: transparent;
	background-color: transparent;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.euiButtonToggle__input:enabled:hover + .euiButtonToggle[class*='primary'],
.euiButtonToggle__input:enabled:focus + .euiButtonToggle[class*='primary'],
.euiButtonToggle__input:enabled:active + .euiButtonToggle[class*='primary'] {
	background-color: rgba(0, 107, 180, 0.1);
}

.euiButtonToggle__input:enabled:hover + .euiButtonToggle[class*='primary'][class*='fill'],
.euiButtonToggle__input:enabled:focus + .euiButtonToggle[class*='primary'][class*='fill'],
.euiButtonToggle__input:enabled:active + .euiButtonToggle[class*='primary'][class*='fill'] {
	background-color: #005c9b;
	border-color: #005c9b;
}

.euiButtonToggle__input:enabled:hover + .euiButtonToggle[class*='secondary'],
.euiButtonToggle__input:enabled:focus + .euiButtonToggle[class*='secondary'],
.euiButtonToggle__input:enabled:active + .euiButtonToggle[class*='secondary'] {
	background-color: rgba(1, 125, 115, 0.1);
}

.euiButtonToggle__input:enabled:hover + .euiButtonToggle[class*='secondary'][class*='fill'],
.euiButtonToggle__input:enabled:focus + .euiButtonToggle[class*='secondary'][class*='fill'],
.euiButtonToggle__input:enabled:active + .euiButtonToggle[class*='secondary'][class*='fill'] {
	background-color: #01645c;
	border-color: #01645c;
}

.euiButtonToggle__input:enabled:hover + .euiButtonToggle[class*='warning'],
.euiButtonToggle__input:enabled:focus + .euiButtonToggle[class*='warning'],
.euiButtonToggle__input:enabled:active + .euiButtonToggle[class*='warning'] {
	background-color: rgba(245, 167, 0, 0.1);
}

.euiButtonToggle__input:enabled:hover + .euiButtonToggle[class*='warning'][class*='fill'],
.euiButtonToggle__input:enabled:focus + .euiButtonToggle[class*='warning'][class*='fill'],
.euiButtonToggle__input:enabled:active + .euiButtonToggle[class*='warning'][class*='fill'] {
	background-color: #dc9600;
	border-color: #dc9600;
}

.euiButtonToggle__input:enabled:hover + .euiButtonToggle[class*='danger'],
.euiButtonToggle__input:enabled:focus + .euiButtonToggle[class*='danger'],
.euiButtonToggle__input:enabled:active + .euiButtonToggle[class*='danger'] {
	background-color: rgba(189, 39, 30, 0.1);
}

.euiButtonToggle__input:enabled:hover + .euiButtonToggle[class*='danger'][class*='fill'],
.euiButtonToggle__input:enabled:focus + .euiButtonToggle[class*='danger'][class*='fill'],
.euiButtonToggle__input:enabled:active + .euiButtonToggle[class*='danger'][class*='fill'] {
	background-color: #a7221b;
	border-color: #a7221b;
}

.euiButtonToggle__input:enabled:hover + .euiButtonToggle[class*='ghost'],
.euiButtonToggle__input:enabled:focus + .euiButtonToggle[class*='ghost'],
.euiButtonToggle__input:enabled:active + .euiButtonToggle[class*='ghost'] {
	background-color: rgba(255, 255, 255, 0.1);
}

.euiButtonToggle__input:enabled:hover + .euiButtonToggle[class*='ghost'][class*='fill'],
.euiButtonToggle__input:enabled:focus + .euiButtonToggle[class*='ghost'][class*='fill'],
.euiButtonToggle__input:enabled:active + .euiButtonToggle[class*='ghost'][class*='fill'] {
	background-color: #f2f2f2;
	border-color: #f2f2f2;
}

.euiButtonToggle__input:enabled:hover + .euiButtonToggle[class*='text'],
.euiButtonToggle__input:enabled:focus + .euiButtonToggle[class*='text'],
.euiButtonToggle__input:enabled:active + .euiButtonToggle[class*='text'] {
	background-color: rgba(105, 112, 125, 0.1);
}

.euiButtonToggle__input:enabled:hover + .euiButtonToggle[class*='text'][class*='fill'],
.euiButtonToggle__input:enabled:focus + .euiButtonToggle[class*='text'][class*='fill'],
.euiButtonToggle__input:enabled:active + .euiButtonToggle[class*='text'][class*='fill'] {
	background-color: #5d646f;
	border-color: #5d646f;
}

.euiButtonGroup {
	max-width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.euiButtonGroup__fieldset {
	display: inline-block;
	max-width: 100%;
}
.euiButtonGroup__fieldset--fullWidth {
	display: block;
}

.euiButtonGroup--fullWidth .euiButtonGroup__toggle {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.euiButtonGroup__toggle {
	margin-left: -1px;
	z-index: 1;
	-webkit-transition: none !important;
	transition: none !important;
	-webkit-transform: none !important;
	transform: none !important;
	-webkit-animation: none !important;
	animation: none !important;
}
.euiButtonGroup__toggle[class*='checked'] {
	z-index: 2;
}
.euiButtonGroup__toggle[class*='checked'] + [class*='checked'] {
	-webkit-box-shadow: -1px 0 0 rgba(255, 255, 255, 0.1);
	box-shadow: -1px 0 0 rgba(255, 255, 255, 0.1);
}
.euiButtonGroup__toggle .euiButtonGroup__button {
	border-radius: 0;
	width: 100%;
	-webkit-transition: none !important;
	transition: none !important;
	-webkit-transform: none !important;
	transform: none !important;
	-webkit-animation: none !important;
	animation: none !important;
}
.euiButtonGroup__toggle .euiButtonGroup__button:not([class*='fill']) {
	border-color: #d3dae6;
}
.euiButtonGroup__toggle .euiButtonGroup__button:enabled {
	-webkit-box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
	box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
}
.euiButtonGroup__toggle:first-child {
	margin-left: 0;
}
.euiButtonGroup__toggle:first-child .euiButtonGroup__button {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}
.euiButtonGroup__toggle:last-child .euiButtonGroup__button {
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}

@media only screen and (max-width: 574px) {
	.euiButtonGroup__fieldset {
		display: block;
	}
	.euiButtonGroup__toggle {
		-webkit-box-flex: 1;
		-ms-flex: 1;
		flex: 1;
		min-width: 0;
	}
	.euiButtonGroup__toggle .euiButtonGroup__button {
		min-width: 0;
	}
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
	.euiButtonGroup__fieldset {
		display: block;
	}
	.euiButtonGroup__toggle {
		-webkit-box-flex: 1;
		-ms-flex: 1;
		flex: 1;
		min-width: 0;
	}
	.euiButtonGroup__toggle .euiButtonGroup__button {
		min-width: 0;
	}
}

.euiButtonGroup--compressed {
	border: 1px solid rgba(15, 39, 118, 0.1);
	border-radius: 2px;
	background-color: #fbfcfd;
}
.euiButtonGroup--compressed .euiButtonGroup__button {
	height: 30px;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	font-size: 14px;
	min-width: 0;
	border: none;
	border-radius: 4px;
	padding: 2px;
	background-clip: content-box;
}
.euiButtonGroup--compressed
	.euiButtonGroup__button:not(.euiButtonGroup__button--selected):not(:disabled) {
	color: #69707d;
}
.euiButtonGroup--compressed .euiButtonGroup__button .euiButton__content {
	padding-left: 8px;
	padding-right: 8px;
}
.euiButtonGroup--compressed .euiButtonGroup__toggle {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	min-width: 0;
}
.euiButtonGroup--compressed .euiButtonToggle__input:enabled:hover + .euiButtonGroup__button,
.euiButtonGroup--compressed .euiButtonToggle__input:enabled:focus + .euiButtonGroup__button {
	background-color: rgba(233, 237, 243, 0.5);
}
.euiButtonGroup--compressed .euiButtonToggle__input:enabled:focus + .euiButtonGroup__button {
	outline: 2px solid rgba(0, 107, 180, 0.3);
}
.euiButtonGroup--compressed .euiButtonGroup__button--selected {
	font-weight: 600;
	background-color: #e9edf3;
}

.euiBreadcrumbs {
	font-size: 14px;
	font-size: 0.875rem;
	line-height: 1.5;
}

.euiBreadcrumb {
	display: inline-block;
}
.euiBreadcrumb:not(.euiBreadcrumb--last) {
	margin-right: 8px;
}

.euiBreadcrumb--last {
	font-weight: 500;
}

.euiBreadcrumb--collapsed {
	color: #d3dae6;
}

.euiBreadcrumbSeparator {
	-ms-flex-negative: 0;
	flex-shrink: 0;
	pointer-events: none;
	display: inline-block;
	margin-right: 8px;
	width: 1px;
	height: 16px;
	-webkit-transform: translateY(0.2em) rotate(15deg);
	transform: translateY(0.2em) rotate(15deg);
	background: #d3dae6;
}

.euiBreadcrumbs__inPopover .euiBreadcrumb--last.euiLink.euiLink--text {
	font-weight: 400;
	color: #69707d;
}

.euiBreadcrumb__collapsedBadge {
	height: 16px;
	margin-top: -6px;
	background-color: rgba(52, 55, 65, 0.15);
}
.euiBreadcrumb__collapsedBadge .euiBadge__content {
	margin-top: -2px;
}

/**
   * 1. Can't target separator vs breadcrumb with -of-type because it takes
   *    the dom element into consideration too and there could be divs, or spans, or a's
   *    as breadcrumbs.
   */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.euiBreadcrumbs--responsive .euiBreadcrumbSeparator,
	.euiBreadcrumbs--responsive .euiBreadcrumb {
		display: none;
	}
	.euiBreadcrumbs--responsive .euiBreadcrumbSeparator:nth-last-child(-n + 7),
	.euiBreadcrumbs--responsive .euiBreadcrumb:nth-last-child(-n + 7) {
		/* 1 */
		display: inline-block;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.euiBreadcrumbs--responsive .euiBreadcrumbSeparator,
	.euiBreadcrumbs--responsive .euiBreadcrumb {
		display: none;
	}
	.euiBreadcrumbs--responsive .euiBreadcrumbSeparator:nth-last-child(-n + 3),
	.euiBreadcrumbs--responsive .euiBreadcrumb:nth-last-child(-n + 3) {
		/* 1 */
		display: inline-block;
	}
}

@media only screen and (max-width: 574px) {
	.euiBreadcrumbs--responsive .euiBreadcrumbSeparator,
	.euiBreadcrumbs--responsive .euiBreadcrumb {
		display: none;
	}
	.euiBreadcrumbs--responsive .euiBreadcrumbSeparator:nth-last-child(-n + 1),
	.euiBreadcrumbs--responsive .euiBreadcrumb:nth-last-child(-n + 1) {
		/* 1 */
		display: inline-block;
	}
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
	.euiBreadcrumbs--responsive .euiBreadcrumbSeparator,
	.euiBreadcrumbs--responsive .euiBreadcrumb {
		display: none;
	}
	.euiBreadcrumbs--responsive .euiBreadcrumbSeparator:nth-last-child(-n + 1),
	.euiBreadcrumbs--responsive .euiBreadcrumb:nth-last-child(-n + 1) {
		/* 1 */
		display: inline-block;
	}
}

.euiBreadcrumbs--truncate {
	white-space: nowrap;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	overflow: hidden;
}
.euiBreadcrumbs--truncate .euiBreadcrumb:not(.euiBreadcrumb--collapsed) {
	max-width: 160px;
	overflow: hidden;
	text-overflow: ellipsis;
}
.euiBreadcrumbs--truncate .euiBreadcrumb:not(.euiBreadcrumb--collapsed).euiBreadcrumb--last {
	max-width: none;
}
.euiBreadcrumbs--truncate .euiBreadcrumbSeparator {
	-webkit-transform: translateY(0) rotate(15deg);
	transform: translateY(0) rotate(15deg);
}
.euiBreadcrumbs--truncate .euiBreadcrumbBadge {
	margin-top: -2px;
}

.euiBreadcrumb--truncate {
	white-space: nowrap;
	max-width: 160px;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: center;
	vertical-align: top;
}

.euiCallOut {
	padding: 16px;
	border-left: 2px solid transparent;
}
.euiCallOut.euiCallOut--small {
	padding: 8px;
}
.euiCallOut .euiCallOutHeader__icon {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	-webkit-transform: translateY(2px);
	transform: translateY(2px);
}
.euiCallOut .euiCallOutHeader__title {
	color: #1a1c21;
	font-size: 1rem;
	line-height: 1.5rem;
	letter-spacing: -0.02em;
	font-weight: 400;
	margin-bottom: 0;
}

.euiCallOut--small .euiCallOutHeader__title {
	color: #1a1c21;
	font-size: 0.875rem;
	line-height: 1.5rem;
	font-weight: 400;
}

.euiCallOut--primary {
	border-color: #ef5742;
	background-color: #e6f0f8;
}
.euiCallOut--primary .euiCallOutHeader__icon {
	fill: #006bb4;
}
.euiCallOut--primary .euiCallOutHeader__title {
	color: #006bb4;
}

.euiCallOut--success {
	border-color: #017d73;
	background-color: #e6f2f1;
}
.euiCallOut--success .euiCallOutHeader__icon {
	fill: #01776d;
}
.euiCallOut--success .euiCallOutHeader__title {
	color: #01776d;
}

.euiCallOut--warning {
	border-color: #f5a700;
	background-color: #fef6e6;
}
.euiCallOut--warning .euiCallOutHeader__icon {
	fill: #936400;
}
.euiCallOut--warning .euiCallOutHeader__title {
	color: #936400;
}

.euiCallOut--danger {
	border-color: #bd271e;
	background-color: #f8e9e9;
}
.euiCallOut--danger .euiCallOutHeader__icon {
	fill: #bd271e;
}
.euiCallOut--danger .euiCallOutHeader__title {
	color: #bd271e;
}

/**
   * 1. Align icon with first line of title text if it wraps.
   * 2. If content exists under the header, space it appropriately.
   * 3. Apply margin to all but last item in the flex.
   */
.euiCallOutHeader {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: baseline;
	-ms-flex-align: baseline;
	align-items: baseline;
	/* 1 */
}
.euiCallOutHeader + * {
	margin-top: 8px;
	/* 1 */
}
.euiCallOutHeader > * + * {
	margin-left: 8px;
	/* 3 */
}

.euiCard {
	-webkit-box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3),
		0 1px 5px -2px rgba(152, 162, 179, 0.3);
	box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3), 0 1px 5px -2px rgba(152, 162, 179, 0.3);
	background-color: #fff;
	border: 1px solid #d3dae6;
	border-radius: 4px;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	padding: 16px;
	min-height: 1px;
}
.euiCard.euiCard--flexGrowZero {
	-webkit-box-flex: 0;
	-ms-flex-positive: 0;
	flex-grow: 0;
}
.euiCard.euiCard--isClickable {
	width: 100%;
	text-align: left;
	-webkit-transition: all 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
	transition: all 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
	display: flex;
}
.euiCard.euiCard--isClickable:hover,
.euiCard.euiCard--isClickable:focus {
	-webkit-box-shadow: 0 4px 8px 0 rgba(152, 162, 179, 0.15), 0 2px 2px -1px rgba(152, 162, 179, 0.3);
	box-shadow: 0 4px 8px 0 rgba(152, 162, 179, 0.15), 0 2px 2px -1px rgba(152, 162, 179, 0.3);
	-webkit-transform: translateY(-2px);
	transform: translateY(-2px);
	cursor: pointer;
}
.euiCard.euiCard--shadow {
	-webkit-box-shadow: 0 6px 12px -1px rgba(152, 162, 179, 0.2),
		0 4px 4px -1px rgba(152, 162, 179, 0.2), 0 2px 2px 0 rgba(152, 162, 179, 0.2);
	box-shadow: 0 6px 12px -1px rgba(152, 162, 179, 0.2), 0 4px 4px -1px rgba(152, 162, 179, 0.2),
		0 2px 2px 0 rgba(152, 162, 179, 0.2);
	border-bottom-color: rgba(152, 162, 179, 0.5);
}
.euiCard.euiCard--shadow.euiCard--isClickable:hover,
.euiCard.euiCard--shadow.euiCard--isClickable:focus {
	-webkit-box-shadow: 0 12px 24px 0 rgba(152, 162, 179, 0.2), 0 6px 12px 0 rgba(152, 162, 179, 0.2),
		0 4px 4px 0 rgba(152, 162, 179, 0.2), 0 2px 2px 0 rgba(152, 162, 179, 0.2);
	box-shadow: 0 12px 24px 0 rgba(152, 162, 179, 0.2), 0 6px 12px 0 rgba(152, 162, 179, 0.2),
		0 4px 4px 0 rgba(152, 162, 179, 0.2), 0 2px 2px 0 rgba(152, 162, 179, 0.2);
}

/**
   * 1. Footer is always at the bottom.
   * 2. Fix for IE where the image correctly resizes in width but doesn't collapse its height
        (https://github.com/philipwalton/flexbugs/issues/75#issuecomment-134702421)
   * 3. Horizontal layouts should always top left align no matter the textAlign prop
   */
.euiCard.euiCard-isDisabled {
	cursor: not-allowed;
	-webkit-box-shadow: none;
	box-shadow: none;
}
.euiCard.euiCard-isDisabled:not(.euiCard--plain) {
	background-color: #f5f7fa;
}
.euiCard.euiCard-isDisabled .euiCard__title,
.euiCard.euiCard-isDisabled .euiCard__description,
.euiCard.euiCard-isDisabled .euiCard__top {
	color: #69707d;
}
.euiCard.euiCard-isDisabled .euiCard__top svg,
.euiCard.euiCard-isDisabled .euiCard__top img {
	-webkit-filter: grayscale(100%);
	filter: grayscale(100%);
}
.euiCard.euiCard-isDisabled .euiCard__titleAnchor,
.euiCard.euiCard-isDisabled .euiCard__titleButton {
	cursor: inherit;
}
.euiCard.euiCard-isDisabled:hover,
.euiCard.euiCard-isDisabled:focus {
	cursor: not-allowed;
	-webkit-transform: none;
	transform: none;
	-webkit-box-shadow: none;
	box-shadow: none;
	text-decoration: none;
}
/* .euiCard.euiCard--isClickable {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; } */
.euiCard.euiCard--isClickable:not(.euiCard-isDisabled):focus-within {
	-webkit-box-shadow: 0 4px 8px 0 rgba(152, 162, 179, 0.15), 0 2px 2px -1px rgba(152, 162, 179, 0.3);
	box-shadow: 0 4px 8px 0 rgba(152, 162, 179, 0.15), 0 2px 2px -1px rgba(152, 162, 179, 0.3);
	-webkit-animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards
		focusRingAnimateLarge !important;
	animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimateLarge !important;
}
.euiCard.euiCard--isClickable:not(.euiCard-isDisabled):focus .euiCard__title,
.euiCard.euiCard--isClickable:not(.euiCard-isDisabled):focus .euiCard__titleAnchor,
.euiCard.euiCard--isClickable:not(.euiCard-isDisabled):focus .euiCard__titleButton,
.euiCard.euiCard--isClickable:not(.euiCard-isDisabled):hover .euiCard__title,
.euiCard.euiCard--isClickable:not(.euiCard-isDisabled):hover .euiCard__titleAnchor,
.euiCard.euiCard--isClickable:not(.euiCard-isDisabled):hover .euiCard__titleButton {
	text-decoration: underline;
}
.euiCard .euiCard__top,
.euiCard .euiCard__content,
.euiCard .euiCard__footer {
	width: 100%;
}
.euiCard.euiCard--leftAligned {
	text-align: left;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
}
.euiCard.euiCard--leftAligned .euiCard__titleButton {
	text-align: left;
}
.euiCard.euiCard--centerAligned {
	text-align: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.euiCard.euiCard--rightAligned {
	text-align: right;
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
}
.euiCard.euiCard--rightAligned .euiCard__titleButton {
	text-align: right;
}
.euiCard.euiCard--isSelectable {
	position: relative;
	padding-bottom: 56px;
}
.euiCard.euiCard-isSelected {
	-webkit-transition: all 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
	transition: all 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
}

.euiCard--hasBetaBadge {
	position: relative;
	overflow: visible;
}
.euiCard--hasBetaBadge .euiCard__betaBadgeWrapper {
	position: absolute;
	top: -12px;
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	z-index: 3;
	min-width: 40%;
	max-width: calc(100% - 32px);
}
.euiCard--hasBetaBadge .euiCard__betaBadgeWrapper .euiToolTipAnchor,
.euiCard--hasBetaBadge .euiCard__betaBadgeWrapper .euiCard__betaBadge {
	width: 100%;
}
.euiCard--hasBetaBadge .euiCard__betaBadgeWrapper .euiCard__betaBadge {
	overflow: hidden;
	text-overflow: ellipsis;
	background-color: #fff;
}

.euiCard--plain:not(.euiCard--isSelectable) {
	border: none;
	-webkit-box-shadow: none;
	box-shadow: none;
}
.euiCard--plain:not(.euiCard--isSelectable) .euiCard__image {
	border-radius: 4px;
}

.euiCard--isSelectable--text.euiCard-isSelected {
	border-color: #017d73;
}

.euiCard--isSelectable--primary.euiCard-isSelected {
	border-color: #ef5742;
}

.euiCard--isSelectable--success.euiCard-isSelected {
	border-color: #017d73;
}

.euiCard--isSelectable--danger.euiCard-isSelected {
	border-color: #bd271e;
}

.euiCard--isSelectable--ghost.euiCard-isSelected {
	border-color: #69707d;
}

.euiCard__top {
	-webkit-box-flex: 0;
	-ms-flex-positive: 0;
	flex-grow: 0;
	/* 1 */
	position: relative;
	min-height: 1px;
	/* 2 */
}
.euiCard__top .euiCard__icon {
	margin-top: 8px;
}
.euiCard__top .euiCard__image {
	position: relative;
	width: calc(100% + (16px * 2));
	left: -16px;
	top: -16px;
	margin-bottom: -16px;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
}
.euiCard__top .euiCard__image + .euiCard__icon {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, calc(-50% + -16px));
	transform: translate(-50%, calc(-50% + -16px));
}

.euiCard__top + .euiCard__content {
	margin-top: 16px;
}

.euiCard__content {
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	/* 1 */
}
.euiCard__content .euiCard__titleAnchor,
.euiCard__content .euiCard__titleButton {
	font: inherit;
	color: inherit;
	letter-spacing: inherit;
}
.euiCard__content .euiCard__titleAnchor:focus,
.euiCard__content .euiCard__titleButton:focus {
	text-decoration: underline;
}
.euiCard__content .euiCard__description {
	margin-top: 8px;
}

.euiCard__footer:not(:empty) {
	-webkit-box-flex: 0;
	-ms-flex-positive: 0;
	flex-grow: 0;
	/* 1 */
	margin-top: 16px;
}

.euiCard.euiCard--horizontal .euiCard__content {
	text-align: left;
	/* 3 */
}

.euiCard.euiCard--horizontal.euiCard--hasIcon {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: start !important;
	-ms-flex-align: start !important;
	align-items: flex-start !important;
	/* 3 */
}
.euiCard.euiCard--horizontal.euiCard--hasIcon .euiCard__top,
.euiCard.euiCard--horizontal.euiCard--hasIcon .euiCard__content {
	width: auto;
	margin-top: 0;
}
.euiCard.euiCard--horizontal.euiCard--hasIcon .euiCard__top .euiCard__icon {
	margin-top: 0;
	margin-right: 16px;
}

.euiCard--hasChildren .euiCard__description {
	margin-bottom: 16px;
}

.euiCardSelect {
	position: absolute;
	bottom: 0;
	left: 0;
	height: 40px !important;
	width: 100%;
	overflow: hidden;
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
	font-weight: 700;
}
.euiCardSelect--text:enabled {
	background-color: #f5f7fa;
}
.euiCardSelect--primary:enabled {
	background-color: #e6f0f8;
}
.euiCardSelect--success:enabled {
	background-color: #e6f2f1;
	color: #01776d;
}
.euiCardSelect--danger:enabled {
	background-color: #f8e9e9;
}
.euiCardSelect--ghost:enabled {
	background-color: #69707d;
}
.euiCardSelect:disabled {
	background-color: #fafbfd;
}

.euiCheckableCard {
	-webkit-box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3),
		0 1px 5px -2px rgba(152, 162, 179, 0.3);
	box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3), 0 1px 5px -2px rgba(152, 162, 179, 0.3);
	background-color: #fff;
	border: 1px solid #d3dae6;
	border-radius: 4px;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-webkit-transition: border-color 250ms ease-in;
	transition: border-color 250ms ease-in;
	overflow: hidden;
}
.euiCheckableCard.euiCheckableCard--flexGrowZero {
	-webkit-box-flex: 0;
	-ms-flex-positive: 0;
	flex-grow: 0;
}
.euiCheckableCard.euiCheckableCard--isClickable {
	display: block;
	width: 100%;
	text-align: left;
	-webkit-transition: all 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
	transition: all 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
}
.euiCheckableCard.euiCheckableCard--isClickable:hover,
.euiCheckableCard.euiCheckableCard--isClickable:focus {
	-webkit-box-shadow: 0 4px 8px 0 rgba(152, 162, 179, 0.15), 0 2px 2px -1px rgba(152, 162, 179, 0.3);
	box-shadow: 0 4px 8px 0 rgba(152, 162, 179, 0.15), 0 2px 2px -1px rgba(152, 162, 179, 0.3);
	-webkit-transform: translateY(-2px);
	transform: translateY(-2px);
	cursor: pointer;
}
.euiCheckableCard.euiCheckableCard--shadow {
	-webkit-box-shadow: 0 6px 12px -1px rgba(152, 162, 179, 0.2),
		0 4px 4px -1px rgba(152, 162, 179, 0.2), 0 2px 2px 0 rgba(152, 162, 179, 0.2);
	box-shadow: 0 6px 12px -1px rgba(152, 162, 179, 0.2), 0 4px 4px -1px rgba(152, 162, 179, 0.2),
		0 2px 2px 0 rgba(152, 162, 179, 0.2);
	border-bottom-color: rgba(152, 162, 179, 0.5);
}
.euiCheckableCard.euiCheckableCard--shadow.euiCheckableCard--isClickable:hover,
.euiCheckableCard.euiCheckableCard--shadow.euiCheckableCard--isClickable:focus {
	-webkit-box-shadow: 0 12px 24px 0 rgba(152, 162, 179, 0.2), 0 6px 12px 0 rgba(152, 162, 179, 0.2),
		0 4px 4px 0 rgba(152, 162, 179, 0.2), 0 2px 2px 0 rgba(152, 162, 179, 0.2);
	box-shadow: 0 12px 24px 0 rgba(152, 162, 179, 0.2), 0 6px 12px 0 rgba(152, 162, 179, 0.2),
		0 4px 4px 0 rgba(152, 162, 179, 0.2), 0 2px 2px 0 rgba(152, 162, 179, 0.2);
}

.euiCheckableCard:not(.euiCheckableCard-isDisabled):focus-within {
	-webkit-animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
	animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
}

.euiCheckableCard-isChecked {
	border-color: #ef5742;
}

.euiCheckableCard__row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.euiCheckableCard__control {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 0;
	-ms-flex: 0 0 40px;
	flex: 0 0 40px;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #f5f7fa;
	-webkit-transition: background-color 250ms ease-in;
	transition: background-color 250ms ease-in;
}
.euiCheckableCard-isChecked .euiCheckableCard__control {
	background-color: #e6f0f8;
}

.euiCheckableCard__label {
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	font-size: 16px;
	line-height: 24px;
	padding: 8px 8px 8px 16px;
	cursor: pointer;
}

.euiCheckableCard__label-isDisabled {
	color: #98a2b3;
	cursor: not-allowed;
}

.euiCheckableCard__children {
	padding: 0 8px 8px 16px;
}

.euiCodeBlock {
	display: block;
	position: relative;
	background: #f5f7fa;
	color: #343741;
	/*
     * 1. Size the code against the text its embedded within.
     */
}
.euiCodeBlock .euiCodeBlock__pre {
	scrollbar-width: thin;
	height: 100%;
	overflow: auto;
	display: block;
}
.euiCodeBlock .euiCodeBlock__pre::-webkit-scrollbar {
	width: 16px;
	height: 16px;
}
.euiCodeBlock .euiCodeBlock__pre::-webkit-scrollbar-thumb {
	background-color: rgba(105, 112, 125, 0.5);
	border: 6px solid transparent;
	background-clip: content-box;
}
.euiCodeBlock .euiCodeBlock__pre::-webkit-scrollbar-corner,
.euiCodeBlock .euiCodeBlock__pre::-webkit-scrollbar-track {
	background-color: transparent;
}
.euiCodeBlock .euiCodeBlock__pre--whiteSpacePre {
	white-space: pre;
}
.euiCodeBlock .euiCodeBlock__pre--whiteSpacePreWrap {
	white-space: pre-wrap;
}
.euiCodeBlock .euiCodeBlock__code {
	font-family: var(--primary-font), 'Roboto Mono', Consolas, Menlo, Courier, monospace;
	letter-spacing: normal;
	display: block;
	line-height: 1.5;
	font-weight: 400;
	font-size: inherit;
}
.euiCodeBlock .euiCodeBlock__controls {
	position: absolute;
	top: 0;
	right: 0;
}
.euiCodeBlock .euiCodeBlock__fullScreenButton + .euiCodeBlock__copyButton {
	margin-top: 4px;
}
.euiCodeBlock.euiCodeBlock-isFullScreen {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
.euiCodeBlock.euiCodeBlock-isFullScreen .euiCodeBlock__pre {
	padding: 32px !important;
}
.euiCodeBlock.euiCodeBlock-isFullScreen .euiCodeBlock__controls {
	top: 4px;
	right: 4px;
}
.euiCodeBlock.euiCodeBlock--fontSmall {
	font-size: 12px;
}
.euiCodeBlock.euiCodeBlock--fontMedium {
	font-size: 14px;
}
.euiCodeBlock.euiCodeBlock--fontLarge {
	font-size: 16px;
}
.euiCodeBlock.euiCodeBlock--paddingSmall .euiCodeBlock__pre {
	padding: 8px;
}
.euiCodeBlock.euiCodeBlock--paddingSmall .euiCodeBlock__controls {
	top: 8px;
	right: 8px;
}
.euiCodeBlock.euiCodeBlock--paddingSmall.euiCodeBlock--hasControls .euiCodeBlock__pre {
	padding-right: 36px;
}
.euiCodeBlock.euiCodeBlock--paddingMedium .euiCodeBlock__pre {
	padding: 16px;
}
.euiCodeBlock.euiCodeBlock--paddingMedium .euiCodeBlock__controls {
	top: 16px;
	right: 16px;
}
.euiCodeBlock.euiCodeBlock--paddingMedium.euiCodeBlock--hasControls .euiCodeBlock__pre {
	padding-right: 44px;
}
.euiCodeBlock.euiCodeBlock--paddingLarge .euiCodeBlock__pre {
	padding: 24px;
}
.euiCodeBlock.euiCodeBlock--paddingLarge .euiCodeBlock__controls {
	top: 24px;
	right: 24px;
}
.euiCodeBlock.euiCodeBlock--paddingLarge.euiCodeBlock--hasControls .euiCodeBlock__pre {
	padding-right: 52px;
}
.euiCodeBlock.euiCodeBlock--inline {
	display: inline-block;
	white-space: pre;
	color: #343741;
	font-size: 90%;
	/* 1 */
	padding: 0 8px;
	background: #f5f7fa;
}
.euiCodeBlock.euiCodeBlock--inline .euiCodeBlock__pre {
	padding: 0 4px;
}
.euiCodeBlock.euiCodeBlock--inline .euiCodeBlock__code {
	display: inline;
	white-space: normal;
}
.euiCodeBlock.euiCodeBlock--transparentBackground {
	background: transparent;
}
.euiCodeBlock .hljs-comment,
.euiCodeBlock .hljs-quote {
	color: #998;
	font-style: italic;
}
.euiCodeBlock .hljs-selector-tag {
	color: inherit;
	font-weight: bold;
}
.euiCodeBlock .hljs-string,
.euiCodeBlock .hljs-subst,
.euiCodeBlock .hljs-doctag {
	color: #dd0a73;
}
.euiCodeBlock .hljs-number,
.euiCodeBlock .hljs-literal,
.euiCodeBlock .hljs-regexp,
.euiCodeBlock .hljs-variable,
.euiCodeBlock .hljs-template-variable,
.euiCodeBlock .hljs-tag .hljs-attr {
	color: #00a69b;
}
.euiCodeBlock .hljs-keyword {
	color: #333;
	font-weight: bold;
}
.euiCodeBlock .hljs-function > .hljs-title {
	color: inherit;
}
.euiCodeBlock .hljs-tag {
	color: #0079a5;
}
.euiCodeBlock .hljs-name {
	color: euiCodeBlockNameColor;
}
.euiCodeBlock .hljs-type,
.euiCodeBlock .hljs-class .hljs-title {
	color: #0079a5;
}
.euiCodeBlock .hljs-attribute {
	color: inherit;
}
.euiCodeBlock .hljs-symbol,
.euiCodeBlock .hljs-bullet,
.euiCodeBlock .hljs-built_in,
.euiCodeBlock .hljs-builtin-name,
.euiCodeBlock .hljs-link {
	color: #990073;
}
.euiCodeBlock .hljs-params {
	color: inherit;
}
.euiCodeBlock .hljs-meta {
	color: #999;
}
.euiCodeBlock .hljs-title {
	color: #900;
}
.euiCodeBlock .hljs-section {
	color: #ffc66d;
}
.euiCodeBlock .hljs-addition {
	background-color: #dfd;
	color: inherit;
	display: inline-block;
	width: 100%;
}
.euiCodeBlock .hljs-deletion {
	background-color: #fdd;
	color: inherit;
	display: inline-block;
	width: 100%;
}
.euiCodeBlock .hljs-selector-class {
	color: inherit;
}
.euiCodeBlock .hljs-selector-id {
	color: inherit;
}
.euiCodeBlock .hljs-emphasis {
	font-style: italic;
}
.euiCodeBlock .hljs-strong {
	font-weight: bold;
}
.euiCodeBlock .hljs-link {
	text-decoration: underline;
}

.euiCodeEditorWrapper {
	position: relative;
}
.euiCodeEditorWrapper .ace_hidden-cursors {
	opacity: 0;
}
.euiCodeEditorWrapper.euiCodeEditorWrapper-isEditing .ace_hidden-cursors {
	opacity: 1;
}

.euiCodeEditorKeyboardHint {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background: rgba(255, 255, 255, 0.7);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	text-align: center;
	opacity: 0;
	cursor: pointer;
}
.euiCodeEditorKeyboardHint:focus {
	opacity: 1;
	border: 2px solid #ef5742;
	z-index: 1000;
}
.euiCodeEditorKeyboardHint.euiCodeEditorKeyboardHint-isInactive {
	display: none;
}

/* @use '../flyout/flyout'; */
.euiCollapsibleNav {
	right: auto;
	left: 0;
	width: 320px;
	max-width: 80vw;
	-webkit-animation: euiCollapsibleNavIn 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
	animation: euiCollapsibleNavIn 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
}

.euiCollapsibleNav__closeButton {
	position: absolute;
	right: 0;
	top: 16px;
	margin-right: -25%;
}

.euiCollapsibleNav.euiCollapsibleNav--isDocked {
	-webkit-box-shadow: 0 6px 12px -1px rgba(152, 162, 179, 0.2),
		0 4px 4px -1px rgba(152, 162, 179, 0.2), 0 2px 2px 0 rgba(152, 162, 179, 0.2);
	box-shadow: 0 6px 12px -1px rgba(152, 162, 179, 0.2), 0 4px 4px -1px rgba(152, 162, 179, 0.2),
		0 2px 2px 0 rgba(152, 162, 179, 0.2);
	z-index: 1000;
}
.euiCollapsibleNav.euiCollapsibleNav--isDocked .euiCollapsibleNav__closeButton {
	display: none;
}

.euiBody--collapsibleNavIsDocked {
	padding-left: 320px !important;
	-webkit-transition: padding 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
	transition: padding 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
}

@media only screen and (max-width: 574px) {
	.euiCollapsibleNav__closeButton {
		margin-right: -15%;
	}
	.euiCollapsibleNav__closeButtonLabel {
		position: absolute;
		left: -10000px;
		top: auto;
		width: 1px;
		height: 1px;
		overflow: hidden;
	}
}

@-webkit-keyframes euiCollapsibleNavIn {
	0% {
		opacity: 0;
		-webkit-transform: translateX(-100%);
		transform: translateX(-100%);
	}
	75% {
		opacity: 1;
		-webkit-transform: translateX(0%);
		transform: translateX(0%);
	}
}

@keyframes euiCollapsibleNavIn {
	0% {
		opacity: 0;
		-webkit-transform: translateX(-100%);
		transform: translateX(-100%);
	}
	75% {
		opacity: 1;
		-webkit-transform: translateX(0%);
		transform: translateX(0%);
	}
}

.euiCollapsibleNavGroup:not(:first-child) {
	border-top: 1px solid #d3dae6;
}

.euiCollapsibleNavGroup .euiAccordion__triggerWrapper {
	padding: 16px;
}

.euiCollapsibleNavGroup--light {
	background-color: #fafbfd;
}

.euiCollapsibleNavGroup--dark {
	background-color: #2a2c34;
	color: #fff;
}
.euiCollapsibleNavGroup--dark .euiCollapsibleNavGroup__heading:focus .euiAccordion__iconWrapper {
	color: #2580bf;
	-webkit-animation-name: euiCollapsibleNavGroupDarkFocusRingAnimate !important;
	animation-name: euiCollapsibleNavGroupDarkFocusRingAnimate !important;
}
.euiCollapsibleNavGroup--dark .euiCollapsibleNavGroup__title {
	color: inherit;
	line-height: inherit;
}

.euiCollapsibleNavGroup__heading {
	font-weight: 600;
}
.euiCollapsibleNavGroup__heading:not(.euiAccordion__button) {
	padding: 16px;
}

.euiCollapsibleNavGroup__children {
	padding: 8px;
}

.euiCollapsibleNavGroup--withHeading .euiCollapsibleNavGroup__children {
	padding-top: 0;
}

@-webkit-keyframes euiCollapsibleNavGroupDarkFocusRingAnimate {
	0% {
		-webkit-box-shadow: 0 0 0 6px rgba(0, 107, 180, 0);
		box-shadow: 0 0 0 6px rgba(0, 107, 180, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 3px #2580bf;
		box-shadow: 0 0 0 3px #2580bf;
	}
}

@keyframes euiCollapsibleNavGroupDarkFocusRingAnimate {
	0% {
		-webkit-box-shadow: 0 0 0 6px rgba(0, 107, 180, 0);
		box-shadow: 0 0 0 6px rgba(0, 107, 180, 0);
	}
	100% {
		-webkit-box-shadow: 0 0 0 3px #2580bf;
		box-shadow: 0 0 0 3px #2580bf;
	}
}

.euiColorPicker {
	position: relative;
	width: 152px;
}

.euiColorPicker__popoverAnchor .euiColorPicker__input {
	padding-right: 40px;
}
.euiColorPicker__popoverAnchor .euiColorPicker__input[class*='--compressed'] {
	padding-right: 32px;
}
.euiColorPicker__popoverAnchor .euiColorPicker__input + .euiFormControlLayoutIcons {
	color: inherit;
}

.euiSwatchInput__stroke {
	fill: none;
	stroke: rgba(0, 0, 0, 0.2);
}

.euiColorPicker__popoverPanel--pickerOnly {
	padding-bottom: 0 !important;
}

.euiColorPicker__input--inGroup {
	height: 38px !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	border-radius: 0;
}
.euiColorPicker__input--inGroup.euiFieldText--compressed {
	height: 30px !important;
	border-radius: 0;
}

.euiColorPicker__alphaRange .euiRangeInput {
	min-width: 0;
}

.euiColorPickerSwatch {
	display: inline-block;
	height: 24px;
	width: 24px;
	border-radius: 2px;
	cursor: pointer;
	border: solid 1px rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.05);
	box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.05);
}
.euiColorPickerSwatch:focus {
	-webkit-animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
	animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
}

.euiHue {
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		from(#ff3232),
		color-stop(20%, #fff130),
		color-stop(35%, #45ff30),
		color-stop(52%, #28fff0),
		color-stop(71%, #282cff),
		color-stop(88%, #ff28fb),
		to(#ff0094)
	);
	background: linear-gradient(
		to right,
		#ff3232 0%,
		#fff130 20%,
		#45ff30 35%,
		#28fff0 52%,
		#282cff 71%,
		#ff28fb 88%,
		#ff0094 100%
	);
	height: 24px;
	margin: 4px 0;
	position: relative;
}
.euiHue:before,
.euiHue:after {
	content: '';
	left: 0;
	position: absolute;
	height: 8px;
	background: #fff;
	width: 100%;
}
.euiHue:after {
	bottom: 0;
}

.euiHue__range {
	position: relative;
	height: 24px;
	width: calc(100% + 2px);
	margin: 0 -1px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: transparent;
	z-index: 2;
}
.euiHue__range::-webkit-slider-thumb {
	-webkit-box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
	box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
	border: 1px solid #c9cbcd;
	background: #fff no-repeat center;
	border-radius: 14px;
	-webkit-transition: background-color 150ms ease-in, border-color 150ms ease-in;
	transition: background-color 150ms ease-in, border-color 150ms ease-in;
	cursor: pointer;
	border-color: #69707d;
	padding: 0;
	height: 16px;
	width: 16px;
	-webkit-appearance: none;
	margin-top: 0;
}
.euiHue__range::-moz-range-thumb {
	box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
	border: 1px solid #c9cbcd;
	background: #fff no-repeat center;
	border-radius: 14px;
	-webkit-transition: background-color 150ms ease-in, border-color 150ms ease-in;
	transition: background-color 150ms ease-in, border-color 150ms ease-in;
	cursor: pointer;
	border-color: #69707d;
	padding: 0;
	height: 16px;
	width: 16px;
}
.euiHue__range::-ms-thumb {
	box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
	border: 1px solid #c9cbcd;
	background: #fff no-repeat center;
	border-radius: 14px;
	-webkit-transition: background-color 150ms ease-in, border-color 150ms ease-in;
	transition: background-color 150ms ease-in, border-color 150ms ease-in;
	cursor: pointer;
	border-color: #69707d;
	padding: 0;
	height: 16px;
	width: 16px;
	margin-top: 0;
}
.euiHue__range::-ms-track {
	height: 24px;
	background: transparent;
	border-color: transparent;
	color: transparent;
}
.euiHue__range::-moz-focus-outer {
	border: none;
}
.euiHue__range::-ms-fill-lower,
.euiHue__range::-ms-fill-upper {
	background: transparent;
}
.euiHue__range:focus::-webkit-slider-thumb {
	-webkit-box-shadow: 0 0 0 3px rgba(0, 107, 180, 0.3);
	box-shadow: 0 0 0 3px rgba(0, 107, 180, 0.3);
	border-color: #ef5742;
}
.euiHue__range:focus::-moz-range-thumb {
	box-shadow: 0 0 0 3px rgba(0, 107, 180, 0.3);
	border-color: #ef5742;
}
.euiHue__range:focus::-ms-thumb {
	box-shadow: 0 0 0 3px rgba(0, 107, 180, 0.3);
	border-color: #ef5742;
}

.euiSaturation {
	position: relative;
	width: 100%;
	padding-bottom: 100%;
	border-radius: 2px;
	-ms-touch-action: none;
	touch-action: none;
	z-index: 3;
}
.euiSaturation .euiSaturation__lightness,
.euiSaturation .euiSaturation__saturation {
	position: absolute;
	top: -1px;
	bottom: 0;
	left: 0;
	right: 0;
	border-radius: 2px;
}
.euiSaturation .euiSaturation__lightness {
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		from(white),
		to(rgba(255, 255, 255, 0))
	);
	background: linear-gradient(to right, white, rgba(255, 255, 255, 0));
}
.euiSaturation .euiSaturation__saturation {
	background: -webkit-gradient(linear, left bottom, left top, from(black), to(rgba(0, 0, 0, 0)));
	background: linear-gradient(to top, black, rgba(0, 0, 0, 0));
}
.euiSaturation .euiSaturation__indicator {
	position: absolute;
	height: 12px;
	width: 12px;
	border-radius: 100%;
	margin-top: -6px;
	margin-left: -6px;
	border: 1px solid #343741;
}
.euiSaturation .euiSaturation__indicator:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 100%;
	border: 1px solid #f5f7fa;
}
.euiSaturation:focus .euiSaturation__indicator {
	-webkit-animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
	animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
}

.euiColorStops:not(.euiColorStops-isDisabled):focus {
	outline: 2px solid rgba(0, 107, 180, 0.3);
}

.euiColorStops__addContainer {
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	top: 50%;
	height: 16px;
	margin-top: -8px;
}
.euiColorStops__addContainer:hover:not(.euiColorStops__addContainer-isDisabled) {
	cursor: pointer;
}
.euiColorStops__addContainer:hover:not(.euiColorStops__addContainer-isDisabled)
	.euiColorStops__addTarget {
	opacity: 0.7;
}

.euiColorStops__addTarget {
	-webkit-box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
	box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
	border: 1px solid #c9cbcd;
	background: #fff no-repeat center;
	border-radius: 14px;
	cursor: pointer;
	border-color: #69707d;
	padding: 0;
	position: absolute;
	top: 0;
	height: 16px;
	width: 16px;
	background-color: #f5f7fa;
	pointer-events: none;
	opacity: 0;
	-webkit-transition: opacity 150ms;
	transition: opacity 150ms;
}

.euiColorStop {
	width: 152px;
}

.euiColorStopPopover.euiPopover {
	position: absolute;
	top: 50%;
	width: 16px;
	height: 16px;
	margin-top: -8px;
}

.euiColorStopPopover-hasFocus {
	z-index: 1;
}

.euiColorStopPopover__anchor {
	position: absolute;
	width: 100%;
	height: 100%;
}
.euiColorStopPopover__anchor:before {
	content: '';
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	height: 16px;
	width: 16px;
	border-radius: 16px;
	background: #fff;
}

.euiColorStopThumb.euiRangeThumb:not(:disabled) {
	top: 0;
	margin-top: 0;
	pointer-events: auto;
	cursor: -webkit-grab;
	cursor: grab;
	border: solid 3px #fff;
	-webkit-box-shadow: 0 0 0 1px #98a2b3, 0 2px 2px -1px rgba(152, 162, 179, 0.2),
		0 1px 5px -2px rgba(152, 162, 179, 0.2);
	box-shadow: 0 0 0 1px #98a2b3, 0 2px 2px -1px rgba(152, 162, 179, 0.2),
		0 1px 5px -2px rgba(152, 162, 179, 0.2);
}
.euiColorStopThumb.euiRangeThumb:not(:disabled):active {
	cursor: -webkit-grabbing;
	cursor: grabbing;
}

.euiColorStopPopover-isLoadingPanel {
	visibility: hidden !important;
}

.euiColorStops.euiColorStops-isDragging:not(.euiColorStops-isDisabled):not(
		.euiColorStops-isReadOnly
	) {
	cursor: -webkit-grabbing;
	cursor: grabbing;
}

.euiColorStops__highlight {
	color: #69707d;
}

.euiComboBox {
	max-width: 400px;
	width: 100%;
	height: auto;
	position: relative;
	/**
     * 1. Allow pills to truncate their text with an ellipsis.
     * 2. Don't allow pills to overlap with the caret or clear button.
     * 3. The height on combo can be larger than normal text inputs.
     */
	/**
     * 1. Force field height to match other field heights.
     * 2. Force input height to expand to fill this element.
     * 3. Reset appearance on Safari.
     * 4. Fix react-input-autosize appearance.
     * 5. Prevent a lot of input from causing the react-input-autosize to overflow the container.
     */
}
.euiComboBox--fullWidth {
	max-width: 100%;
}
.euiComboBox--compressed {
	height: 32px;
}
.euiComboBox--inGroup {
	height: 100%;
}
.euiComboBox--compressed,
.euiComboBox .euiFormControlLayout {
	height: auto;
}
.euiComboBox .euiComboBox__inputWrap {
	background-color: #fbfcfd;
	background-repeat: no-repeat;
	background-size: 0% 100%;
	-webkit-box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2),
		0 3px 2px -2px rgba(152, 162, 179, 0.2), inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 3px 2px -2px rgba(152, 162, 179, 0.2),
		inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	-webkit-transition: background-image 150ms ease-in, background-size 150ms ease-in,
		background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
		background-size 150ms ease-in, background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	font-family: var(--primary-font), 'Inter UI', -apple-system, BlinkMacSystemFont, 'Segoe UI',
		Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
	font-weight: 400;
	letter-spacing: -0.005em;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-webkit-font-kerning: normal;
	font-kerning: normal;
	font-size: 14px;
	line-height: 1em;
	color: #343741;
	border: none;
	border-radius: 0;
	max-width: 400px;
	width: 100%;
	height: auto;
	padding: 4px 8px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	/* 1 */
	padding-right: 40px;
	/* 2 */
}
/* .euiComboBox .euiComboBox__inputWrap--fullWidth {
        max-width: 100%; } */
@supports (-moz-appearance: none) {
	.euiComboBox .euiComboBox__inputWrap {
		-webkit-transition-property: background-image, background-size, -webkit-box-shadow;
		transition-property: background-image, background-size, -webkit-box-shadow;
		transition-property: box-shadow, background-image, background-size;
		transition-property: box-shadow, background-image, background-size, -webkit-box-shadow;
	}
}
.euiComboBox .euiComboBox__inputWrap::-webkit-input-placeholder {
	color: #69707d;
}
.euiComboBox .euiComboBox__inputWrap::-moz-placeholder {
	color: #69707d;
}
.euiComboBox .euiComboBox__inputWrap:-ms-input-placeholder {
	color: #69707d;
}
.euiComboBox .euiComboBox__inputWrap:-moz-placeholder {
	color: #69707d;
}
.euiComboBox .euiComboBox__inputWrap::-ms-input-placeholder {
	color: #69707d;
}
.euiComboBox .euiComboBox__inputWrap::placeholder {
	color: #69707d;
}
.euiComboBox .euiComboBox__inputWrap--compressed {
	height: 32px;
	background-color: #fbfcfd;
	background-repeat: no-repeat;
	background-size: 0% 100%;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	-webkit-transition: background-image 150ms ease-in, background-size 150ms ease-in,
		background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
		background-size 150ms ease-in, background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	padding: 8px;
	border-radius: 2px;
}
@supports (-moz-appearance: none) {
	.euiComboBox .euiComboBox__inputWrap--compressed {
		-webkit-transition-property: background-image, background-size, -webkit-box-shadow;
		transition-property: background-image, background-size, -webkit-box-shadow;
		transition-property: box-shadow, background-image, background-size;
		transition-property: box-shadow, background-image, background-size, -webkit-box-shadow;
	}
}
.euiComboBox .euiComboBox__inputWrap--inGroup {
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	border-radius: 0;
	height: 100%;
}
.euiComboBox .euiComboBox__inputWrap--withIcon {
	padding-left: 40px;
}
.euiComboBox .euiComboBox__inputWrap--fullWidth {
	max-width: 100%;
}
.euiComboBox .euiComboBox__inputWrap:not(.euiComboBox__inputWrap--noWrap) {
	padding-top: 4px;
	padding-bottom: 4px;
	padding-left: 4px;
	height: auto;
	/* 3 */
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	/* 1 */
	-ms-flex-line-pack: start;
	align-content: flex-start;
}
.euiComboBox .euiComboBox__inputWrap:not(.euiComboBox__inputWrap--noWrap):hover {
	cursor: text;
}
.euiComboBox .euiComboBox__inputWrap.euiComboBox__inputWrap-isClearable {
	padding-right: 68px;
	/* 2 */
}
.euiComboBox .euiComboBox__input {
	display: -webkit-inline-box !important;
	display: -ms-inline-flexbox !important;
	display: inline-flex !important;
	/* 1 */
	height: 32px;
	/* 2 */
	overflow: hidden;
	/* 5 */
}
.euiComboBox .euiComboBox__input > input {
	font-family: var(--primary-font), 'Inter UI', -apple-system, BlinkMacSystemFont, 'Segoe UI',
		Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
	font-weight: 400;
	letter-spacing: -0.005em;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-webkit-font-kerning: normal;
	font-kerning: normal;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	/* 3 */
	padding: 0;
	border: none;
	background: transparent;
	font-size: 14px;
	color: #343741;
	margin: 4px;
	line-height: 1.5;
	/* 4 */
}
.euiComboBox.euiComboBox-isOpen .euiComboBox__inputWrap {
	background-color: white;
	background-image: linear-gradient(
		to top,
		#ef5742,
		#ef5742 2px,
		transparent 2px,
		transparent 100%
	);
	background-size: 100% 100%;
	-webkit-box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2),
		0 4px 4px -2px rgba(152, 162, 179, 0.2), inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 4px 4px -2px rgba(152, 162, 179, 0.2),
		inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiComboBox.euiComboBox-isOpen .euiComboBox__inputWrap--compressed {
	background-color: white;
	background-image: linear-gradient(
		to top,
		#ef5742,
		#ef5742 2px,
		transparent 2px,
		transparent 100%
	);
	background-size: 100% 100%;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiComboBox.euiComboBox-isInvalid .euiComboBox__inputWrap {
	background-image: linear-gradient(
		to top,
		#bd271e,
		#bd271e 2px,
		transparent 2px,
		transparent 100%
	);
	background-size: 100%;
}
.euiComboBox.euiComboBox-isDisabled .euiComboBox__inputWrap {
	cursor: not-allowed;
	color: #98a2b3;
	background: #eef2f7;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiComboBox.euiComboBox-isDisabled .euiComboBox__inputWrap::-webkit-input-placeholder {
	color: #98a2b3;
}
.euiComboBox.euiComboBox-isDisabled .euiComboBox__inputWrap::-moz-placeholder {
	color: #98a2b3;
}
.euiComboBox.euiComboBox-isDisabled .euiComboBox__inputWrap:-ms-input-placeholder {
	color: #98a2b3;
}
.euiComboBox.euiComboBox-isDisabled .euiComboBox__inputWrap:-moz-placeholder {
	color: #98a2b3;
}
.euiComboBox.euiComboBox-isDisabled .euiComboBox__inputWrap::-ms-input-placeholder {
	color: #98a2b3;
}
.euiComboBox.euiComboBox-isDisabled .euiComboBox__inputWrap::placeholder {
	color: #98a2b3;
}
.euiComboBox.euiComboBox--compressed .euiComboBox__inputWrap {
	line-height: 32px;
	/* 2 */
	padding-top: 0;
	padding-bottom: 0;
}
.euiComboBox.euiComboBox--compressed .euiComboBox__inputWrap.euiComboBox__inputWrap-isClearable {
	padding-right: 56px;
	/* 2 */
}

.euiComboBox__input {
	max-width: 100%;
}
.euiComboBox__input input[aria-hidden='true'] {
	border: none !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	outline: none !important;
}

/*
   * 1. Overwrites the base styling of EuiBadge, to give it a larger size and margins
   * that make sense in the input wrap.
   */
.euiComboBoxPill {
	height: 22px;
	line-height: 22px;
	vertical-align: baseline;
}
.euiComboBoxPill,
.euiComboBoxPill + .euiComboBoxPill {
	margin: 4px;
}
.euiComboBox--compressed .euiComboBoxPill,
.euiComboBox--compressed .euiComboBoxPill + .euiComboBoxPill {
	margin: 5px 4px 0 0;
}
.euiComboBoxPill--plainText {
	font-family: var(--primary-font), 'Inter UI', -apple-system, BlinkMacSystemFont, 'Segoe UI',
		Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
	font-weight: 400;
	letter-spacing: -0.005em;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-webkit-font-kerning: normal;
	font-kerning: normal;
	max-width: 100%;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
	white-space: nowrap !important;
	word-wrap: normal !important;
	line-height: 24px;
	font-size: 14px;
	padding: 0;
	color: #343741;
	vertical-align: middle;
	display: inline-block;
}

.euiComboBoxPlaceholder {
	position: absolute;
	pointer-events: none;
	padding: 0 4px;
	line-height: 32px;
	color: #98a2b3;
	margin-bottom: 0 !important;
}

/**
   * 1. Make width match that of the input and tweak position to match.
   * 2. Put the list at the top of the screen, otherwise it will cause a scrollbar to show up when
   *    the portal is appended to the body. This would throw off our logic for positioning the
   *    list beneath the input.
   * 3. The height can expand, hence auto
   * 4. Using specificity to override panel shadow
   */
.euiComboBoxOptionsList {
	max-width: 400px;
	width: 100%;
	height: auto;
	/* 3 */
	z-index: 8001;
	position: absolute;
	/* 2 */
	top: 0;
	/* 2 */
}
.euiComboBoxOptionsList--fullWidth {
	max-width: 100%;
}
.euiComboBoxOptionsList--compressed {
	height: 32px;
}
.euiComboBoxOptionsList--inGroup {
	height: 100%;
}
.euiComboBoxOptionsList .ReactVirtualized__List {
	scrollbar-width: thin;
}
.euiComboBoxOptionsList .ReactVirtualized__List::-webkit-scrollbar {
	width: 16px;
	height: 16px;
}
.euiComboBoxOptionsList .ReactVirtualized__List::-webkit-scrollbar-thumb {
	background-color: rgba(105, 112, 125, 0.5);
	border: 6px solid transparent;
	background-clip: content-box;
}
.euiComboBoxOptionsList .ReactVirtualized__List::-webkit-scrollbar-corner,
.euiComboBoxOptionsList .ReactVirtualized__List::-webkit-scrollbar-track {
	background-color: transparent;
}
.euiComboBoxOptionsList.euiComboBoxOptionsList--bottom {
	/* 4 */
	-webkit-box-shadow: 0 6px 12px -1px rgba(152, 162, 179, 0.2),
		0 4px 4px -1px rgba(152, 162, 179, 0.2), 0 2px 2px 0 rgba(152, 162, 179, 0.2);
	box-shadow: 0 6px 12px -1px rgba(152, 162, 179, 0.2), 0 4px 4px -1px rgba(152, 162, 179, 0.2),
		0 2px 2px 0 rgba(152, 162, 179, 0.2);
}
.euiComboBoxOptionsList.euiComboBoxOptionsList--top {
	/* 4 */
	-webkit-box-shadow: 0 -2px 4px -1px rgba(152, 162, 179, 0.2), 0 0 2px 0 rgba(152, 162, 179, 0.2);
	box-shadow: 0 -2px 4px -1px rgba(152, 162, 179, 0.2), 0 0 2px 0 rgba(152, 162, 179, 0.2);
}

.euiComboBoxOptionsList--bottom {
	border-radius: 0 0 4px 4px !important;
	border-top: none !important;
}

.euiComboBoxOptionsList--top {
	border-radius: 4px 4px 0 0 !important;
}

/**
    * 1. Prevent really long input from overflowing the container.
    */
.euiComboBoxOptionsList__empty {
	padding: 8px;
	text-align: center;
	color: #69707d;
	word-wrap: break-word;
	/* 1 */
}

.euiComboBoxOptionsList__rowWrap {
	padding: 0;
	max-height: 200px;
	overflow: hidden;
}

.euiComboBoxOption {
	font-size: 14px;
	padding: 4px 8px 4px 16px;
	width: 100%;
	text-align: left;
	border: 1px solid #d3dae6;
	border-color: transparent;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.euiComboBoxOption:hover {
	text-decoration: underline;
}
.euiComboBoxOption.euiComboBoxOption-isFocused {
	cursor: pointer;
	color: #ef5742;
	background-color: #e6f0f8;
}
.euiComboBoxOption.euiComboBoxOption-isDisabled {
	color: #98a2b3;
	cursor: not-allowed;
}
.euiComboBoxOption.euiComboBoxOption-isDisabled:hover {
	text-decoration: none;
}

.euiComboBoxOption__content {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

/**
   * 1. Force each title to be the same height as an option, so that the virtualized scroll logic
   *    works.
   */
.euiComboBoxTitle {
	font-size: 12px;
	padding: 11px 8px 4px;
	/* 1 */
	width: 100%;
	font-weight: 700;
	color: #000;
}

.euiContextMenu {
	width: 256px;
	max-width: 100%;
	position: relative;
	overflow: hidden;
	-webkit-transition: height 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
	transition: height 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
	border-radius: 4px;
}
.euiContextMenu .euiContextMenu__content {
	padding: 8px;
}

/**
    * 1. When there are multiple ContextMenuPanels, the ContextMenu will absolutely
    *    position them. ContextMenuPanel will break the layout of a Popover if it's
    *    absolutely positioned by default.
    */
.euiContextMenu__panel {
	position: absolute;
	/* 1 */
}

.euiContextMenu__icon {
	margin-right: 8px;
}

.euiContextMenuPanel {
	width: 100%;
	visibility: visible;
}
.euiContextMenuPanel.euiContextMenuPanel-txInLeft {
	pointer-events: none;
	-webkit-animation: euiContextMenuPanelTxInLeft 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
	animation: euiContextMenuPanelTxInLeft 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
}
.euiContextMenuPanel.euiContextMenuPanel-txOutLeft {
	pointer-events: none;
	-webkit-animation: euiContextMenuPanelTxOutLeft 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
	animation: euiContextMenuPanelTxOutLeft 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
}
.euiContextMenuPanel.euiContextMenuPanel-txInRight {
	pointer-events: none;
	-webkit-animation: euiContextMenuPanelTxInRight 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
	animation: euiContextMenuPanelTxInRight 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
}
.euiContextMenuPanel.euiContextMenuPanel-txOutRight {
	pointer-events: none;
	-webkit-animation: euiContextMenuPanelTxOutRight 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
	animation: euiContextMenuPanelTxOutRight 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
}

.euiContextMenuPanel--next {
	-webkit-transform: translateX(256px);
	transform: translateX(256px);
	visibility: hidden;
}

.euiContextMenuPanel--previous {
	-webkit-transform: translateX(-256px);
	transform: translateX(-256px);
	visibility: hidden;
}

.euiContextMenuPanelTitle {
	color: #1a1c21;
	font-size: 14px;
	font-size: 0.875rem;
	line-height: 1.5rem;
	font-weight: 700;
	padding: 12px;
	text-transform: uppercase;
	border-bottom: 1px solid #d3dae6;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
	width: 100%;
	text-align: left;
}
.euiContextMenuPanelTitle:hover,
.euiContextMenuPanelTitle:focus {
	text-decoration: underline;
}

@-webkit-keyframes euiContextMenuPanelTxInLeft {
	0% {
		-webkit-transform: translateX(256px);
		transform: translateX(256px);
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}

@keyframes euiContextMenuPanelTxInLeft {
	0% {
		-webkit-transform: translateX(256px);
		transform: translateX(256px);
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}

@-webkit-keyframes euiContextMenuPanelTxOutLeft {
	0% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
	100% {
		-webkit-transform: translateX(-256px);
		transform: translateX(-256px);
	}
}

@keyframes euiContextMenuPanelTxOutLeft {
	0% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
	100% {
		-webkit-transform: translateX(-256px);
		transform: translateX(-256px);
	}
}

@-webkit-keyframes euiContextMenuPanelTxInRight {
	0% {
		-webkit-transform: translateX(-256px);
		transform: translateX(-256px);
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}

@keyframes euiContextMenuPanelTxInRight {
	0% {
		-webkit-transform: translateX(-256px);
		transform: translateX(-256px);
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}

@-webkit-keyframes euiContextMenuPanelTxOutRight {
	0% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
	100% {
		-webkit-transform: translateX(256px);
		transform: translateX(256px);
	}
}

@keyframes euiContextMenuPanelTxOutRight {
	0% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
	100% {
		-webkit-transform: translateX(256px);
		transform: translateX(256px);
	}
}

.euiContextMenuItem {
	display: block;
	padding: 12px;
	width: 100%;
	text-align: left;
	color: #343741;
}
.euiContextMenuItem:hover,
.euiContextMenuItem:focus {
	text-decoration: underline;
}
.euiContextMenuItem:focus {
	background-color: #e6f0f8;
}
.euiContextMenuItem.euiContextMenuItem-isDisabled {
	color: #afb0b3;
	cursor: default;
}
.euiContextMenuItem.euiContextMenuItem-isDisabled:hover,
.euiContextMenuItem.euiContextMenuItem-isDisabled:focus {
	text-decoration: none;
}

.euiContextMenuItem__inner {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.euiContextMenuItem__text {
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	overflow: hidden;
}

.euiContextMenuItem__arrow {
	-ms-flex-item-align: end;
	align-self: flex-end;
}

.euiContextMenu__itemLayout {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.euiContextMenu__itemLayout.euiContextMenu__itemLayout--bottom {
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
}
.euiContextMenu__itemLayout.euiContextMenu__itemLayout--top {
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
}
.euiContextMenu__itemLayout .euiContextMenu__icon {
	-ms-flex-negative: 0;
	flex-shrink: 0;
}

.euiControlBar {
	background: #343741;
	color: white;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-shadow: inset 0 40px 0 #343741, inset 0 600rem 0 #fafbfd;
	box-shadow: inset 0 40px 0 #343741, inset 0 600rem 0 #fafbfd;
	bottom: 0;
	-webkit-transform: translateY(0);
	transform: translateY(0);
	height: 40px;
	max-height: calc(100vh - 80px);
}
.euiControlBar--fixed {
	position: fixed;
	z-index: 6000;
}
.euiControlBar--absolute {
	position: absolute;
	z-index: 1000;
}
.euiControlBar--relative {
	position: relative;
}
.euiControlBar-isOpen {
	-webkit-animation-duration: 250ms;
	animation-duration: 250ms;
	-webkit-animation-timing-function: cubic-bezier(0.694, 0.0482, 0.335, 1);
	animation-timing-function: cubic-bezier(0.694, 0.0482, 0.335, 1);
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
}
.euiControlBar-isOpen.euiControlBar--large {
	-webkit-animation-name: euiControlBarOpenPanelLarge;
	animation-name: euiControlBarOpenPanelLarge;
	height: calc(100vh - 80px);
	bottom: -100vh;
}
.euiControlBar-isOpen.euiControlBar--medium {
	-webkit-animation-name: euiControlBarOpenPanelMedium;
	animation-name: euiControlBarOpenPanelMedium;
	height: 480px;
	bottom: -480px;
}
.euiControlBar-isOpen.euiControlBar--small {
	-webkit-animation-name: euiControlBarOpenPanelSmall;
	animation-name: euiControlBarOpenPanelSmall;
	height: 240px;
	bottom: -240px;
}

.euiControlBar__controls {
	height: 40px;
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	overflow-y: hidden;
	overflow-x: auto;
	padding: 0 12px;
}
.euiControlBar__controls .euiBreadcrumbSeparator {
	background: rgba(255, 255, 255, 0.2);
}
.euiControlBar__controls .euiBreadcrumb.euiLink--subdued {
	color: #9ca0aa;
}

.euiControlBar__content {
	scrollbar-width: thin;
	overflow-y: auto;
	width: 100%;
	height: calc(100% - 40px);
	background-color: #fafbfd;
	-webkit-animation-name: euiControlBarShowContent;
	animation-name: euiControlBarShowContent;
	-webkit-animation-duration: 350ms;
	animation-duration: 350ms;
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;
	-webkit-animation-timing-function: cubic-bezier(0.694, 0.0482, 0.335, 1);
	animation-timing-function: cubic-bezier(0.694, 0.0482, 0.335, 1);
	color: #343741;
}
.euiControlBar__content::-webkit-scrollbar {
	width: 16px;
	height: 16px;
}
.euiControlBar__content::-webkit-scrollbar-thumb {
	background-color: rgba(105, 112, 125, 0.5);
	border: 6px solid transparent;
	background-clip: content-box;
}
.euiControlBar__content::-webkit-scrollbar-corner,
.euiControlBar__content::-webkit-scrollbar-track {
	background-color: transparent;
}

.euiControlBar__icon {
	-ms-flex-negative: 0;
	flex-shrink: 0;
	margin-left: 8px;
	margin-right: 8px;
}

.euiControlBar__buttonIcon {
	-ms-flex-negative: 0;
	flex-shrink: 0;
	min-width: 40px;
	min-height: 40px;
}

.euiControlBar__button {
	-ms-flex-negative: 0;
	flex-shrink: 0;
	border-radius: 2px;
	margin-left: 4px;
	font-size: 14px;
}
.euiControlBar__button:enabled:hover {
	-webkit-transform: none;
	transform: none;
	-webkit-box-shadow: none;
	box-shadow: none;
}
.euiControlBar__button:last-child {
	margin-right: 4px;
}

.euiControlBar__spacer {
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	height: 100%;
}

.euiControlBar__divider {
	-ms-flex-negative: 0;
	flex-shrink: 0;
	height: 100%;
	width: 1px;
	background-color: rgba(255, 255, 255, 0.2);
}

.euiControlBar__text {
	max-width: 100%;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
	white-space: nowrap !important;
	word-wrap: normal !important;
	font-size: 14px;
	font-size: 0.875rem;
	line-height: 1.5;
	padding: 0 8px;
	color: white;
}
.euiControlBar__text:last-child {
	padding-right: 0;
}

.euiControlBar__tab {
	max-width: 100%;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
	white-space: nowrap !important;
	word-wrap: normal !important;
	font-size: 14px;
	font-size: 0.875rem;
	line-height: 1.5;
	color: white;
	padding: 0 16px;
	text-align: center;
	height: 100%;
}
.euiControlBar__tab:hover,
.euiControlBar__tab:focus {
	text-decoration: underline;
	cursor: pointer;
}
.euiControlBar__tab.euiControlBar__tab--active {
	background-color: #fafbfd;
	-webkit-box-shadow: inset 0 4px 0 #ef5742;
	box-shadow: inset 0 4px 0 #ef5742;
	color: #ef5742;
}

.euiControlBar__controls .euiLink.euiLink--primary {
	color: #6eaad4;
}
.euiControlBar__controls .euiLink.euiLink--primary:hover {
	color: #4d97cb;
}

.euiControlBar__controls .euiControlBar__button.euiButton--primary:enabled {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.euiControlBar__controls .euiControlBar__button.euiButton--primary:enabled:not(.euiButton--fill) {
	color: #6eaad4;
	border-color: #6eaad4;
}

.euiControlBar__controls .euiButtonIcon--primary {
	color: #6eaad4;
}

.euiControlBar__controls .euiLink.euiLink--secondary {
	color: #5eaea7;
}
.euiControlBar__controls .euiLink.euiLink--secondary:hover {
	color: #4da49d;
}

.euiControlBar__controls .euiControlBar__button.euiButton--secondary:enabled {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.euiControlBar__controls .euiControlBar__button.euiButton--secondary:enabled:not(.euiButton--fill) {
	color: #5eaea7;
	border-color: #5eaea7;
}

.euiControlBar__controls .euiButtonIcon--secondary {
	color: #5eaea7;
}

.euiControlBar__controls .euiLink.euiLink--warning {
	color: #f5a700;
}
.euiControlBar__controls .euiLink.euiLink--warning:hover {
	color: #f8c14d;
}

.euiControlBar__controls .euiControlBar__button.euiButton--warning:enabled {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.euiControlBar__controls .euiControlBar__button.euiButton--warning:enabled:not(.euiButton--fill) {
	color: #f5a700;
	border-color: #f5a700;
}

.euiControlBar__controls .euiButtonIcon--warning {
	color: #f5a700;
}

.euiControlBar__controls .euiLink.euiLink--danger {
	color: #db8a85;
}
.euiControlBar__controls .euiLink.euiLink--danger:hover {
	color: #d16862;
}

.euiControlBar__controls .euiControlBar__button.euiButton--danger:enabled {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.euiControlBar__controls .euiControlBar__button.euiButton--danger:enabled:not(.euiButton--fill) {
	color: #db8a85;
	border-color: #db8a85;
}

.euiControlBar__controls .euiButtonIcon--danger {
	color: #db8a85;
}

.euiControlBar__controls .euiLink.euiLink--ghost {
	color: #fff;
}
.euiControlBar__controls .euiLink.euiLink--ghost:hover {
	color: white;
}

.euiControlBar__controls .euiControlBar__button.euiButton--ghost:enabled {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.euiControlBar__controls .euiControlBar__button.euiButton--ghost:enabled:not(.euiButton--fill) {
	color: #fff;
	border-color: #fff;
}

.euiControlBar__controls .euiButtonIcon--ghost {
	color: #fff;
}

.euiControlBar__controls .euiLink.euiLink--text {
	color: #9ca0aa;
}
.euiControlBar__controls .euiLink.euiLink--text:hover {
	color: #969ba4;
}

.euiControlBar__controls .euiControlBar__button.euiButton--text:enabled {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.euiControlBar__controls .euiControlBar__button.euiButton--text:enabled:not(.euiButton--fill) {
	color: #9ca0aa;
	border-color: #9ca0aa;
}

.euiControlBar__controls .euiButtonIcon--text {
	color: #9ca0aa;
}

@media only screen and (max-width: 574px) {
	.euiControlBar:not(.euiControlBar--showOnMobile) {
		display: none;
	}
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
	.euiControlBar:not(.euiControlBar--showOnMobile) {
		display: none;
	}
}

@-webkit-keyframes euiControlBarOpenPanelLarge {
	0% {
		-webkit-transform: translateY(calc((40px * 3) * -1));
		transform: translateY(calc((40px * 3) * -1));
	}
	100% {
		-webkit-transform: translateY(-100vh);
		transform: translateY(-100vh);
	}
}

@keyframes euiControlBarOpenPanelLarge {
	0% {
		-webkit-transform: translateY(calc((40px * 3) * -1));
		transform: translateY(calc((40px * 3) * -1));
	}
	100% {
		-webkit-transform: translateY(-100vh);
		transform: translateY(-100vh);
	}
}

@-webkit-keyframes euiControlBarOpenPanelMedium {
	0% {
		-webkit-transform: translateY(-40px);
		transform: translateY(-40px);
	}
	100% {
		-webkit-transform: translateY(-480px);
		transform: translateY(-480px);
	}
}

@keyframes euiControlBarOpenPanelMedium {
	0% {
		-webkit-transform: translateY(-40px);
		transform: translateY(-40px);
	}
	100% {
		-webkit-transform: translateY(-480px);
		transform: translateY(-480px);
	}
}

@-webkit-keyframes euiControlBarOpenPanelSmall {
	0% {
		-webkit-transform: translateY(-40px);
		transform: translateY(-40px);
	}
	100% {
		-webkit-transform: translateY(-240px);
		transform: translateY(-240px);
	}
}

@keyframes euiControlBarOpenPanelSmall {
	0% {
		-webkit-transform: translateY(-40px);
		transform: translateY(-40px);
	}
	100% {
		-webkit-transform: translateY(-240px);
		transform: translateY(-240px);
	}
}

@-webkit-keyframes euiControlBarShowContent {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes euiControlBarShowContent {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

/* This file is a heavy retheme of react-datepicker's Sass as of v1.4.0
  ** https://github.com/Hacker0x01/react-datepicker
  **
  ** In places where features were disabled, I've commented out the original Sass
  ** selectors rather than removing it so we can better understand what's changed.
  ** Commented out selectors that don't have properties indicate that we are not
  ** using those dom elements for styling of any kind. For example, react-datepicker
  ** has lots of pointer arrows attached to its popovers, but we choose not to render
  ** then in any way.
  **
  ** Similarly, you will also find several times where we use display: none to
  ** completely remove extranous UI (they had some overly obvious legends for example).
  */
.euiDatePicker .euiFormControlLayout {
	height: auto;
}

.euiDatePicker.euiDatePicker--shadow .react-datepicker-popper {
	-webkit-box-shadow: 0 6px 12px -1px rgba(152, 162, 179, 0.2),
		0 4px 4px -1px rgba(152, 162, 179, 0.2), 0 2px 2px 0 rgba(152, 162, 179, 0.2);
	box-shadow: 0 6px 12px -1px rgba(152, 162, 179, 0.2), 0 4px 4px -1px rgba(152, 162, 179, 0.2),
		0 2px 2px 0 rgba(152, 162, 179, 0.2);
	border: 1px solid #d3dae6;
	background-color: #fff;
	border-radius: 0 0 4px 4px;
	z-index: 1;
	-webkit-animation: euiAnimFadeIn 150ms ease-in;
	animation: euiAnimFadeIn 150ms ease-in;
}

.euiDatePicker.euiDatePicker--shadow.euiDatePicker--inline .react-datepicker {
	-webkit-box-shadow: 0 6px 12px -1px rgba(152, 162, 179, 0.2),
		0 4px 4px -1px rgba(152, 162, 179, 0.2), 0 2px 2px 0 rgba(152, 162, 179, 0.2);
	box-shadow: 0 6px 12px -1px rgba(152, 162, 179, 0.2), 0 4px 4px -1px rgba(152, 162, 179, 0.2),
		0 2px 2px 0 rgba(152, 162, 179, 0.2);
	border: 1px solid #d3dae6;
	background-color: #fff;
	border-radius: 4px;
}

.react-datepicker {
	font-family: var(--primary-font), 'Inter UI', -apple-system, BlinkMacSystemFont, 'Segoe UI',
		Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
	font-weight: 400;
	letter-spacing: -0.005em;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-webkit-font-kerning: normal;
	font-kerning: normal;
	font-size: 12px;
	color: #000;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: relative;
	border-radius: 4px;
}

.react-datepicker--time-only
	.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box {
	width: 100%;
}
.react-datepicker--time-only
	.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	.react-datepicker__time-list
	li.react-datepicker__time-list-item {
	font-size: 14px;
	text-align: left;
	padding-left: 36px;
	padding-right: 36px;
	color: #343741;
}
.react-datepicker--time-only
	.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	.react-datepicker__time-list
	li.react-datepicker__time-list-item.react-datepicker__time-list-item--selected {
	color: #fff;
}

.react-datepicker--time-only .react-datepicker__time-container {
	border-left: 0;
}

.euiDatePicker.euiDatePicker--shadow .react-datepicker-popper[data-placement^='top'] {
	-webkit-box-shadow: 0 0 12px -1px rgba(152, 162, 179, 0.2), 0 0 4px -1px rgba(152, 162, 179, 0.2),
		0 0 2px 0 rgba(152, 162, 179, 0.2);
	box-shadow: 0 0 12px -1px rgba(152, 162, 179, 0.2), 0 0 4px -1px rgba(152, 162, 179, 0.2),
		0 0 2px 0 rgba(152, 162, 179, 0.2);
	border-radius: 4px 4px 0 0 !important;
}
.euiDatePicker.euiDatePicker--shadow .react-datepicker-popper[data-placement^='right'] {
	margin-left: 0;
}
.euiDatePicker.euiDatePicker--shadow .react-datepicker-popper[data-placement^='left'] {
	margin-right: 0;
}

.react-datepicker__header {
	text-align: center;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
}
.react-datepicker__header--time {
	display: none;
}

.react-datepicker__header__dropdown {
	padding: 16px 0 8px 0;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
	display: inline-block;
	margin: 0 4px;
}

.react-datepicker__current-month,
.react-datepicker-time__header {
	display: none;
}

.react-datepicker-time__header {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.react-datepicker__navigation {
	cursor: pointer;
	position: absolute;
	top: 18px;
	width: 0;
	padding: 0;
	z-index: 1;
	text-indent: -999em;
	overflow: hidden;
}
.react-datepicker__navigation--previous {
	background-position: center;
	background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiI+ICA8ZGVmcz4gICAgPHBhdGggaWQ9ImFycm93X2Rvd24tYSIgZD0iTTEzLjA2ODg1MDgsNS4xNTcyNTAzOCBMOC4zODQyMzk3NSw5Ljc2ODI3NDI4IEM4LjE3MDU0NDE1LDkuOTc4NjEzMDggNy44Mjk5OTIxNCw5Ljk3OTE0MDk1IDcuNjE1NzYwMjUsOS43NjgyNzQyOCBMMi45MzExNDkxNSw1LjE1NzI1MDM4IEMyLjcxODEzNTksNC45NDc1ODMyMSAyLjM3Mjc3MzE5LDQuOTQ3NTgzMjEgMi4xNTk3NTk5NCw1LjE1NzI1MDM4IEMxLjk0Njc0NjY5LDUuMzY2OTE3NTYgMS45NDY3NDY2OSw1LjcwNjg1NTIyIDIuMTU5NzU5OTQsNS45MTY1MjI0IEw2Ljg0NDM3MTA0LDEwLjUyNzU0NjMgQzcuNDg1MTc0MjQsMTEuMTU4MjgzNiA4LjUxNjQ0OTc5LDExLjE1NjY4NTEgOS4xNTU2Mjg5NiwxMC41Mjc1NDYzIEwxMy44NDAyNDAxLDUuOTE2NTIyNCBDMTQuMDUzMjUzMyw1LjcwNjg1NTIyIDE0LjA1MzI1MzMsNS4zNjY5MTc1NiAxMy44NDAyNDAxLDUuMTU3MjUwMzggQzEzLjYyNzIyNjgsNC45NDc1ODMyMSAxMy4yODE4NjQxLDQuOTQ3NTgzMjEgMTMuMDY4ODUwOCw1LjE1NzI1MDM4IFoiLz4gIDwvZGVmcz4gIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCI+ICAgIDx1c2UgZmlsbC1ydWxlPSJub256ZXJvIiB4bGluazpocmVmPSIjYXJyb3dfZG93bi1hIi8+ICA8L2c+PC9zdmc+);
	left: 20px;
	height: 16px;
	width: 16px;
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg);
	-webkit-transition: -webkit-transform 90ms ease-in-out;
	transition: -webkit-transform 90ms ease-in-out;
	transition: transform 90ms ease-in-out;
	transition: transform 90ms ease-in-out, -webkit-transform 90ms ease-in-out;
}
.react-datepicker__navigation--previous:hover,
.react-datepicker__navigation--previous:focus {
	border-radius: 4px;
	-webkit-transform: scale(1.2) rotate(90deg);
	transform: scale(1.2) rotate(90deg);
}
.react-datepicker__navigation--previous:hover {
	background-color: #f5f7fa;
	-webkit-box-shadow: 0 0 0 2px #f5f7fa;
	box-shadow: 0 0 0 2px #f5f7fa;
}
.react-datepicker__navigation--previous:focus {
	background-color: #e6f0f8;
	-webkit-box-shadow: 0 0 0 2px #e6f0f8;
	box-shadow: 0 0 0 2px #e6f0f8;
}
.react-datepicker__navigation--previous--disabled,
.react-datepicker__navigation--previous--disabled:hover {
	cursor: not-allowed;
	opacity: 0.2;
}
.react-datepicker__navigation--next {
	background-position: center;
	background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiI+ICA8ZGVmcz4gICAgPHBhdGggaWQ9ImFycm93X2Rvd24tYSIgZD0iTTEzLjA2ODg1MDgsNS4xNTcyNTAzOCBMOC4zODQyMzk3NSw5Ljc2ODI3NDI4IEM4LjE3MDU0NDE1LDkuOTc4NjEzMDggNy44Mjk5OTIxNCw5Ljk3OTE0MDk1IDcuNjE1NzYwMjUsOS43NjgyNzQyOCBMMi45MzExNDkxNSw1LjE1NzI1MDM4IEMyLjcxODEzNTksNC45NDc1ODMyMSAyLjM3Mjc3MzE5LDQuOTQ3NTgzMjEgMi4xNTk3NTk5NCw1LjE1NzI1MDM4IEMxLjk0Njc0NjY5LDUuMzY2OTE3NTYgMS45NDY3NDY2OSw1LjcwNjg1NTIyIDIuMTU5NzU5OTQsNS45MTY1MjI0IEw2Ljg0NDM3MTA0LDEwLjUyNzU0NjMgQzcuNDg1MTc0MjQsMTEuMTU4MjgzNiA4LjUxNjQ0OTc5LDExLjE1NjY4NTEgOS4xNTU2Mjg5NiwxMC41Mjc1NDYzIEwxMy44NDAyNDAxLDUuOTE2NTIyNCBDMTQuMDUzMjUzMyw1LjcwNjg1NTIyIDE0LjA1MzI1MzMsNS4zNjY5MTc1NiAxMy44NDAyNDAxLDUuMTU3MjUwMzggQzEzLjYyNzIyNjgsNC45NDc1ODMyMSAxMy4yODE4NjQxLDQuOTQ3NTgzMjEgMTMuMDY4ODUwOCw1LjE1NzI1MDM4IFoiLz4gIDwvZGVmcz4gIDxnIGZpbGwtcnVsZT0iZXZlbm9kZCI+ICAgIDx1c2UgZmlsbC1ydWxlPSJub256ZXJvIiB4bGluazpocmVmPSIjYXJyb3dfZG93bi1hIi8+ICA8L2c+PC9zdmc+);
	right: 20px;
	height: 16px;
	width: 16px;
	-webkit-transform: rotate(-90deg);
	transform: rotate(-90deg);
}
.react-datepicker__navigation--next--with-time:not(
		.react-datepicker__navigation--next--with-today-button
	) {
	left: 248px;
}
.react-datepicker__navigation--next:hover,
.react-datepicker__navigation--next:focus {
	border-radius: 4px;
	-webkit-transform: scale(1.2) rotate(-90deg);
	transform: scale(1.2) rotate(-90deg);
}
.react-datepicker__navigation--next:hover {
	background-color: #f5f7fa;
	-webkit-box-shadow: 0 0 0 2px #f5f7fa;
	box-shadow: 0 0 0 2px #f5f7fa;
}
.react-datepicker__navigation--next:focus {
	background-color: #e6f0f8;
	-webkit-box-shadow: 0 0 0 2px #e6f0f8;
	box-shadow: 0 0 0 2px #e6f0f8;
}
.react-datepicker__navigation--next--disabled,
.react-datepicker__navigation--next--disabled:hover {
	cursor: not-allowed;
	opacity: 0.2;
}
.react-datepicker__navigation--years {
	position: relative;
	top: 0;
	display: block;
	margin-left: auto;
	margin-right: auto;
}
.react-datepicker__navigation--years-previous {
	top: 4px;
	border-top-color: #f5f7fa;
}
.react-datepicker__navigation--years-previous:hover {
	border-top-color: #d3dce9;
}
.react-datepicker__navigation--years-upcoming {
	top: -4px;
	border-bottom-color: #f5f7fa;
}
.react-datepicker__navigation--years-upcoming:hover {
	border-bottom-color: #d3dce9;
}

.react-datepicker__month {
	margin: 0 16px 16px 16px;
	text-align: center;
	border-radius: 4px;
}

.react-datepicker__time-container {
	border-left: #d3dae6;
	width: auto;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding: 16px 0;
	border-radius: 0 4px 4px 0;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}
.react-datepicker__time-container .react-datepicker__time {
	position: relative;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding-left: 4px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
	width: auto;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}
.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list {
	scrollbar-width: thin;
	height: 204px !important;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	overflow-y: auto;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list::-webkit-scrollbar {
	width: 16px;
	height: 16px;
}
.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list::-webkit-scrollbar-thumb {
	background-color: rgba(105, 112, 125, 0.5);
	border: 6px solid transparent;
	background-clip: content-box;
}
.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list::-webkit-scrollbar-corner,
.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list::-webkit-scrollbar-track {
	background-color: transparent;
}
.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list
	li.react-datepicker__time-list-item {
	padding: 4px 8px;
	margin-bottom: 4px;
	text-align: right;
	color: #69707d;
	white-space: nowrap;
	line-height: 12px;
}
.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list
	li.react-datepicker__time-list-item:hover,
.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list
	li.react-datepicker__time-list-item:focus {
	cursor: pointer;
	text-decoration: underline;
}
.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list
	li.react-datepicker__time-list-item--selected {
	background-color: #ef5742;
	color: white;
	border-radius: 2px;
}
.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list
	li.react-datepicker__time-list-item--selected:hover {
	background-color: #ef5742;
}
.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list
	li.react-datepicker__time-list-item--disabled {
	color: #d3dae6;
}
.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list
	li.react-datepicker__time-list-item--disabled:hover {
	cursor: not-allowed;
	text-decoration: none;
	background-color: transparent;
}

.react-datepicker__week-number {
	color: #f5f7fa;
	display: inline-block;
	width: 32px;
	line-height: 28px;
	text-align: center;
	margin: 0 4px;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable {
	cursor: pointer;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
	border-radius: 4px;
	background-color: #fff;
}

.react-datepicker__day-names,
.react-datepicker__week {
	white-space: nowrap;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
	color: #000;
	display: inline-block;
	width: 32px;
	line-height: 28px;
	text-align: center;
	margin: 0 2px;
}

.react-datepicker__day-name {
	color: #69707d;
	text-transform: uppercase;
}

.react-datepicker__day {
	cursor: pointer;
	border: solid 2px transparent;
	-webkit-transition: -webkit-transform 90ms ease-in-out;
	transition: -webkit-transform 90ms ease-in-out;
	transition: transform 90ms ease-in-out;
	transition: transform 90ms ease-in-out, -webkit-transform 90ms ease-in-out;
}
.react-datepicker__day:hover:not(.react-datepicker__day--disabled) {
	text-decoration: underline;
	font-weight: 700;
	-webkit-transform: scale(1.2);
	transform: scale(1.2);
}
.react-datepicker__day--today {
	font-weight: bold;
}
.react-datepicker__day--outside-month {
	color: #69707d;
}
.react-datepicker__day--highlighted {
	border-radius: 4px;
	background-color: #017d73;
	color: #fff;
}
.react-datepicker__day--highlighted:hover {
	background-color: #01645c;
}
.react-datepicker__day--in-range {
	background-color: rgba(0, 107, 180, 0.1);
	color: #000;
	border-radius: 0;
	border-top: solid 6px #fff;
	border-bottom: solid 6px #fff;
	border-right: none;
	border-left: none;
	line-height: 20px;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range {
	height: 32px;
	margin: 0 2px;
	border-radius: 4px;
	background-color: #ef5742;
	line-height: 28px;
	border: solid 2px #ef5742;
	color: #fff;
}

.react-datepicker__day--keyboard-selected {
	border-radius: 4px;
	border: solid 2px #ef5742;
	font-weight: 700;
}
.react-datepicker__day--keyboard-selected:hover {
	background-color: #005c9b;
	color: #fff;
}
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
	background-color: rgba(0, 107, 180, 0.5);
}
.react-datepicker__month--selecting-range
	.react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range) {
	background-color: #fff;
	color: #000;
}
.react-datepicker__day--disabled {
	cursor: not-allowed;
	color: #d3dae6;
}
.react-datepicker__day--disabled:hover {
	background-color: transparent;
}

.react-datepicker__input-container {
	position: relative;
}

.react-datepicker__year-read-view {
	font-weight: 300;
	color: #69707d;
}

.react-datepicker__month-read-view {
	font-weight: 500;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
	font-size: 20px;
}
.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
	cursor: pointer;
	color: #ef5742;
}
.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
	border-top-color: #d3dce9;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
	display: none;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
	background-color: #fff;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	z-index: 1;
	text-align: center;
	border-radius: 4px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-animation: euiAnimFadeIn 150ms ease-in;
	animation: euiAnimFadeIn 150ms ease-in;
	-ms-flex-line-pack: distribute;
	align-content: space-around;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 8px;
}
.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
	cursor: pointer;
}

.react-datepicker__year-dropdown {
	-ms-flex-wrap: wrap-reverse;
	flex-wrap: wrap-reverse;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: reverse;
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.react-datepicker__year-option:first-of-type,
.react-datepicker__year-option:last-of-type {
	display: none;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
	font-size: 12px;
	padding: 8px;
	color: #343741;
	-ms-flex-preferred-size: 33.3%;
	flex-basis: 33.3%;
}
.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
}
.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
}
.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
	background-color: #f5f7fa;
}
.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
	border-bottom-color: #d3dce9;
}
.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
	border-top-color: #d3dce9;
}
.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
	display: none;
}

.react-datepicker__screenReaderOnly {
	position: absolute;
	left: -10000px;
	top: auto;
	width: 1px;
	height: 1px;
	overflow: hidden;
}

.react-datepicker__year-option--preselected,
.react-datepicker__month-option--preselected {
	background: #e6f0f8;
}

.react-datepicker__time-list-item--preselected,
.react-datepicker__year-option--preselected,
.react-datepicker__month-option--preselected {
	background: #d2e4f2;
}

.react-datepicker__time-container--focus {
	background: #e6f0f8;
}

.react-datepicker__month-read-view:focus,
.react-datepicker__year-read-view:focus {
	text-decoration: underline;
}

.react-datepicker__month--accessible:focus {
	background: #e6f0f8;
}
.react-datepicker__month--accessible:focus
	.react-datepicker__day--in-range:not(.react-datepicker__day--selected) {
	border-top-color: #e6f0f8;
	border-bottom-color: #e6f0f8;
}

.react-datepicker__navigation:focus {
	background-color: #e6f0f8;
}

.react-datepicker__year-option--selected_year,
.react-datepicker__month-option--selected_month {
	background: #ef5742;
	color: #fff;
	font-weight: 700;
	border-radius: 4px;
}

.react-datepicker__focusTrap {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

@media only screen and (max-width: 574px) {
	.euiDatePicker--inline {
		max-width: 284px;
		display: block;
	}
	.react-datepicker__time-container {
		display: none;
	}
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
	.euiDatePicker--inline {
		max-width: 284px;
		display: block;
	}
	.react-datepicker__time-container {
		display: none;
	}
}

/**
   * 1. Account for inner box-shadow style border
   */
.euiDatePickerRange {
	max-width: 400px;
	width: 100%;
	height: auto;
	background-color: #fbfcfd;
	background-repeat: no-repeat;
	background-size: 0% 100%;
	-webkit-box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2),
		0 3px 2px -2px rgba(152, 162, 179, 0.2), inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 3px 2px -2px rgba(152, 162, 179, 0.2),
		inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	-webkit-transition: background-image 150ms ease-in, background-size 150ms ease-in,
		background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	transition: background-image 150ms ease-in, background-size 150ms ease-in,
		background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
		background-size 150ms ease-in, background-color 150ms ease-in;
	transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
		background-size 150ms ease-in, background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 1px;
	/* 1 */
}
.euiDatePickerRange--fullWidth {
	max-width: 100%;
}
.euiDatePickerRange--compressed {
	height: 32px;
}
@supports (-moz-appearance: none) {
	.euiDatePickerRange {
		-webkit-transition-property: background-image, background-size, -webkit-box-shadow;
		transition-property: background-image, background-size, -webkit-box-shadow;
		transition-property: box-shadow, background-image, background-size;
		transition-property: box-shadow, background-image, background-size, -webkit-box-shadow;
	}
}
.euiDatePickerRange > * {
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}
.euiDatePickerRange .euiFieldText.euiDatePicker {
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	text-align: center;
}
.euiDatePickerRange .react-datepicker-popper .euiFieldText.euiDatePicker {
	text-align: left;
}
.euiDatePickerRange--inGroup {
	-webkit-box-shadow: none;
	box-shadow: none;
	padding: 0;
	height: 100%;
}
.euiDatePickerRange--inGroup .euiDatePicker {
	height: 38px;
}
.euiDatePickerRange > .euiDatePickerRange__icon {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	padding-left: 12px;
	padding-right: 12px;
}
.euiDatePickerRange > .euiDatePickerRange__delimeter {
	background-color: transparent !important;
	line-height: 1 !important;
	-webkit-box-flex: 0;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	padding-left: 6px;
	padding-right: 6px;
}

.euiDatePickerRange--readOnly {
	background: #eef2f7;
}

.euiSuperDatePicker__absoluteDateFormRow {
	padding: 0 8px 8px;
}

.euiDatePopoverButton {
	font-family: var(--primary-font), 'Inter UI', -apple-system, BlinkMacSystemFont, 'Segoe UI',
		Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
	font-weight: 400;
	letter-spacing: -0.005em;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-webkit-font-kerning: normal;
	font-kerning: normal;
	font-size: 14px;
	color: #343741;
	display: block;
	width: 100%;
	padding: 0 8px;
	line-height: 38px;
	height: 38px;
	word-break: break-all;
	-webkit-transition: background 150ms ease-in;
	transition: background 150ms ease-in;
	background-size: 100%;
}
.euiDatePopoverButton::-webkit-input-placeholder {
	color: #69707d;
}
.euiDatePopoverButton::-moz-placeholder {
	color: #69707d;
}
.euiDatePopoverButton:-ms-input-placeholder {
	color: #69707d;
}
.euiDatePopoverButton:-moz-placeholder {
	color: #69707d;
}
.euiDatePopoverButton::-ms-input-placeholder {
	color: #69707d;
}
.euiDatePopoverButton::placeholder {
	color: #69707d;
}
.euiDatePopoverButton:focus,
.euiDatePopoverButton-isSelected {
	background-image: linear-gradient(
		to top,
		#ef5742,
		#ef5742 2px,
		transparent 2px,
		transparent 100%
	);
}
.euiDatePopoverButton-needsUpdating {
	background-color: #e6f2f1;
	color: #01776d;
}
.euiDatePopoverButton-needsUpdating:focus,
.euiDatePopoverButton-needsUpdating.euiDatePopoverButton-isSelected {
	background-image: linear-gradient(
		to top,
		#017d73,
		#017d73 2px,
		transparent 2px,
		transparent 100%
	);
}
.euiDatePopoverButton-isInvalid {
	background-color: #f8e9e9;
	color: #bd271e;
}
.euiDatePopoverButton-isInvalid:focus,
.euiDatePopoverButton-isInvalid.euiDatePopoverButton-isSelected {
	background-image: linear-gradient(
		to top,
		#bd271e,
		#bd271e 2px,
		transparent 2px,
		transparent 100%
	);
}
.euiDatePopoverButton:disabled {
	background-color: #eef2f7;
	color: #69707d;
	cursor: default;
}

.euiDatePopoverButton--start {
	text-align: right;
}

.euiDatePopoverButton--end {
	text-align: left;
}

.euiDatePopoverContent {
	width: 400px;
	max-width: 100%;
}

.euiDatePopoverContent__padded {
	padding: 8px;
}

.euiDatePopoverContent__padded--large {
	padding: 16px;
}

@media only screen and (max-width: 574px) {
	.euiDatePopoverContent {
		width: 284px;
	}
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
	.euiDatePopoverContent {
		width: 284px;
	}
}

.euiQuickSelectPopover__content {
	width: 400px;
	max-width: 100%;
}

.euiQuickSelectPopover__section {
	scrollbar-width: thin;
	max-height: 132px;
	overflow: hidden;
	overflow-y: auto;
	padding: 4px 0;
}
.euiQuickSelectPopover__section::-webkit-scrollbar {
	width: 16px;
	height: 16px;
}
.euiQuickSelectPopover__section::-webkit-scrollbar-thumb {
	background-color: rgba(105, 112, 125, 0.5);
	border: 6px solid transparent;
	background-clip: content-box;
}
.euiQuickSelectPopover__section::-webkit-scrollbar-corner,
.euiQuickSelectPopover__section::-webkit-scrollbar-track {
	background-color: transparent;
}

.euiQuickSelectPopover__buttonText {
	margin-right: 4px !important;
}

.euiQuickSelectPopover__anchor {
	height: 100%;
}

.euiQuickSelect__applyButton {
	min-width: 0;
}

.euiRefreshInterval__startButton {
	min-width: 90px;
}

.euiCommonlyUsedTimeRanges__item {
	font-size: 14px;
	line-height: 14px;
}

.euiSuperDatePicker__flexWrapper {
	max-width: calc(100% + 8px);
	width: 500px;
}

.euiSuperDatePicker__flexWrapper--isAutoRefreshOnly {
	width: 400px;
	max-width: 100%;
}

.euiSuperDatePicker__flexWrapper--noUpdateButton {
	width: 480px;
	max-width: 100%;
}

.euiSuperDatePicker {
	max-width: 100% !important;
}
.euiSuperDatePicker > .euiFormControlLayout__childrenWrapper {
	-webkit-box-flex: 1;
	-ms-flex: 1 1 100%;
	flex: 1 1 100%;
	overflow: hidden;
}
.euiSuperDatePicker > .euiFormControlLayout__childrenWrapper > .euiDatePickerRange {
	max-width: none;
	width: auto;
}

.euiSuperDatePicker__startPopoverButton {
	margin-right: -12px;
}

.euiSuperDatePicker__prettyFormat {
	font-family: var(--primary-font), 'Inter UI', -apple-system, BlinkMacSystemFont, 'Segoe UI',
		Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
	font-weight: 400;
	letter-spacing: -0.005em;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-webkit-font-kerning: normal;
	font-kerning: normal;
	font-size: 14px;
	color: #343741;
	width: 100%;
	padding: 0 8px;
	line-height: 38px;
	height: 38px;
	word-break: break-all;
	-webkit-transition: background 150ms ease-in;
	transition: background 150ms ease-in;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	text-align: left;
}
.euiSuperDatePicker__prettyFormat::-webkit-input-placeholder {
	color: #69707d;
}
.euiSuperDatePicker__prettyFormat::-moz-placeholder {
	color: #69707d;
}
.euiSuperDatePicker__prettyFormat:-ms-input-placeholder {
	color: #69707d;
}
.euiSuperDatePicker__prettyFormat:-moz-placeholder {
	color: #69707d;
}
.euiSuperDatePicker__prettyFormat::-ms-input-placeholder {
	color: #69707d;
}
.euiSuperDatePicker__prettyFormat::placeholder {
	color: #69707d;
}
.euiSuperDatePicker__prettyFormat:not(:disabled):hover,
.euiSuperDatePicker__prettyFormat:focus {
	text-decoration: none;
}
.euiSuperDatePicker__prettyFormat:not(:disabled):hover .euiSuperDatePicker__prettyFormatLink,
.euiSuperDatePicker__prettyFormat:focus .euiSuperDatePicker__prettyFormatLink {
	text-decoration: underline;
}
.euiSuperDatePicker__prettyFormat:disabled {
	background-color: #eef2f7;
	color: #69707d;
	cursor: not-allowed;
}
.euiSuperDatePicker__prettyFormat:disabled .euiSuperDatePicker__prettyFormatLink {
	display: none;
}

.euiSuperDatePicker__prettyFormatLink {
	color: #ef5742;
	padding-left: 4px;
	-ms-flex-negative: 0;
	flex-shrink: 0;
}

@media only screen and (max-width: 574px) {
	.euiSuperDatePicker__flexWrapper,
	.euiSuperDatePicker__flexWrapper--isAutoRefreshOnly,
	.euiSuperDatePicker__flexWrapper--noUpdateButton {
		width: 100%;
	}
	.euiSuperDatePicker__prettyFormatLink {
		-ms-flex-negative: 1;
		flex-shrink: 1;
		min-width: 3em;
	}
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
	.euiSuperDatePicker__flexWrapper,
	.euiSuperDatePicker__flexWrapper--isAutoRefreshOnly,
	.euiSuperDatePicker__flexWrapper--noUpdateButton {
		width: 100%;
	}
	.euiSuperDatePicker__prettyFormatLink {
		-ms-flex-negative: 1;
		flex-shrink: 1;
		min-width: 3em;
	}
}

.euiSuperUpdateButton {
	min-width: 118px;
}

@media only screen and (max-width: 574px) {
	.euiSuperUpdateButton {
		min-width: 0;
	}
	.euiSuperUpdateButton .euiSuperUpdateButton__text {
		display: none;
	}
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
	.euiSuperUpdateButton {
		min-width: 0;
	}
	.euiSuperUpdateButton .euiSuperUpdateButton__text {
		display: none;
	}
}

.euiDataGrid {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	overflow: hidden;
	height: 100%;
}

.euiDataGrid--fullScreen {
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 8000;
	background: #fff;
}
.euiDataGrid--fullScreen .euiDataGrid__pagination {
	padding-bottom: 4px;
	background: #f5f7fa;
	border-top: 1px solid #d3dae6;
}

.euiDataGrid__content {
	scrollbar-width: thin;
	height: 100%;
	overflow: auto;
	-webkit-font-feature-settings: 'tnum' 1;
	font-feature-settings: 'tnum' 1;
	scroll-padding: 0;
	max-width: 100%;
	width: 100%;
	z-index: 2;
}
.euiDataGrid__content::-webkit-scrollbar {
	width: 16px;
	height: 16px;
}
.euiDataGrid__content::-webkit-scrollbar-thumb {
	background-color: rgba(105, 112, 125, 0.5);
	border: 6px solid transparent;
	background-clip: content-box;
}
.euiDataGrid__content::-webkit-scrollbar-corner,
.euiDataGrid__content::-webkit-scrollbar-track {
	background-color: transparent;
}

.euiDataGrid__controls {
	background: #fafbfd;
	position: relative;
	z-index: 3;
	border: 1px solid #d3dae6;
	padding: 4px;
	-webkit-box-flex: 0;
	-ms-flex-positive: 0;
	flex-grow: 0;
}
.euiDataGrid__controls > * {
	margin-left: 2px;
}

.euiDataGrid__controlBtn {
	border-radius: 4px;
}
.euiDataGrid__controlBtn:focus {
	background: #dddee1;
}

.euiDataGrid__controlBtn--active,
.euiDataGrid__controlBtn--active:focus {
	font-weight: 600;
	color: #000;
}

.euiDataGrid--bordersNone .euiDataGrid__controls {
	border: none;
	background: #fff;
}

.euiDataGrid--bordersHorizontal .euiDataGrid__controls {
	border-right: none;
	border-left: none;
	border-top: none;
	background: #fff;
}

.euiDataGrid__pagination {
	padding-top: 4px;
	-webkit-box-flex: 0;
	-ms-flex-positive: 0;
	flex-grow: 0;
}

.euiDataGrid__verticalScroll {
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	overflow-y: hidden;
	height: 100%;
}

.euiDataGrid__overflow {
	overflow-y: hidden;
	height: 100%;
	background: #fafbfd;
}

.euiDataGrid__restrictBody {
	height: 100vh;
	overflow: hidden;
}

.euiDataGridHeader {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	min-width: 100%;
	z-index: 3;
	background: #f5f7fa;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
}

.euiDataGridHeaderCell {
	font-size: 14px;
	font-size: 0.875rem;
	line-height: 1.5;
	font-weight: 700;
	padding: 6px;
	-webkit-box-flex: 0;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	position: relative;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.euiDataGridHeaderCell > * {
	max-width: 100%;
	width: 100%;
}
.euiDataGridHeaderCell.euiDataGridHeaderCell--numeric {
	text-align: right;
}
.euiDataGridHeaderCell.euiDataGridHeaderCell--currency {
	text-align: right;
}
.euiDataGridHeaderCell:focus {
	border: 1px solid transparent;
	-webkit-box-shadow: 0 0 0 2px rgba(0, 107, 180, 0.3);
	box-shadow: 0 0 0 2px rgba(0, 107, 180, 0.3);
	border-radius: 1px;
	z-index: 2;
}
.euiDataGridHeaderCell:not(.euiDataGridHeaderCell--controlColumn) .euiDataGridHeaderCell__content {
	max-width: 100%;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
	white-space: nowrap !important;
	word-wrap: normal !important;
}

.euiDataGrid--bordersNone.euiDataGrid--bordersHorizontal .euiDataGridHeader {
	background: #fff;
}

.euiDataGrid--headerUnderline .euiDataGridHeaderCell {
	border-top: none;
	border-left: none;
	border-right: none;
	border-bottom: 2px solid #d3dae6;
	border-bottom-color: #343741;
}

.euiDataGrid--bordersNone.euiDataGrid--headerUnderline .euiDataGridHeaderCell {
	border-bottom: 2px solid #d3dae6;
	border-color: #343741;
}

.euiDataGrid--headerShade .euiDataGridHeaderCell {
	background: #f5f7fa;
}

.euiDataGrid--headerShade.euiDataGrid--bordersAll .euiDataGridHeaderCell {
	border-right: 1px solid #d3dae6;
	border-bottom: 1px solid #d3dae6;
	border-left: none;
}
.euiDataGrid--headerShade.euiDataGrid--bordersAll .euiDataGridHeaderCell:first-of-type {
	border-left: 1px solid #d3dae6;
}

.euiDataGrid--headerShade.euiDataGrid--bordersHorizontal .euiDataGridHeaderCell {
	border-top: none;
	border-bottom: 1px solid #d3dae6;
}

.euiDataGrid--bordersNone .euiDataGridHeaderCell {
	border: none;
}

.euiDataGrid--borderhorizontal .euiDataGridHeaderCell {
	border-top: none;
	border-right: none;
	border-left: none;
}

.euiDataGrid--fontSizeSmall .euiDataGridHeaderCell {
	font-size: 12px;
	font-size: 0.75rem;
	line-height: 1.5;
}

.euiDataGrid--fontSizeLarge .euiDataGridHeaderCell {
	font-size: 16px;
	font-size: 1rem;
	line-height: 1.5;
}

.euiDataGrid--paddingSmall .euiDataGridHeaderCell {
	padding: 4px;
}

.euiDataGrid--paddingLarge .euiDataGridHeaderCell {
	padding: 8px;
}

.euiDataGrid--noControls.euiDataGrid--bordersAll .euiDataGridHeaderCell {
	border-top: 1px solid #d3dae6;
}

.euiDataGrid--noControls.euiDataGrid--bordersHorizontal .euiDataGridHeaderCell {
	border-top: 1px solid #d3dae6;
}

.euiDataGridColumnResizer {
	position: absolute;
	top: 0;
	right: -8px;
	height: 100%;
	width: 16px;
	cursor: ew-resize;
	opacity: 0;
	z-index: 2;
}
.euiDataGridColumnResizer:after {
	content: '';
	position: absolute;
	left: 7px;
	top: 0;
	bottom: 0;
	width: 3px;
	background-color: #ef5742;
}
.euiDataGridColumnResizer:hover,
.euiDataGridColumnResizer:active {
	opacity: 1;
}
.euiDataGridColumnResizer:hover ~ .euiDataGridHeaderCell__content,
.euiDataGridColumnResizer:active ~ .euiDataGridHeaderCell__content {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.euiDataGridHeaderCell:last-child .euiDataGridColumnResizer {
	right: 0;
}
.euiDataGridHeaderCell:last-child .euiDataGridColumnResizer:after {
	left: auto;
	right: 0;
}

.euiDataGridRow {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	min-width: 100%;
}

.euiDataGridRowCell {
	font-size: 14px;
	font-size: 0.875rem;
	line-height: 1.5;
	padding: 6px;
	border-right: solid 1px #edf0f5;
	border-bottom: 1px solid #d3dae6;
	-webkit-box-flex: 0;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	background: #fff;
	position: relative;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.euiDataGridRowCell > * {
	max-width: 100%;
	width: 100%;
}
.euiDataGridRowCell:first-of-type {
	border-left: 1px solid #d3dae6;
}
.euiDataGridRowCell:last-of-type {
	border-right-color: #d3dae6;
}
.euiDataGridRowCell:focus {
	border: 1px solid transparent;
	margin-top: -1px;
	-webkit-box-shadow: 0 0 0 2px rgba(0, 107, 180, 0.3);
	box-shadow: 0 0 0 2px rgba(0, 107, 180, 0.3);
	border-radius: 1px;
	z-index: 2;
}
.euiDataGridRowCell:focus .euiDataGridRowCell__expandButton {
	margin-left: 6px;
}
.euiDataGridRowCell:focus .euiDataGridRowCell__expandButtonIcon {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: inherit;
	visibility: visible;
}
.euiDataGridRowCell:focus:not(:first-of-type) {
	padding-left: 5px;
}
.euiDataGridRowCell.euiDataGridRowCell--numeric {
	text-align: right;
}
.euiDataGridRowCell.euiDataGridRowCell--currency {
	text-align: right;
}
.euiDataGridRowCell.euiDataGridRowCell--boolean {
	text-transform: capitalize;
}
.euiDataGridRowCell:not(.euiDataGridRowCell--controlColumn) .euiDataGridRowCell__content,
.euiDataGridRowCell:not(.euiDataGridRowCell--controlColumn) .euiDataGridRowCell__truncate,
.euiDataGridRowCell:not(.euiDataGridRowCell--controlColumn) .euiDataGridRowCell__expandContent {
	max-width: 100%;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
	white-space: nowrap !important;
	word-wrap: normal !important;
}

.euiDataGridRowCell__popover {
	scrollbar-width: thin;
	overflow: auto;
	max-width: 400px !important;
	max-height: 400px !important;
}
.euiDataGridRowCell__popover::-webkit-scrollbar {
	width: 16px;
	height: 16px;
}
.euiDataGridRowCell__popover::-webkit-scrollbar-thumb {
	background-color: rgba(105, 112, 125, 0.5);
	border: 6px solid transparent;
	background-clip: content-box;
}
.euiDataGridRowCell__popover::-webkit-scrollbar-corner,
.euiDataGridRowCell__popover::-webkit-scrollbar-track {
	background-color: transparent;
}

.euiDataGridRowCell__expand {
	width: 100%;
	max-width: 100%;
}

.euiDataGridRowCell__expandFlex {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.euiDataGridRowCell__expandContent {
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

.euiDataGridRowCell__expandButton {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 0;
	-ms-flex-positive: 0;
	flex-grow: 0;
}
.euiDataGridRowCell__expandButton-isActive,
.euiDataGridRowCell__expandButton:focus {
	margin-left: 6px;
}

.euiDataGridRowCell__expandButtonIcon {
	height: 12px;
	min-height: 12px;
	background: #ef5742;
	color: #fff;
	border-radius: 2px;
	padding: 0;
	width: 0;
	min-width: 0;
	overflow: hidden;
	visibility: hidden;
}
.euiDataGridRowCell__expandButtonIcon-isActive,
.euiDataGridRowCell__expandButtonIcon:focus {
	width: inherit;
	visibility: visible;
	background: #ef5742;
}

.euiDataGrid--rowHoverHighlight .euiDataGridRow:hover .euiDataGridRowCell {
	background-color: #fffcdd !important;
}

.euiDataGrid--stripes .euiDataGridRow:nth-child(odd) .euiDataGridRowCell {
	background: #f5f7fa;
}

.euiDataGrid--bordersNone .euiDataGridRowCell {
	border-color: transparent !important;
}

.euiDataGrid--bordersHorizontal .euiDataGridRowCell {
	border-right-color: transparent;
	border-left-color: transparent;
}

.euiDataGrid--fontSizeSmall .euiDataGridRowCell {
	font-size: 12px;
	font-size: 0.75rem;
	line-height: 1.5;
}

.euiDataGrid--fontSizeLarge .euiDataGridRowCell {
	font-size: 16px;
	font-size: 1rem;
	line-height: 1.5;
}

.euiDataGrid--paddingSmall .euiDataGridRowCell {
	padding: 4px;
}
.euiDataGrid--paddingSmall .euiDataGridRowCell:focus:not(:first-of-type) {
	padding-left: 3px;
}

.euiDataGrid--paddingLarge .euiDataGridRowCell {
	padding: 8px;
}
.euiDataGrid--paddingLarge .euiDataGridRowCell:focus:not(:first-of-type) {
	padding-left: 7px;
}

.euiDataGridColumnSelector__item {
	padding: 4px;
}
.euiDataGridColumnSelector__item-isDragging {
	-webkit-box-shadow: 0 12px 24px 0 rgba(65, 78, 101, 0.1), 0 6px 12px 0 rgba(65, 78, 101, 0.1),
		0 4px 4px 0 rgba(65, 78, 101, 0.1), 0 2px 2px 0 rgba(65, 78, 101, 0.1);
	box-shadow: 0 12px 24px 0 rgba(65, 78, 101, 0.1), 0 6px 12px 0 rgba(65, 78, 101, 0.1),
		0 4px 4px 0 rgba(65, 78, 101, 0.1), 0 2px 2px 0 rgba(65, 78, 101, 0.1);
	background: #fff;
}

.euiDataGridColumnSelector__columnList {
	scrollbar-width: thin;
	height: 100%;
	overflow-y: auto;
	-webkit-mask-image: linear-gradient(
		to bottom,
		rgba(255, 0, 0, 0.1) 0%,
		red 7.5px,
		red calc(100% - 7.5px),
		rgba(255, 0, 0, 0.1) 100%
	);
	mask-image: linear-gradient(
		to bottom,
		rgba(255, 0, 0, 0.1) 0%,
		red 7.5px,
		red calc(100% - 7.5px),
		rgba(255, 0, 0, 0.1) 100%
	);
	max-height: 400px;
	padding: 8px;
	margin: 0 -8px;
}
.euiDataGridColumnSelector__columnList::-webkit-scrollbar {
	width: 16px;
	height: 16px;
}
.euiDataGridColumnSelector__columnList::-webkit-scrollbar-thumb {
	background-color: rgba(105, 112, 125, 0.5);
	border: 6px solid transparent;
	background-clip: content-box;
}
.euiDataGridColumnSelector__columnList::-webkit-scrollbar-corner,
.euiDataGridColumnSelector__columnList::-webkit-scrollbar-track {
	background-color: transparent;
}

.euiDataGridColumnSelector__itemLabel {
	font-size: 12px;
	font-size: 0.75rem;
	line-height: 1.5;
}

.euiDataGridColumnSelectorPopover {
	-webkit-transform: none !important;
	transform: none !important;
	-webkit-transition: none !important;
	transition: none !important;
	margin-top: -8px;
	min-width: 192px;
}

.euiDataGridColumnSorting__item-isDragging {
	-webkit-box-shadow: 0 12px 24px 0 rgba(65, 78, 101, 0.1), 0 6px 12px 0 rgba(65, 78, 101, 0.1),
		0 4px 4px 0 rgba(65, 78, 101, 0.1), 0 2px 2px 0 rgba(65, 78, 101, 0.1);
	box-shadow: 0 12px 24px 0 rgba(65, 78, 101, 0.1), 0 6px 12px 0 rgba(65, 78, 101, 0.1),
		0 4px 4px 0 rgba(65, 78, 101, 0.1), 0 2px 2px 0 rgba(65, 78, 101, 0.1);
	background: #fff;
}

.euiDataGridColumnSortingPopover {
	-webkit-transform: none !important;
	transform: none !important;
	-webkit-transition: none !important;
	transition: none !important;
	margin-top: -8px;
	min-width: 192px;
}

.euiDataGridColumnSorting__button {
	height: 20px !important;
	width: 20px !important;
	padding: 2px !important;
}

.euiDataGridColumnSorting__fieldList {
	scrollbar-width: thin;
	height: 100%;
	overflow-y: auto;
	-webkit-mask-image: linear-gradient(
		to bottom,
		rgba(255, 0, 0, 0.1) 0%,
		red 7.5px,
		red calc(100% - 7.5px),
		rgba(255, 0, 0, 0.1) 100%
	);
	mask-image: linear-gradient(
		to bottom,
		rgba(255, 0, 0, 0.1) 0%,
		red 7.5px,
		red calc(100% - 7.5px),
		rgba(255, 0, 0, 0.1) 100%
	);
	max-height: 300px;
}
.euiDataGridColumnSorting__fieldList::-webkit-scrollbar {
	width: 16px;
	height: 16px;
}
.euiDataGridColumnSorting__fieldList::-webkit-scrollbar-thumb {
	background-color: rgba(105, 112, 125, 0.5);
	border: 6px solid transparent;
	background-clip: content-box;
}
.euiDataGridColumnSorting__fieldList::-webkit-scrollbar-corner,
.euiDataGridColumnSorting__fieldList::-webkit-scrollbar-track {
	background-color: transparent;
}

.euiDataGridColumnSorting__field {
	display: block;
	padding: 4px;
	width: 100%;
}
.euiDataGridColumnSorting__field:focus {
	background: #e6f0f8;
	text-decoration: underline;
}

.euiDataGridColumnSorting__orderButtons {
	padding-left: 24px;
}
.euiDataGridColumnSorting__orderButtons .euiDataGridColumnSorting__order {
	min-width: 200px;
	border: none;
}
.euiDataGridColumnSorting__orderButtons .euiDataGridColumnSorting__order button {
	font-size: 12px !important;
}

.euiDescriptionList.euiDescriptionList--row .euiDescriptionList__title {
	color: #1a1c21;
	font-size: 16px;
	font-size: 1rem;
	font-weight: 600;
	letter-spacing: -0.02em;
	line-height: 1.5;
	margin-top: 16px;
}
.euiDescriptionList.euiDescriptionList--row .euiDescriptionList__title:first-of-type {
	margin-top: 0;
}

.euiDescriptionList.euiDescriptionList--row .euiDescriptionList__description {
	font-size: 14px;
	font-size: 0.875rem;
	line-height: 1.5;
}

.euiDescriptionList.euiDescriptionList--row.euiDescriptionList--center {
	text-align: center;
}

.euiDescriptionList.euiDescriptionList--row.euiDescriptionList--reverse .euiDescriptionList__title {
	color: #343741;
	font-weight: 400;
	font-size: 14px;
	font-size: 0.875rem;
	line-height: 1.5;
}

.euiDescriptionList.euiDescriptionList--row.euiDescriptionList--reverse
	.euiDescriptionList__description {
	color: #1a1c21;
	font-size: 16px;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 600;
	letter-spacing: -0.02em;
}

.euiDescriptionList.euiDescriptionList--row.euiDescriptionList--compressed
	.euiDescriptionList__title {
	color: #1a1c21;
	font-size: 14px;
	font-size: 0.875rem;
	font-weight: 700;
	line-height: 1.5;
}

.euiDescriptionList.euiDescriptionList--row.euiDescriptionList--compressed
	.euiDescriptionList__description {
	font-size: 14px;
	font-size: 0.875rem;
	line-height: 1.5;
}

.euiDescriptionList.euiDescriptionList--row.euiDescriptionList--compressed.euiDescriptionList--reverse
	.euiDescriptionList__title {
	color: #343741;
	font-weight: 400;
	font-size: 14px;
	font-size: 0.875rem;
	line-height: 1.5;
}

.euiDescriptionList.euiDescriptionList--row.euiDescriptionList--compressed.euiDescriptionList--reverse
	.euiDescriptionList__description {
	color: #1a1c21;
	font-size: 14px;
	font-size: 0.875rem;
	font-weight: 700;
	line-height: 1.5;
}

.euiDescriptionList.euiDescriptionList--column,
.euiDescriptionList.euiDescriptionList--responsiveColumn {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}
.euiDescriptionList.euiDescriptionList--column > *,
.euiDescriptionList.euiDescriptionList--responsiveColumn > * {
	margin-top: 16px;
}
.euiDescriptionList.euiDescriptionList--column > *:first-child,
.euiDescriptionList.euiDescriptionList--column > :nth-child(2),
.euiDescriptionList.euiDescriptionList--responsiveColumn > *:first-child,
.euiDescriptionList.euiDescriptionList--responsiveColumn > :nth-child(2) {
	margin-top: 0;
}
.euiDescriptionList.euiDescriptionList--column .euiDescriptionList__title,
.euiDescriptionList.euiDescriptionList--responsiveColumn .euiDescriptionList__title {
	color: #1a1c21;
	font-size: 16px;
	font-size: 1rem;
	font-weight: 600;
	letter-spacing: -0.02em;
	line-height: 1.5;
	width: 50%;
	padding-right: 8px;
}
.euiDescriptionList.euiDescriptionList--column .euiDescriptionList__description,
.euiDescriptionList.euiDescriptionList--responsiveColumn .euiDescriptionList__description {
	font-size: 16px;
	font-size: 1rem;
	line-height: 1.5;
	width: 50%;
	padding-left: 8px;
}
.euiDescriptionList.euiDescriptionList--column.euiDescriptionList--center
	.euiDescriptionList__title,
.euiDescriptionList.euiDescriptionList--responsiveColumn.euiDescriptionList--center
	.euiDescriptionList__title {
	text-align: right;
}
.euiDescriptionList.euiDescriptionList--column.euiDescriptionList--reverse
	.euiDescriptionList__title,
.euiDescriptionList.euiDescriptionList--responsiveColumn.euiDescriptionList--reverse
	.euiDescriptionList__title {
	color: #343741;
	font-weight: 400;
	font-size: 16px;
	font-size: 1rem;
	line-height: 1.5;
}
.euiDescriptionList.euiDescriptionList--column.euiDescriptionList--reverse
	.euiDescriptionList__description,
.euiDescriptionList.euiDescriptionList--responsiveColumn.euiDescriptionList--reverse
	.euiDescriptionList__description {
	color: #1a1c21;
	font-size: 16px;
	font-size: 1rem;
	font-weight: 600;
	letter-spacing: -0.02em;
	line-height: 1.5;
}
.euiDescriptionList.euiDescriptionList--column.euiDescriptionList--compressed
	.euiDescriptionList__title,
.euiDescriptionList.euiDescriptionList--responsiveColumn.euiDescriptionList--compressed
	.euiDescriptionList__title {
	color: #1a1c21;
	font-size: 14px;
	font-size: 0.875rem;
	font-weight: 700;
	line-height: 1.5;
}
.euiDescriptionList.euiDescriptionList--column.euiDescriptionList--compressed
	.euiDescriptionList__description,
.euiDescriptionList.euiDescriptionList--responsiveColumn.euiDescriptionList--compressed
	.euiDescriptionList__description {
	font-size: 14px;
	font-size: 0.875rem;
	line-height: 1.5;
}
.euiDescriptionList.euiDescriptionList--column.euiDescriptionList--compressed.euiDescriptionList--reverse
	.euiDescriptionList__title,
.euiDescriptionList.euiDescriptionList--responsiveColumn.euiDescriptionList--compressed.euiDescriptionList--reverse
	.euiDescriptionList__title {
	color: #343741;
	font-weight: 400;
	font-size: 14px;
	font-size: 0.875rem;
	line-height: 1.5;
}
.euiDescriptionList.euiDescriptionList--column.euiDescriptionList--compressed.euiDescriptionList--reverse
	.euiDescriptionList__description,
.euiDescriptionList.euiDescriptionList--responsiveColumn.euiDescriptionList--compressed.euiDescriptionList--reverse
	.euiDescriptionList__description {
	color: #1a1c21;
	font-size: 14px;
	font-size: 0.875rem;
	font-weight: 700;
	line-height: 1.5;
}

@media only screen and (max-width: 574px) {
	.euiDescriptionList.euiDescriptionList--responsiveColumn {
		display: block;
	}
	.euiDescriptionList.euiDescriptionList--responsiveColumn .euiDescriptionList__title,
	.euiDescriptionList.euiDescriptionList--responsiveColumn .euiDescriptionList__description {
		width: 100%;
		padding: 0;
	}
	.euiDescriptionList.euiDescriptionList--responsiveColumn .euiDescriptionList__description {
		font-size: 14px;
		font-size: 0.875rem;
		line-height: 1.5;
		margin-top: 0;
	}
	.euiDescriptionList.euiDescriptionList--responsiveColumn.euiDescriptionList--center
		.euiDescriptionList__title,
	.euiDescriptionList.euiDescriptionList--responsiveColumn.euiDescriptionList--center
		.euiDescriptionList__description {
		text-align: center;
	}
	.euiDescriptionList.euiDescriptionList--responsiveColumn.euiDescriptionList--reverse
		.euiDescriptionList__title {
		font-size: 14px;
		font-size: 0.875rem;
		line-height: 1.5;
	}
	.euiDescriptionList.euiDescriptionList--responsiveColumn.euiDescriptionList--reverse
		.euiDescriptionList__description {
		color: #1a1c21;
		font-size: 16px;
		font-size: 1rem;
		line-height: 1.5rem;
		font-weight: 600;
		letter-spacing: -0.02em;
	}
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
	.euiDescriptionList.euiDescriptionList--responsiveColumn {
		display: block;
	}
	.euiDescriptionList.euiDescriptionList--responsiveColumn .euiDescriptionList__title,
	.euiDescriptionList.euiDescriptionList--responsiveColumn .euiDescriptionList__description {
		width: 100%;
		padding: 0;
	}
	.euiDescriptionList.euiDescriptionList--responsiveColumn .euiDescriptionList__description {
		font-size: 14px;
		font-size: 0.875rem;
		line-height: 1.5;
		margin-top: 0;
	}
	.euiDescriptionList.euiDescriptionList--responsiveColumn.euiDescriptionList--center
		.euiDescriptionList__title,
	.euiDescriptionList.euiDescriptionList--responsiveColumn.euiDescriptionList--center
		.euiDescriptionList__description {
		text-align: center;
	}
	.euiDescriptionList.euiDescriptionList--responsiveColumn.euiDescriptionList--reverse
		.euiDescriptionList__title {
		font-size: 14px;
		font-size: 0.875rem;
		line-height: 1.5;
	}
	.euiDescriptionList.euiDescriptionList--responsiveColumn.euiDescriptionList--reverse
		.euiDescriptionList__description {
		color: #1a1c21;
		font-size: 16px;
		font-size: 1rem;
		line-height: 1.5rem;
		font-weight: 600;
		letter-spacing: -0.02em;
	}
}

.euiDescriptionList.euiDescriptionList--inline .euiDescriptionList__title {
	font-size: 14px;
	font-size: 0.875rem;
	line-height: 1.5;
	display: inline;
	border-radius: 4px;
	font-weight: 400;
	background: #f5f7fa;
	border: 1px solid #d3dae6;
	padding: 0 4px;
	margin: 0 4px;
}
.euiDescriptionList.euiDescriptionList--inline .euiDescriptionList__title:first-of-type {
	margin-left: 0;
}

.euiDescriptionList.euiDescriptionList--inline .euiDescriptionList__description {
	font-size: 14px;
	font-size: 0.875rem;
	line-height: 1.5;
	display: inline;
	word-break: break-all;
}

.euiDescriptionList.euiDescriptionList--inline.euiDescriptionList--compressed
	.euiDescriptionList__title {
	font-size: 12px;
	font-size: 0.75rem;
	line-height: 1.5;
}

.euiDescriptionList.euiDescriptionList--inline.euiDescriptionList--compressed
	.euiDescriptionList__description {
	font-size: 12px;
	font-size: 0.75rem;
	line-height: 1.5;
}

.euiDescriptionList.euiDescriptionList--inline.euiDescriptionList--center {
	text-align: center;
}

.euiDraggable.euiDraggable--isDragging {
	z-index: 9000 !important;
}

.euiDraggable.euiDraggable--hasClone:not(.euiDraggable--isDragging) {
	-webkit-transform: none !important;
	transform: none !important;
}

.euiDraggable.euiDraggable--withoutDropAnimation {
	-webkit-transition-duration: 0.001s !important;
	transition-duration: 0.001s !important;
}

.euiDraggable:focus > .euiDraggable__item,
.euiDraggable.euiDraggable--hasCustomDragHandle
	> .euiDraggable__item
	[data-react-beautiful-dnd-drag-handle]:focus {
	-webkit-animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
	animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
}

.euiDraggable .euiDraggable__item.euiDraggable__item--isDisabled {
	cursor: not-allowed;
}

.euiDraggable--s {
	padding: 2px;
}

.euiDraggable--m {
	padding: 4px;
}

.euiDraggable--l {
	padding: 8px;
}

.euiDroppable {
	-webkit-transition: background-color 500ms ease;
	transition: background-color 500ms ease;
}
.euiDroppable.euiDroppable--isDraggingType:not(.euiDroppable--isDisabled) {
	background-color: rgba(1, 125, 115, 0.1);
}
.euiDroppable.euiDroppable--isDraggingType:not(
		.euiDroppable--isDisabled
	).euiDroppable--isDraggingOver {
	background-color: rgba(1, 125, 115, 0.25);
}
.euiDroppable .euiDroppable__placeholder.euiDroppable__placeholder--isHidden {
	display: none !important;
}

.euiDroppable--withPanel {
	-webkit-box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3),
		0 1px 5px -2px rgba(152, 162, 179, 0.3);
	box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3), 0 1px 5px -2px rgba(152, 162, 179, 0.3);
	background-color: #fff;
	border: 1px solid #d3dae6;
	border-radius: 4px;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}
.euiDroppable--withPanel.euiDroppable--withPanel--flexGrowZero {
	-webkit-box-flex: 0;
	-ms-flex-positive: 0;
	flex-grow: 0;
}
.euiDroppable--withPanel.euiDroppable--withPanel--isClickable {
	display: block;
	width: 100%;
	text-align: left;
	-webkit-transition: all 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
	transition: all 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
}
.euiDroppable--withPanel.euiDroppable--withPanel--isClickable:hover,
.euiDroppable--withPanel.euiDroppable--withPanel--isClickable:focus {
	-webkit-box-shadow: 0 4px 8px 0 rgba(152, 162, 179, 0.15), 0 2px 2px -1px rgba(152, 162, 179, 0.3);
	box-shadow: 0 4px 8px 0 rgba(152, 162, 179, 0.15), 0 2px 2px -1px rgba(152, 162, 179, 0.3);
	-webkit-transform: translateY(-2px);
	transform: translateY(-2px);
	cursor: pointer;
}
.euiDroppable--withPanel.euiDroppable--withPanel--shadow {
	-webkit-box-shadow: 0 6px 12px -1px rgba(152, 162, 179, 0.2),
		0 4px 4px -1px rgba(152, 162, 179, 0.2), 0 2px 2px 0 rgba(152, 162, 179, 0.2);
	box-shadow: 0 6px 12px -1px rgba(152, 162, 179, 0.2), 0 4px 4px -1px rgba(152, 162, 179, 0.2),
		0 2px 2px 0 rgba(152, 162, 179, 0.2);
	border-bottom-color: rgba(152, 162, 179, 0.5);
}
.euiDroppable--withPanel.euiDroppable--withPanel--shadow.euiDroppable--withPanel--isClickable:hover,
.euiDroppable--withPanel.euiDroppable--withPanel--shadow.euiDroppable--withPanel--isClickable:focus {
	-webkit-box-shadow: 0 12px 24px 0 rgba(152, 162, 179, 0.2), 0 6px 12px 0 rgba(152, 162, 179, 0.2),
		0 4px 4px 0 rgba(152, 162, 179, 0.2), 0 2px 2px 0 rgba(152, 162, 179, 0.2);
	box-shadow: 0 12px 24px 0 rgba(152, 162, 179, 0.2), 0 6px 12px 0 rgba(152, 162, 179, 0.2),
		0 4px 4px 0 rgba(152, 162, 179, 0.2), 0 2px 2px 0 rgba(152, 162, 179, 0.2);
}

.euiDroppable--noGrow {
	-webkit-box-flex: 0;
	-ms-flex-positive: 0;
	flex-grow: 0;
}

.euiDroppable--grow {
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

.euiDroppable--s {
	padding: 2px;
}

.euiDroppable--m {
	padding: 4px;
}

.euiDroppable--l {
	padding: 8px;
}

.euiEmptyPrompt {
	max-width: 36em;
	text-align: center;
	padding: 24px;
	margin: auto;
}

.euiErrorBoundary {
	background: repeating-linear-gradient(
		45deg,
		rgba(189, 39, 30, 0.25),
		rgba(189, 39, 30, 0.25) 1px,
		rgba(189, 39, 30, 0.05) 1px,
		rgba(189, 39, 30, 0.05) 20px
	);
	overflow: auto;
	padding: 16px;
}

.euiErrorBoundary__text {
	background-color: #fff;
	padding: 8px;
}

.euiErrorBoundary__stack {
	white-space: pre-wrap;
}

/**
   * 1. Keep each expression's text together as much as possible,
   *    but then wrap long words
   */
.euiExpression {
	overflow-wrap: break-word !important;
	word-wrap: break-word !important;
	word-break: break-word;
	/* 1 */
	font-size: 14px;
	font-size: 0.875rem;
	line-height: 1.5;
	font-family: var(--primary-font), 'Roboto Mono', Consolas, Menlo, Courier, monospace;
	letter-spacing: normal;
	display: inline-block;
	/* 1 */
	text-align: left;
	padding: 2px 0;
	-webkit-transition: all 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
	transition: all 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
	color: #343741;
	border-bottom: 2px solid transparent;
}
.euiExpression:focus {
	border-bottom-style: solid;
}
.euiExpression + .euiExpression {
	margin-left: 8px;
}

.euiExpression-isUppercase .euiExpression__description {
	text-transform: uppercase;
}

.euiExpression-isClickable {
	cursor: pointer;
	border-bottom: 2px dotted #d3dae6;
}
.euiExpression-isClickable:hover:not(:disabled) {
	border-bottom-style: solid;
	-webkit-transform: translateY(-1px);
	transform: translateY(-1px);
}

.euiExpression-isActive {
	border-bottom-style: solid;
}

.euiExpression--subdued:focus {
	background-color: rgba(106, 113, 125, 0.1);
}

.euiExpression--subdued.euiExpression-isActive {
	border-bottom-color: #6a717d;
}

.euiExpression--subdued .euiExpression__description {
	color: #6a717d;
}

.euiExpression--primary:focus {
	background-color: rgba(0, 107, 180, 0.1);
}

.euiExpression--primary.euiExpression-isActive {
	border-bottom-color: #ef5742;
}

.euiExpression--primary .euiExpression__description {
	color: #ef5742;
}

.euiExpression--secondary:focus {
	background-color: rgba(1, 125, 115, 0.1);
}

.euiExpression--secondary.euiExpression-isActive {
	border-bottom-color: #017d73;
}

.euiExpression--secondary .euiExpression__description {
	color: #017d73;
}

.euiExpression--warning:focus {
	background-color: rgba(155, 105, 0, 0.1);
}

.euiExpression--warning.euiExpression-isActive {
	border-bottom-color: #9b6900;
}

.euiExpression--warning .euiExpression__description {
	color: #9b6900;
}

.euiExpression--danger:focus {
	background-color: rgba(189, 39, 30, 0.1);
}

.euiExpression--danger.euiExpression-isActive {
	border-bottom-color: #bd271e;
}

.euiExpression--danger .euiExpression__description {
	color: #bd271e;
}

.euiExpression--accent:focus {
	background-color: rgba(221, 10, 115, 0.1);
}

.euiExpression--accent.euiExpression-isActive {
	border-bottom-color: #dd0a73;
}

.euiExpression--accent .euiExpression__description {
	color: #dd0a73;
}

/**
   * 1. We don't want any of the animations that come inherited from the mixin.
   *    These should act like normal links instead.
   * 2. Change the easing, quickness to not bounce so lighter backgrounds don't flash
   * 3. Make sure the quantity doesn't get an underline on hover
   */
.euiFacetButton {
	display: inline-block;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	cursor: pointer;
	height: 40px;
	white-space: nowrap;
	max-width: 100%;
	vertical-align: middle;
	font-family: var(--primary-font), 'Inter UI', -apple-system, BlinkMacSystemFont, 'Segoe UI',
		Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
	font-weight: 400;
	letter-spacing: -0.005em;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-webkit-font-kerning: normal;
	font-kerning: normal;
	text-decoration: none;
	font-size: 0.875rem;
	line-height: 1.5;
	text-align: left;
	border: none;
	-webkit-transform: none !important;
	transform: none !important;
	/* 1 */
	-webkit-animation: none !important;
	animation: none !important;
	/* 1 */
	-webkit-transition: all 150ms ease-in;
	transition: all 150ms ease-in;
	/* 2 */
}
.euiFacetButton:hover:not(:disabled) {
	-webkit-transform: translateY(-1px);
	transform: translateY(-1px);
	text-decoration: underline;
}
.euiFacetButton:focus {
	-webkit-animation: euiButtonActive 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
	animation: euiButtonActive 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
	text-decoration: none !important;
	background-color: #e6f0f8;
	-webkit-box-shadow: -4px 0 #e6f0f8, 4px 0 #e6f0f8;
	box-shadow: -4px 0 #e6f0f8, 4px 0 #e6f0f8;
}
.euiFacetButton:active:not(:disabled) {
	-webkit-transform: translateY(1px);
	transform: translateY(1px);
}
.euiFacetButton:hover {
	text-decoration: none !important;
	/* 3 */
}
.euiFacetButton:hover:not(:disabled) .euiFacetButton__text,
.euiFacetButton:focus:not(:disabled) .euiFacetButton__text {
	text-decoration: underline;
	/* 3 */
}
.euiFacetButton:disabled {
	color: #afb0b3;
	pointer-events: none;
}
.euiFacetButton:disabled .euiFacetButton__content {
	pointer-events: auto;
	cursor: not-allowed;
}
.euiFacetButton:disabled .euiFacetButton__icon,
.euiFacetButton:disabled .euiFacetButton__quantity {
	opacity: 0.5;
}
.euiFacetButton:disabled:focus {
	background-color: transparent;
}
.euiFacetButton:disabled:hover,
.euiFacetButton:disabled:focus {
	text-decoration: none;
}

.euiFacetButton__content {
	height: 100%;
	width: 100%;
	vertical-align: middle;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.euiFacetButton__content .euiButton__icon,
.euiFacetButton__content .euiButton__spinner {
	-ms-flex-negative: 0;
	flex-shrink: 0;
}
.euiFacetButton__content > * + * {
	margin-left: 8px;
}

.euiFacetButton__text {
	max-width: 100%;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
	white-space: nowrap !important;
	word-wrap: normal !important;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	vertical-align: middle;
}
.euiFacetButton__text::after {
	display: block;
	content: attr(data-text);
	font-weight: 700;
	height: 0;
	overflow: hidden;
	visibility: hidden;
}
.euiFacetButton--isSelected .euiFacetButton__text {
	font-weight: 700;
}

.euiFacetButton__icon {
	-webkit-transition: all 150ms ease-in;
	transition: all 150ms ease-in;
}

.euiFacetGroup--horizontal .euiFacetButton {
	height: 32px;
}
.euiFacetGroup--horizontal .euiFacetButton:not(:last-of-type) {
	margin-right: 24px;
}

.euiFilterGroup {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	max-width: 100%;
	border-right: 1px solid rgba(15, 39, 118, 0.1);
	-webkit-box-shadow: 0 1px 2px -1px rgba(152, 162, 179, 0.2),
		0 3px 3px -2px rgba(152, 162, 179, 0.2);
	box-shadow: 0 1px 2px -1px rgba(152, 162, 179, 0.2), 0 3px 3px -2px rgba(152, 162, 179, 0.2);
	overflow-x: auto;
}
.euiFilterGroup > * {
	-webkit-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	min-width: 48px;
}
.euiFilterGroup > .euiFilterButton--noGrow {
	-webkit-box-flex: 0;
	-ms-flex-positive: 0;
	flex-grow: 0;
}
.euiFilterGroup > .euiFilterButton-hasNotification {
	min-width: 96px;
}
.euiFilterGroup > .euiFilterButton--hasIcon {
	min-width: 128px;
}
.euiFilterGroup .euiPopover__anchor {
	display: block;
}
.euiFilterGroup .euiPopover__anchor .euiFilterButton {
	width: 100%;
}

.euiFilterGroup--fullWidth {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.euiFilterGroup__popoverPanel {
	width: 288px;
}

@media only screen and (max-width: 574px) {
	.euiFilterGroup {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
	.euiFilterGroup {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
}

@media only screen and (max-width: 574px) {
	.euiFilterGroup {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
	.euiFilterGroup .euiFilterButton {
		-webkit-box-flex: 1 !important;
		-ms-flex-positive: 1 !important;
		flex-grow: 1 !important;
	}
}

.euiFilterButton {
	background-color: #fbfcfd;
	height: 40px;
	width: auto;
	border: 1px solid rgba(15, 39, 118, 0.1);
	border-right: none;
	font-size: 14px;
}
.euiFilterButton:disabled {
	color: #afb0b3;
	pointer-events: none;
}
.euiFilterButton:disabled .euiFilterButton__notification {
	opacity: 0.5;
}
.euiFilterButton:hover:not(:disabled),
.euiFilterButton:focus:not(:disabled) {
	text-decoration: none;
}
.euiFilterButton:hover:not(:disabled) .euiFilterButton__textShift,
.euiFilterButton:focus:not(:disabled) .euiFilterButton__textShift {
	text-decoration: underline;
}

.euiFilterButton-hasActiveFilters {
	font-weight: 700;
}

.euiFilterButton--hasIcon .euiButtonEmpty__content {
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.euiFilterButton--withNext + .euiFilterButton {
	margin-left: -4px;
	border-left: none;
}

.euiFilterButton-isSelected {
	background-color: #f5f7fa;
}

.euiFilterButton__text-hasNotification {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.euiFilterButton__notification {
	margin-left: 8px;
	vertical-align: text-bottom;
}

.euiFilterButton__textShift {
	max-width: 100%;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
	white-space: nowrap !important;
	word-wrap: normal !important;
	min-width: 48px;
}
.euiFilterButton__textShift::after {
	display: block;
	content: attr(data-text);
	font-weight: 700;
	height: 0;
	overflow: hidden;
	visibility: hidden;
}

.euiFilterSelectItem {
	font-size: 14px;
	font-size: 0.875rem;
	line-height: 1.5;
	padding: 4px 12px;
	display: block;
	width: 100%;
	text-align: left;
	color: #343741;
	border-bottom: 1px solid #d3dae6;
	border-color: #eef2f7;
}
.euiFilterSelectItem:hover,
.euiFilterSelectItem:focus {
	text-decoration: underline;
}
.euiFilterSelectItem:focus,
.euiFilterSelectItem-isFocused {
	cursor: pointer;
	color: #ef5742;
	background-color: #e6f0f8;
}
.euiFilterSelectItem[disabled] {
	color: #98a2b3;
	cursor: not-allowed;
}
.euiFilterSelectItem[disabled]:hover {
	text-decoration: none;
}

.euiFilterSelectItem__content {
	max-width: 100%;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
	white-space: nowrap !important;
	word-wrap: normal !important;
}

.euiFilterSelect__items {
	scrollbar-width: thin;
	overflow-y: auto;
	max-height: 480px;
}
.euiFilterSelect__items::-webkit-scrollbar {
	width: 16px;
	height: 16px;
}
.euiFilterSelect__items::-webkit-scrollbar-thumb {
	background-color: rgba(105, 112, 125, 0.5);
	border: 6px solid transparent;
	background-clip: content-box;
}
.euiFilterSelect__items::-webkit-scrollbar-corner,
.euiFilterSelect__items::-webkit-scrollbar-track {
	background-color: transparent;
}

.euiFilterSelect__note {
	height: 64px;
	text-align: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: distribute;
	justify-content: space-around;
}

.euiFilterSelect__noteContent {
	color: #69707d;
	font-size: 14px;
}

/**
   * 1. Not always needed, but fixes an IE11 issue when flex-groups are nested under display: flex elements.
   * 2. IE requires a unit to grow.
   */
.euiFlexGroup {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	/* 1 */
}
.euiFlexGroup .euiFlexItem {
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-ms-flex-preferred-size: 0%;
	flex-basis: 0%;
	/* 2 */
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
	.euiFlexGroup .euiFlexItem {
		min-width: 1px;
	}
}

.euiFlexGroup--gutterExtraSmall {
	margin: -2px;
}
.euiFlexGroup--gutterExtraSmall > .euiFlexItem {
	margin: 2px;
}

.euiFlexGroup--gutterSmall {
	margin: -4px;
}
.euiFlexGroup--gutterSmall > .euiFlexItem {
	margin: 4px;
}

.euiFlexGroup--gutterMedium {
	margin: -8px;
}
.euiFlexGroup--gutterMedium > .euiFlexItem {
	margin: 8px;
}

.euiFlexGroup--gutterLarge {
	margin: -12px;
}
.euiFlexGroup--gutterLarge > .euiFlexItem {
	margin: 12px;
}

.euiFlexGroup--gutterExtraLarge {
	margin: -20px;
}
.euiFlexGroup--gutterExtraLarge > .euiFlexItem {
	margin: 20px;
}

.euiFlexGroup--justifyContentSpaceEvenly {
	-webkit-box-pack: space-evenly;
	-ms-flex-pack: space-evenly;
	justify-content: space-evenly;
}

.euiFlexGroup--justifyContentSpaceBetween {
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.euiFlexGroup--justifyContentSpaceAround {
	-ms-flex-pack: distribute;
	justify-content: space-around;
}

.euiFlexGroup--justifyContentCenter {
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.euiFlexGroup--justifyContentFlexEnd {
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.euiFlexGroup--alignItemsFlexStart {
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.euiFlexGroup--alignItemsCenter {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.euiFlexGroup--alignItemsFlexEnd {
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
}

.euiFlexGroup--alignItemsBaseline {
	-webkit-box-align: baseline;
	-ms-flex-align: baseline;
	align-items: baseline;
}

.euiFlexGroup--directionRow {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
}

.euiFlexGroup--directionRowReverse {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: reverse;
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse;
}

.euiFlexGroup--directionColumn {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}

.euiFlexGroup--directionColumnReverse {
	-webkit-box-orient: vertical;
	-webkit-box-direction: reverse;
	-ms-flex-direction: column-reverse;
	flex-direction: column-reverse;
}

.euiFlexGroup--wrap {
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

@media only screen and (max-width: 574px) {
	.euiFlexGroup--responsive {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		margin-left: 0;
		margin-right: 0;
	}
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
	.euiFlexGroup--responsive {
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		margin-left: 0;
		margin-right: 0;
	}
}

.euiFlexGrid {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-bottom: 0;
}
.euiFlexGrid > .euiFlexItem {
	-webkit-box-flex: 0;
	-ms-flex-positive: 0;
	flex-grow: 0;
}
.euiFlexGrid > .euiFlexItem.euiFlexItem--flexGrowZero {
	-webkit-box-flex: 0 !important;
	-ms-flex-positive: 0 !important;
	flex-grow: 0 !important;
	-ms-flex-preferred-size: auto !important;
	flex-basis: auto !important;
}

/**
    * 1. For vertical layouts we use columns instead of flex
    */
.euiFlexGrid--directionColumn {
	display: block;
	/* 1 */
	-webkit-column-gap: 0;
	-moz-column-gap: 0;
	column-gap: 0;
}
.euiFlexGrid--directionColumn > .euiFlexItem {
	display: inline-block;
	/* 1 */
	line-height: initial;
}

/**
     * Uncouple the gutter margin from the column widths to support cases where we use a FlexGrid
     * without columns.
     */
.euiFlexGrid--gutterNone {
	margin: 0px;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}
.euiFlexGrid--gutterNone > .euiFlexItem {
	margin: 0px;
}

.euiFlexGrid--gutterNone.euiFlexGrid--fourths > .euiFlexItem {
	-ms-flex-preferred-size: calc(25% - 0px);
	flex-basis: calc(25% - 0px);
}

.euiFlexGrid--gutterNone.euiFlexGrid--fourths.euiFlexGrid--directionColumn {
	/* 1 */
	-webkit-column-count: 4;
	-moz-column-count: 4;
	column-count: 4;
}
.euiFlexGrid--gutterNone.euiFlexGrid--fourths.euiFlexGrid--directionColumn > .euiFlexItem {
	width: calc(100% - 0px);
}

.euiFlexGrid--gutterNone.euiFlexGrid--thirds > .euiFlexItem {
	-ms-flex-preferred-size: calc(33.3% - 0px);
	flex-basis: calc(33.3% - 0px);
}

.euiFlexGrid--gutterNone.euiFlexGrid--thirds.euiFlexGrid--directionColumn {
	/* 1 */
	-webkit-column-count: 3;
	-moz-column-count: 3;
	column-count: 3;
}
.euiFlexGrid--gutterNone.euiFlexGrid--thirds.euiFlexGrid--directionColumn > .euiFlexItem {
	width: calc(100% - 0px);
}

.euiFlexGrid--gutterNone.euiFlexGrid--halves > .euiFlexItem {
	-ms-flex-preferred-size: calc(50% - 0px);
	flex-basis: calc(50% - 0px);
}

.euiFlexGrid--gutterNone.euiFlexGrid--halves.euiFlexGrid--directionColumn {
	/* 1 */
	-webkit-column-count: 2;
	-moz-column-count: 2;
	column-count: 2;
}
.euiFlexGrid--gutterNone.euiFlexGrid--halves.euiFlexGrid--directionColumn > .euiFlexItem {
	width: calc(100% - 0px);
}

.euiFlexGrid--gutterNone.euiFlexGrid--single > .euiFlexItem {
	-ms-flex-preferred-size: calc(100% - 0px);
	flex-basis: calc(100% - 0px);
}

.euiFlexGrid--gutterNone.euiFlexGrid--single.euiFlexGrid--directionColumn {
	/* 1 */
	-webkit-column-count: 1;
	-moz-column-count: 1;
	column-count: 1;
}
.euiFlexGrid--gutterNone.euiFlexGrid--single.euiFlexGrid--directionColumn > .euiFlexItem {
	width: calc(100% - 0px);
}

/**
     * Uncouple the gutter margin from the column widths to support cases where we use a FlexGrid
     * without columns.
     */
.euiFlexGrid--gutterSmall {
	margin: -4px;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}
.euiFlexGrid--gutterSmall > .euiFlexItem {
	margin: 4px;
}

.euiFlexGrid--gutterSmall.euiFlexGrid--fourths > .euiFlexItem {
	-ms-flex-preferred-size: calc(25% - 8px);
	flex-basis: calc(25% - 8px);
}

.euiFlexGrid--gutterSmall.euiFlexGrid--fourths.euiFlexGrid--directionColumn {
	/* 1 */
	-webkit-column-count: 4;
	-moz-column-count: 4;
	column-count: 4;
}
.euiFlexGrid--gutterSmall.euiFlexGrid--fourths.euiFlexGrid--directionColumn > .euiFlexItem {
	width: calc(100% - 8px);
}

.euiFlexGrid--gutterSmall.euiFlexGrid--thirds > .euiFlexItem {
	-ms-flex-preferred-size: calc(33.3% - 8px);
	flex-basis: calc(33.3% - 8px);
}

.euiFlexGrid--gutterSmall.euiFlexGrid--thirds.euiFlexGrid--directionColumn {
	/* 1 */
	-webkit-column-count: 3;
	-moz-column-count: 3;
	column-count: 3;
}
.euiFlexGrid--gutterSmall.euiFlexGrid--thirds.euiFlexGrid--directionColumn > .euiFlexItem {
	width: calc(100% - 8px);
}

.euiFlexGrid--gutterSmall.euiFlexGrid--halves > .euiFlexItem {
	-ms-flex-preferred-size: calc(50% - 8px);
	flex-basis: calc(50% - 8px);
}

.euiFlexGrid--gutterSmall.euiFlexGrid--halves.euiFlexGrid--directionColumn {
	/* 1 */
	-webkit-column-count: 2;
	-moz-column-count: 2;
	column-count: 2;
}
.euiFlexGrid--gutterSmall.euiFlexGrid--halves.euiFlexGrid--directionColumn > .euiFlexItem {
	width: calc(100% - 8px);
}

.euiFlexGrid--gutterSmall.euiFlexGrid--single > .euiFlexItem {
	-ms-flex-preferred-size: calc(100% - 8px);
	flex-basis: calc(100% - 8px);
}

.euiFlexGrid--gutterSmall.euiFlexGrid--single.euiFlexGrid--directionColumn {
	/* 1 */
	-webkit-column-count: 1;
	-moz-column-count: 1;
	column-count: 1;
}
.euiFlexGrid--gutterSmall.euiFlexGrid--single.euiFlexGrid--directionColumn > .euiFlexItem {
	width: calc(100% - 8px);
}

/**
     * Uncouple the gutter margin from the column widths to support cases where we use a FlexGrid
     * without columns.
     */
.euiFlexGrid--gutterMedium {
	margin: -8px;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}
.euiFlexGrid--gutterMedium > .euiFlexItem {
	margin: 8px;
}

.euiFlexGrid--gutterMedium.euiFlexGrid--fourths > .euiFlexItem {
	-ms-flex-preferred-size: calc(25% - 16px);
	flex-basis: calc(25% - 16px);
}

.euiFlexGrid--gutterMedium.euiFlexGrid--fourths.euiFlexGrid--directionColumn {
	/* 1 */
	-webkit-column-count: 4;
	-moz-column-count: 4;
	column-count: 4;
}
.euiFlexGrid--gutterMedium.euiFlexGrid--fourths.euiFlexGrid--directionColumn > .euiFlexItem {
	width: calc(100% - 16px);
}

.euiFlexGrid--gutterMedium.euiFlexGrid--thirds > .euiFlexItem {
	-ms-flex-preferred-size: calc(33.3% - 16px);
	flex-basis: calc(33.3% - 16px);
}

.euiFlexGrid--gutterMedium.euiFlexGrid--thirds.euiFlexGrid--directionColumn {
	/* 1 */
	-webkit-column-count: 3;
	-moz-column-count: 3;
	column-count: 3;
}
.euiFlexGrid--gutterMedium.euiFlexGrid--thirds.euiFlexGrid--directionColumn > .euiFlexItem {
	width: calc(100% - 16px);
}

.euiFlexGrid--gutterMedium.euiFlexGrid--halves > .euiFlexItem {
	-ms-flex-preferred-size: calc(50% - 16px);
	flex-basis: calc(50% - 16px);
}

.euiFlexGrid--gutterMedium.euiFlexGrid--halves.euiFlexGrid--directionColumn {
	/* 1 */
	-webkit-column-count: 2;
	-moz-column-count: 2;
	column-count: 2;
}
.euiFlexGrid--gutterMedium.euiFlexGrid--halves.euiFlexGrid--directionColumn > .euiFlexItem {
	width: calc(100% - 16px);
}

.euiFlexGrid--gutterMedium.euiFlexGrid--single > .euiFlexItem {
	-ms-flex-preferred-size: calc(100% - 16px);
	flex-basis: calc(100% - 16px);
}

.euiFlexGrid--gutterMedium.euiFlexGrid--single.euiFlexGrid--directionColumn {
	/* 1 */
	-webkit-column-count: 1;
	-moz-column-count: 1;
	column-count: 1;
}
.euiFlexGrid--gutterMedium.euiFlexGrid--single.euiFlexGrid--directionColumn > .euiFlexItem {
	width: calc(100% - 16px);
}

/**
     * Uncouple the gutter margin from the column widths to support cases where we use a FlexGrid
     * without columns.
     */
.euiFlexGrid--gutterLarge {
	margin: -12px;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}
.euiFlexGrid--gutterLarge > .euiFlexItem {
	margin: 12px;
}

.euiFlexGrid--gutterLarge.euiFlexGrid--fourths > .euiFlexItem {
	-ms-flex-preferred-size: calc(25% - 24px);
	flex-basis: calc(25% - 24px);
}

.euiFlexGrid--gutterLarge.euiFlexGrid--fourths.euiFlexGrid--directionColumn {
	/* 1 */
	-webkit-column-count: 4;
	-moz-column-count: 4;
	column-count: 4;
}
.euiFlexGrid--gutterLarge.euiFlexGrid--fourths.euiFlexGrid--directionColumn > .euiFlexItem {
	width: calc(100% - 24px);
}

.euiFlexGrid--gutterLarge.euiFlexGrid--thirds > .euiFlexItem {
	-ms-flex-preferred-size: calc(33.3% - 24px);
	flex-basis: calc(33.3% - 24px);
}

.euiFlexGrid--gutterLarge.euiFlexGrid--thirds.euiFlexGrid--directionColumn {
	/* 1 */
	-webkit-column-count: 3;
	-moz-column-count: 3;
	column-count: 3;
}
.euiFlexGrid--gutterLarge.euiFlexGrid--thirds.euiFlexGrid--directionColumn > .euiFlexItem {
	width: calc(100% - 24px);
}

.euiFlexGrid--gutterLarge.euiFlexGrid--halves > .euiFlexItem {
	-ms-flex-preferred-size: calc(50% - 24px);
	flex-basis: calc(50% - 24px);
}

.euiFlexGrid--gutterLarge.euiFlexGrid--halves.euiFlexGrid--directionColumn {
	/* 1 */
	-webkit-column-count: 2;
	-moz-column-count: 2;
	column-count: 2;
}
.euiFlexGrid--gutterLarge.euiFlexGrid--halves.euiFlexGrid--directionColumn > .euiFlexItem {
	width: calc(100% - 24px);
}

.euiFlexGrid--gutterLarge.euiFlexGrid--single > .euiFlexItem {
	-ms-flex-preferred-size: calc(100% - 24px);
	flex-basis: calc(100% - 24px);
}

.euiFlexGrid--gutterLarge.euiFlexGrid--single.euiFlexGrid--directionColumn {
	/* 1 */
	-webkit-column-count: 1;
	-moz-column-count: 1;
	column-count: 1;
}
.euiFlexGrid--gutterLarge.euiFlexGrid--single.euiFlexGrid--directionColumn > .euiFlexItem {
	width: calc(100% - 24px);
}

/**
     * Uncouple the gutter margin from the column widths to support cases where we use a FlexGrid
     * without columns.
     */
.euiFlexGrid--gutterXLarge {
	margin: -16px;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}
.euiFlexGrid--gutterXLarge > .euiFlexItem {
	margin: 16px;
}

.euiFlexGrid--gutterXLarge.euiFlexGrid--fourths > .euiFlexItem {
	-ms-flex-preferred-size: calc(25% - 32px);
	flex-basis: calc(25% - 32px);
}

.euiFlexGrid--gutterXLarge.euiFlexGrid--fourths.euiFlexGrid--directionColumn {
	/* 1 */
	-webkit-column-count: 4;
	-moz-column-count: 4;
	column-count: 4;
}
.euiFlexGrid--gutterXLarge.euiFlexGrid--fourths.euiFlexGrid--directionColumn > .euiFlexItem {
	width: calc(100% - 32px);
}

.euiFlexGrid--gutterXLarge.euiFlexGrid--thirds > .euiFlexItem {
	-ms-flex-preferred-size: calc(33.3% - 32px);
	flex-basis: calc(33.3% - 32px);
}

.euiFlexGrid--gutterXLarge.euiFlexGrid--thirds.euiFlexGrid--directionColumn {
	/* 1 */
	-webkit-column-count: 3;
	-moz-column-count: 3;
	column-count: 3;
}
.euiFlexGrid--gutterXLarge.euiFlexGrid--thirds.euiFlexGrid--directionColumn > .euiFlexItem {
	width: calc(100% - 32px);
}

.euiFlexGrid--gutterXLarge.euiFlexGrid--halves > .euiFlexItem {
	-ms-flex-preferred-size: calc(50% - 32px);
	flex-basis: calc(50% - 32px);
}

.euiFlexGrid--gutterXLarge.euiFlexGrid--halves.euiFlexGrid--directionColumn {
	/* 1 */
	-webkit-column-count: 2;
	-moz-column-count: 2;
	column-count: 2;
}
.euiFlexGrid--gutterXLarge.euiFlexGrid--halves.euiFlexGrid--directionColumn > .euiFlexItem {
	width: calc(100% - 32px);
}

.euiFlexGrid--gutterXLarge.euiFlexGrid--single > .euiFlexItem {
	-ms-flex-preferred-size: calc(100% - 32px);
	flex-basis: calc(100% - 32px);
}

.euiFlexGrid--gutterXLarge.euiFlexGrid--single.euiFlexGrid--directionColumn {
	/* 1 */
	-webkit-column-count: 1;
	-moz-column-count: 1;
	column-count: 1;
}
.euiFlexGrid--gutterXLarge.euiFlexGrid--single.euiFlexGrid--directionColumn > .euiFlexItem {
	width: calc(100% - 32px);
}

@media only screen and (max-width: 574px) {
	.euiFlexGrid.euiFlexGrid--responsive {
		margin-left: 0 !important;
		margin-right: 0 !important;
		-webkit-column-count: 1 !important;
		-moz-column-count: 1 !important;
		column-count: 1 !important;
	}
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
	.euiFlexGrid.euiFlexGrid--responsive {
		margin-left: 0 !important;
		margin-right: 0 !important;
		-webkit-column-count: 1 !important;
		-moz-column-count: 1 !important;
		column-count: 1 !important;
	}
}

/**
   * 1. Allow EuiPanels to expand to fill the item.
   * 2. IE11 hack forces inner content of flex items to respect a higher parent's width (mostly) and
   *    not cause weird wrapping issues.
   */
.euiFlexItem {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	/* 1 */
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	/* 1 */
	/*
     * 1. We need the extra specificity here to override the FlexGroup > FlexItem styles.
     * 2. FlexItem can be manually set to not grow if needed.
     */
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
	.euiFlexItem {
		min-width: 1px;
		/* 2 */
	}
}
.euiFlexItem.euiFlexItem--flexGrowZero {
	/* 1 */
	-webkit-box-flex: 0;
	-ms-flex-positive: 0;
	flex-grow: 0;
	/* 2 */
	-ms-flex-preferred-size: auto;
	flex-basis: auto;
	/* 2 */
}
.euiFlexItem.euiFlexItem--flexGrow1 {
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}
.euiFlexItem.euiFlexItem--flexGrow2 {
	-webkit-box-flex: 2;
	-ms-flex-positive: 2;
	flex-grow: 2;
}
.euiFlexItem.euiFlexItem--flexGrow3 {
	-webkit-box-flex: 3;
	-ms-flex-positive: 3;
	flex-grow: 3;
}
.euiFlexItem.euiFlexItem--flexGrow4 {
	-webkit-box-flex: 4;
	-ms-flex-positive: 4;
	flex-grow: 4;
}
.euiFlexItem.euiFlexItem--flexGrow5 {
	-webkit-box-flex: 5;
	-ms-flex-positive: 5;
	flex-grow: 5;
}
.euiFlexItem.euiFlexItem--flexGrow6 {
	-webkit-box-flex: 6;
	-ms-flex-positive: 6;
	flex-grow: 6;
}
.euiFlexItem.euiFlexItem--flexGrow7 {
	-webkit-box-flex: 7;
	-ms-flex-positive: 7;
	flex-grow: 7;
}
.euiFlexItem.euiFlexItem--flexGrow8 {
	-webkit-box-flex: 8;
	-ms-flex-positive: 8;
	flex-grow: 8;
}
.euiFlexItem.euiFlexItem--flexGrow9 {
	-webkit-box-flex: 9;
	-ms-flex-positive: 9;
	flex-grow: 9;
}
.euiFlexItem.euiFlexItem--flexGrow10 {
	-webkit-box-flex: 10;
	-ms-flex-positive: 10;
	flex-grow: 10;
}

@media only screen and (max-width: 574px) {
	.euiFlexGroup--responsive > .euiFlexItem,
	.euiFlexGrid--responsive > .euiFlexItem {
		width: 100% !important;
		-ms-flex-preferred-size: 100% !important;
		flex-basis: 100% !important;
		margin-left: 0 !important;
		margin-right: 0 !important;
		margin-bottom: 16px !important;
	}
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
	.euiFlexGroup--responsive > .euiFlexItem,
	.euiFlexGrid--responsive > .euiFlexItem {
		width: 100% !important;
		-ms-flex-preferred-size: 100% !important;
		flex-basis: 100% !important;
		margin-left: 0 !important;
		margin-right: 0 !important;
		margin-bottom: 16px !important;
	}
}

.euiCollapsibleNav,
.euiFlyout {
	border-left: 1px solid #d3dae6;
	-webkit-box-shadow: 0 40px 64px 0 rgba(65, 78, 101, 0.1), 0 24px 32px 0 rgba(65, 78, 101, 0.1),
		0 16px 16px 0 rgba(65, 78, 101, 0.1), 0 8px 8px 0 rgba(65, 78, 101, 0.1),
		0 4px 4px 0 rgba(65, 78, 101, 0.1), 0 2px 2px 0 rgba(65, 78, 101, 0.1);
	box-shadow: 0 40px 64px 0 rgba(65, 78, 101, 0.1), 0 24px 32px 0 rgba(65, 78, 101, 0.1),
		0 16px 16px 0 rgba(65, 78, 101, 0.1), 0 8px 8px 0 rgba(65, 78, 101, 0.1),
		0 4px 4px 0 rgba(65, 78, 101, 0.1), 0 2px 2px 0 rgba(65, 78, 101, 0.1);
	border-color: #c6cad1;
	border-top-color: #e3e4e8;
	border-bottom-color: #aaafba;
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	height: 100%;
	z-index: 8000;
	background: #fff;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}
.euiBody--headerIsFixed .euiCollapsibleNav,
.euiBody--headerIsFixed .euiFlyout {
	top: 49px;
	height: calc(100% - 49px);
}

.euiFlyout {
	-webkit-animation: euiFlyout 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
	animation: euiFlyout 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
}

.euiFlyout__closeButton {
	background-color: rgba(255, 255, 255, 0.9);
	position: absolute;
	right: 17px;
	top: 17px;
	z-index: 3;
}

/**
   * 1. Calculating the minimum width based on the screen takover breakpoint
   */
.euiFlyout--small {
	min-width: 384px;
	width: 25vw;
}
.euiFlyout--small.euiFlyout--maxWidth-default {
	max-width: 403px;
}

.euiFlyout--medium {
	min-width: 424px;
	width: 50vw;
}
.euiFlyout--medium.euiFlyout--maxWidth-default {
	max-width: 768px;
}

.euiFlyout--large {
	min-width: 691px;
	width: 75vw;
}
.euiFlyout--large.euiFlyout--maxWidth-default {
	max-width: 992px;
}

@-webkit-keyframes euiFlyout {
	0% {
		opacity: 0;
		-webkit-transform: translateX(100%);
		transform: translateX(100%);
	}
	75% {
		opacity: 1;
		-webkit-transform: translateX(0%);
		transform: translateX(0%);
	}
}

@keyframes euiFlyout {
	0% {
		opacity: 0;
		-webkit-transform: translateX(100%);
		transform: translateX(100%);
	}
	75% {
		opacity: 1;
		-webkit-transform: translateX(0%);
		transform: translateX(0%);
	}
}

/**
   * 1. Only small flyouts should NOT takover the entire screen
   * 2. If a custom maxWidth is set, we need to override it.
   */
@media only screen and (max-width: 574px) {
	.euiFlyout:not(.euiFlyout--small) {
		/* 1 */
		left: 0;
		min-width: 0;
		width: auto;
		border-left: none;
		max-width: 100vw !important;
		/* 2 */
	}
	.euiFlyout--small {
		width: 90vw;
		min-width: 0;
		/* 1 */
		max-width: 403px;
	}
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
	.euiFlyout:not(.euiFlyout--small) {
		/* 1 */
		left: 0;
		min-width: 0;
		width: auto;
		border-left: none;
		max-width: 100vw !important;
		/* 2 */
	}
	.euiFlyout--small {
		width: 90vw;
		min-width: 0;
		/* 1 */
		max-width: 403px;
	}
}

.euiFlyoutBody {
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	overflow-y: hidden;
	height: 100%;
}
.euiFlyoutBody .euiFlyoutBody__overflow {
	scrollbar-width: thin;
	height: 100%;
	overflow-y: auto;
	-webkit-mask-image: linear-gradient(
		to bottom,
		rgba(255, 0, 0, 0.1) 0%,
		red 7.5px,
		red calc(100% - 7.5px),
		rgba(255, 0, 0, 0.1) 100%
	);
	mask-image: linear-gradient(
		to bottom,
		rgba(255, 0, 0, 0.1) 0%,
		red 7.5px,
		red calc(100% - 7.5px),
		rgba(255, 0, 0, 0.1) 100%
	);
}
.euiFlyoutBody .euiFlyoutBody__overflow::-webkit-scrollbar {
	width: 16px;
	height: 16px;
}
.euiFlyoutBody .euiFlyoutBody__overflow::-webkit-scrollbar-thumb {
	background-color: rgba(105, 112, 125, 0.5);
	border: 6px solid transparent;
	background-clip: content-box;
}
.euiFlyoutBody .euiFlyoutBody__overflow::-webkit-scrollbar-corner,
.euiFlyoutBody .euiFlyoutBody__overflow::-webkit-scrollbar-track {
	background-color: transparent;
}
.euiFlyoutBody .euiFlyoutBody__overflow.euiFlyoutBody__overflow--hasBanner {
	-webkit-mask-image: linear-gradient(to bottom, red calc(100% - 7.5px), rgba(255, 0, 0, 0.1) 100%);
	mask-image: linear-gradient(to bottom, red calc(100% - 7.5px), rgba(255, 0, 0, 0.1) 100%);
}
.euiFlyoutBody .euiFlyoutBody__banner .euiCallOut {
	border: none;
	padding-left: 24px;
	padding-right: 24px;
}
.euiFlyoutBody .euiFlyoutBody__overflowContent {
	padding: 24px;
}

.euiFlyoutFooter {
	background: #f5f7fa;
	-webkit-box-flex: 0;
	-ms-flex-positive: 0;
	flex-grow: 0;
	padding: 16px 24px;
}

.euiFlyoutHeader {
	-webkit-box-flex: 0;
	-ms-flex-positive: 0;
	flex-grow: 0;
	padding: 24px 40px 0 24px;
}

.euiFlyoutHeader--hasBorder {
	padding-bottom: 24px;
	border-bottom: 1px solid #d3dae6;
}

.euiCheckbox {
	position: relative;
	/**
     * 1. Float above the visual radio and match its dimension, so that when users try to click it
     *    they actually click this input.
     */
}
.euiCheckbox .euiCheckbox__input {
	position: absolute;
	left: -10000px;
	top: auto;
	width: 1px;
	height: 1px;
	overflow: hidden;
}
.euiCheckbox .euiCheckbox__input ~ .euiCheckbox__label {
	display: inline-block;
	padding-left: 24px;
	line-height: 24px;
	font-size: 14px;
	position: relative;
	z-index: 2;
	cursor: pointer;
}
.euiCheckbox .euiCheckbox__input + .euiCheckbox__square {
	-webkit-box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
	box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
	padding: 7px;
	border: 1px solid #c9cbcd;
	background: #fff no-repeat center;
	border-radius: 4px;
	-webkit-transition: background-color 150ms ease-in, border-color 150ms ease-in;
	transition: background-color 150ms ease-in, border-color 150ms ease-in;
	display: inline-block;
	position: absolute;
	left: 0;
	top: 3px;
}
.euiCheckbox .euiCheckbox__input:checked + .euiCheckbox__square {
	border-color: #ef5742;
	background-color: #ef5742;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='8' viewBox='0 0 10 8'%3E%3Cpath d='M.375 2.625L3.375 5.625M3.375 5.625L8.625.375' fill='none' fill-rule='evenodd' stroke='rgb%28255, 255, 255%29' stroke-linecap='round' stroke-width='1.5' transform='translate(.5 1)'/%3E%3C/svg%3E");
}
.euiCheckbox .euiCheckbox__input:indeterminate + .euiCheckbox__square {
	border-color: #ef5742;
	background-color: #ef5742;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='6' viewBox='0 0 6 6'%3E%3Crect width='6' height='6' fill='rgb%28255, 255, 255%29' fill-rule='evenodd'/%3E%3C/svg%3E");
}
.euiCheckbox .euiCheckbox__input[disabled] {
	cursor: not-allowed !important;
}
.euiCheckbox .euiCheckbox__input[disabled] ~ .euiCheckbox__label {
	color: #98a2b3;
	cursor: not-allowed !important;
}
.euiCheckbox .euiCheckbox__input[disabled] + .euiCheckbox__square {
	border-color: #d3dae6;
	background-color: #d3dae6;
	-webkit-box-shadow: none;
	box-shadow: none;
}
.euiCheckbox .euiCheckbox__input:checked[disabled] + .euiCheckbox__square {
	border-color: #d3dae6;
	background-color: #d3dae6;
	-webkit-box-shadow: none;
	box-shadow: none;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='8' viewBox='0 0 10 8'%3E%3Cpath d='M.375 2.625L3.375 5.625M3.375 5.625L8.625.375' fill='none' fill-rule='evenodd' stroke='rgb%2894, 100, 111%29' stroke-linecap='round' stroke-width='1.5' transform='translate(.5 1)'/%3E%3C/svg%3E");
}
.euiCheckbox .euiCheckbox__input:indeterminate[disabled] + .euiCheckbox__square {
	border-color: #d3dae6;
	background-color: #d3dae6;
	-webkit-box-shadow: none;
	box-shadow: none;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='6' viewBox='0 0 6 6'%3E%3Ccircle cx='8' cy='11' r='3' fill='rgb%2894, 100, 111%29' fill-rule='evenodd' transform='translate(-5 -8)'/%3E%3C/svg%3E");
}
.euiCheckbox .euiCheckbox__input:focus + .euiCheckbox__square,
.euiCheckbox .euiCheckbox__input:active:not(:disabled) + .euiCheckbox__square {
	-webkit-animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
	animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
	border-color: #ef5742;
}
.euiCheckbox.euiCheckbox--inList,
.euiCheckbox.euiCheckbox--noLabel {
	min-height: 16px;
	min-width: 16px;
}
.euiCheckbox.euiCheckbox--inList .euiCheckbox__square,
.euiCheckbox.euiCheckbox--noLabel .euiCheckbox__square {
	top: 0;
}
.euiCheckbox.euiCheckbox--inList .euiCheckbox__input,
.euiCheckbox.euiCheckbox--noLabel .euiCheckbox__input {
	width: 16px;
	height: 16px;
	/* 1 */
	position: absolute;
	/* 1 */
	opacity: 0;
	/* 1 */
	z-index: 1;
	/* 1 */
	margin: 0;
	/* 1 */
	left: 0;
	/* 1 */
	cursor: pointer;
}

.euiCheckboxGroup__item + .euiCheckboxGroup__item {
	margin-top: 4px;
}
.euiCheckboxGroup__item + .euiCheckboxGroup__item.euiCheckbox--compressed {
	margin-top: 0;
}

.euiDescribedFormGroup {
	max-width: 800px;
}
.euiDescribedFormGroup + * {
	margin-top: 24px;
}
.euiDescribedFormGroup.euiDescribedFormGroup--fullWidth {
	max-width: 100%;
}
.euiDescribedFormGroup .euiDescribedFormGroup__description {
	padding-top: 8px;
}
.euiDescribedFormGroup .euiDescribedFormGroup__fields {
	min-width: 0;
}
.euiDescribedFormGroup .euiDescribedFormGroup__fieldPadding--xxxsmall {
	padding-top: 8px;
}
.euiDescribedFormGroup .euiDescribedFormGroup__fieldPadding--xxsmall {
	padding-top: 11px;
}
.euiDescribedFormGroup .euiDescribedFormGroup__fieldPadding--xsmall {
	padding-top: 14px;
}
.euiDescribedFormGroup .euiDescribedFormGroup__fieldPadding--small {
	padding-top: 20px;
}
.euiDescribedFormGroup .euiDescribedFormGroup__fieldPadding--medium {
	padding-top: 32px;
}
.euiDescribedFormGroup .euiDescribedFormGroup__fieldPadding--large {
	padding-top: 44px;
}
@media only screen and (max-width: 574px) {
	.euiDescribedFormGroup .euiDescribedFormGroup__fields {
		padding-top: 0;
	}
	.euiDescribedFormGroup
		.euiDescribedFormGroup__fields
		> .euiFormRow--hasEmptyLabelSpace:first-child {
		padding-top: 0;
	}
}
@media only screen and (min-width: 575px) and (max-width: 767px) {
	.euiDescribedFormGroup .euiDescribedFormGroup__fields {
		padding-top: 0;
	}
	.euiDescribedFormGroup
		.euiDescribedFormGroup__fields
		> .euiFormRow--hasEmptyLabelSpace:first-child {
		padding-top: 0;
	}
}

.euiFieldNumber {
	max-width: 400px;
	width: 100%;
	height: 40px;
	background-color: #fbfcfd;
	background-repeat: no-repeat;
	background-size: 0% 100%;
	-webkit-box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2),
		0 3px 2px -2px rgba(152, 162, 179, 0.2), inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 3px 2px -2px rgba(152, 162, 179, 0.2),
		inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	-webkit-transition: background-image 150ms ease-in, background-size 150ms ease-in,
		background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	transition: background-image 150ms ease-in, background-size 150ms ease-in,
		background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
		background-size 150ms ease-in, background-color 150ms ease-in;
	transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
		background-size 150ms ease-in, background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	font-family: var(--primary-font), 'Inter UI', -apple-system, BlinkMacSystemFont, 'Segoe UI',
		Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
	font-weight: 400;
	letter-spacing: -0.005em;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-webkit-font-kerning: normal;
	font-kerning: normal;
	font-size: 14px;
	line-height: 1em;
	color: #343741;
	border: none;
	border-radius: 0;
	padding: 12px;
}
.euiFieldNumber--fullWidth {
	max-width: 100%;
}
@supports (-moz-appearance: none) {
	.euiFieldNumber {
		-webkit-transition-property: background-image, background-size, -webkit-box-shadow;
		transition-property: background-image, background-size, -webkit-box-shadow;
		transition-property: box-shadow, background-image, background-size;
		transition-property: box-shadow, background-image, background-size, -webkit-box-shadow;
	}
}
.euiFieldNumber::-webkit-input-placeholder {
	color: #69707d;
}
.euiFieldNumber::-moz-placeholder {
	color: #69707d;
}
.euiFieldNumber:-ms-input-placeholder {
	color: #69707d;
}
.euiFieldNumber:-moz-placeholder {
	color: #69707d;
}
.euiFieldNumber::-ms-input-placeholder {
	color: #69707d;
}
.euiFieldNumber::placeholder {
	color: #69707d;
}
.euiFieldNumber:invalid {
	background-image: linear-gradient(
		to top,
		#bd271e,
		#bd271e 2px,
		transparent 2px,
		transparent 100%
	);
	background-size: 100%;
}
.euiFieldNumber:focus {
	background-color: white;
	background-image: linear-gradient(
		to top,
		#ef5742,
		#ef5742 2px,
		transparent 2px,
		transparent 100%
	);
	background-size: 100% 100%;
	-webkit-box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2),
		0 4px 4px -2px rgba(152, 162, 179, 0.2), inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 4px 4px -2px rgba(152, 162, 179, 0.2),
		inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiFieldNumber:disabled {
	cursor: not-allowed;
	color: #98a2b3;
	background: #eef2f7;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiFieldNumber:disabled::-webkit-input-placeholder {
	color: #98a2b3;
}
.euiFieldNumber:disabled::-moz-placeholder {
	color: #98a2b3;
}
.euiFieldNumber:disabled:-ms-input-placeholder {
	color: #98a2b3;
}
.euiFieldNumber:disabled:-moz-placeholder {
	color: #98a2b3;
}
.euiFieldNumber:disabled::-ms-input-placeholder {
	color: #98a2b3;
}
.euiFieldNumber:disabled::placeholder {
	color: #98a2b3;
}
.euiFieldNumber[readOnly] {
	cursor: default;
	background: rgba(211, 218, 230, 0.05);
	border-color: transparent;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiFieldNumber:-webkit-autofill {
	-webkit-text-fill-color: #343741;
}
.euiFieldNumber:-webkit-autofill ~ .euiFormControlLayoutIcons {
	color: #343741;
}
.euiFieldNumber--compressed {
	background-color: #fbfcfd;
	background-repeat: no-repeat;
	background-size: 0% 100%;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	-webkit-transition: background-image 150ms ease-in, background-size 150ms ease-in,
		background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	transition: background-image 150ms ease-in, background-size 150ms ease-in,
		background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
		background-size 150ms ease-in, background-color 150ms ease-in;
	transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
		background-size 150ms ease-in, background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	padding: 8px;
	border-radius: 2px;
	height: 32px;
}
@supports (-moz-appearance: none) {
	.euiFieldNumber--compressed {
		-webkit-transition-property: background-image, background-size, -webkit-box-shadow;
		transition-property: background-image, background-size, -webkit-box-shadow;
		transition-property: box-shadow, background-image, background-size;
		transition-property: box-shadow, background-image, background-size, -webkit-box-shadow;
	}
}
.euiFieldNumber--compressed:invalid {
	background-image: linear-gradient(
		to top,
		#bd271e,
		#bd271e 2px,
		transparent 2px,
		transparent 100%
	);
	background-size: 100%;
}
.euiFieldNumber--compressed:focus {
	background-color: white;
	background-image: linear-gradient(
		to top,
		#ef5742,
		#ef5742 2px,
		transparent 2px,
		transparent 100%
	);
	background-size: 100% 100%;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiFieldNumber--compressed:disabled {
	cursor: not-allowed;
	color: #98a2b3;
	background: #eef2f7;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiFieldNumber--compressed:disabled::-webkit-input-placeholder {
	color: #98a2b3;
}
.euiFieldNumber--compressed:disabled::-moz-placeholder {
	color: #98a2b3;
}
.euiFieldNumber--compressed:disabled:-ms-input-placeholder {
	color: #98a2b3;
}
.euiFieldNumber--compressed:disabled:-moz-placeholder {
	color: #98a2b3;
}
.euiFieldNumber--compressed:disabled::-ms-input-placeholder {
	color: #98a2b3;
}
.euiFieldNumber--compressed:disabled::placeholder {
	color: #98a2b3;
}
.euiFieldNumber--compressed[readOnly] {
	cursor: default;
	background: rgba(211, 218, 230, 0.05);
	border-color: transparent;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiFieldNumber--inGroup {
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	border-radius: 0;
	height: 100%;
}
.euiFieldNumber--withIcon {
	padding-left: 40px;
}
.euiFieldNumber-isLoading {
	padding-right: 40px;
}

.euiFieldNumber--withIcon.euiFieldNumber--compressed {
	padding-left: 32px;
}

.euiFieldPassword {
	max-width: 400px;
	width: 100%;
	height: 40px;
	background-color: #fbfcfd;
	background-repeat: no-repeat;
	background-size: 0% 100%;
	-webkit-box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2),
		0 3px 2px -2px rgba(152, 162, 179, 0.2), inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 3px 2px -2px rgba(152, 162, 179, 0.2),
		inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	-webkit-transition: background-image 150ms ease-in, background-size 150ms ease-in,
		background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	transition: background-image 150ms ease-in, background-size 150ms ease-in,
		background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
		background-size 150ms ease-in, background-color 150ms ease-in;
	transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
		background-size 150ms ease-in, background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	font-family: var(--primary-font), 'Inter UI', -apple-system, BlinkMacSystemFont, 'Segoe UI',
		Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
	font-weight: 400;
	letter-spacing: -0.005em;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-webkit-font-kerning: normal;
	font-kerning: normal;
	font-size: 14px;
	line-height: 1em;
	color: #343741;
	border: none;
	border-radius: 0;
	padding: 12px;
	padding-left: 40px;
}
.euiFieldPassword--fullWidth {
	max-width: 100%;
}
@supports (-moz-appearance: none) {
	.euiFieldPassword {
		-webkit-transition-property: background-image, background-size, -webkit-box-shadow;
		transition-property: background-image, background-size, -webkit-box-shadow;
		transition-property: box-shadow, background-image, background-size;
		transition-property: box-shadow, background-image, background-size, -webkit-box-shadow;
	}
}
.euiFieldPassword::-webkit-input-placeholder {
	color: #69707d;
}
.euiFieldPassword::-moz-placeholder {
	color: #69707d;
}
.euiFieldPassword:-ms-input-placeholder {
	color: #69707d;
}
.euiFieldPassword:-moz-placeholder {
	color: #69707d;
}
.euiFieldPassword::-ms-input-placeholder {
	color: #69707d;
}
.euiFieldPassword::placeholder {
	color: #69707d;
}
.euiFieldPassword:invalid {
	background-image: linear-gradient(
		to top,
		#bd271e,
		#bd271e 2px,
		transparent 2px,
		transparent 100%
	);
	background-size: 100%;
}
.euiFieldPassword:focus {
	background-color: white;
	background-image: linear-gradient(
		to top,
		#ef5742,
		#ef5742 2px,
		transparent 2px,
		transparent 100%
	);
	background-size: 100% 100%;
	-webkit-box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2),
		0 4px 4px -2px rgba(152, 162, 179, 0.2), inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 4px 4px -2px rgba(152, 162, 179, 0.2),
		inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiFieldPassword:disabled {
	cursor: not-allowed;
	color: #98a2b3;
	background: #eef2f7;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiFieldPassword:disabled::-webkit-input-placeholder {
	color: #98a2b3;
}
.euiFieldPassword:disabled::-moz-placeholder {
	color: #98a2b3;
}
.euiFieldPassword:disabled:-ms-input-placeholder {
	color: #98a2b3;
}
.euiFieldPassword:disabled:-moz-placeholder {
	color: #98a2b3;
}
.euiFieldPassword:disabled::-ms-input-placeholder {
	color: #98a2b3;
}
.euiFieldPassword:disabled::placeholder {
	color: #98a2b3;
}
.euiFieldPassword[readOnly] {
	cursor: default;
	background: rgba(211, 218, 230, 0.05);
	border-color: transparent;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiFieldPassword:-webkit-autofill {
	-webkit-text-fill-color: #343741;
}
.euiFieldPassword:-webkit-autofill ~ .euiFormControlLayoutIcons {
	color: #343741;
}
.euiFieldPassword--compressed {
	height: 32px;
	background-color: #fbfcfd;
	background-repeat: no-repeat;
	background-size: 0% 100%;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	-webkit-transition: background-image 150ms ease-in, background-size 150ms ease-in,
		background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	transition: background-image 150ms ease-in, background-size 150ms ease-in,
		background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
		background-size 150ms ease-in, background-color 150ms ease-in;
	transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
		background-size 150ms ease-in, background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	padding: 8px;
	border-radius: 2px;
}
@supports (-moz-appearance: none) {
	.euiFieldPassword--compressed {
		-webkit-transition-property: background-image, background-size, -webkit-box-shadow;
		transition-property: background-image, background-size, -webkit-box-shadow;
		transition-property: box-shadow, background-image, background-size;
		transition-property: box-shadow, background-image, background-size, -webkit-box-shadow;
	}
}
.euiFieldPassword--compressed:invalid {
	background-image: linear-gradient(
		to top,
		#bd271e,
		#bd271e 2px,
		transparent 2px,
		transparent 100%
	);
	background-size: 100%;
}
.euiFieldPassword--compressed:focus {
	background-color: white;
	background-image: linear-gradient(
		to top,
		#ef5742,
		#ef5742 2px,
		transparent 2px,
		transparent 100%
	);
	background-size: 100% 100%;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiFieldPassword--compressed:disabled {
	cursor: not-allowed;
	color: #98a2b3;
	background: #eef2f7;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiFieldPassword--compressed:disabled::-webkit-input-placeholder {
	color: #98a2b3;
}
.euiFieldPassword--compressed:disabled::-moz-placeholder {
	color: #98a2b3;
}
.euiFieldPassword--compressed:disabled:-ms-input-placeholder {
	color: #98a2b3;
}
.euiFieldPassword--compressed:disabled:-moz-placeholder {
	color: #98a2b3;
}
.euiFieldPassword--compressed:disabled::-ms-input-placeholder {
	color: #98a2b3;
}
.euiFieldPassword--compressed:disabled::placeholder {
	color: #98a2b3;
}
.euiFieldPassword--compressed[readOnly] {
	cursor: default;
	background: rgba(211, 218, 230, 0.05);
	border-color: transparent;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiFieldPassword--inGroup {
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	border-radius: 0;
	height: 100%;
}
.euiFieldPassword-isLoading {
	padding-right: 40px;
}
.euiFieldPassword.euiFieldPassword--compressed {
	padding-left: 32px;
}

/*
   * 1. Fix for Safari to ensure that it renders like a normal text input
   *    and doesn't add extra spacing around text
   * 2. Remove the X clear button from input type search in Chrome and IE
  */
.euiFieldSearch {
	max-width: 400px;
	width: 100%;
	height: 40px;
	background-color: #fbfcfd;
	background-repeat: no-repeat;
	background-size: 0% 100%;
	-webkit-box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2),
		0 3px 2px -2px rgba(152, 162, 179, 0.2), inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 3px 2px -2px rgba(152, 162, 179, 0.2),
		inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	-webkit-transition: background-image 150ms ease-in, background-size 150ms ease-in,
		background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	transition: background-image 150ms ease-in, background-size 150ms ease-in,
		background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
		background-size 150ms ease-in, background-color 150ms ease-in;
	transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
		background-size 150ms ease-in, background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	font-family: var(--primary-font), 'Inter UI', -apple-system, BlinkMacSystemFont, 'Segoe UI',
		Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
	font-weight: 400;
	letter-spacing: -0.005em;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-webkit-font-kerning: normal;
	font-kerning: normal;
	font-size: 14px;
	line-height: 1em;
	color: #343741;
	border: none;
	border-radius: 0;
	padding: 12px;
	padding-left: 40px;
	-webkit-appearance: textfield;
	/* 1 */
}
.euiFieldSearch--fullWidth {
	max-width: 100%;
}
@supports (-moz-appearance: none) {
	.euiFieldSearch {
		-webkit-transition-property: background-image, background-size, -webkit-box-shadow;
		transition-property: background-image, background-size, -webkit-box-shadow;
		transition-property: box-shadow, background-image, background-size;
		transition-property: box-shadow, background-image, background-size, -webkit-box-shadow;
	}
}
.euiFieldSearch::-webkit-input-placeholder {
	color: #69707d;
}
.euiFieldSearch::-moz-placeholder {
	color: #69707d;
}
.euiFieldSearch:-ms-input-placeholder {
	color: #69707d;
}
.euiFieldSearch:-moz-placeholder {
	color: #69707d;
}
.euiFieldSearch::-ms-input-placeholder {
	color: #69707d;
}
.euiFieldSearch::placeholder {
	color: #69707d;
}
.euiFieldSearch:invalid {
	background-image: linear-gradient(
		to top,
		#bd271e,
		#bd271e 2px,
		transparent 2px,
		transparent 100%
	);
	background-size: 100%;
}
.euiFieldSearch:focus {
	background-color: white;
	background-image: linear-gradient(
		to top,
		#ef5742,
		#ef5742 2px,
		transparent 2px,
		transparent 100%
	);
	background-size: 100% 100%;
	-webkit-box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2),
		0 4px 4px -2px rgba(152, 162, 179, 0.2), inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 4px 4px -2px rgba(152, 162, 179, 0.2),
		inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiFieldSearch:disabled {
	cursor: not-allowed;
	color: #98a2b3;
	background: #eef2f7;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiFieldSearch:disabled::-webkit-input-placeholder {
	color: #98a2b3;
}
.euiFieldSearch:disabled::-moz-placeholder {
	color: #98a2b3;
}
.euiFieldSearch:disabled:-ms-input-placeholder {
	color: #98a2b3;
}
.euiFieldSearch:disabled:-moz-placeholder {
	color: #98a2b3;
}
.euiFieldSearch:disabled::-ms-input-placeholder {
	color: #98a2b3;
}
.euiFieldSearch:disabled::placeholder {
	color: #98a2b3;
}
.euiFieldSearch[readOnly] {
	cursor: default;
	background: rgba(211, 218, 230, 0.05);
	border-color: transparent;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiFieldSearch:-webkit-autofill {
	-webkit-text-fill-color: #343741;
}
.euiFieldSearch:-webkit-autofill ~ .euiFormControlLayoutIcons {
	color: #343741;
}
.euiFieldSearch--compressed {
	height: 32px;
	background-color: #fbfcfd;
	background-repeat: no-repeat;
	background-size: 0% 100%;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	-webkit-transition: background-image 150ms ease-in, background-size 150ms ease-in,
		background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	transition: background-image 150ms ease-in, background-size 150ms ease-in,
		background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
		background-size 150ms ease-in, background-color 150ms ease-in;
	transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
		background-size 150ms ease-in, background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	padding: 8px;
	border-radius: 2px;
}
@supports (-moz-appearance: none) {
	.euiFieldSearch--compressed {
		-webkit-transition-property: background-image, background-size, -webkit-box-shadow;
		transition-property: background-image, background-size, -webkit-box-shadow;
		transition-property: box-shadow, background-image, background-size;
		transition-property: box-shadow, background-image, background-size, -webkit-box-shadow;
	}
}
.euiFieldSearch--compressed:invalid {
	background-image: linear-gradient(
		to top,
		#bd271e,
		#bd271e 2px,
		transparent 2px,
		transparent 100%
	);
	background-size: 100%;
}
.euiFieldSearch--compressed:focus {
	background-color: white;
	background-image: linear-gradient(
		to top,
		#ef5742,
		#ef5742 2px,
		transparent 2px,
		transparent 100%
	);
	background-size: 100% 100%;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiFieldSearch--compressed:disabled {
	cursor: not-allowed;
	color: #98a2b3;
	background: #eef2f7;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiFieldSearch--compressed:disabled::-webkit-input-placeholder {
	color: #98a2b3;
}
.euiFieldSearch--compressed:disabled::-moz-placeholder {
	color: #98a2b3;
}
.euiFieldSearch--compressed:disabled:-ms-input-placeholder {
	color: #98a2b3;
}
.euiFieldSearch--compressed:disabled:-moz-placeholder {
	color: #98a2b3;
}
.euiFieldSearch--compressed:disabled::-ms-input-placeholder {
	color: #98a2b3;
}
.euiFieldSearch--compressed:disabled::placeholder {
	color: #98a2b3;
}
.euiFieldSearch--compressed[readOnly] {
	cursor: default;
	background: rgba(211, 218, 230, 0.05);
	border-color: transparent;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiFieldSearch--inGroup {
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	border-radius: 0;
	height: 100%;
}
.euiFieldSearch-isLoading {
	padding-right: 40px;
}
.euiFieldSearch::-webkit-search-decoration,
.euiFieldSearch::-webkit-search-cancel-button {
	-webkit-appearance: none;
	/* 1, 2 */
}
.euiFieldSearch::-ms-clear {
	display: none;
	/* 2 */
}
.euiFieldSearch.euiFieldSearch--compressed {
	padding-left: 32px;
}

.euiFieldText {
	max-width: 400px;
	width: 100%;
	height: 40px;
	background-color: #fbfcfd;
	background-repeat: no-repeat;
	background-size: 0% 100%;
	-webkit-box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2),
		0 3px 2px -2px rgba(152, 162, 179, 0.2), inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 3px 2px -2px rgba(152, 162, 179, 0.2),
		inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	-webkit-transition: background-image 150ms ease-in, background-size 150ms ease-in,
		background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	transition: background-image 150ms ease-in, background-size 150ms ease-in,
		background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
		background-size 150ms ease-in, background-color 150ms ease-in;
	transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
		background-size 150ms ease-in, background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	font-family: var(--primary-font), 'Inter UI', -apple-system, BlinkMacSystemFont, 'Segoe UI',
		Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
	font-weight: 400;
	letter-spacing: -0.005em;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-webkit-font-kerning: normal;
	font-kerning: normal;
	font-size: 14px;
	line-height: 1em;
	color: #343741;
	border: none;
	border-radius: 0;
	padding: 12px;
	/* Invalid state normally comes from :invalid, but several components
    /* like EuiDatePicker need it toggled through an extra class.
    */
}
.euiFieldText--fullWidth {
	max-width: 100%;
}
@supports (-moz-appearance: none) {
	.euiFieldText {
		-webkit-transition-property: background-image, background-size, -webkit-box-shadow;
		transition-property: background-image, background-size, -webkit-box-shadow;
		transition-property: box-shadow, background-image, background-size;
		transition-property: box-shadow, background-image, background-size, -webkit-box-shadow;
	}
}
.euiFieldText::-webkit-input-placeholder {
	color: #69707d;
}
.euiFieldText::-moz-placeholder {
	color: #69707d;
}
.euiFieldText:-ms-input-placeholder {
	color: #69707d;
}
.euiFieldText:-moz-placeholder {
	color: #69707d;
}
.euiFieldText::-ms-input-placeholder {
	color: #69707d;
}
.euiFieldText::placeholder {
	color: #69707d;
}
.euiFieldText:invalid {
	background-image: linear-gradient(
		to top,
		#bd271e,
		#bd271e 2px,
		transparent 2px,
		transparent 100%
	);
	background-size: 100%;
}
.euiFieldText:focus {
	background-color: white;
	background-image: linear-gradient(
		to top,
		#ef5742,
		#ef5742 2px,
		transparent 2px,
		transparent 100%
	);
	background-size: 100% 100%;
	-webkit-box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2),
		0 4px 4px -2px rgba(152, 162, 179, 0.2), inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 4px 4px -2px rgba(152, 162, 179, 0.2),
		inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiFieldText:disabled {
	cursor: not-allowed;
	color: #98a2b3;
	background: #eef2f7;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiFieldText:disabled::-webkit-input-placeholder {
	color: #98a2b3;
}
.euiFieldText:disabled::-moz-placeholder {
	color: #98a2b3;
}
.euiFieldText:disabled:-ms-input-placeholder {
	color: #98a2b3;
}
.euiFieldText:disabled:-moz-placeholder {
	color: #98a2b3;
}
.euiFieldText:disabled::-ms-input-placeholder {
	color: #98a2b3;
}
.euiFieldText:disabled::placeholder {
	color: #98a2b3;
}
.euiFieldText[readOnly] {
	cursor: default;
	background: rgba(211, 218, 230, 0.05);
	border-color: transparent;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiFieldText:-webkit-autofill {
	-webkit-text-fill-color: #343741;
}
.euiFieldText:-webkit-autofill ~ .euiFormControlLayoutIcons {
	color: #343741;
}
.euiFieldText--compressed {
	height: 32px;
	background-color: #fbfcfd;
	background-repeat: no-repeat;
	background-size: 0% 100%;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	-webkit-transition: background-image 150ms ease-in, background-size 150ms ease-in,
		background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	transition: background-image 150ms ease-in, background-size 150ms ease-in,
		background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
		background-size 150ms ease-in, background-color 150ms ease-in;
	transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
		background-size 150ms ease-in, background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	padding: 8px;
	border-radius: 2px;
}
@supports (-moz-appearance: none) {
	.euiFieldText--compressed {
		-webkit-transition-property: background-image, background-size, -webkit-box-shadow;
		transition-property: background-image, background-size, -webkit-box-shadow;
		transition-property: box-shadow, background-image, background-size;
		transition-property: box-shadow, background-image, background-size, -webkit-box-shadow;
	}
}
.euiFieldText--compressed:invalid {
	background-image: linear-gradient(
		to top,
		#bd271e,
		#bd271e 2px,
		transparent 2px,
		transparent 100%
	);
	background-size: 100%;
}
.euiFieldText--compressed:focus {
	background-color: white;
	background-image: linear-gradient(
		to top,
		#ef5742,
		#ef5742 2px,
		transparent 2px,
		transparent 100%
	);
	background-size: 100% 100%;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiFieldText--compressed:disabled {
	cursor: not-allowed;
	color: #98a2b3;
	background: #eef2f7;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiFieldText--compressed:disabled::-webkit-input-placeholder {
	color: #98a2b3;
}
.euiFieldText--compressed:disabled::-moz-placeholder {
	color: #98a2b3;
}
.euiFieldText--compressed:disabled:-ms-input-placeholder {
	color: #98a2b3;
}
.euiFieldText--compressed:disabled:-moz-placeholder {
	color: #98a2b3;
}
.euiFieldText--compressed:disabled::-ms-input-placeholder {
	color: #98a2b3;
}
.euiFieldText--compressed:disabled::placeholder {
	color: #98a2b3;
}
.euiFieldText--compressed[readOnly] {
	cursor: default;
	background: rgba(211, 218, 230, 0.05);
	border-color: transparent;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiFieldText--inGroup {
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	border-radius: 0;
	height: 100%;
}
.euiFieldText--withIcon {
	padding-left: 40px;
}
.euiFieldText-isLoading {
	padding-right: 40px;
}
.euiFieldText.euiFieldText-isInvalid {
	background-image: linear-gradient(
		to top,
		#bd271e,
		#bd271e 2px,
		transparent 2px,
		transparent 100%
	);
	background-size: 100%;
}

.euiFieldText--withIcon.euiFieldText--compressed {
	padding-left: 32px;
}

/**
    * REMEMBER: --large modifiers must come last to override --compressed
    */
.euiFilePicker {
	max-width: 400px;
	width: 100%;
	height: 40px;
	position: relative;
}
.euiFilePicker--fullWidth {
	max-width: 100%;
}
.euiFilePicker--compressed {
	height: 32px;
}
.euiFilePicker--inGroup {
	height: 100%;
}
.euiFilePicker.euiFilePicker--large {
	height: auto;
}

.euiFilePicker__input {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	overflow: hidden;
}
.euiFilePicker__input:hover {
	cursor: pointer;
}
.euiFilePicker__input:hover:disabled {
	cursor: not-allowed;
}
.euiFilePicker__input:disabled ~ .euiFilePicker__prompt {
	color: #98a2b3;
}

.euiFilePicker__icon {
	position: absolute;
	left: 12px;
	top: 12px;
	-webkit-transition: -webkit-transform 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
	transition: -webkit-transform 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
	transition: transform 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
	transition: transform 150ms cubic-bezier(0.694, 0.0482, 0.335, 1),
		-webkit-transform 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
}
.euiFilePicker--compressed .euiFilePicker__icon {
	top: 8px;
	left: 8px;
}
.euiFilePicker--large .euiFilePicker__icon {
	position: static;
	margin-bottom: 16px;
}

/**
    * 1. Don't block the user from dropping files onto the filepicker.
    * 2. Ensure space for import icon, loading spinner, and clear button (only if it has files)
    * 3. Delay focus gradient or else it will only partially transition while file chooser opens
    * 4. Static height so that it doesn't shift its surrounding contents around
    */
.euiFilePicker__prompt {
	background-color: #fbfcfd;
	background-repeat: no-repeat;
	background-size: 0% 100%;
	-webkit-box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2),
		0 3px 2px -2px rgba(152, 162, 179, 0.2), inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 3px 2px -2px rgba(152, 162, 179, 0.2),
		inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	padding-left: 40px;
	/* 2 */
	height: 40px;
	padding-top: 12px;
	padding-right: 12px;
	padding-bottom: 12px;
	pointer-events: none;
	/* 1 */
	-webkit-transition: background-color 150ms ease-in, background-image 150ms ease-in,
		background-size 150ms ease-in 150ms, -webkit-box-shadow 150ms ease-in;
	transition: background-color 150ms ease-in, background-image 150ms ease-in,
		background-size 150ms ease-in 150ms, -webkit-box-shadow 150ms ease-in;
	transition: box-shadow 150ms ease-in, background-color 150ms ease-in,
		background-image 150ms ease-in, background-size 150ms ease-in 150ms;
	transition: box-shadow 150ms ease-in, background-color 150ms ease-in,
		background-image 150ms ease-in, background-size 150ms ease-in 150ms,
		-webkit-box-shadow 150ms ease-in;
	/* 3 */
}
@supports (-moz-appearance: none) {
	.euiFilePicker__prompt {
		-webkit-transition-property: background-image, background-size, -webkit-box-shadow;
		transition-property: background-image, background-size, -webkit-box-shadow;
		transition-property: box-shadow, background-image, background-size;
		transition-property: box-shadow, background-image, background-size, -webkit-box-shadow;
	}
}
.euiFilePicker--compressed .euiFilePicker__prompt {
	background-color: #fbfcfd;
	background-repeat: no-repeat;
	background-size: 0% 100%;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	-webkit-transition: background-image 150ms ease-in, background-size 150ms ease-in,
		background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	transition: background-image 150ms ease-in, background-size 150ms ease-in,
		background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
		background-size 150ms ease-in, background-color 150ms ease-in;
	transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
		background-size 150ms ease-in, background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	padding: 8px;
	border-radius: 2px;
	padding-left: 32px;
	/* 2 */
	height: 32px;
}
@supports (-moz-appearance: none) {
	.euiFilePicker--compressed .euiFilePicker__prompt {
		-webkit-transition-property: background-image, background-size, -webkit-box-shadow;
		transition-property: background-image, background-size, -webkit-box-shadow;
		transition-property: box-shadow, background-image, background-size;
		transition-property: box-shadow, background-image, background-size, -webkit-box-shadow;
	}
}
.euiFilePicker--large .euiFilePicker__prompt {
	height: 128px;
	/* 4 */
	padding: 0 24px;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}
.euiFilePicker--large.euiFilePicker--compressed .euiFilePicker__prompt {
	height: 104px;
	/* 4 */
}
.euiFilePicker-isInvalid .euiFilePicker__prompt {
	background-image: linear-gradient(
		to top,
		#bd271e,
		#bd271e 2px,
		transparent 2px,
		transparent 100%
	);
	background-size: 100%;
}

.euiFilePicker__promptText {
	font-size: 14px;
	font-size: 0.875rem;
	max-width: 100%;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
	white-space: nowrap !important;
	word-wrap: normal !important;
	line-height: 16px;
}
.euiFilePicker:not(.euiFilePicker--large):not(.euiFilePicker-hasFiles) .euiFilePicker__promptText {
	color: #98a2b3;
}

.euiFilePicker__clearButton,
.euiFilePicker__loadingSpinner {
	position: absolute;
	right: 12px;
	top: 12px;
}
.euiFilePicker--compressed .euiFilePicker__clearButton,
.euiFilePicker--compressed .euiFilePicker__loadingSpinner {
	top: 8px;
}

/**
    * 1. Undo the pointer-events: none applied to the enclosing prompt.
    */
.euiFilePicker__clearButton {
	pointer-events: auto;
	/* 1 */
}
.euiFilePicker:not(.euiFilePicker--large) .euiFilePicker__clearButton {
	width: 16px;
	height: 16px;
	pointer-events: all;
	background-color: #98a2b3;
	border-radius: 16px;
	line-height: 0;
}
.euiFilePicker:not(.euiFilePicker--large) .euiFilePicker__clearButton:focus {
	-webkit-animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
	animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
}
.euiFilePicker:not(.euiFilePicker--large) .euiFilePicker__clearButton .euiFilePicker__clearIcon {
	width: 8px;
	height: 8px;
	fill: #fff;
	stroke: #fff;
	stroke-width: 2px;
}
.euiFilePicker--large .euiFilePicker__clearButton {
	position: relative;
	top: 0;
	right: 0;
}

.euiFilePicker__showDrop .euiFilePicker__prompt,
.euiFilePicker__input:focus + .euiFilePicker__prompt {
	background-color: white;
	background-image: linear-gradient(
		to top,
		#ef5742,
		#ef5742 2px,
		transparent 2px,
		transparent 100%
	);
	background-size: 100% 100%;
	-webkit-box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2),
		0 4px 4px -2px rgba(152, 162, 179, 0.2), inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 4px 4px -2px rgba(152, 162, 179, 0.2),
		inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiFilePicker--compressed .euiFilePicker__showDrop .euiFilePicker__prompt,
.euiFilePicker--compressed .euiFilePicker__input:focus + .euiFilePicker__prompt {
	background-color: white;
	background-image: linear-gradient(
		to top,
		#ef5742,
		#ef5742 2px,
		transparent 2px,
		transparent 100%
	);
	background-size: 100% 100%;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}

.euiFilePicker__input:disabled + .euiFilePicker__prompt {
	cursor: not-allowed;
	color: #98a2b3;
	background: #eef2f7;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiFilePicker__input:disabled + .euiFilePicker__prompt::-webkit-input-placeholder {
	color: #98a2b3;
}
.euiFilePicker__input:disabled + .euiFilePicker__prompt::-moz-placeholder {
	color: #98a2b3;
}
.euiFilePicker__input:disabled + .euiFilePicker__prompt:-ms-input-placeholder {
	color: #98a2b3;
}
.euiFilePicker__input:disabled + .euiFilePicker__prompt:-moz-placeholder {
	color: #98a2b3;
}
.euiFilePicker__input:disabled + .euiFilePicker__prompt::-ms-input-placeholder {
	color: #98a2b3;
}
.euiFilePicker__input:disabled + .euiFilePicker__prompt::placeholder {
	color: #98a2b3;
}

.euiFilePicker:not(.euiFilePicker--large).euiFilePicker-isLoading .euiFilePicker__prompt,
.euiFilePicker:not(.euiFilePicker--large).euiFilePicker-hasFiles .euiFilePicker__prompt {
	padding-right: 40px;
	/* 2 */
}

.euiFilePicker-hasFiles .euiFilePicker__promptText {
	color: #343741;
}

.euiFilePicker--large
	.euiFilePicker__input:hover:not(:disabled)
	+ .euiFilePicker__prompt
	.euiFilePicker__promptText,
.euiFilePicker--large
	.euiFilePicker__input:focus
	+ .euiFilePicker__prompt
	.euiFilePicker__promptText {
	text-decoration: underline;
}

.euiFilePicker--large
	.euiFilePicker__input:hover:not(:disabled)
	+ .euiFilePicker__prompt
	.euiFilePicker__icon,
.euiFilePicker--large .euiFilePicker__input:focus + .euiFilePicker__prompt .euiFilePicker__icon {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}

.euiFilePicker--large.euiFilePicker__showDrop .euiFilePicker__prompt .euiFilePicker__promptText {
	text-decoration: underline;
}

.euiFilePicker--large.euiFilePicker__showDrop .euiFilePicker__prompt .euiFilePicker__icon {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}

.euiFilePicker--large.euiFilePicker-hasFiles .euiFilePicker__promptText {
	font-weight: 700;
}

.euiForm__error {
	font-size: 14px;
	font-size: 0.875rem;
	line-height: 1.5;
	list-style: disc;
}

.euiForm__errors {
	margin-bottom: 16px;
}

.euiFormControlLayout {
	max-width: 400px;
	width: 100%;
	height: 40px;
}
.euiFormControlLayout--fullWidth {
	max-width: 100%;
}
.euiFormControlLayout--compressed {
	height: 32px;
}
.euiFormControlLayout--inGroup {
	height: 100%;
}

.euiFormControlLayout__childrenWrapper {
	position: relative;
}

/**
   * 1. Account for inner box-shadow style border
   * 2. Ensure truncation works in children elements
   */
.euiFormControlLayout--group {
	background-color: #fbfcfd;
	background-repeat: no-repeat;
	background-size: 0% 100%;
	-webkit-box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2),
		0 3px 2px -2px rgba(152, 162, 179, 0.2), inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 3px 2px -2px rgba(152, 162, 179, 0.2),
		inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	-webkit-transition: background-image 150ms ease-in, background-size 150ms ease-in,
		background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	transition: background-image 150ms ease-in, background-size 150ms ease-in,
		background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
		background-size 150ms ease-in, background-color 150ms ease-in;
	transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
		background-size 150ms ease-in, background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	padding: 1px;
	/* 1 */
}
@supports (-moz-appearance: none) {
	.euiFormControlLayout--group {
		-webkit-transition-property: background-image, background-size, -webkit-box-shadow;
		transition-property: background-image, background-size, -webkit-box-shadow;
		transition-property: box-shadow, background-image, background-size;
		transition-property: box-shadow, background-image, background-size, -webkit-box-shadow;
	}
}
.euiFormControlLayout--group > *,
.euiFormControlLayout--group .euiPopover__anchor,
.euiFormControlLayout--group .euiButtonEmpty,
.euiFormControlLayout--group .euiText,
.euiFormControlLayout--group .euiFormLabel,
.euiFormControlLayout--group .euiButtonIcon {
	height: 100%;
}
.euiFormControlLayout--group .euiFormControlLayout__childrenWrapper {
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	overflow: hidden;
	/* 2 */
}
.euiFormControlLayout--group .euiFormControlLayout__prepend,
.euiFormControlLayout--group .euiFormControlLayout__append {
	max-width: 100%;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
	white-space: nowrap !important;
	word-wrap: normal !important;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	height: 100%;
	border-radius: 0;
}
.euiFormControlLayout--group .euiFormControlLayout__prepend.euiIcon,
.euiFormControlLayout--group .euiFormControlLayout__prepend .euiIcon,
.euiFormControlLayout--group .euiFormControlLayout__append.euiIcon,
.euiFormControlLayout--group .euiFormControlLayout__append .euiIcon {
	padding: 0 8px;
	width: 32px;
	border-radius: 0;
	background-color: #e9edf3;
}
.euiFormControlLayout--group .euiFormControlLayout__prepend.euiButtonIcon,
.euiFormControlLayout--group .euiFormControlLayout__prepend.euiButtonEmpty,
.euiFormControlLayout--group .euiFormControlLayout__prepend .euiButtonIcon,
.euiFormControlLayout--group .euiFormControlLayout__prepend .euiButtonEmpty,
.euiFormControlLayout--group .euiFormControlLayout__append.euiButtonIcon,
.euiFormControlLayout--group .euiFormControlLayout__append.euiButtonEmpty,
.euiFormControlLayout--group .euiFormControlLayout__append .euiButtonIcon,
.euiFormControlLayout--group .euiFormControlLayout__append .euiButtonEmpty {
	-webkit-transform: none !important;
	transform: none !important;
}
.euiFormControlLayout--group .euiFormControlLayout__prepend.euiButtonIcon .euiIcon,
.euiFormControlLayout--group .euiFormControlLayout__prepend.euiButtonEmpty .euiIcon,
.euiFormControlLayout--group .euiFormControlLayout__prepend .euiButtonIcon .euiIcon,
.euiFormControlLayout--group .euiFormControlLayout__prepend .euiButtonEmpty .euiIcon,
.euiFormControlLayout--group .euiFormControlLayout__append.euiButtonIcon .euiIcon,
.euiFormControlLayout--group .euiFormControlLayout__append.euiButtonEmpty .euiIcon,
.euiFormControlLayout--group .euiFormControlLayout__append .euiButtonIcon .euiIcon,
.euiFormControlLayout--group .euiFormControlLayout__append .euiButtonEmpty .euiIcon {
	background: none !important;
	padding: 0;
	width: 16px;
}
.euiFormControlLayout--group .euiButtonIcon {
	padding: 0 8px;
	width: 32px;
	border-radius: 0;
	background-color: #e9edf3;
}
.euiFormControlLayout--group .euiButtonIcon:focus {
	-webkit-box-shadow: inset 0 0 0 2px rgba(0, 107, 180, 0.3);
	box-shadow: inset 0 0 0 2px rgba(0, 107, 180, 0.3);
}
.euiFormControlLayout--group .euiToolTipAnchor > .euiIcon {
	height: 100%;
	background-color: #e9edf3;
	padding: 0 8px;
	width: 32px;
	border-radius: 0;
}
.euiFormControlLayout--group > .euiFormControlLayout__prepend,
.euiFormControlLayout--group > .euiFormControlLayout__append {
	max-width: 50%;
}
.euiFormControlLayout--group .euiFormLabel,
.euiFormControlLayout--group .euiText {
	background-color: #e9edf3;
	padding: 12px;
	line-height: 16px !important;
	cursor: default !important;
}
.euiFormControlLayout--group .euiFormLabel + *:not(.euiFormControlLayout__childrenWrapper),
.euiFormControlLayout--group .euiText + *:not(.euiFormControlLayout__childrenWrapper) {
	margin-left: -12px;
}
.euiFormControlLayout--group > *:not(.euiFormControlLayout__childrenWrapper) + .euiFormLabel,
.euiFormControlLayout--group > *:not(.euiFormControlLayout__childrenWrapper) + .euiText {
	margin-left: -12px;
}
.euiFormControlLayout--group .euiButtonEmpty {
	border-right: 1px solid #e4e8ee;
}
.euiFormControlLayout--group .euiFormControlLayout__childrenWrapper ~ .euiButtonEmpty,
.euiFormControlLayout--group .euiFormControlLayout__childrenWrapper ~ * .euiButtonEmpty {
	border-right: none;
	border-left: 1px solid #e4e8ee;
}
.euiFormControlLayout--group.euiFormControlLayout--compressed {
	background-color: #fbfcfd;
	background-repeat: no-repeat;
	background-size: 0% 100%;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	-webkit-transition: background-image 150ms ease-in, background-size 150ms ease-in,
		background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	transition: background-image 150ms ease-in, background-size 150ms ease-in,
		background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
		background-size 150ms ease-in, background-color 150ms ease-in;
	transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
		background-size 150ms ease-in, background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	border-radius: 2px;
	overflow: hidden;
}
@supports (-moz-appearance: none) {
	.euiFormControlLayout--group.euiFormControlLayout--compressed {
		-webkit-transition-property: background-image, background-size, -webkit-box-shadow;
		transition-property: background-image, background-size, -webkit-box-shadow;
		transition-property: box-shadow, background-image, background-size;
		transition-property: box-shadow, background-image, background-size, -webkit-box-shadow;
	}
}
.euiFormControlLayout--group.euiFormControlLayout--compressed .euiFormLabel,
.euiFormControlLayout--group.euiFormControlLayout--compressed .euiText {
	padding: 8px;
}
.euiFormControlLayout--group.euiFormControlLayout--compressed
	.euiFormLabel
	+ *:not(.euiFormControlLayout__childrenWrapper),
.euiFormControlLayout--group.euiFormControlLayout--compressed
	.euiText
	+ *:not(.euiFormControlLayout__childrenWrapper) {
	margin-left: -8px;
}
.euiFormControlLayout--group.euiFormControlLayout--compressed
	> *:not(.euiFormControlLayout__childrenWrapper)
	+ .euiFormLabel,
.euiFormControlLayout--group.euiFormControlLayout--compressed
	> *:not(.euiFormControlLayout__childrenWrapper)
	+ .euiText {
	margin-left: -8px;
}
.euiFormControlLayout--group.euiFormControlLayout--readOnly {
	cursor: default;
	background: rgba(211, 218, 230, 0.05);
	border-color: transparent;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiFormControlLayout--group.euiFormControlLayout--readOnly input {
	background-color: transparent;
}

.euiFormControlLayoutDelimited {
	background-color: #fbfcfd;
	background-repeat: no-repeat;
	background-size: 0% 100%;
	-webkit-box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2),
		0 3px 2px -2px rgba(152, 162, 179, 0.2), inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 3px 2px -2px rgba(152, 162, 179, 0.2),
		inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	-webkit-transition: background-image 150ms ease-in, background-size 150ms ease-in,
		background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	transition: background-image 150ms ease-in, background-size 150ms ease-in,
		background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
		background-size 150ms ease-in, background-color 150ms ease-in;
	transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
		background-size 150ms ease-in, background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	padding: 1px;
	/* 1 */
}
@supports (-moz-appearance: none) {
	.euiFormControlLayoutDelimited {
		-webkit-transition-property: background-image, background-size, -webkit-box-shadow;
		transition-property: background-image, background-size, -webkit-box-shadow;
		transition-property: box-shadow, background-image, background-size;
		transition-property: box-shadow, background-image, background-size, -webkit-box-shadow;
	}
}
.euiFormControlLayoutDelimited > .euiFormControlLayout__childrenWrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;
}
.euiFormControlLayoutDelimited[class*='--compressed'] {
	background-color: #fbfcfd;
	background-repeat: no-repeat;
	background-size: 0% 100%;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	-webkit-transition: background-image 150ms ease-in, background-size 150ms ease-in,
		background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	transition: background-image 150ms ease-in, background-size 150ms ease-in,
		background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
		background-size 150ms ease-in, background-color 150ms ease-in;
	transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
		background-size 150ms ease-in, background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	border-radius: 2px;
}
@supports (-moz-appearance: none) {
	.euiFormControlLayoutDelimited[class*='--compressed'] {
		-webkit-transition-property: background-image, background-size, -webkit-box-shadow;
		transition-property: background-image, background-size, -webkit-box-shadow;
		transition-property: box-shadow, background-image, background-size;
		transition-property: box-shadow, background-image, background-size, -webkit-box-shadow;
	}
}
.euiFormControlLayoutDelimited[class*='--compressed'] .euiFormControlLayoutDelimited__input {
	height: 100%;
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 8px;
	padding-right: 8px;
	max-width: none;
}
.euiFormControlLayoutDelimited[class*='--compressed'] .euiFormControlLayoutIcons {
	padding-left: 8px;
	padding-right: 8px;
}
.euiFormControlLayoutDelimited[class*='--fullWidth'] .euiFormControlLayout__childrenWrapper,
.euiFormControlLayoutDelimited[class*='--fullWidth'] input {
	width: 100%;
	max-width: none;
}
.euiFormControlLayoutDelimited[class*='-isDisabled'] {
	cursor: not-allowed;
	color: #98a2b3;
	background: #eef2f7;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiFormControlLayoutDelimited[class*='-isDisabled']::-webkit-input-placeholder {
	color: #98a2b3;
}
.euiFormControlLayoutDelimited[class*='-isDisabled']::-moz-placeholder {
	color: #98a2b3;
}
.euiFormControlLayoutDelimited[class*='-isDisabled']:-ms-input-placeholder {
	color: #98a2b3;
}
.euiFormControlLayoutDelimited[class*='-isDisabled']:-moz-placeholder {
	color: #98a2b3;
}
.euiFormControlLayoutDelimited[class*='-isDisabled']::-ms-input-placeholder {
	color: #98a2b3;
}
.euiFormControlLayoutDelimited[class*='-isDisabled']::placeholder {
	color: #98a2b3;
}
.euiFormControlLayoutDelimited[class*='--readOnly'] {
	cursor: default;
	background: rgba(211, 218, 230, 0.05);
	border-color: transparent;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiFormControlLayoutDelimited[class*='--readOnly'] input {
	background-color: transparent;
}
.euiFormControlLayoutDelimited .euiFormControlLayoutIcons {
	position: static;
	padding-left: 12px;
	padding-right: 12px;
	-ms-flex-negative: 0;
	flex-shrink: 0;
}
.euiFormControlLayoutDelimited .euiFormControlLayoutIcons:not(.euiFormControlLayoutIcons--right) {
	-webkit-box-ordinal-group: 0;
	-ms-flex-order: -1;
	order: -1;
}

.euiFormControlLayoutDelimited__input {
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	border-radius: 0 !important;
	text-align: center;
	height: 100%;
	min-width: 0;
}

.euiFormControlLayoutDelimited__delimeter {
	background-color: transparent !important;
	line-height: 1 !important;
	-webkit-box-flex: 0;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	padding-left: 6px;
	padding-right: 6px;
}

.euiFormControlLayoutIcons {
	pointer-events: none;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 12px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.euiFormControlLayoutIcons > * + * {
	margin-left: 6px;
}
.euiFormControlLayout--compressed .euiFormControlLayoutIcons {
	left: 8px;
}

.euiFormControlLayoutIcons--right {
	left: auto;
	right: 12px;
}
.euiFormControlLayout--compressed .euiFormControlLayoutIcons--right {
	left: auto;
	right: 8px;
}

*:disabled + .euiFormControlLayoutIcons {
	cursor: not-allowed;
	color: #98a2b3;
}

.euiFormControlLayoutClearButton {
	width: 16px;
	height: 16px;
	pointer-events: all;
	background-color: #98a2b3;
	border-radius: 16px;
	line-height: 0;
}
.euiFormControlLayoutClearButton:focus {
	-webkit-animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
	animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
}
.euiFormControlLayoutClearButton .euiFormControlLayoutClearButton__icon {
	width: 8px;
	height: 8px;
	fill: #fff;
	stroke: #fff;
	stroke-width: 2px;
}

.euiFormControlLayoutCustomIcon {
	pointer-events: none;
}
.euiFormControlLayoutCustomIcon .euiFormControlLayoutCustomIcon__icon {
	-webkit-transform: translateY(-1px);
	transform: translateY(-1px);
}

.euiFormControlLayoutCustomIcon--clickable {
	width: 16px;
	height: 16px;
	pointer-events: all;
}
.euiFormControlLayoutCustomIcon--clickable .euiFormControlLayoutCustomIcon__icon {
	vertical-align: baseline;
	-webkit-transform: none;
	transform: none;
}
.euiFormControlLayoutCustomIcon--clickable:focus {
	-webkit-animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
	animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
}
.euiFormControlLayoutCustomIcon--clickable:disabled {
	cursor: not-allowed;
	color: #98a2b3;
}

.euiFormErrorText {
	font-size: 12px;
	font-size: 0.75rem;
	line-height: 1.5;
	padding-top: 4px;
	color: #bd271e;
}

.euiFormLegend {
	font-size: 12px;
	font-size: 0.75rem;
	line-height: 1.5;
	color: #1a1c21;
	font-weight: 600;
}
.euiFormLegend:not(.euiFormLegend-isHidden) {
	margin-bottom: 8px;
}
.euiFormLegend:not(.euiFormLegend-isHidden).euiFormLegend--compressed {
	margin-bottom: 4px;
}

.euiFormHelpText {
	font-size: 12px;
	font-size: 0.75rem;
	line-height: 1.5;
	padding-top: 4px;
	color: #69707d;
}

/**
   * 1. Focused state overrides invalid state.
   */
.euiFormLabel {
	font-size: 12px;
	font-size: 0.75rem;
	line-height: 1.5;
	color: #1a1c21;
	font-weight: 600;
	display: inline-block;
	-webkit-transition: all 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
	transition: all 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
}
.euiFormLabel.euiFormLabel-isInvalid {
	color: #bd271e;
	/* 1 */
}
.euiFormLabel.euiFormLabel-isFocused {
	color: #ef5742;
	/* 1 */
}

.euiFormLabel[for] {
	cursor: pointer;
}

/**
   * 1. Coerce inline form elements to behave as block-level elements.
   * 2. For inline forms, we need to add margin if the label doesn't exist.
   */
.euiFormRow {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	/* 1 */
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	/* 1 */
	max-width: 400px;
}
.euiFormRow + .euiFormRow,
.euiFormRow + .euiButton {
	margin-top: 16px;
}

.euiFormRow--fullWidth {
	max-width: 100%;
}

.euiFormRow--hasEmptyLabelSpace {
	margin-top: 22px;
	/* 2 */
	min-height: 40px;
	padding-bottom: 0;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.euiFormRow__labelWrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	margin-bottom: 4px;
}

.euiFormRow--horizontal {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}
.euiFormRow--horizontal .euiFormRow__label {
	overflow-wrap: break-word !important;
	word-wrap: break-word !important;
	word-break: break-word;
	-webkit-hyphens: auto;
	-ms-hyphens: auto;
	hyphens: auto;
	max-width: 100%;
}
.euiFormRow--horizontal .euiFormRow__labelWrapper {
	display: block;
	line-height: 31px;
	width: calc(33% - 8px);
	margin-right: 8px;
	margin-bottom: 0;
}
.euiFormRow--horizontal .euiFormRow__fieldWrapper {
	width: 67%;
}
.euiFormRow--horizontal + .euiFormRow--horizontal {
	margin-top: 8px;
}
.euiFormRow--horizontal + .euiFormRow--horizontal.euiFormRow--hasSwitch {
	margin-top: 12px;
}
.euiFormRow--horizontal.euiFormRow--hasSwitch .euiFormRow__labelWrapper {
	line-height: 19px;
	width: auto;
	min-width: calc(33% - 8px);
}
.euiFormRow--horizontal.euiFormRow--hasSwitch .euiFormRow__fieldWrapper {
	width: auto;
}
.euiFormRow--horizontal.euiFormRow--hasSwitch .euiFormRow__fieldWrapper .euiSwitch--compressed {
	margin-top: 2px;
}
.euiFormRow--horizontal.euiFormRow--hasSwitch + .euiFormRow--horizontal {
	margin-top: 12px;
}

.euiFormRow__fieldWrapperDisplayOnly {
	min-height: 40px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.euiFormRow--compressed.euiFormRow--hasEmptyLabelSpace {
	min-height: 32px;
}

.euiFormRow--compressed .euiFormRow__fieldWrapperDisplayOnly {
	min-height: 32px;
}

.euiRadio {
	position: relative;
	/**
     * 1. Float above the visual radio and match its dimension, so that when users try to click it
     *    they actually click this input.
     */
}
.euiRadio .euiRadio__input {
	position: absolute;
	left: -10000px;
	top: auto;
	width: 1px;
	height: 1px;
	overflow: hidden;
}
.euiRadio .euiRadio__input ~ .euiRadio__label {
	display: inline-block;
	padding-left: 24px;
	line-height: 24px;
	font-size: 14px;
	position: relative;
	z-index: 2;
	cursor: pointer;
}
.euiRadio .euiRadio__input + .euiRadio__circle {
	-webkit-box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
	box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
	padding: 7px;
	border: 1px solid #c9cbcd;
	background: #fff no-repeat center;
	border-radius: 14px;
	-webkit-transition: background-color 150ms ease-in, border-color 150ms ease-in;
	transition: background-color 150ms ease-in, border-color 150ms ease-in;
	display: inline-block;
	position: absolute;
	left: 0;
	top: 3px;
}
.euiRadio .euiRadio__input:checked + .euiRadio__circle {
	border-color: #ef5742;
	background-color: #ef5742;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='6' viewBox='0 0 6 6'%3E%3Ccircle cx='8' cy='11' r='3' fill='rgb%28255, 255, 255%29' fill-rule='evenodd' transform='translate(-5 -8)'/%3E%3C/svg%3E");
}
.euiRadio .euiRadio__input[disabled] {
	cursor: not-allowed !important;
}
.euiRadio .euiRadio__input[disabled] ~ .euiRadio__label {
	color: #98a2b3;
	cursor: not-allowed !important;
}
.euiRadio .euiRadio__input[disabled] + .euiRadio__circle {
	border-color: #d3dae6;
	background-color: #d3dae6;
	-webkit-box-shadow: none;
	box-shadow: none;
}
.euiRadio .euiRadio__input:checked[disabled] + .euiRadio__circle {
	border-color: #d3dae6;
	background-color: #d3dae6;
	-webkit-box-shadow: none;
	box-shadow: none;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='6' viewBox='0 0 6 6'%3E%3Ccircle cx='8' cy='11' r='3' fill='rgb%2894, 100, 111%29' fill-rule='evenodd' transform='translate(-5 -8)'/%3E%3C/svg%3E");
}
.euiRadio .euiRadio__input:focus + .euiRadio__circle,
.euiRadio .euiRadio__input:active:not(:disabled) + .euiRadio__circle {
	-webkit-animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
	animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
	border-color: #ef5742;
}
.euiRadio.euiRadio--inList,
.euiRadio.euiRadio--noLabel {
	min-height: 16px;
	min-width: 16px;
}
.euiRadio.euiRadio--inList .euiRadio__circle,
.euiRadio.euiRadio--noLabel .euiRadio__circle {
	top: 0;
}
.euiRadio.euiRadio--inList .euiRadio__input,
.euiRadio.euiRadio--noLabel .euiRadio__input {
	width: 16px;
	height: 16px;
	/* 1 */
	position: absolute;
	/* 1 */
	opacity: 0;
	/* 1 */
	z-index: 1;
	/* 1 */
	margin: 0;
	/* 1 */
	left: 0;
	/* 1 */
	cursor: pointer;
}

.euiRadioGroup__item + .euiRadioGroup__item {
	margin-top: 4px;
}
.euiRadioGroup__item + .euiRadioGroup__item.euiRadio--compressed {
	margin-top: 0;
}

.euiRange__horizontalSpacer {
	width: 16px;
}

.euiRangeHighlight {
	position: absolute;
	left: 0;
	width: 100%;
	top: calc(50% - 2px);
	overflow: hidden;
}
.euiRangeHighlight__progress {
	height: 4px;
	border-radius: 4px;
	background-color: #69707d;
}
.euiRangeHighlight__progress--hasFocus {
	background-color: #ef5742;
}
.euiRangeHighlight--hasTicks {
	top: 8px;
}
.euiRangeHighlight--hasTicks.euiRangeHighlight--compressed {
	top: 6px;
}

.euiRangeInput {
	width: auto;
	min-width: 64px;
}
.euiRange__popover .euiRangeInput {
	margin: 0 !important;
	width: 100%;
}

.euiRangeLabel--min,
.euiRangeLabel--max {
	font-size: 12px;
}

.euiRangeLabel--min {
	margin-right: 8px;
}

.euiRangeLabel--max {
	margin-left: 8px;
}

.euiRangeLabel--isDisabled {
	opacity: 0.25;
}

.euiRangeLevels {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: stretch;
	-ms-flex-pack: stretch;
	justify-content: stretch;
	position: absolute;
	left: 0;
	right: 0;
	top: 22px;
}
.euiRangeLevels--hasTicks {
	top: 12px;
}
.euiRangeLevels--compressed {
	top: 18px;
}
.euiRangeLevels--compressed.euiRangeLevels--hasTicks {
	top: 10px;
}

.euiRangeLevel {
	display: block;
	height: 6px;
	border-radius: 6px;
	margin: 2px;
}

.euiRangeLevel--primary {
	background-color: rgba(0, 107, 180, 0.3);
}

.euiRangeLevel--success {
	background-color: rgba(1, 125, 115, 0.3);
}

.euiRangeLevel--warning {
	background-color: rgba(245, 167, 0, 0.3);
}

.euiRangeLevel--danger {
	background-color: rgba(189, 39, 30, 0.3);
}

/*
   *  Input Range Customization by browser
   */
.euiRangeSlider {
	height: 40px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: transparent;
	width: 100%;
	position: relative;
	cursor: pointer;
}
.euiRangeSlider:disabled {
	cursor: not-allowed;
}
.euiRangeSlider:disabled::-webkit-slider-thumb {
	cursor: not-allowed;
	border-color: #69707d;
	background-color: #69707d;
	-webkit-box-shadow: none;
	box-shadow: none;
}
.euiRangeSlider:disabled::-moz-range-thumb {
	cursor: not-allowed;
	border-color: #69707d;
	background-color: #69707d;
	box-shadow: none;
}
.euiRangeSlider:disabled::-ms-thumb {
	cursor: not-allowed;
	border-color: #69707d;
	background-color: #69707d;
	box-shadow: none;
}
.euiRangeSlider:disabled ~ .euiRangeThumb {
	cursor: not-allowed;
	border-color: #69707d;
	background-color: #69707d;
	-webkit-box-shadow: none;
	box-shadow: none;
}
.euiRangeSlider::-webkit-slider-thumb {
	-webkit-box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
	box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
	border: 1px solid #c9cbcd;
	background: #fff no-repeat center;
	border-radius: 14px;
	-webkit-transition: background-color 150ms ease-in, border-color 150ms ease-in;
	transition: background-color 150ms ease-in, border-color 150ms ease-in;
	cursor: pointer;
	border-color: #69707d;
	padding: 0;
	height: 16px;
	width: 16px;
	-webkit-appearance: none;
	margin-top: -7px;
}
.euiRangeSlider::-moz-range-thumb {
	box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
	border: 1px solid #c9cbcd;
	background: #fff no-repeat center;
	border-radius: 14px;
	-webkit-transition: background-color 150ms ease-in, border-color 150ms ease-in;
	transition: background-color 150ms ease-in, border-color 150ms ease-in;
	cursor: pointer;
	border-color: #69707d;
	padding: 0;
	height: 16px;
	width: 16px;
}
.euiRangeSlider::-ms-thumb {
	box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
	border: 1px solid #c9cbcd;
	background: #fff no-repeat center;
	border-radius: 14px;
	-webkit-transition: background-color 150ms ease-in, border-color 150ms ease-in;
	transition: background-color 150ms ease-in, border-color 150ms ease-in;
	cursor: pointer;
	border-color: #69707d;
	padding: 0;
	height: 16px;
	width: 16px;
	margin-top: 0;
}
.euiRangeSlider::-webkit-slider-runnable-track {
	height: 2px;
	-webkit-transition: all 250ms ease-in;
	transition: all 250ms ease-in;
	width: 100%;
	background: #69707d;
	border: 0 solid #69707d;
	border-radius: 4px;
}
.euiRangeSlider::-moz-range-track {
	height: 2px;
	-webkit-transition: all 250ms ease-in;
	transition: all 250ms ease-in;
	width: 100%;
	background: #69707d;
	border: 0 solid #69707d;
	border-radius: 4px;
}
.euiRangeSlider::-ms-fill-lower {
	height: 2px;
	-webkit-transition: all 250ms ease-in;
	transition: all 250ms ease-in;
	width: 100%;
	background: #69707d;
	border: 0 solid #69707d;
	border-radius: 4px;
}
.euiRangeSlider::-ms-fill-upper {
	height: 2px;
	-webkit-transition: all 250ms ease-in;
	transition: all 250ms ease-in;
	width: 100%;
	background: #69707d;
	border: 0 solid #69707d;
	border-radius: 4px;
}
.euiRangeSlider:focus::-webkit-slider-thumb,
.euiRangeSlider--hasFocus::-webkit-slider-thumb {
	-webkit-animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
	animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
	border-color: #ef5742;
}
.euiRangeSlider:focus::-moz-range-thumb,
.euiRangeSlider--hasFocus::-moz-range-thumb {
	animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
	border-color: #ef5742;
}
.euiRangeSlider:focus::-ms-thumb,
.euiRangeSlider--hasFocus::-ms-thumb {
	animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
	border-color: #ef5742;
}
.euiRangeSlider:focus ~ .euiRangeThumb,
.euiRangeSlider--hasFocus ~ .euiRangeThumb {
	border-color: #ef5742;
}
.euiRangeSlider:focus::-webkit-slider-runnable-track,
.euiRangeSlider--hasFocus::-webkit-slider-runnable-track {
	background-color: #ef5742;
	border-color: #ef5742;
}
.euiRangeSlider:focus::-moz-range-track,
.euiRangeSlider--hasFocus::-moz-range-track {
	background-color: #ef5742;
	border-color: #ef5742;
}
.euiRangeSlider:focus::-ms-fill-lower,
.euiRangeSlider--hasFocus::-ms-fill-lower {
	background-color: #ef5742;
	border-color: #ef5742;
}
.euiRangeSlider:focus::-ms-fill-upper,
.euiRangeSlider--hasFocus::-ms-fill-upper {
	background-color: #ef5742;
	border-color: #ef5742;
}
.euiRangeSlider:focus ~ .euiRangeHighlight .euiRangeHighlight__progress,
.euiRangeSlider--hasFocus ~ .euiRangeHighlight .euiRangeHighlight__progress {
	background-color: #ef5742;
}
.euiRangeSlider:focus ~ .euiRangeTooltip .euiRangeTooltip__value,
.euiRangeSlider--hasFocus ~ .euiRangeTooltip .euiRangeTooltip__value {
	-webkit-box-shadow: 0 6px 12px -1px rgba(152, 162, 179, 0.2),
		0 4px 4px -1px rgba(152, 162, 179, 0.2), 0 2px 2px 0 rgba(152, 162, 179, 0.2);
	box-shadow: 0 6px 12px -1px rgba(152, 162, 179, 0.2), 0 4px 4px -1px rgba(152, 162, 179, 0.2),
		0 2px 2px 0 rgba(152, 162, 179, 0.2);
}
.euiRangeSlider:focus ~ .euiRangeTooltip .euiRangeTooltip__value.euiRangeTooltip__value--right,
.euiRangeSlider:focus ~ .euiRangeTooltip .euiRangeTooltip__value.euiRangeTooltip__value--left,
.euiRangeSlider--hasFocus ~ .euiRangeTooltip .euiRangeTooltip__value.euiRangeTooltip__value--right,
.euiRangeSlider--hasFocus ~ .euiRangeTooltip .euiRangeTooltip__value.euiRangeTooltip__value--left {
	-webkit-transform: translateX(0) translateY(-50%) scale(1.1);
	transform: translateX(0) translateY(-50%) scale(1.1);
}
.euiRangeSlider::-moz-focus-outer {
	border: none;
}
.euiRangeSlider::-ms-track {
	height: 2px;
	-webkit-transition: all 250ms ease-in;
	transition: all 250ms ease-in;
	width: 100%;
	background: transparent;
	border-color: transparent;
	border-width: 8px 0;
	color: transparent;
}
.euiRangeSlider--hasTicks {
	height: 20px;
}
.euiRangeSlider--compressed {
	height: 32px;
}
.euiRangeSlider--compressed.euiRangeSlider--hasTicks {
	height: 16px;
}

.euiRangeSlider--hasRange::-webkit-slider-runnable-track {
	background-color: rgba(105, 112, 125, 0.4);
	border-color: rgba(105, 112, 125, 0.4);
}

.euiRangeSlider--hasRange::-moz-range-track {
	background-color: rgba(105, 112, 125, 0.4);
	border-color: rgba(105, 112, 125, 0.4);
}

.euiRangeSlider--hasRange::-ms-fill-lower {
	background-color: rgba(105, 112, 125, 0.4);
	border-color: rgba(105, 112, 125, 0.4);
}

.euiRangeSlider--hasRange::-ms-fill-upper {
	background-color: rgba(105, 112, 125, 0.4);
	border-color: rgba(105, 112, 125, 0.4);
}

.euiRangeThumb {
	-webkit-box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
	box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
	border: 1px solid #c9cbcd;
	background: #fff no-repeat center;
	border-radius: 14px;
	-webkit-transition: background-color 150ms ease-in, border-color 150ms ease-in;
	transition: background-color 150ms ease-in, border-color 150ms ease-in;
	cursor: pointer;
	border-color: #69707d;
	padding: 0;
	height: 16px;
	width: 16px;
	content: '';
	position: absolute;
	left: 0;
	top: 50%;
	margin-top: -8px;
	pointer-events: none;
}
.euiRangeThumb:focus {
	-webkit-animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
	animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
	border-color: #ef5742;
}
.euiRangeThumb--hasTicks {
	top: 25%;
}

.euiRangeTicks {
	position: absolute;
	left: 8px;
	right: 8px;
	top: 8px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.euiRangeTick {
	overflow-x: hidden;
	text-overflow: ellipsis;
	font-size: 12px;
	position: relative;
	padding-top: 16px;
}
.euiRangeTick::before {
	width: 4px;
	height: 4px;
	content: '';
	background-color: #69707d;
	border-radius: 100%;
	position: absolute;
	top: 0;
	left: calc(50% - 2px);
}
.euiRangeTick--isCustom {
	position: absolute;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
}
.euiRangeTick:enabled:hover,
.euiRangeTick:focus,
.euiRangeTick--selected {
	color: #ef5742;
}
.euiRangeTick--selected {
	font-weight: 500;
}
.euiRangeTick:disabled {
	cursor: not-allowed;
}

.euiRangeTicks--compressed {
	top: 6px;
}
.euiRangeTicks--compressed .euiRangeTick {
	padding-top: 14px;
}

.euiRangeTooltip {
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	width: calc(100% - 16px);
	margin-left: 8px;
	pointer-events: none;
}

.euiRangeTooltip__value {
	font-size: 14px;
	font-size: 0.875rem;
	line-height: 1.5;
	border: 1px solid #404040;
	position: absolute;
	border-radius: 4px;
	padding: 2px 8px;
	background-color: #404040;
	color: #fff;
	max-width: 256px;
	top: 50%;
	-webkit-transition: -webkit-box-shadow 250ms cubic-bezier(0.694, 0.0482, 0.335, 1),
		-webkit-transform 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
	transition: -webkit-box-shadow 250ms cubic-bezier(0.694, 0.0482, 0.335, 1),
		-webkit-transform 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
	transition: box-shadow 250ms cubic-bezier(0.694, 0.0482, 0.335, 1),
		transform 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
	transition: box-shadow 250ms cubic-bezier(0.694, 0.0482, 0.335, 1),
		transform 250ms cubic-bezier(0.694, 0.0482, 0.335, 1),
		-webkit-box-shadow 250ms cubic-bezier(0.694, 0.0482, 0.335, 1),
		-webkit-transform 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
}
.euiRangeTooltip__value::after,
.euiRangeTooltip__value::before {
	content: '';
	position: absolute;
	bottom: -6px;
	left: 50%;
	-webkit-transform-origin: center;
	transform-origin: center;
	background-color: #404040;
	width: 12px;
	height: 12px;
	border-radius: 2px;
}
.euiRangeTooltip__value::before {
	background-color: #404040;
}
.euiRangeTooltip__value.euiRangeTooltip__value--right {
	margin-left: 24px;
}
.euiRangeTooltip__value.euiRangeTooltip__value--right:before,
.euiRangeTooltip__value.euiRangeTooltip__value--right:after {
	left: -5px;
}
.euiRangeTooltip__value.euiRangeTooltip__value--right::before {
	margin-left: -1px;
}
.euiRangeTooltip__value.euiRangeTooltip__value--left {
	margin-right: 24px;
}
.euiRangeTooltip__value.euiRangeTooltip__value--left:before,
.euiRangeTooltip__value.euiRangeTooltip__value--left:after {
	left: auto;
	right: -5px;
}
.euiRangeTooltip__value.euiRangeTooltip__value--left::before {
	margin-right: -1px;
}
.euiRangeTooltip__value.euiRangeTooltip__value--right,
.euiRangeTooltip__value.euiRangeTooltip__value--left {
	-webkit-transform: translateX(0) translateY(-50%);
	transform: translateX(0) translateY(-50%);
}
.euiRangeTooltip__value.euiRangeTooltip__value--right:before,
.euiRangeTooltip__value.euiRangeTooltip__value--right:after,
.euiRangeTooltip__value.euiRangeTooltip__value--left:before,
.euiRangeTooltip__value.euiRangeTooltip__value--left:after {
	bottom: 50%;
	-webkit-transform: translateY(50%) rotateZ(45deg);
	transform: translateY(50%) rotateZ(45deg);
}
.euiRangeTooltip__value--hasTicks {
	top: 10px;
}
.euiRangeTooltip--compressed .euiRangeTooltip__value--hasTicks {
	top: 8px;
}

.euiRangeTrack {
	height: 100%;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	position: relative;
	-ms-flex-item-align: start;
	align-self: flex-start;
}
.euiRangeTrack--disabled {
	opacity: 0.25;
}

/*
   * 1. There's no way to target the layout of the extra input, so we must
   *    use the descendant selector to allow the width to shrink.
   *
   * 2. Prevent the prepend/append label from extending outside the parent element
   */
.euiRangeWrapper {
	max-width: 400px;
	width: 100%;
	height: 40px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.euiRangeWrapper--fullWidth {
	max-width: 100%;
}
.euiRangeWrapper--compressed {
	height: 32px;
}
.euiRangeWrapper--inGroup {
	height: 100%;
}
.euiRangeWrapper > .euiFormControlLayout {
	/* 1 */
	width: auto;
}
.euiRangeWrapper > .euiFormControlLayout.euiFormControlLayout--group {
	-ms-flex-negative: 0;
	flex-shrink: 0;
	/* 2 */
}

.euiDualRange__slider::-webkit-slider-thumb {
	visibility: hidden;
}

.euiDualRange__slider::-moz-range-thumb {
	visibility: hidden;
}

.euiDualRange__slider::-ms-thumb {
	visibility: hidden;
}

/**
   * 1. Leave room for caret.
   * 2. Ensure the descenders don't get cut off
   */
.euiSelect {
	max-width: 400px;
	width: 100%;
	height: 40px;
	background-color: #fbfcfd;
	background-repeat: no-repeat;
	background-size: 0% 100%;
	-webkit-box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2),
		0 3px 2px -2px rgba(152, 162, 179, 0.2), inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 3px 2px -2px rgba(152, 162, 179, 0.2),
		inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	-webkit-transition: background-image 150ms ease-in, background-size 150ms ease-in,
		background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	transition: background-image 150ms ease-in, background-size 150ms ease-in,
		background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
		background-size 150ms ease-in, background-color 150ms ease-in;
	transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
		background-size 150ms ease-in, background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	font-family: var(--primary-font), 'Inter UI', -apple-system, BlinkMacSystemFont, 'Segoe UI',
		Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
	font-weight: 400;
	letter-spacing: -0.005em;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-webkit-font-kerning: normal;
	font-kerning: normal;
	font-size: 14px;
	color: #343741;
	border: none;
	border-radius: 0;
	padding: 12px;
	padding-right: 40px;
	/* 1 */
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	line-height: 40px;
	/* 2 */
	padding-top: 0;
	/* 2 */
	padding-bottom: 0;
	/* 2 */
}
.euiSelect--fullWidth {
	max-width: 100%;
}
@supports (-moz-appearance: none) {
	.euiSelect {
		-webkit-transition-property: background-image, background-size, -webkit-box-shadow;
		transition-property: background-image, background-size, -webkit-box-shadow;
		transition-property: box-shadow, background-image, background-size;
		transition-property: box-shadow, background-image, background-size, -webkit-box-shadow;
	}
}
.euiSelect::-webkit-input-placeholder {
	color: #69707d;
}
.euiSelect::-moz-placeholder {
	color: #69707d;
}
.euiSelect:-ms-input-placeholder {
	color: #69707d;
}
.euiSelect:-moz-placeholder {
	color: #69707d;
}
.euiSelect::-ms-input-placeholder {
	color: #69707d;
}
.euiSelect::placeholder {
	color: #69707d;
}
.euiSelect:invalid {
	background-image: linear-gradient(
		to top,
		#bd271e,
		#bd271e 2px,
		transparent 2px,
		transparent 100%
	);
	background-size: 100%;
}
.euiSelect:focus {
	background-color: white;
	background-image: linear-gradient(
		to top,
		#ef5742,
		#ef5742 2px,
		transparent 2px,
		transparent 100%
	);
	background-size: 100% 100%;
	-webkit-box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2),
		0 4px 4px -2px rgba(152, 162, 179, 0.2), inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 4px 4px -2px rgba(152, 162, 179, 0.2),
		inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiSelect:disabled {
	cursor: not-allowed;
	color: #98a2b3;
	background: #eef2f7;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiSelect:disabled::-webkit-input-placeholder {
	color: #98a2b3;
}
.euiSelect:disabled::-moz-placeholder {
	color: #98a2b3;
}
.euiSelect:disabled:-ms-input-placeholder {
	color: #98a2b3;
}
.euiSelect:disabled:-moz-placeholder {
	color: #98a2b3;
}
.euiSelect:disabled::-ms-input-placeholder {
	color: #98a2b3;
}
.euiSelect:disabled::placeholder {
	color: #98a2b3;
}
.euiSelect[readOnly] {
	cursor: default;
	background: rgba(211, 218, 230, 0.05);
	border-color: transparent;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiSelect:-webkit-autofill {
	-webkit-text-fill-color: #343741;
}
.euiSelect:-webkit-autofill ~ .euiFormControlLayoutIcons {
	color: #343741;
}
.euiSelect--compressed {
	line-height: 16px;
	height: 32px;
	background-color: #fbfcfd;
	background-repeat: no-repeat;
	background-size: 0% 100%;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	-webkit-transition: background-image 150ms ease-in, background-size 150ms ease-in,
		background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	transition: background-image 150ms ease-in, background-size 150ms ease-in,
		background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
		background-size 150ms ease-in, background-color 150ms ease-in;
	transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
		background-size 150ms ease-in, background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	padding: 8px;
	border-radius: 2px;
}
@supports (-moz-appearance: none) {
	.euiSelect--compressed {
		-webkit-transition-property: background-image, background-size, -webkit-box-shadow;
		transition-property: background-image, background-size, -webkit-box-shadow;
		transition-property: box-shadow, background-image, background-size;
		transition-property: box-shadow, background-image, background-size, -webkit-box-shadow;
	}
}
.euiSelect--compressed:invalid {
	background-image: linear-gradient(
		to top,
		#bd271e,
		#bd271e 2px,
		transparent 2px,
		transparent 100%
	);
	background-size: 100%;
}
.euiSelect--compressed:focus {
	background-color: white;
	background-image: linear-gradient(
		to top,
		#ef5742,
		#ef5742 2px,
		transparent 2px,
		transparent 100%
	);
	background-size: 100% 100%;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiSelect--compressed:disabled {
	cursor: not-allowed;
	color: #98a2b3;
	background: #eef2f7;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiSelect--compressed:disabled::-webkit-input-placeholder {
	color: #98a2b3;
}
.euiSelect--compressed:disabled::-moz-placeholder {
	color: #98a2b3;
}
.euiSelect--compressed:disabled:-ms-input-placeholder {
	color: #98a2b3;
}
.euiSelect--compressed:disabled:-moz-placeholder {
	color: #98a2b3;
}
.euiSelect--compressed:disabled::-ms-input-placeholder {
	color: #98a2b3;
}
.euiSelect--compressed:disabled::placeholder {
	color: #98a2b3;
}
.euiSelect--compressed[readOnly] {
	cursor: default;
	background: rgba(211, 218, 230, 0.05);
	border-color: transparent;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiSelect--inGroup {
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	border-radius: 0;
	line-height: 38px;
	height: 100%;
}
.euiSelect-isLoading {
	padding-right: 68px;
}
.euiSelect--inGroup.euiSelect--compressed {
	line-height: 30px;
	/* 2 */
}
.euiSelect::-ms-expand {
	display: none;
}
.euiSelect:focus::-ms-value {
	color: #343741;
	background: transparent;
}
.euiSelect:-moz-focusring {
	color: transparent;
	text-shadow: 0 0 0 #343741;
}

/*
   * 1. Make popover the same width as the form control
   * 2. Style popover similar to combobox
   * 3. Use attribute selector to match popover position without needing the full popover class name
   */
.euiSuperSelect:not(.euiSuperSelect--fullWidth) {
	/* 1 */
	max-width: 400px !important;
}

.euiSuperSelect__listbox {
	scrollbar-width: thin;
	max-height: 300px;
	overflow: hidden;
	overflow-y: auto;
}
.euiSuperSelect__listbox::-webkit-scrollbar {
	width: 16px;
	height: 16px;
}
.euiSuperSelect__listbox::-webkit-scrollbar-thumb {
	background-color: rgba(105, 112, 125, 0.5);
	border: 6px solid transparent;
	background-clip: content-box;
}
.euiSuperSelect__listbox::-webkit-scrollbar-corner,
.euiSuperSelect__listbox::-webkit-scrollbar-track {
	background-color: transparent;
}

.euiSuperSelect__popoverPanel[class*='bottom'] {
	/* 3 */
	border-top-color: rgba(211, 218, 230, 0.8);
	border-top-right-radius: 0;
	/* 2 */
	border-top-left-radius: 0;
	/* 2 */
}

.euiSuperSelect__popoverPanel[class*='top'] {
	/* 3 */
	-webkit-box-shadow: 0 0 12px -1px rgba(152, 162, 179, 0.2), 0 0 4px -1px rgba(152, 162, 179, 0.2),
		0 0 2px 0 rgba(152, 162, 179, 0.2);
	box-shadow: 0 0 12px -1px rgba(152, 162, 179, 0.2), 0 0 4px -1px rgba(152, 162, 179, 0.2),
		0 0 2px 0 rgba(152, 162, 179, 0.2);
	/* 2 */
	border-bottom-color: rgba(211, 218, 230, 0.8);
	border-bottom-right-radius: 0;
	/* 2 */
	border-bottom-left-radius: 0;
	/* 2 */
}

.euiSuperSelect__item {
	font-size: 14px;
	font-size: 0.875rem;
	line-height: 1.5;
	padding: 8px;
}
.euiSuperSelect__item:hover:not(:disabled),
.euiSuperSelect__item:focus {
	text-decoration: none;
	background-color: #e6f0f8;
}
.euiSuperSelect__item:disabled {
	cursor: not-allowed;
}

.euiSuperSelect__item--hasDividers:not(:last-of-type) {
	border-bottom: 1px solid #d3dae6;
}

/**
   * 1. Leave room for caret.
   * 2. Ensure the descenders don't get cut off
   * 3. Makes sure the height is correct when there's no selection
   */
.euiSuperSelectControl {
	max-width: 400px;
	width: 100%;
	height: 40px;
	background-color: #fbfcfd;
	background-repeat: no-repeat;
	background-size: 0% 100%;
	-webkit-box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2),
		0 3px 2px -2px rgba(152, 162, 179, 0.2), inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 3px 2px -2px rgba(152, 162, 179, 0.2),
		inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	-webkit-transition: background-image 150ms ease-in, background-size 150ms ease-in,
		background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	transition: background-image 150ms ease-in, background-size 150ms ease-in,
		background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
		background-size 150ms ease-in, background-color 150ms ease-in;
	transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
		background-size 150ms ease-in, background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	font-family: var(--primary-font), 'Inter UI', -apple-system, BlinkMacSystemFont, 'Segoe UI',
		Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
	font-weight: 400;
	letter-spacing: -0.005em;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-webkit-font-kerning: normal;
	font-kerning: normal;
	font-size: 14px;
	color: #343741;
	border: none;
	border-radius: 0;
	padding: 12px;
	padding-right: 40px;
	/* 1 */
	display: block;
	/* 3 */
	text-align: left;
	line-height: 40px;
	/* 2 */
	padding-top: 0;
	/* 2 */
	padding-bottom: 0;
	/* 2 */
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.euiSuperSelectControl--fullWidth {
	max-width: 100%;
}
@supports (-moz-appearance: none) {
	.euiSuperSelectControl {
		-webkit-transition-property: background-image, background-size, -webkit-box-shadow;
		transition-property: background-image, background-size, -webkit-box-shadow;
		transition-property: box-shadow, background-image, background-size;
		transition-property: box-shadow, background-image, background-size, -webkit-box-shadow;
	}
}
.euiSuperSelectControl::-webkit-input-placeholder {
	color: #69707d;
}
.euiSuperSelectControl::-moz-placeholder {
	color: #69707d;
}
.euiSuperSelectControl:-ms-input-placeholder {
	color: #69707d;
}
.euiSuperSelectControl:-moz-placeholder {
	color: #69707d;
}
.euiSuperSelectControl::-ms-input-placeholder {
	color: #69707d;
}
.euiSuperSelectControl::placeholder {
	color: #69707d;
}
.euiSuperSelectControl:invalid {
	background-image: linear-gradient(
		to top,
		#bd271e,
		#bd271e 2px,
		transparent 2px,
		transparent 100%
	);
	background-size: 100%;
}
.euiSuperSelectControl:focus {
	background-color: white;
	background-image: linear-gradient(
		to top,
		#ef5742,
		#ef5742 2px,
		transparent 2px,
		transparent 100%
	);
	background-size: 100% 100%;
	-webkit-box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2),
		0 4px 4px -2px rgba(152, 162, 179, 0.2), inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 4px 4px -2px rgba(152, 162, 179, 0.2),
		inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiSuperSelectControl:disabled {
	cursor: not-allowed;
	color: #98a2b3;
	background: #eef2f7;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiSuperSelectControl:disabled::-webkit-input-placeholder {
	color: #98a2b3;
}
.euiSuperSelectControl:disabled::-moz-placeholder {
	color: #98a2b3;
}
.euiSuperSelectControl:disabled:-ms-input-placeholder {
	color: #98a2b3;
}
.euiSuperSelectControl:disabled:-moz-placeholder {
	color: #98a2b3;
}
.euiSuperSelectControl:disabled::-ms-input-placeholder {
	color: #98a2b3;
}
.euiSuperSelectControl:disabled::placeholder {
	color: #98a2b3;
}
.euiSuperSelectControl[readOnly] {
	cursor: default;
	background: rgba(211, 218, 230, 0.05);
	border-color: transparent;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiSuperSelectControl:-webkit-autofill {
	-webkit-text-fill-color: #343741;
}
.euiSuperSelectControl:-webkit-autofill ~ .euiFormControlLayoutIcons {
	color: #343741;
}
.euiSuperSelectControl--compressed {
	background-color: #fbfcfd;
	background-repeat: no-repeat;
	background-size: 0% 100%;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	-webkit-transition: background-image 150ms ease-in, background-size 150ms ease-in,
		background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	transition: background-image 150ms ease-in, background-size 150ms ease-in,
		background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
		background-size 150ms ease-in, background-color 150ms ease-in;
	transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
		background-size 150ms ease-in, background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	padding: 0 8px;
	border-radius: 2px;
	line-height: 32px;
	height: 32px;
}
@supports (-moz-appearance: none) {
	.euiSuperSelectControl--compressed {
		-webkit-transition-property: background-image, background-size, -webkit-box-shadow;
		transition-property: background-image, background-size, -webkit-box-shadow;
		transition-property: box-shadow, background-image, background-size;
		transition-property: box-shadow, background-image, background-size, -webkit-box-shadow;
	}
}
.euiSuperSelectControl--compressed:invalid {
	background-image: linear-gradient(
		to top,
		#bd271e,
		#bd271e 2px,
		transparent 2px,
		transparent 100%
	);
	background-size: 100%;
}
.euiSuperSelectControl--compressed:focus {
	background-color: white;
	background-image: linear-gradient(
		to top,
		#ef5742,
		#ef5742 2px,
		transparent 2px,
		transparent 100%
	);
	background-size: 100% 100%;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiSuperSelectControl--compressed:disabled {
	cursor: not-allowed;
	color: #98a2b3;
	background: #eef2f7;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiSuperSelectControl--compressed:disabled::-webkit-input-placeholder {
	color: #98a2b3;
}
.euiSuperSelectControl--compressed:disabled::-moz-placeholder {
	color: #98a2b3;
}
.euiSuperSelectControl--compressed:disabled:-ms-input-placeholder {
	color: #98a2b3;
}
.euiSuperSelectControl--compressed:disabled:-moz-placeholder {
	color: #98a2b3;
}
.euiSuperSelectControl--compressed:disabled::-ms-input-placeholder {
	color: #98a2b3;
}
.euiSuperSelectControl--compressed:disabled::placeholder {
	color: #98a2b3;
}
.euiSuperSelectControl--compressed[readOnly] {
	cursor: default;
	background: rgba(211, 218, 230, 0.05);
	border-color: transparent;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiSuperSelectControl--inGroup {
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	border-radius: 0;
	height: 100%;
}
.euiSuperSelectControl-isLoading {
	padding-right: 68px;
}
.euiSuperSelectControl-isInvalid {
	background-image: linear-gradient(
		to top,
		#bd271e,
		#bd271e 2px,
		transparent 2px,
		transparent 100%
	);
	background-size: 100%;
}
.euiSuperSelectControl.euiSuperSelect--isOpen__button {
	background-color: white;
	background-image: linear-gradient(
		to top,
		#ef5742,
		#ef5742 2px,
		transparent 2px,
		transparent 100%
	);
	background-size: 100% 100%;
	-webkit-box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2),
		0 4px 4px -2px rgba(152, 162, 179, 0.2), inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 4px 4px -2px rgba(152, 162, 179, 0.2),
		inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}

.euiSwitch {
	position: relative;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	min-height: 20px;
}
.euiSwitch .euiSwitch__label {
	cursor: pointer;
	padding-left: 8px;
	line-height: 20px;
	font-size: 14px;
	vertical-align: middle;
	display: inline-block;
}
.euiSwitch .euiSwitch__button {
	-ms-flex-negative: 0;
	flex-shrink: 0;
	line-height: 0;
}
.euiSwitch .euiSwitch__button:focus .euiSwitch__track {
	-webkit-animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
	animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimate !important;
	border-color: #ef5742;
}
.euiSwitch .euiSwitch__button:disabled:hover,
.euiSwitch .euiSwitch__button:disabled ~ .euiSwitch__label:hover {
	cursor: not-allowed;
}
.euiSwitch .euiSwitch__button:disabled .euiSwitch__body {
	background-color: rgba(152, 162, 179, 0.2);
}
.euiSwitch .euiSwitch__button:disabled .euiSwitch__thumb {
	border-color: #d3dae6;
	-webkit-box-shadow: none;
	box-shadow: none;
	background-color: rgba(152, 162, 179, 0.2);
}
.euiSwitch .euiSwitch__button:disabled .euiSwitch__icon {
	fill: #5e646f;
}
.euiSwitch .euiSwitch__button:disabled + .euiSwitch__label {
	color: #98a2b3;
}
.euiSwitch .euiSwitch__button[aria-checked='false'] .euiSwitch__body {
	background-color: rgba(152, 162, 179, 0.2);
}
.euiSwitch .euiSwitch__button[aria-checked='false'] .euiSwitch__thumb {
	left: 0;
}
.euiSwitch .euiSwitch__button[aria-checked='false'] .euiSwitch__icon {
	right: -8px;
}
.euiSwitch .euiSwitch__button[aria-checked='false'] .euiSwitch__icon.euiSwitch__icon--checked {
	right: auto;
	left: -34px;
}
.euiSwitch .euiSwitch__body {
	pointer-events: none;
	width: 44px;
	height: 20px;
	display: inline-block;
	position: relative;
	border-radius: 20px;
	vertical-align: middle;
}
.euiSwitch .euiSwitch__thumb {
	-webkit-box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
	box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
	padding: 9px;
	border: 1px solid #c9cbcd;
	background: #fff no-repeat center;
	border-radius: 18px;
	position: absolute;
	display: inline-block;
	left: 24px;
	-webkit-transition: border-color 250ms cubic-bezier(0.34, 1.61, 0.7, 1),
		background-color 250ms cubic-bezier(0.34, 1.61, 0.7, 1),
		left 250ms cubic-bezier(0.34, 1.61, 0.7, 1),
		-webkit-transform 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
	transition: border-color 250ms cubic-bezier(0.34, 1.61, 0.7, 1),
		background-color 250ms cubic-bezier(0.34, 1.61, 0.7, 1),
		left 250ms cubic-bezier(0.34, 1.61, 0.7, 1), transform 250ms cubic-bezier(0.34, 1.61, 0.7, 1),
		-webkit-transform 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
}
.euiSwitch .euiSwitch__track {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
	border-radius: 20px;
}
.euiSwitch .euiSwitch__icon {
	position: absolute;
	right: -34px;
	top: 2px;
	bottom: 0;
	width: 42px;
	height: 16px;
	-webkit-transition: left 250ms cubic-bezier(0.34, 1.61, 0.7, 1),
		right 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
	transition: left 250ms cubic-bezier(0.34, 1.61, 0.7, 1),
		right 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
	fill: #343741;
}
.euiSwitch .euiSwitch__icon--checked {
	right: auto;
	left: -8px;
	fill: #fff;
}
.euiSwitch:hover .euiSwitch__button:not(:disabled) .euiSwitch__thumb {
	-webkit-transform: scale(1.05);
	transform: scale(1.05);
}
.euiSwitch:hover .euiSwitch__button:active .euiSwitch__thumb {
	-webkit-transform: scale(0.95);
	transform: scale(0.95);
}
.euiSwitch.euiSwitch--compressed {
	min-height: 16px;
}
.euiSwitch.euiSwitch--compressed .euiSwitch__label {
	line-height: 16px;
}
.euiSwitch.euiSwitch--compressed .euiSwitch__body {
	width: 28px;
	height: 16px;
	border-radius: 16px;
}
.euiSwitch.euiSwitch--compressed .euiSwitch__thumb {
	-webkit-box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
	box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
	padding: 6px;
	border: 1px solid #c9cbcd;
	background: #fff no-repeat center;
	border-radius: 12px;
	left: 13px;
	top: 1px;
	-webkit-transition: border-color 250ms cubic-bezier(0.34, 1.61, 0.7, 1),
		background-color 250ms cubic-bezier(0.34, 1.61, 0.7, 1),
		left 250ms cubic-bezier(0.34, 1.61, 0.7, 1),
		-webkit-transform 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
	transition: border-color 250ms cubic-bezier(0.34, 1.61, 0.7, 1),
		background-color 250ms cubic-bezier(0.34, 1.61, 0.7, 1),
		left 250ms cubic-bezier(0.34, 1.61, 0.7, 1), transform 250ms cubic-bezier(0.34, 1.61, 0.7, 1),
		-webkit-transform 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
}
.euiSwitch.euiSwitch--compressed .euiSwitch__track {
	border-radius: 16px;
}
.euiSwitch.euiSwitch--mini {
	min-height: 10px;
}
.euiSwitch.euiSwitch--mini .euiSwitch__label {
	line-height: 10px;
	font-size: 12px;
}
.euiSwitch.euiSwitch--mini .euiSwitch__body {
	width: 22px;
	height: 10px;
	border-radius: 10px;
}
.euiSwitch.euiSwitch--mini .euiSwitch__thumb {
	-webkit-box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
	box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
	padding: 3px;
	border: 1px solid #c9cbcd;
	background: #fff no-repeat center;
	border-radius: 6px;
	left: 13px;
	top: 1px;
	-webkit-transition: border-color 250ms cubic-bezier(0.34, 1.61, 0.7, 1),
		background-color 250ms cubic-bezier(0.34, 1.61, 0.7, 1),
		left 250ms cubic-bezier(0.34, 1.61, 0.7, 1),
		-webkit-transform 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
	transition: border-color 250ms cubic-bezier(0.34, 1.61, 0.7, 1),
		background-color 250ms cubic-bezier(0.34, 1.61, 0.7, 1),
		left 250ms cubic-bezier(0.34, 1.61, 0.7, 1), transform 250ms cubic-bezier(0.34, 1.61, 0.7, 1),
		-webkit-transform 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
}
.euiSwitch.euiSwitch--mini .euiSwitch__track {
	border-radius: 10px;
}
.euiSwitch.euiSwitch--compressed .euiSwitch__button[aria-checked='false'] .euiSwitch__thumb,
.euiSwitch.euiSwitch--mini .euiSwitch__button[aria-checked='false'] .euiSwitch__thumb {
	left: 1px;
}
.euiSwitch.euiSwitch--compressed .euiSwitch__button[aria-checked='false'] .euiSwitch__thumb,
.euiSwitch.euiSwitch--compressed .euiSwitch__button[aria-checked='true']:disabled .euiSwitch__thumb,
.euiSwitch.euiSwitch--mini .euiSwitch__button[aria-checked='false'] .euiSwitch__thumb,
.euiSwitch.euiSwitch--mini .euiSwitch__button[aria-checked='true']:disabled .euiSwitch__thumb {
	border-color: #c9cbcd;
}
.euiSwitch.euiSwitch--compressed .euiSwitch__button[aria-checked='true'] .euiSwitch__thumb,
.euiSwitch.euiSwitch--mini .euiSwitch__button[aria-checked='true'] .euiSwitch__thumb {
	border-color: #ef5742;
}

.euiTextArea {
	max-width: 400px;
	width: 100%;
	background-color: #fbfcfd;
	background-repeat: no-repeat;
	background-size: 0% 100%;
	-webkit-box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2),
		0 3px 2px -2px rgba(152, 162, 179, 0.2), inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 3px 2px -2px rgba(152, 162, 179, 0.2),
		inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	-webkit-transition: background-image 150ms ease-in, background-size 150ms ease-in,
		background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
		background-size 150ms ease-in, background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	font-family: var(--primary-font), 'Inter UI', -apple-system, BlinkMacSystemFont, 'Segoe UI',
		Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
	font-weight: 400;
	letter-spacing: -0.005em;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-webkit-font-kerning: normal;
	font-kerning: normal;
	font-size: 14px;
	color: #343741;
	border: none;
	border-radius: 0;
	padding: 12px;
	line-height: 1.5;
	height: auto;
}
.euiTextArea--fullWidth {
	max-width: 100%;
}
@supports (-moz-appearance: none) {
	.euiTextArea {
		-webkit-transition-property: background-image, background-size, -webkit-box-shadow;
		transition-property: background-image, background-size, -webkit-box-shadow;
		transition-property: box-shadow, background-image, background-size;
		transition-property: box-shadow, background-image, background-size, -webkit-box-shadow;
	}
}
.euiTextArea::-webkit-input-placeholder {
	color: #69707d;
}
.euiTextArea::-moz-placeholder {
	color: #69707d;
}
.euiTextArea:-ms-input-placeholder {
	color: #69707d;
}
.euiTextArea:-moz-placeholder {
	color: #69707d;
}
.euiTextArea::-ms-input-placeholder {
	color: #69707d;
}
.euiTextArea::placeholder {
	color: #69707d;
}
.euiTextArea:invalid {
	background-image: linear-gradient(
		to top,
		#bd271e,
		#bd271e 2px,
		transparent 2px,
		transparent 100%
	);
	background-size: 100%;
}
.euiTextArea:focus {
	background-color: white;
	background-image: linear-gradient(
		to top,
		#ef5742,
		#ef5742 2px,
		transparent 2px,
		transparent 100%
	);
	background-size: 100% 100%;
	-webkit-box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2),
		0 4px 4px -2px rgba(152, 162, 179, 0.2), inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: 0 1px 1px -1px rgba(152, 162, 179, 0.2), 0 4px 4px -2px rgba(152, 162, 179, 0.2),
		inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiTextArea:disabled {
	cursor: not-allowed;
	color: #98a2b3;
	background: #eef2f7;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiTextArea:disabled::-webkit-input-placeholder {
	color: #98a2b3;
}
.euiTextArea:disabled::-moz-placeholder {
	color: #98a2b3;
}
.euiTextArea:disabled:-ms-input-placeholder {
	color: #98a2b3;
}
.euiTextArea:disabled:-moz-placeholder {
	color: #98a2b3;
}
.euiTextArea:disabled::-ms-input-placeholder {
	color: #98a2b3;
}
.euiTextArea:disabled::placeholder {
	color: #98a2b3;
}
.euiTextArea[readOnly] {
	cursor: default;
	background: rgba(211, 218, 230, 0.05);
	border-color: transparent;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiTextArea:-webkit-autofill {
	-webkit-text-fill-color: #343741;
}
.euiTextArea:-webkit-autofill ~ .euiFormControlLayoutIcons {
	color: #343741;
}
.euiTextArea--compressed {
	background-color: #fbfcfd;
	background-repeat: no-repeat;
	background-size: 0% 100%;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	-webkit-transition: background-image 150ms ease-in, background-size 150ms ease-in,
		background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	transition: box-shadow 150ms ease-in, background-image 150ms ease-in,
		background-size 150ms ease-in, background-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	padding: 8px;
	border-radius: 2px;
	height: auto;
}
@supports (-moz-appearance: none) {
	.euiTextArea--compressed {
		-webkit-transition-property: background-image, background-size, -webkit-box-shadow;
		transition-property: background-image, background-size, -webkit-box-shadow;
		transition-property: box-shadow, background-image, background-size;
		transition-property: box-shadow, background-image, background-size, -webkit-box-shadow;
	}
}
.euiTextArea--compressed:invalid {
	background-image: linear-gradient(
		to top,
		#bd271e,
		#bd271e 2px,
		transparent 2px,
		transparent 100%
	);
	background-size: 100%;
}
.euiTextArea--compressed:focus {
	background-color: white;
	background-image: linear-gradient(
		to top,
		#ef5742,
		#ef5742 2px,
		transparent 2px,
		transparent 100%
	);
	background-size: 100% 100%;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiTextArea--compressed:disabled {
	cursor: not-allowed;
	color: #98a2b3;
	background: #eef2f7;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiTextArea--compressed:disabled::-webkit-input-placeholder {
	color: #98a2b3;
}
.euiTextArea--compressed:disabled::-moz-placeholder {
	color: #98a2b3;
}
.euiTextArea--compressed:disabled:-ms-input-placeholder {
	color: #98a2b3;
}
.euiTextArea--compressed:disabled:-moz-placeholder {
	color: #98a2b3;
}
.euiTextArea--compressed:disabled::-ms-input-placeholder {
	color: #98a2b3;
}
.euiTextArea--compressed:disabled::placeholder {
	color: #98a2b3;
}
.euiTextArea--compressed[readOnly] {
	cursor: default;
	background: rgba(211, 218, 230, 0.05);
	border-color: transparent;
	-webkit-box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
	box-shadow: inset 0 0 0 1px rgba(15, 39, 118, 0.1);
}
.euiTextArea--inGroup {
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
	border-radius: 0;
	height: 100%;
}

.euiTextArea--resizeVertical {
	resize: vertical;
}

.euiTextArea--resizeHorizontal {
	resize: horizontal;
}

.euiTextArea--resizeBoth {
	resize: both;
}

.euiTextArea--resizeNone {
	resize: none;
}

.euiHeader {
	-webkit-box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3),
		0 1px 5px -2px rgba(152, 162, 179, 0.3);
	box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3), 0 1px 5px -2px rgba(152, 162, 179, 0.3);
	position: relative;
	z-index: 1000;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	background: #fff;
	border-bottom: 1px solid #d3dae6;
}
.euiHeader--fixed {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
}

.euiBody--headerIsFixed {
	padding-top: 49px;
}

.euiBody--collapsibleNavIsOpen .euiHeader--fixed,
.euiBody--hasFlyout .euiHeader--fixed {
	z-index: 8001;
}

.euiHeaderProfile {
	padding: 16px;
}

.euiHeaderLinks {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	position: relative;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

.euiHeaderLinks__list {
	white-space: nowrap;
	overflow: hidden;
}

.euiHeaderLinks__mobile {
	display: none !important;
	position: absolute !important;
	right: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.euiHeaderLinks__mobileList {
		display: none !important;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.euiHeaderLinks__mobileList {
		display: none !important;
	}
}

@media only screen and (min-width: 1200px) {
	.euiHeaderLinks__mobileList {
		display: none !important;
	}
}

@media only screen and (max-width: 574px) {
	.euiHeaderLinks__mobile {
		display: block !important;
	}
	.euiHeaderLinks__list {
		display: none;
	}
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
	.euiHeaderLinks__mobile {
		display: block !important;
	}
	.euiHeaderLinks__list {
		display: none;
	}
}

.euiHeaderLink {
	display: inline-block;
	height: 48px;
	line-height: 48px;
	padding: 0 8px;
	text-align: left;
}
.euiHeaderLink:hover {
	text-decoration: underline;
}
.euiHeaderLink:focus {
	text-decoration: underline;
	background: #e6f0f8;
}

.euiHeaderLinks__mobileList .euiHeaderLink {
	display: block;
	height: auto;
	line-height: 1.5;
	padding: 8px;
}
.euiHeaderLinks__mobileList .euiHeaderLink > span {
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.euiHeaderLogo {
	text-align: left;
	position: relative;
	height: 48px;
	line-height: 48px;
	min-width: 49px;
	padding: 0 13px 0 12px;
	display: inline-block;
	vertical-align: middle;
	white-space: nowrap;
}
.euiHeaderLogo:focus {
	text-decoration: none;
	background: #e6f0f8;
}
.euiHeaderLogo:hover {
	background: #f5f7fa;
	text-decoration: none;
}

.euiHeaderLogo__icon {
	opacity: 1;
	position: relative;
	top: -2px;
}

.euiHeaderLogo__text {
	color: #1a1c21;
	font-size: 20px;
	font-size: 1.25rem;
	line-height: 2rem;
	letter-spacing: -0.025em;
	padding-left: 16px;
	font-weight: 300;
}

@media only screen and (max-width: 574px) {
	.euiHeaderLogo {
		padding: 0 12px;
	}
	.euiHeaderLogo__icon.euiIcon--xLarge {
		width: 24px;
		height: 24px;
	}
	.euiHeaderLogo__text {
		color: #1a1c21;
		font-size: 1rem;
		line-height: 1.5rem;
		letter-spacing: -0.02em;
		font-weight: 400;
	}
}

.euiHeaderAlert {
	min-width: 300px;
	position: relative;
	margin-bottom: 24px;
	padding: 0 8px 24px;
	border-bottom: 1px solid #d3dae6;
	border-top: none;
}
.euiHeaderAlert .euiHeaderAlert__dismiss {
	opacity: 0;
	position: absolute;
	right: 12px;
	top: 12px;
	-webkit-transition: opacity 250ms ease-in;
	transition: opacity 250ms ease-in;
}
.euiHeaderAlert:hover .euiHeaderAlert__dismiss,
.euiHeaderAlert .euiHeaderAlert__dismiss:focus {
	opacity: 1;
}
.euiHeaderAlert .euiHeaderAlert__title {
	color: #1a1c21;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 600;
	letter-spacing: -0.02em;
	margin-bottom: 8px;
}
.euiHeaderAlert .euiHeaderAlert__text {
	font-size: 0.875rem;
	line-height: 1.5;
	margin-bottom: 16px;
}
.euiHeaderAlert .euiHeaderAlert__action {
	font-size: 0.875rem;
	line-height: 1.5;
}
.euiHeaderAlert .euiHeaderAlert__date {
	font-size: 0.75rem;
	line-height: 1.5;
	color: #69707d;
}

.euiHeaderBreadcrumbs {
	margin-left: 16px;
	margin-right: 16px;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

@media only screen and (max-width: 574px) {
	.euiHeaderBreadcrumbs {
		margin-left: 8px;
		margin-right: 8px;
	}
}

.euiHeaderSection {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 0;
	-ms-flex-positive: 0;
	flex-grow: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
}

.euiHeaderSection--grow,
.euiHeaderSection--left {
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

.euiHeaderSection--dontGrow {
	-webkit-box-flex: 0;
	-ms-flex-positive: 0;
	flex-grow: 0;
}

.euiHeaderSectionItem {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.euiHeaderSectionItem:after {
	position: absolute;
	content: '';
	top: 16px;
	bottom: 0;
	background: #d3dae6;
	left: 0;
}

.euiHeaderSectionItem__button {
	height: 48px;
	min-width: 48px;
	text-align: center;
	font-size: 0;
}
.euiHeaderSectionItem__button:hover {
	background: #f5f7fa;
}
.euiHeaderSectionItem__button:focus {
	background: #e6f0f8;
}

.euiHeaderSectionItem--borderLeft:after {
	left: 0;
	width: 1px;
}

.euiHeaderSectionItem--borderRight:after {
	width: 1px;
	left: auto;
	right: 0;
}

.euiHeaderNotification,
.euiHeaderSectionItemButton__notification {
	position: absolute;
	top: 9%;
	right: 9%;
	-webkit-box-shadow: 0 0 0 1px #fff;
	box-shadow: 0 0 0 1px #fff;
}

@media only screen and (max-width: 574px) {
	.euiHeaderSectionItem,
	.euiHeaderSectionItem__button {
		min-width: 36px;
	}
	.euiHeaderSectionItem--borderLeft:after,
	.euiHeaderSectionItem--borderRight:after {
		display: none;
	}
	.euiHeaderNotification,
	.euiHeaderSectionItemButton__notification {
		width: 8px;
		height: 8px;
		top: 20%;
		min-width: 0;
		border-radius: 8px;
		color: #dd0a73;
		overflow: hidden;
	}
}

.euiHealth {
	font-size: 14px;
	font-size: 0.875rem;
	line-height: 1.5;
	display: inline-block;
}

.euiHorizontalRule {
	border: none;
	height: 1px;
	background-color: #d3dae6;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	-webkit-box-flex: 0;
	-ms-flex-positive: 0;
	flex-grow: 0;
}
.euiHorizontalRule.euiHorizontalRule--full {
	width: 100%;
}
.euiHorizontalRule.euiHorizontalRule--half {
	width: 50%;
	margin-left: auto;
	margin-right: auto;
}
.euiHorizontalRule.euiHorizontalRule--quarter {
	width: 25%;
	margin-left: auto;
	margin-right: auto;
}

.euiHorizontalRule--marginXSmall {
	margin: 8px 0;
}

.euiHorizontalRule--marginSmall {
	margin: 12px 0;
}

.euiHorizontalRule--marginMedium {
	margin: 16px 0;
}

.euiHorizontalRule--marginLarge {
	margin: 24px 0;
}

.euiHorizontalRule--marginXLarge {
	margin: 32px 0;
}

.euiHorizontalRule--marginXXLarge {
	margin: 40px 0;
}

.euiIcon {
	-ms-flex-negative: 0;
	flex-shrink: 0;
	display: inline-block;
	vertical-align: middle;
	fill: currentColor;
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0);
}
.euiIcon:focus {
	opacity: 1;
	background: #e6f0f8;
}

.euiIcon--app {
	fill: #343741;
}
.euiIcon--app .euiIcon__fillSecondary {
	fill: #017d73;
}

.euiIcon-isLoading {
	opacity: 0.05;
	background-color: currentColor;
	border-radius: 4px;
}

.euiIcon-isLoaded {
	-webkit-animation: euiIconLoading 250ms ease-in 0s 1 forwards;
	animation: euiIconLoading 250ms ease-in 0s 1 forwards;
}

.euiIcon--text {
	color: #343741;
}

.euiIcon--subdued {
	color: #6a717d;
}

.euiIcon--primary {
	color: #ef5742;
}

.euiIcon--secondary {
	color: #017d73;
}

.euiIcon--success {
	color: #017d73;
}

.euiIcon--accent {
	color: #dd0a73;
}

.euiIcon--warning {
	color: #9b6900;
}

.euiIcon--danger {
	color: #bd271e;
}

.euiIcon--ghost {
	color: #fff;
}

.euiIcon--text,
.euiIcon--text .euiIcon__fillSecondary,
.euiIcon--subdued,
.euiIcon--subdued .euiIcon__fillSecondary,
.euiIcon--primary,
.euiIcon--primary .euiIcon__fillSecondary {
	fill: currentColor;
}

.euiIcon__fillNegative {
	fill: #343741;
}

.euiIcon--small {
	width: 12px;
	height: 12px;
}

.euiIcon--medium {
	width: 16px;
	height: 16px;
}

.euiIcon--large {
	width: 24px;
	height: 24px;
}

.euiIcon--xLarge {
	width: 32px;
	height: 32px;
}

.euiIcon--xxLarge {
	width: 40px;
	height: 40px;
}

@-webkit-keyframes euiIconLoading {
	0% {
		opacity: 0.05;
	}
	100% {
		opacity: 1;
	}
}

@keyframes euiIconLoading {
	0% {
		opacity: 0.05;
	}
	100% {
		opacity: 1;
	}
}

/**
   * 1. Fix for IE where the image correctly resizes in width but doesn't collapse its height
        (https://github.com/philipwalton/flexbugs/issues/75#issuecomment-134702421)
   */
.euiImage {
	display: inline-block;
	max-width: 100%;
	position: relative;
	min-height: 1px;
	/* 1 */
	line-height: 0;
}
.euiImage.euiImage--hasShadow .euiImage__img {
	-webkit-box-shadow: 0 6px 12px -1px rgba(152, 162, 179, 0.2),
		0 4px 4px -1px rgba(152, 162, 179, 0.2), 0 2px 2px 0 rgba(152, 162, 179, 0.2);
	box-shadow: 0 6px 12px -1px rgba(152, 162, 179, 0.2), 0 4px 4px -1px rgba(152, 162, 179, 0.2),
		0 2px 2px 0 rgba(152, 162, 179, 0.2);
}
.euiImage .euiImage__button {
	cursor: pointer;
	-webkit-transition: all 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
	transition: all 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
}
.euiImage .euiImage__button:focus {
	outline: 2px solid rgba(0, 107, 180, 0.3);
}
.euiImage .euiImage__button:hover .euiImage__icon {
	visibility: visible;
	fill-opacity: 1;
}
.euiImage.euiImage--allowFullScreen:hover .euiImage__caption {
	text-decoration: underline;
}
.euiImage.euiImage--allowFullScreen:not(.euiImage--hasShadow) .euiImage__button:hover,
.euiImage.euiImage--allowFullScreen:not(.euiImage--hasShadow) .euiImage__button:focus {
	-webkit-box-shadow: 0 6px 12px -1px rgba(152, 162, 179, 0.2),
		0 4px 4px -1px rgba(152, 162, 179, 0.2), 0 2px 2px 0 rgba(152, 162, 179, 0.2);
	box-shadow: 0 6px 12px -1px rgba(152, 162, 179, 0.2), 0 4px 4px -1px rgba(152, 162, 179, 0.2),
		0 2px 2px 0 rgba(152, 162, 179, 0.2);
}
.euiImage.euiImage--allowFullScreen.euiImage--hasShadow .euiImage__button:hover,
.euiImage.euiImage--allowFullScreen.euiImage--hasShadow .euiImage__button:focus {
	-webkit-box-shadow: 0 12px 24px 0 rgba(65, 78, 101, 0.1), 0 6px 12px 0 rgba(65, 78, 101, 0.1),
		0 4px 4px 0 rgba(65, 78, 101, 0.1), 0 2px 2px 0 rgba(65, 78, 101, 0.1);
	box-shadow: 0 12px 24px 0 rgba(65, 78, 101, 0.1), 0 6px 12px 0 rgba(65, 78, 101, 0.1),
		0 4px 4px 0 rgba(65, 78, 101, 0.1), 0 2px 2px 0 rgba(65, 78, 101, 0.1);
}
.euiImage.euiImage--small {
	width: 7.5rem;
}
.euiImage.euiImage--medium {
	width: 12.5rem;
}
.euiImage.euiImage--large {
	width: 22.5rem;
}
.euiImage.euiImage--xlarge {
	width: 37.5rem;
}
.euiImage.euiImage--fullWidth {
	width: 100%;
}

.euiImage__img {
	width: 100%;
	vertical-align: middle;
}

.euiImage__caption {
	font-size: 14px;
	font-size: 0.875rem;
	line-height: 1.5;
	text-align: center;
}

.euiImage__icon {
	visibility: hidden;
	fill-opacity: 0;
	position: absolute;
	right: 16px;
	top: 16px;
	-webkit-transition: fill-opacity 350ms cubic-bezier(0.694, 0.0482, 0.335, 1);
	transition: fill-opacity 350ms cubic-bezier(0.694, 0.0482, 0.335, 1);
	cursor: pointer;
}

.euiImage-isFullScreen {
	position: relative;
	max-height: 80vh;
	max-width: 80vw;
	-webkit-animation: euiImageFullScreen 500ms cubic-bezier(0.34, 1.61, 0.7, 1);
	animation: euiImageFullScreen 500ms cubic-bezier(0.34, 1.61, 0.7, 1);
}
.euiImage-isFullScreen:hover .euiImage__button {
	-webkit-box-shadow: 0 12px 24px 0 rgba(65, 78, 101, 0.1), 0 6px 12px 0 rgba(65, 78, 101, 0.1),
		0 4px 4px 0 rgba(65, 78, 101, 0.1), 0 2px 2px 0 rgba(65, 78, 101, 0.1);
	box-shadow: 0 12px 24px 0 rgba(65, 78, 101, 0.1), 0 6px 12px 0 rgba(65, 78, 101, 0.1),
		0 4px 4px 0 rgba(65, 78, 101, 0.1), 0 2px 2px 0 rgba(65, 78, 101, 0.1);
}
.euiImage-isFullScreen:hover .euiImage__caption {
	text-decoration: underline;
}
.euiImage-isFullScreen__icon {
	position: absolute;
	right: 16px;
	top: 16px;
}
.euiImage-isFullScreen__img {
	max-height: 80vh;
	max-width: 80vw;
	vertical-align: middle;
	cursor: pointer;
	-webkit-transition: all 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
	transition: all 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
}

@-webkit-keyframes euiImageFullScreen {
	0% {
		opacity: 0;
		-webkit-transform: translateY(64px);
		transform: translateY(64px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}

@keyframes euiImageFullScreen {
	0% {
		opacity: 0;
		-webkit-transform: translateY(64px);
		transform: translateY(64px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}

/**
   * 1. Default to grid of 3
   */
.euiKeyPadMenu {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	width: 288px;
}

/**
   * 1. If this class is applied to a button, we need to override the Chrome default font.
   * 2. If it has a BetaBadge, make sure only the first letter shows
   */
.euiKeyPadMenuItem {
	font-family: var(--primary-font), 'Inter UI', -apple-system, BlinkMacSystemFont, 'Segoe UI',
		Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
	font-weight: 400;
	letter-spacing: -0.005em;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-webkit-font-kerning: normal;
	font-kerning: normal;
	/* 1 */
	display: block;
	padding: 4px;
	height: 96px;
	width: 96px;
	color: #69707d;
	border: 1px solid #d3dae6;
	border-color: transparent;
	border-radius: 4px;
	-webkit-transition: border-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	transition: border-color 150ms ease-in, -webkit-box-shadow 150ms ease-in;
	transition: border-color 150ms ease-in, box-shadow 150ms ease-in;
	transition: border-color 150ms ease-in, box-shadow 150ms ease-in, -webkit-box-shadow 150ms ease-in;
}
.euiKeyPadMenuItem:not(:disabled):hover,
.euiKeyPadMenuItem:not(:disabled):focus {
	-webkit-box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
	box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3);
	border-color: #d3dae6;
}
.euiKeyPadMenuItem:not(:disabled):hover .euiKeyPadMenuItem__icon,
.euiKeyPadMenuItem:not(:disabled):focus .euiKeyPadMenuItem__icon {
	-webkit-transform: translateY(0);
	transform: translateY(0);
}
.euiKeyPadMenuItem:disabled {
	color: #afb0b3;
	cursor: not-allowed;
}
.euiKeyPadMenuItem:disabled .euiKeyPadMenuItem__icon {
	-webkit-filter: grayscale(100%);
	filter: grayscale(100%);
}
.euiKeyPadMenuItem:disabled .euiKeyPadMenuItem__icon svg * {
	fill: #afb0b3;
}

.euiKeyPadMenuItem__inner {
	width: 100%;
	height: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}
.euiKeyPadMenuItem--hasBetaBadge .euiKeyPadMenuItem__inner {
	position: relative;
}
.euiKeyPadMenuItem--hasBetaBadge .euiKeyPadMenuItem__inner .euiKeyPadMenuItem__betaBadgeWrapper {
	position: absolute;
	top: 4px;
	right: 8px;
	z-index: 3;
}
.euiKeyPadMenuItem--hasBetaBadge
	.euiKeyPadMenuItem__inner
	.euiKeyPadMenuItem__betaBadgeWrapper
	.euiKeyPadMenuItem__betaBadge:not(.euiBetaBadge--iconOnly) {
	padding: 0 6px;
	/* 2 */
	overflow: hidden;
	/* 2 */
	letter-spacing: 3rem;
	/* 2 */
}

.euiKeyPadMenuItem__betaBadge {
	width: 20px;
	height: 20px;
	line-height: 20px;
	color: #000;
	background-color: #e9edf3;
	-webkit-box-shadow: none;
	box-shadow: none;
}
.euiKeyPadMenuItem__betaBadge .euiBetaBadge__icon {
	width: 12px;
	height: 12px;
}

.euiKeyPadMenuItem__icon {
	-webkit-transition: -webkit-transform 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
	transition: -webkit-transform 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
	transition: transform 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
	transition: transform 250ms cubic-bezier(0.34, 1.61, 0.7, 1),
		-webkit-transform 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
	-webkit-transform: translateY(2px);
	transform: translateY(2px);
	margin-bottom: 12px;
}

.euiKeyPadMenuItem__label {
	font-size: 12px;
	font-weight: 500;
	line-height: 16px;
	text-align: center;
}

.euiLink {
	text-align: left;
}
.euiLink:hover {
	text-decoration: underline;
}
.euiLink:focus {
	text-decoration: underline;
	background: #e6f0f8;
}
.euiLink .euiLink__externalIcon {
	margin-left: 4px;
}
.euiLink.euiLink-disabled {
	text-decoration: none;
	cursor: default;
}
.euiLink.euiLink--subdued {
	color: #6a717d;
}
.euiLink.euiLink--subdued:hover {
	color: #535861;
}
.euiLink.euiLink--subdued:focus {
	outline: solid 3px rgba(106, 113, 125, 0.1);
	background-color: rgba(106, 113, 125, 0.1);
}
.euiLink.euiLink--primary {
	color: #ef5742;
}
.euiLink.euiLink--primary:hover {
	color: #004d81;
}
.euiLink.euiLink--primary:focus {
	outline: solid 3px rgba(0, 107, 180, 0.1);
	background-color: rgba(0, 107, 180, 0.1);
}
.euiLink.euiLink--secondary {
	color: #017d73;
}
.euiLink.euiLink--secondary:hover {
	color: #014a44;
}
.euiLink.euiLink--secondary:focus {
	outline: solid 3px rgba(1, 125, 115, 0.1);
	background-color: rgba(1, 125, 115, 0.1);
}
.euiLink.euiLink--accent {
	color: #dd0a73;
}
.euiLink.euiLink--accent:hover {
	color: #ac085a;
}
.euiLink.euiLink--accent:focus {
	outline: solid 3px rgba(221, 10, 115, 0.1);
	background-color: rgba(221, 10, 115, 0.1);
}
.euiLink.euiLink--warning {
	color: #9b6900;
}
.euiLink.euiLink--warning:hover {
	color: #684600;
}
.euiLink.euiLink--warning:focus {
	outline: solid 3px rgba(155, 105, 0, 0.1);
	background-color: rgba(155, 105, 0, 0.1);
}
.euiLink.euiLink--danger {
	color: #bd271e;
}
.euiLink.euiLink--danger:hover {
	color: #911e17;
}
.euiLink.euiLink--danger:focus {
	outline: solid 3px rgba(189, 39, 30, 0.1);
	background-color: rgba(189, 39, 30, 0.1);
}
.euiLink.euiLink--text {
	color: #343741;
}
.euiLink.euiLink--text:hover {
	color: #1d1f25;
}
.euiLink.euiLink--text:focus {
	outline: solid 3px rgba(52, 55, 65, 0.1);
	background-color: rgba(52, 55, 65, 0.1);
}
.euiLink.euiLink--ghost {
	color: #fff;
}
.euiLink.euiLink--ghost:hover {
	color: #e6e6e6;
}
.euiLink.euiLink--ghost:focus {
	outline: solid 3px rgba(255, 255, 255, 0.1);
	background-color: rgba(255, 255, 255, 0.1);
}

button.euiLink {
	-webkit-user-select: text;
	-moz-user-select: text;
	-ms-user-select: text;
	user-select: text;
}

/**
   * The List Group component provides neatly styled lists containing plain text
   * or links. The outer container can be bordered, with padding, or borderless
   * with links flush to the sides.
   */
.euiListGroup.euiListGroup-flush {
	padding: 0;
	border: none;
}

.euiListGroup.euiListGroup-bordered {
	border-radius: 4px;
	border: 1px solid #d3dae6;
}

.euiListGroup-maxWidthDefault {
	max-width: 400px;
}

.euiListGroup--gutterSmall {
	padding: 8px;
}
.euiListGroup--gutterSmall .euiListGroupItem:not(:first-of-type) {
	margin-top: 8px;
}

.euiListGroup--gutterMedium {
	padding: 16px;
}
.euiListGroup--gutterMedium .euiListGroupItem:not(:first-of-type) {
	margin-top: 16px;
}

.euiListGroupItem {
	padding: 0;
	border-radius: 4px;
	overflow: hidden;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transition: background-color 150ms;
	transition: background-color 150ms;
	position: relative;
}
.euiListGroupItem.euiListGroupItem-isActive,
.euiListGroupItem.euiListGroupItem-isClickable:hover {
	background-color: rgba(211, 218, 230, 0.25);
}
.euiListGroupItem.euiListGroupItem-isClickable:focus-within {
	background-color: rgba(211, 218, 230, 0.25);
}
.euiListGroupItem.euiListGroupItem--ghost.euiListGroupItem-isClickable:hover {
	background-color: rgba(255, 255, 255, 0.1);
}
.euiListGroupItem.euiListGroupItem--ghost.euiListGroupItem-isClickable:focus-within {
	background-color: rgba(255, 255, 255, 0.1);
}
.euiListGroupItem.euiListGroupItem-isClickable:hover .euiListGroupItem__button,
.euiListGroupItem .euiListGroupItem__button:hover,
.euiListGroupItem .euiListGroupItem__button:focus {
	text-decoration: underline;
}
.euiListGroupItem.euiListGroupItem-isDisabled,
.euiListGroupItem.euiListGroupItem-isDisabled:hover,
.euiListGroupItem.euiListGroupItem-isDisabled:focus,
.euiListGroupItem.euiListGroupItem-isDisabled .euiListGroupItem__button:hover,
.euiListGroupItem.euiListGroupItem-isDisabled .euiListGroupItem__button:focus {
	color: #c2c3c6;
	cursor: not-allowed;
	background-color: transparent;
	text-decoration: none;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
	.euiListGroupItem__button:hover,
	.euiListGroupItem__button:focus {
		background-color: rgba(211, 218, 230, 0.25);
		border-radius: 4px;
	}
	.euiListGroupItem__button:hover .euiListGroupItem--ghost .euiListGroupItem__button:hover,
	.euiListGroupItem__button:hover .euiListGroupItem--ghost .euiListGroupItem__button:focus,
	.euiListGroupItem__button:focus .euiListGroupItem--ghost .euiListGroupItem__button:hover,
	.euiListGroupItem__button:focus .euiListGroupItem--ghost .euiListGroupItem__button:focus {
		background-color: rgba(255, 255, 255, 0.1);
	}
}

.euiListGroupItem__text,
.euiListGroupItem__button {
	line-height: 24px;
	padding: 4px 8px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	text-align: left;
	max-width: 100%;
	font-weight: inherit;
}
.euiListGroupItem-hasExtraAction .euiListGroupItem__text,
.euiListGroupItem-hasExtraAction .euiListGroupItem__button {
	max-width: calc(100% - 32px);
}
.euiListGroupItem--primary .euiListGroupItem__text:not(:disabled),
.euiListGroupItem--primary .euiListGroupItem__button:not(:disabled) {
	color: #ef5742;
}
.euiListGroupItem--text .euiListGroupItem__text:not(:disabled),
.euiListGroupItem--text .euiListGroupItem__button:not(:disabled) {
	color: #343741;
}
.euiListGroupItem--subdued .euiListGroupItem__text:not(:disabled),
.euiListGroupItem--subdued .euiListGroupItem__button:not(:disabled) {
	color: #6a717d;
}
.euiListGroupItem--ghost .euiListGroupItem__text:not(:disabled),
.euiListGroupItem--ghost .euiListGroupItem__button:not(:disabled) {
	color: #fff;
}
.euiListGroupItem-isActive:not(.euiListGroupItem--ghost) .euiListGroupItem__text,
.euiListGroupItem-isActive:not(.euiListGroupItem--ghost) .euiListGroupItem__button {
	color: #343741;
}

.euiListGroupItem__label {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.euiListGroupItem__extraAction {
	opacity: 0;
	margin-right: 8px;
	-webkit-transition: opacity 150ms;
	transition: opacity 150ms;
}
.euiListGroupItem:not(.euiListGroupItem-isDisabled):focus .euiListGroupItem__extraAction,
.euiListGroupItem:not(.euiListGroupItem-isDisabled):hover .euiListGroupItem__extraAction,
.euiListGroupItem__extraAction.euiListGroupItem__extraAction-alwaysShow,
.euiListGroupItem__extraAction:focus {
	opacity: 1;
}

.euiListGroupItem__icon {
	margin-right: 12px;
	-webkit-box-flex: 0;
	-ms-flex-positive: 0;
	flex-grow: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
}

.euiListGroupItem--xSmall {
	font-size: 12px;
}

.euiListGroupItem--small {
	font-size: 14px;
}

.euiListGroupItem--medium {
	font-size: 16px;
}

.euiListGroupItem--large {
	font-size: 20px;
}

.euiListGroupItem--xSmall,
.euiListGroupItem--small {
	font-weight: 500;
	letter-spacing: 0;
}

.euiListGroupItem--xSmall .euiListGroupItem__button,
.euiListGroupItem--xSmall .euiListGroupItem__text {
	line-height: 16px;
}

.euiListGroupItem--large .euiListGroupItem__button,
.euiListGroupItem--large .euiListGroupItem__text {
	line-height: 32px;
}

.euiListGroupItem--wrapText .euiListGroupItem__button,
.euiListGroupItem--wrapText .euiListGroupItem__text {
	width: 100%;
	word-break: break-word;
}

.euiListGroupItem--wrapText .euiListGroupItem__label {
	white-space: inherit;
}

.euiListGroup-flush .euiListGroupItem {
	border-radius: 0;
}

.euiListGroup-bordered .euiListGroupItem:first-child {
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
}

.euiListGroup-bordered .euiListGroupItem:last-child {
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
}

.euiListGroupItem__tooltip {
	width: 100%;
}

.euiPinnableListGroup__itemExtraAction svg {
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}

.euiPinnableListGroup__itemExtraAction-pinned:not(:hover):not(:focus) {
	color: #8c919a;
}

.euiLoadingKibana {
	position: relative;
	display: inline-block;
}
.euiLoadingKibana:before,
.euiLoadingKibana:after {
	position: absolute;
	content: '';
	width: 90%;
	left: 5%;
	border-radius: 50%;
	opacity: 0.2;
	z-index: 1;
}
.euiLoadingKibana:before {
	-webkit-box-shadow: 0 0 8px #000;
	box-shadow: 0 0 8px #000;
	-webkit-animation: 1s euiLoadingKibanaPulsateAndFade cubic-bezier(0.694, 0.0482, 0.335, 1)
		infinite;
	animation: 1s euiLoadingKibanaPulsateAndFade cubic-bezier(0.694, 0.0482, 0.335, 1) infinite;
}
.euiLoadingKibana:after {
	background-color: #000;
	-webkit-animation: 1s euiLoadingKibanaPulsate cubic-bezier(0.694, 0.0482, 0.335, 1) infinite;
	animation: 1s euiLoadingKibanaPulsate cubic-bezier(0.694, 0.0482, 0.335, 1) infinite;
}
.euiLoadingKibana .euiLoadingKibana__icon {
	display: block;
}

/**
   * 1. Requires pixel math for animation.
   */
.euiLoadingKibana--medium {
	width: 16px;
}
.euiLoadingKibana--medium:before,
.euiLoadingKibana--medium:after {
	height: 3px;
	/* 1 */
	bottom: -4px;
}
.euiLoadingKibana--medium .euiLoadingKibana__icon {
	z-index: 999;
	-webkit-animation: 1s euiLoadingKibanaBounceMedium cubic-bezier(0.694, 0.0482, 0.335, 1) infinite;
	animation: 1s euiLoadingKibanaBounceMedium cubic-bezier(0.694, 0.0482, 0.335, 1) infinite;
}

/**
   * 1. Requires pixel math for animation.
   */
.euiLoadingKibana--large {
	width: 24px;
}
.euiLoadingKibana--large:before,
.euiLoadingKibana--large:after {
	height: 6px;
	/* 1 */
	bottom: -8px;
}
.euiLoadingKibana--large .euiLoadingKibana__icon {
	-webkit-animation: 1s euiLoadingKibanaBounceLarge cubic-bezier(0.694, 0.0482, 0.335, 1) infinite;
	animation: 1s euiLoadingKibanaBounceLarge cubic-bezier(0.694, 0.0482, 0.335, 1) infinite;
}

.euiLoadingKibana--xLarge {
	width: 32px;
}
.euiLoadingKibana--xLarge:before,
.euiLoadingKibana--xLarge:after {
	height: 8px;
	bottom: -12px;
}
.euiLoadingKibana--xLarge .euiLoadingKibana__icon {
	-webkit-animation: 1s euiLoadingKibanaBounceXLarge cubic-bezier(0.694, 0.0482, 0.335, 1) infinite;
	animation: 1s euiLoadingKibanaBounceXLarge cubic-bezier(0.694, 0.0482, 0.335, 1) infinite;
}

@-webkit-keyframes euiLoadingKibanaBounceMedium {
	50% {
		-webkit-transform: translateY(-8px);
		transform: translateY(-8px);
	}
}

@keyframes euiLoadingKibanaBounceMedium {
	50% {
		-webkit-transform: translateY(-8px);
		transform: translateY(-8px);
	}
}

@-webkit-keyframes euiLoadingKibanaBounceLarge {
	50% {
		-webkit-transform: translateY(-12px);
		transform: translateY(-12px);
	}
}

@keyframes euiLoadingKibanaBounceLarge {
	50% {
		-webkit-transform: translateY(-12px);
		transform: translateY(-12px);
	}
}

@-webkit-keyframes euiLoadingKibanaBounceXLarge {
	50% {
		-webkit-transform: translateY(-16px);
		transform: translateY(-16px);
	}
}

@keyframes euiLoadingKibanaBounceXLarge {
	50% {
		-webkit-transform: translateY(-16px);
		transform: translateY(-16px);
	}
}

@-webkit-keyframes euiLoadingKibanaPulsateAndFade {
	0% {
		opacity: 0;
	}
	50% {
		-webkit-transform: scale(0.5);
		transform: scale(0.5);
		opacity: 0.1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes euiLoadingKibanaPulsateAndFade {
	0% {
		opacity: 0;
	}
	50% {
		-webkit-transform: scale(0.5);
		transform: scale(0.5);
		opacity: 0.1;
	}
	100% {
		opacity: 0;
	}
}

@-webkit-keyframes euiLoadingKibanaPulsate {
	0% {
		opacity: 0.15;
	}
	50% {
		-webkit-transform: scale(0.5);
		transform: scale(0.5);
		opacity: 0.05;
	}
	100% {
		opacity: 0.15;
	}
}

@keyframes euiLoadingKibanaPulsate {
	0% {
		opacity: 0.15;
	}
	50% {
		-webkit-transform: scale(0.5);
		transform: scale(0.5);
		opacity: 0.05;
	}
	100% {
		opacity: 0.15;
	}
}

.euiLoadingElastic {
	position: relative;
	display: inline-block;
}

.euiLoadingElastic--medium {
	width: 16px;
}

.euiLoadingElastic--large {
	width: 24px;
}

.euiLoadingElastic--xLarge {
	width: 32px;
}

.euiLoadingElastic--xxLarge {
	width: 40px;
}

.euiLoadingElastic path {
	-webkit-animation-name: euiLoadingElastic;
	animation-name: euiLoadingElastic;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
	-webkit-animation-direction: alternate;
	animation-direction: alternate;
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-timing-function: cubic-bezier(0, 0.63, 0.49, 1);
	animation-timing-function: cubic-bezier(0, 0.63, 0.49, 1);
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
}

.euiLoadingElastic path:nth-of-type(1) {
	-webkit-animation-delay: 0s;
	animation-delay: 0s;
}

.euiLoadingElastic path:nth-of-type(2) {
	-webkit-animation-delay: 0.035s;
	animation-delay: 0.035s;
}

.euiLoadingElastic path:nth-of-type(3) {
	-webkit-animation-delay: 0.125s;
	animation-delay: 0.125s;
}

.euiLoadingElastic path:nth-of-type(4) {
	-webkit-animation-delay: 0.155s;
	animation-delay: 0.155s;
}

.euiLoadingElastic path:nth-of-type(5) {
	-webkit-animation-delay: 0.075s;
	animation-delay: 0.075s;
}

.euiLoadingElastic path:nth-of-type(6) {
	-webkit-animation-delay: 0.06s;
	animation-delay: 0.06s;
}

@-webkit-keyframes euiLoadingElastic {
	0% {
		-webkit-transform: scale3d(0, 0, -0.7);
		transform: scale3d(0, 0, -0.7);
		opacity: 0;
	}
	40% {
		-webkit-transform: scale3d(1, 1, 2);
		transform: scale3d(1, 1, 2);
		opacity: 1;
	}
	50% {
		-webkit-transform: scale3d(0.99, 0.99, 2);
		transform: scale3d(0.99, 0.99, 2);
	}
	70% {
		-webkit-transform: scale3d(0.96, 0.96, -2.5);
		transform: scale3d(0.96, 0.96, -2.5);
	}
	100% {
		-webkit-transform: scale3d(0.98, 0.98, 2);
		transform: scale3d(0.98, 0.98, 2);
	}
}

@keyframes euiLoadingElastic {
	0% {
		-webkit-transform: scale3d(0, 0, -0.7);
		transform: scale3d(0, 0, -0.7);
		opacity: 0;
	}
	40% {
		-webkit-transform: scale3d(1, 1, 2);
		transform: scale3d(1, 1, 2);
		opacity: 1;
	}
	50% {
		-webkit-transform: scale3d(0.99, 0.99, 2);
		transform: scale3d(0.99, 0.99, 2);
	}
	70% {
		-webkit-transform: scale3d(0.96, 0.96, -2.5);
		transform: scale3d(0.96, 0.96, -2.5);
	}
	100% {
		-webkit-transform: scale3d(0.98, 0.98, 2);
		transform: scale3d(0.98, 0.98, 2);
	}
}

.euiLoadingChart {
	height: 32px;
	z-index: 500;
	overflow: hidden;
	display: inline-block;
}

.euiLoadingChart__bar {
	height: 100%;
	width: 8px;
	display: inline-block;
	margin-bottom: -16px;
	margin-left: 2px;
	-webkit-animation: euiLoadingChart 1s infinite;
	animation: euiLoadingChart 1s infinite;
}
.euiLoadingChart__bar:nth-child(1) {
	background-color: #54b399;
}
.euiLoadingChart__bar:nth-child(2) {
	background-color: #6092c0;
	-webkit-animation-delay: 0.1s;
	animation-delay: 0.1s;
}
.euiLoadingChart__bar:nth-child(3) {
	background-color: #d36086;
	-webkit-animation-delay: 0.2s;
	animation-delay: 0.2s;
}
.euiLoadingChart__bar:nth-child(4) {
	background-color: #9170b8;
	-webkit-animation-delay: 0.3s;
	animation-delay: 0.3s;
}

.euiLoadingChart--mono .euiLoadingChart__bar:nth-child(1) {
	background-color: #d3dae6;
}

.euiLoadingChart--mono .euiLoadingChart__bar:nth-child(2) {
	background-color: #cbd1dd;
}

.euiLoadingChart--mono .euiLoadingChart__bar:nth-child(3) {
	background-color: #c2c9d4;
}

.euiLoadingChart--mono .euiLoadingChart__bar:nth-child(4) {
	background-color: #bac0ca;
}

.euiLoadingChart--medium {
	height: 16px;
}
.euiLoadingChart--medium > span {
	width: 2px;
	margin-left: 2px;
	margin-bottom: 8px;
}

.euiLoadingChart--large {
	height: 24px;
}
.euiLoadingChart--large > span {
	width: 4px;
	margin-left: 2px;
	margin-bottom: 12px;
}

.euiLoadingChart--xLarge {
	height: 32px;
}
.euiLoadingChart--xLarge > span {
	width: 8px;
	margin-left: 4px;
	margin-bottom: 16px;
}

@-webkit-keyframes euiLoadingChart {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	50% {
		-webkit-transform: translateY(66%);
		transform: translateY(66%);
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}

@keyframes euiLoadingChart {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	50% {
		-webkit-transform: translateY(66%);
		transform: translateY(66%);
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}

.euiLoadingContent__loader {
	display: block;
	width: 100%;
}

.euiLoadingContent__singleLine {
	display: block;
	width: 100%;
	height: 16px;
	margin-bottom: 8px;
	border-radius: 4px;
	overflow: hidden;
}
.euiLoadingContent__singleLine:last-child:not(:only-child) {
	width: 75%;
}

.euiLoadingContent__singleLineBackground {
	display: block;
	width: 220%;
	height: 100%;
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		color-stop(45%, #d5dce7),
		color-stop(50%, #e9edf3),
		color-stop(55%, #d5dce7)
	);
	background: linear-gradient(to right, #d5dce7 45%, #e9edf3 50%, #d5dce7 55%);
	-webkit-animation: euiLoadingContentGradientLoad 1.5s cubic-bezier(0.694, 0.0482, 0.335, 1)
		infinite;
	animation: euiLoadingContentGradientLoad 1.5s cubic-bezier(0.694, 0.0482, 0.335, 1) infinite;
}

@-webkit-keyframes euiLoadingContentGradientLoad {
	0% {
		-webkit-transform: translateX(-53%);
		transform: translateX(-53%);
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}

@keyframes euiLoadingContentGradientLoad {
	0% {
		-webkit-transform: translateX(-53%);
		transform: translateX(-53%);
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}

.euiLoadingSpinner {
	-ms-flex-negative: 0;
	flex-shrink: 0;
	display: inline-block;
	width: 32px;
	height: 32px;
	border-radius: 50%;
	border: solid 2px #d3dae6;
	border-color: #ef5742 #d3dae6 #d3dae6 #d3dae6;
	-webkit-animation: euiLoadingSpinner 0.6s infinite linear;
	animation: euiLoadingSpinner 0.6s infinite linear;
}

.euiLoadingSpinner--small {
	width: 8px;
	height: 8px;
	border-width: 1px;
}

.euiLoadingSpinner--medium {
	width: 16px;
	height: 16px;
	border-width: 1px;
}

.euiLoadingSpinner--large {
	width: 24px;
	height: 24px;
}

.euiLoadingSpinner--xLarge {
	width: 32px;
	height: 32px;
}

@-webkit-keyframes euiLoadingSpinner {
	from {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}

@keyframes euiLoadingSpinner {
	from {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}

.euiMark {
	margin: 4px;
	padding: 4px;
}

/**
   * 1. Fix IE overflow issue (min-height) by adding a separate wrapper for the
   *    flex display. https://github.com/philipwalton/flexbugs#flexbug-3
   * 2. IE has trouble with min-widths on flex elements. Use the pixel value
   *    from our forms since that's usually the smallest we want them.
   */
.euiModal {
	border: 1px solid #d3dae6;
	-webkit-box-shadow: 0 40px 64px 0 rgba(65, 78, 101, 0.1), 0 24px 32px 0 rgba(65, 78, 101, 0.1),
		0 16px 16px 0 rgba(65, 78, 101, 0.1), 0 8px 8px 0 rgba(65, 78, 101, 0.1),
		0 4px 4px 0 rgba(65, 78, 101, 0.1), 0 2px 2px 0 rgba(65, 78, 101, 0.1);
	box-shadow: 0 40px 64px 0 rgba(65, 78, 101, 0.1), 0 24px 32px 0 rgba(65, 78, 101, 0.1),
		0 16px 16px 0 rgba(65, 78, 101, 0.1), 0 8px 8px 0 rgba(65, 78, 101, 0.1),
		0 4px 4px 0 rgba(65, 78, 101, 0.1), 0 2px 2px 0 rgba(65, 78, 101, 0.1);
	border-color: #c6cad1;
	border-top-color: #e3e4e8;
	border-bottom-color: #aaafba;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	/* 1 */
	position: relative;
	background-color: #fff;
	border-radius: 4px;
	z-index: 8000;
	min-width: 400px;
	-webkit-animation: euiModal 350ms cubic-bezier(0.34, 1.61, 0.7, 1);
	animation: euiModal 350ms cubic-bezier(0.34, 1.61, 0.7, 1);
}
.euiModal .euiModal__flex {
	/* 1 */
	-webkit-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	max-height: 75vh;
	overflow: hidden;
}

.euiModal--maxWidth-default {
	max-width: 768px;
}

.euiModal--confirmation {
	min-width: 400px;
}

.euiModalHeader {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 24px 40px 16px 24px;
	-webkit-box-flex: 0;
	-ms-flex-positive: 0;
	flex-grow: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
}

.euiModalHeader__title {
	color: #1a1c21;
	font-size: 28px;
	font-size: 1.75rem;
	line-height: 2.5rem;
	font-weight: 300;
	letter-spacing: -0.04em;
}

.euiModalBody {
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	overflow: hidden;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}
.euiModalBody .euiModalBody__overflow {
	scrollbar-width: thin;
	height: 100%;
	overflow-y: auto;
	-webkit-mask-image: linear-gradient(
		to bottom,
		rgba(255, 0, 0, 0.1) 0%,
		red 7.5px,
		red calc(100% - 7.5px),
		rgba(255, 0, 0, 0.1) 100%
	);
	mask-image: linear-gradient(
		to bottom,
		rgba(255, 0, 0, 0.1) 0%,
		red 7.5px,
		red calc(100% - 7.5px),
		rgba(255, 0, 0, 0.1) 100%
	);
	padding: 8px 24px;
}
.euiModalBody .euiModalBody__overflow::-webkit-scrollbar {
	width: 16px;
	height: 16px;
}
.euiModalBody .euiModalBody__overflow::-webkit-scrollbar-thumb {
	background-color: rgba(105, 112, 125, 0.5);
	border: 6px solid transparent;
	background-clip: content-box;
}
.euiModalBody .euiModalBody__overflow::-webkit-scrollbar-corner,
.euiModalBody .euiModalBody__overflow::-webkit-scrollbar-track {
	background-color: transparent;
}

.euiModalFooter {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	padding: 16px 24px 24px;
	-webkit-box-flex: 0;
	-ms-flex-positive: 0;
	flex-grow: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
}
.euiModalFooter > * + * {
	margin-left: 16px;
}

.euiModalHeader + .euiModalFooter {
	padding-top: 8px;
}

.euiModalBody:last-of-type .euiModalBody__overflow {
	padding-bottom: 24px;
}

.euiModal__closeIcon {
	background-color: rgba(255, 255, 255, 0.9);
	position: absolute;
	right: 4px;
	top: 4px;
	z-index: 3;
}

@-webkit-keyframes euiModal {
	0% {
		opacity: 0;
		-webkit-transform: translateY(32px);
		transform: translateY(32px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}

@keyframes euiModal {
	0% {
		opacity: 0;
		-webkit-transform: translateY(32px);
		transform: translateY(32px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}

@media only screen and (max-width: 574px) {
	.euiModal {
		position: fixed;
		width: 100vw !important;
		max-width: none !important;
		min-width: 0 !important;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		border-radius: 0;
		border: none;
	}
	.euiModal.euiModal--confirmation {
		-webkit-box-shadow: 0 -40px 64px 0 rgba(65, 78, 101, 0.1), 0 -24px 32px 0 rgba(65, 78, 101, 0.1),
			0 -16px 16px 0 rgba(65, 78, 101, 0.1), 0 -8px 8px 0 rgba(65, 78, 101, 0.1);
		box-shadow: 0 -40px 64px 0 rgba(65, 78, 101, 0.1), 0 -24px 32px 0 rgba(65, 78, 101, 0.1),
			0 -16px 16px 0 rgba(65, 78, 101, 0.1), 0 -8px 8px 0 rgba(65, 78, 101, 0.1);
		top: auto;
	}
	.euiModal .euiModal__flex {
		/* 1 */
		max-height: 100vh;
	}
	.euiModalHeader {
		width: 100vw;
	}
	.euiModalFooter {
		background: #f5f7fa;
		padding: 12px 24px !important;
		width: 100vw;
		-webkit-box-pack: stretch;
		-ms-flex-pack: stretch;
		justify-content: stretch;
	}
	.euiModalFooter > * {
		-webkit-box-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}
	.euiModalFooter > * + * {
		margin-left: 0;
	}
	.euiModalBody {
		width: 100vw;
	}
	.euiModalBody .euiModalBody__overflow {
		padding-bottom: 24px;
	}
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
	.euiModal {
		position: fixed;
		width: 100vw !important;
		max-width: none !important;
		min-width: 0 !important;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		border-radius: 0;
		border: none;
	}
	.euiModal.euiModal--confirmation {
		-webkit-box-shadow: 0 -40px 64px 0 rgba(65, 78, 101, 0.1), 0 -24px 32px 0 rgba(65, 78, 101, 0.1),
			0 -16px 16px 0 rgba(65, 78, 101, 0.1), 0 -8px 8px 0 rgba(65, 78, 101, 0.1);
		box-shadow: 0 -40px 64px 0 rgba(65, 78, 101, 0.1), 0 -24px 32px 0 rgba(65, 78, 101, 0.1),
			0 -16px 16px 0 rgba(65, 78, 101, 0.1), 0 -8px 8px 0 rgba(65, 78, 101, 0.1);
		top: auto;
	}
	.euiModal .euiModal__flex {
		/* 1 */
		max-height: 100vh;
	}
	.euiModalHeader {
		width: 100vw;
	}
	.euiModalFooter {
		background: #f5f7fa;
		padding: 12px 24px !important;
		width: 100vw;
		-webkit-box-pack: stretch;
		-ms-flex-pack: stretch;
		justify-content: stretch;
	}
	.euiModalFooter > * {
		-webkit-box-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}
	.euiModalFooter > * + * {
		margin-left: 0;
	}
	.euiModalBody {
		width: 100vw;
	}
	.euiModalBody .euiModalBody__overflow {
		padding-bottom: 24px;
	}
}

.euiNavDrawer {
	width: 48px;
	height: calc(100% - 49px);
	position: fixed;
	left: 0;
	top: 49px;
	overflow: hidden;
	z-index: 1000;
	background: #fff;
	-webkit-box-shadow: 2px 0 2px -1px rgba(152, 162, 179, 0.3);
	box-shadow: 2px 0 2px -1px rgba(152, 162, 179, 0.3);
	-webkit-transition: width 90ms cubic-bezier(0.694, 0.0482, 0.335, 1);
	transition: width 90ms cubic-bezier(0.694, 0.0482, 0.335, 1);
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.euiNavDrawer .euiNavDrawerMenu {
	scrollbar-width: thin;
	overflow-y: auto;
	overflow-x: hidden;
	width: 48px;
	height: 100%;
}
.euiNavDrawer .euiNavDrawerMenu::-webkit-scrollbar {
	width: 16px;
	height: 16px;
}
.euiNavDrawer .euiNavDrawerMenu::-webkit-scrollbar-thumb {
	background-color: rgba(105, 112, 125, 0.5);
	border: 6px solid transparent;
	background-clip: content-box;
}
.euiNavDrawer .euiNavDrawerMenu::-webkit-scrollbar-corner,
.euiNavDrawer .euiNavDrawerMenu::-webkit-scrollbar-track {
	background-color: transparent;
}
.euiNavDrawer .euiNavDrawerMenu-hasFooter {
	margin-bottom: 40px;
}
.euiNavDrawer .euiNavDrawer__expandButton {
	-webkit-box-shadow: 0 0 12px -1px rgba(152, 162, 179, 0.2), 0 0 4px -1px rgba(152, 162, 179, 0.2),
		0 0 2px 0 rgba(152, 162, 179, 0.2);
	box-shadow: 0 0 12px -1px rgba(152, 162, 179, 0.2), 0 0 4px -1px rgba(152, 162, 179, 0.2),
		0 0 2px 0 rgba(152, 162, 179, 0.2);
	background-color: #fff;
	position: fixed;
	bottom: 0;
	width: 48px;
	-webkit-transition: width 90ms;
	transition: width 90ms;
	z-index: 1001;
}
.euiNavDrawer .euiNavDrawer__expandButton .euiListGroupItem__button {
	padding: 12px 16px;
}
.euiNavDrawer
	.euiNavDrawer__expandButton
	.navDrawerExpandButton-isCollapsed
	.euiListGroupItem__button {
	max-width: 100%;
}
.euiNavDrawer.euiNavDrawer-isCollapsed.euiNavDrawer-flyoutIsExpanded {
	width: 288px;
}
.euiNavDrawer.euiNavDrawer-isCollapsed .euiNavDrawerMenu {
	-ms-overflow-style: -ms-autohiding-scrollbar;
	scrollbar-width: none;
}
.euiNavDrawer.euiNavDrawer-isCollapsed .euiNavDrawerMenu::-webkit-scrollbar {
	width: 0;
	height: 0;
}
.euiNavDrawer.euiNavDrawer-isCollapsed
	.euiNavDrawerMenu
	.euiListGroup:not(.euiNavDrawer__expandButton)
	.euiListGroupItem__button {
	max-width: 32px;
}
.euiNavDrawer.euiNavDrawer-isCollapsed .euiNavDrawerMenu .euiListGroupItem__extraAction {
	visibility: hidden;
}
.euiNavDrawer.euiNavDrawer-isExpanded {
	width: 240px;
}
.euiNavDrawer.euiNavDrawer-isExpanded .euiNavDrawerMenu,
.euiNavDrawer.euiNavDrawer-isExpanded .euiNavDrawer__expandButton {
	width: 240px;
}
.euiNavDrawer.euiNavDrawer-isExpanded.euiNavDrawer-flyoutIsExpanded {
	width: 288px;
}

.euiNavDrawerPage {
	height: 100%;
}
.euiNavDrawerPage .euiNavDrawerPage__pageBody {
	margin-left: 48px;
}

@media only screen and (max-width: 574px) {
	.euiNavDrawer {
		width: 0;
	}
	.euiNavDrawer.euiNavDrawer-isExpanded .euiNavDrawerMenu .euiListGroupItem__icon {
		margin-right: 12px;
	}
	.euiNavDrawer.euiNavDrawer-flyoutIsCollapsed .euiNavDrawerFlyout {
		width: 0;
		-webkit-transition-duration: 0s;
		transition-duration: 0s;
	}
	.euiNavDrawer .euiNavDrawerMenu-hasFooter {
		margin-bottom: 0;
	}
	.euiNavDrawer .euiNavDrawer__expandButton {
		display: none;
	}
	.euiNavDrawerPage .euiNavDrawerPage__pageBody {
		margin-left: 0;
	}
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
	.euiNavDrawer {
		width: 0;
	}
	.euiNavDrawer.euiNavDrawer-isExpanded .euiNavDrawerMenu .euiListGroupItem__icon {
		margin-right: 12px;
	}
	.euiNavDrawer.euiNavDrawer-flyoutIsCollapsed .euiNavDrawerFlyout {
		width: 0;
		-webkit-transition-duration: 0s;
		transition-duration: 0s;
	}
	.euiNavDrawer .euiNavDrawerMenu-hasFooter {
		margin-bottom: 0;
	}
	.euiNavDrawer .euiNavDrawer__expandButton {
		display: none;
	}
	.euiNavDrawerPage .euiNavDrawerPage__pageBody {
		margin-left: 0;
	}
}

@media only screen and (max-width: 574px) {
	.euiNavDrawer__expandButtonLockAction {
		display: none;
	}
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
	.euiNavDrawer__expandButtonLockAction {
		display: none;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.euiNavDrawer__expandButtonLockAction {
		display: none;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.euiNavDrawer__expandButtonLockAction {
		display: none;
	}
}

@media only screen and (min-width: 1200px) {
	.euiNavDrawer-isLocked + .euiNavDrawerPage .euiNavDrawerPage__pageBody {
		margin-left: 240px !important;
		-webkit-transition: margin 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
		transition: margin 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
	}
	.euiNavDrawer-isLocked.euiNavDrawer-flyoutIsExpanded {
		width: 480px !important;
		-webkit-transition: none;
		transition: none;
	}
	.euiNavDrawer-isExpanded.euiNavDrawer-flyoutIsExpanded {
		width: 480px !important;
	}
}

.euiNavDrawerFlyout {
	scrollbar-width: thin;
	width: 0;
	height: 100%;
	padding: 12px 8px;
	overflow-y: auto;
	background-color: #fff;
	border-left: 1px solid #d3dae6;
	-webkit-box-shadow: 2px 0 2px -1px rgba(152, 162, 179, 0.3);
	box-shadow: 2px 0 2px -1px rgba(152, 162, 179, 0.3);
	visibility: hidden;
	opacity: 0;
}
.euiNavDrawerFlyout::-webkit-scrollbar {
	width: 16px;
	height: 16px;
}
.euiNavDrawerFlyout::-webkit-scrollbar-thumb {
	background-color: rgba(105, 112, 125, 0.5);
	border: 6px solid transparent;
	background-clip: content-box;
}
.euiNavDrawerFlyout::-webkit-scrollbar-corner,
.euiNavDrawerFlyout::-webkit-scrollbar-track {
	background-color: transparent;
}
.euiNavDrawerFlyout.euiNavDrawerFlyout-isExpanded {
	visibility: visible;
	opacity: 1;
	width: 240px;
	-webkit-transition: opacity 150ms 150ms, width 250ms;
	transition: opacity 150ms 150ms, width 250ms;
}
.euiNavDrawerFlyout.euiNavDrawerFlyout-isCollapsed {
	-webkit-transition: opacity 150ms, width 150ms;
	transition: opacity 150ms, width 150ms;
}
.euiNavDrawerFlyout .euiNavDrawerFlyout__title {
	margin: 0 8px 4px;
}
.euiNavDrawerFlyout .euiNavDrawerFlyout__listGroup {
	padding-left: 0;
	padding-right: 0;
}

.euiNavDrawerGroup__item .euiListGroupItem__label {
	-webkit-transition: all 90ms;
	transition: all 90ms;
}

.euiNavDrawerGroup__item .euiListGroupItem__button {
	color: inherit;
}
.euiNavDrawerGroup__item .euiListGroupItem__button:focus {
	background-color: #e6f0f8;
	border-radius: 4px;
}

.euiNavDrawerGroup__item .euiListGroupItem__icon {
	max-width: 16px;
}

.euiNavDrawerGroup__itemDefaultIcon {
	position: relative;
	width: 16px;
	height: 16px;
	line-height: 16px;
	font-size: 12px;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	display: inline-block;
	text-align: center;
	vertical-align: middle;
	overflow: visible;
	font-weight: 500;
	border-radius: 50%;
}
.euiNavDrawerGroup__itemDefaultIcon:after {
	position: absolute;
	top: -4px;
	left: -4px;
	right: -4px;
	bottom: -4px;
	border-radius: 50%;
	content: '';
	pointer-events: none;
	border: 1px solid rgba(52, 55, 65, 0.05);
}

.euiOverlayMask {
	position: fixed;
	z-index: 6000;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	padding-bottom: 10vh;
	-webkit-animation: euiAnimFadeIn 150ms ease-in;
	animation: euiAnimFadeIn 150ms ease-in;
	background: rgba(255, 255, 255, 0.8);
}

.euiBody-hasOverlayMask {
	overflow: hidden;
}

.euiPage {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding: 16px;
	background-color: #fafbfd;
}
.euiPage--restrictWidth-default,
.euiPage--restrictWidth-custom {
	margin-left: auto;
	margin-right: auto;
}
.euiPage--restrictWidth-default {
	max-width: 1000px;
}
@media only screen and (max-width: 574px) {
	.euiPage {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
	}
}
@media only screen and (min-width: 575px) and (max-width: 767px) {
	.euiPage {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
	}
}

.euiPageBody {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	-webkit-box-flex: 1;
	-ms-flex: 1 1 100%;
	flex: 1 1 100%;
}
.euiPageBody--restrictWidth-default,
.euiPageBody--restrictWidth-custom {
	margin-left: auto;
	margin-right: auto;
}
.euiPageBody--restrictWidth-default {
	max-width: 1000px;
}

.euiPageContent {
	width: 100%;
	/** TEMPORARILY REMOVING
    // At small screens, the content extends edge to edge, so remove the side borders and shadow
    @include euiBreakpoint('xs', 's') {
      .euiPanel:not(.euiPageContent--horizontalCenter) { // Override panel styles without the need for !important
        // border-radius: 0;
        // border-left: none;
        // border-right: none;
        // box-shadow: none;
      }
    }
    **/
}
.euiPageContent.euiPageContent--verticalCenter {
	-ms-flex-item-align: center;
	align-self: center;
	margin-top: auto;
	margin-bottom: auto;
	-webkit-box-flex: 0;
	-ms-flex-positive: 0;
	flex-grow: 0;
}
.euiPageContent.euiPageContent--horizontalCenter {
	width: auto;
	max-width: 100%;
	margin-left: auto;
	margin-right: auto;
	-webkit-box-flex: 0;
	-ms-flex-positive: 0;
	flex-grow: 0;
}

.euiPageContentHeader {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.euiPageContent[class*='paddingSmall'] .euiPageContentHeader {
	margin-bottom: 8px;
}
.euiPageContent[class*='paddingMedium'] .euiPageContentHeader {
	margin-bottom: 16px;
}
.euiPageContent[class*='paddingLarge'] .euiPageContentHeader {
	margin-bottom: 24px;
}

@media only screen and (max-width: 574px) {
	.euiPageContentHeader--responsive {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: start;
		-ms-flex-align: start;
		align-items: flex-start;
	}
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
	.euiPageContentHeader--responsive {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: start;
		-ms-flex-align: start;
		align-items: flex-start;
	}
}

.euiPageContentHeaderSection + .euiPageContentHeaderSection {
	margin-left: 32px;
}

@media only screen and (max-width: 574px) {
	.euiPageContent[class*='paddingSmall']
		.euiPageContentHeader--responsive
		.euiPageContentHeaderSection
		+ .euiPageContentHeaderSection {
		margin-left: 0;
		margin-top: 4px;
	}
	.euiPageContent[class*='paddingMedium']
		.euiPageContentHeader--responsive
		.euiPageContentHeaderSection
		+ .euiPageContentHeaderSection {
		margin-left: 0;
		margin-top: 8px;
	}
	.euiPageContent[class*='paddingLarge']
		.euiPageContentHeader--responsive
		.euiPageContentHeaderSection
		+ .euiPageContentHeaderSection {
		margin-left: 0;
		margin-top: 12px;
	}
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
	.euiPageContent[class*='paddingSmall']
		.euiPageContentHeader--responsive
		.euiPageContentHeaderSection
		+ .euiPageContentHeaderSection {
		margin-left: 0;
		margin-top: 4px;
	}
	.euiPageContent[class*='paddingMedium']
		.euiPageContentHeader--responsive
		.euiPageContentHeaderSection
		+ .euiPageContentHeaderSection {
		margin-left: 0;
		margin-top: 8px;
	}
	.euiPageContent[class*='paddingLarge']
		.euiPageContentHeader--responsive
		.euiPageContentHeaderSection
		+ .euiPageContentHeaderSection {
		margin-left: 0;
		margin-top: 12px;
	}
}

/**
   * 1. Prevent side bar width from changing when content width changes.
   */
.euiPageSideBar {
	min-width: 192px;
	/* 1 */
	-webkit-box-flex: 0;
	-ms-flex: 0 1 0%;
	flex: 0 1 0%;
	/* 1 */
	margin-right: 24px;
}

@media only screen and (max-width: 574px) {
	.euiPageSideBar {
		width: 100%;
	}
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
	.euiPageSideBar {
		width: 100%;
	}
}

.euiPageHeader {
	margin-bottom: 16px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

@media only screen and (max-width: 574px) {
	.euiPageHeader {
		padding: 16px 0;
		margin-bottom: 0;
	}
	.euiPageHeader--responsive {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
	}
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
	.euiPageHeader {
		padding: 16px 0;
		margin-bottom: 0;
	}
	.euiPageHeader--responsive {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
	}
}

.euiPageHeaderSection + .euiPageHeaderSection {
	margin-left: 32px;
}

@media only screen and (max-width: 574px) {
	.euiPageHeader--responsive .euiPageHeaderSection {
		width: 100%;
	}
	.euiPageHeader--responsive .euiPageHeaderSection + .euiPageHeaderSection {
		margin-left: 0;
		margin-top: 16px;
	}
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
	.euiPageHeader--responsive .euiPageHeaderSection {
		width: 100%;
	}
	.euiPageHeader--responsive .euiPageHeaderSection + .euiPageHeaderSection {
		margin-left: 0;
		margin-top: 16px;
	}
}

.euiPagination {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.euiPagination__compressedText {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.euiPagination__compressedText > *:first-child {
	margin-right: 4px;
}
.euiPagination__compressedText > *:last-child {
	margin-left: 4px;
}

.euiPaginationButton {
	font-size: 14px;
	padding: 0;
	text-align: center;
	border-radius: 4px;
}

.euiPaginationButton-isActive {
	pointer-events: none;
	color: #ef5742;
	text-decoration: underline;
	font-weight: 700;
}

.euiPaginationButton-isPlaceholder:disabled .euiButtonEmpty__content {
	cursor: default;
}

@media only screen and (max-width: 574px) {
	.euiPaginationButton--hideOnMobile {
		display: none;
	}
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
	.euiPaginationButton--hideOnMobile {
		display: none;
	}
}

.euiPanel {
	-webkit-box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3),
		0 1px 5px -2px rgba(152, 162, 179, 0.3);
	box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3), 0 1px 5px -2px rgba(152, 162, 179, 0.3);
	background-color: #fff;
	border: 1px solid #d3dae6;
	border-radius: 4px;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}
.euiPanel.euiPanel--flexGrowZero {
	-webkit-box-flex: 0;
	-ms-flex-positive: 0;
	flex-grow: 0;
}
.euiPanel.euiPanel--isClickable {
	display: block;
	width: 100%;
	text-align: left;
	-webkit-transition: all 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
	transition: all 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
}
.euiPanel.euiPanel--isClickable:hover,
.euiPanel.euiPanel--isClickable:focus {
	-webkit-box-shadow: 0 4px 8px 0 rgba(152, 162, 179, 0.15), 0 2px 2px -1px rgba(152, 162, 179, 0.3);
	box-shadow: 0 4px 8px 0 rgba(152, 162, 179, 0.15), 0 2px 2px -1px rgba(152, 162, 179, 0.3);
	-webkit-transform: translateY(-2px);
	transform: translateY(-2px);
	cursor: pointer;
}
.euiPanel.euiPanel--shadow {
	-webkit-box-shadow: 0 6px 12px -1px rgba(152, 162, 179, 0.2),
		0 4px 4px -1px rgba(152, 162, 179, 0.2), 0 2px 2px 0 rgba(152, 162, 179, 0.2);
	box-shadow: 0 6px 12px -1px rgba(152, 162, 179, 0.2), 0 4px 4px -1px rgba(152, 162, 179, 0.2),
		0 2px 2px 0 rgba(152, 162, 179, 0.2);
	border-bottom-color: rgba(152, 162, 179, 0.5);
}
.euiPanel.euiPanel--shadow.euiPanel--isClickable:hover,
.euiPanel.euiPanel--shadow.euiPanel--isClickable:focus {
	-webkit-box-shadow: 0 12px 24px 0 rgba(152, 162, 179, 0.2), 0 6px 12px 0 rgba(152, 162, 179, 0.2),
		0 4px 4px 0 rgba(152, 162, 179, 0.2), 0 2px 2px 0 rgba(152, 162, 179, 0.2);
	box-shadow: 0 12px 24px 0 rgba(152, 162, 179, 0.2), 0 6px 12px 0 rgba(152, 162, 179, 0.2),
		0 4px 4px 0 rgba(152, 162, 179, 0.2), 0 2px 2px 0 rgba(152, 162, 179, 0.2);
}

.euiPanel--hasBetaBadge {
	position: relative;
	overflow: visible;
}
.euiPanel--hasBetaBadge .euiPanel__betaBadgeWrapper {
	position: absolute;
	top: -12px;
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	z-index: 3;
	min-width: 40%;
	max-width: calc(100% - 32px);
}
.euiPanel--hasBetaBadge .euiPanel__betaBadgeWrapper .euiToolTipAnchor,
.euiPanel--hasBetaBadge .euiPanel__betaBadgeWrapper .euiPanel__betaBadge {
	width: 100%;
}
.euiPanel--hasBetaBadge .euiPanel__betaBadgeWrapper .euiPanel__betaBadge {
	overflow: hidden;
	text-overflow: ellipsis;
	background-color: #fff;
}

.euiPanel--paddingSmall {
	padding: 8px;
}
.euiPanel--paddingSmall .euiPanel__betaBadgeWrapper {
	max-width: calc(100% - 16px);
}

.euiPanel--paddingMedium {
	padding: 16px;
}
.euiPanel--paddingMedium .euiPanel__betaBadgeWrapper {
	max-width: calc(100% - 32px);
}

.euiPanel--paddingLarge {
	padding: 24px;
}
.euiPanel--paddingLarge .euiPanel__betaBadgeWrapper {
	max-width: calc(100% - 48px);
}

.euiPopover {
	display: inline-block;
	position: relative;
	vertical-align: middle;
	max-width: 100%;
}

.euiPopover__anchor {
	display: inline-block;
}

.euiPopover--displayBlock {
	display: block;
}
.euiPopover--displayBlock .euiPopover__anchor {
	display: block;
}

/**
   * 1. Can expand further, but it looks weird if it's smaller than the originating button.
   * 2. Animation happens on the panel.
   * 3. Make sure the panel stays within the window.
   */
.euiPopover__panel {
	position: absolute;
	min-width: 112px;
	/* 1 */
	max-width: calc(100vw - 32px);
	/* 3 */
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	pointer-events: none;
	-webkit-transition: opacity cubic-bezier(0.34, 1.61, 0.7, 1) 350ms,
		visibility cubic-bezier(0.34, 1.61, 0.7, 1) 350ms,
		-webkit-transform cubic-bezier(0.34, 1.61, 0.7, 1) 450ms;
	transition: opacity cubic-bezier(0.34, 1.61, 0.7, 1) 350ms,
		visibility cubic-bezier(0.34, 1.61, 0.7, 1) 350ms,
		-webkit-transform cubic-bezier(0.34, 1.61, 0.7, 1) 450ms;
	transition: opacity cubic-bezier(0.34, 1.61, 0.7, 1) 350ms,
		visibility cubic-bezier(0.34, 1.61, 0.7, 1) 350ms,
		transform cubic-bezier(0.34, 1.61, 0.7, 1) 450ms;
	transition: opacity cubic-bezier(0.34, 1.61, 0.7, 1) 350ms,
		visibility cubic-bezier(0.34, 1.61, 0.7, 1) 350ms,
		transform cubic-bezier(0.34, 1.61, 0.7, 1) 450ms,
		-webkit-transform cubic-bezier(0.34, 1.61, 0.7, 1) 450ms;
	opacity: 0;
	/* 2 */
	visibility: hidden;
	/* 2 */
	-webkit-transform: translateY(0) translateX(0) translateZ(0);
	transform: translateY(0) translateX(0) translateZ(0);
	/* 2 */
	-webkit-box-shadow: 0 12px 24px 0 rgba(65, 78, 101, 0.1), 0 6px 12px 0 rgba(65, 78, 101, 0.1),
		0 4px 4px 0 rgba(65, 78, 101, 0.1), 0 2px 2px 0 rgba(65, 78, 101, 0.1);
	box-shadow: 0 12px 24px 0 rgba(65, 78, 101, 0.1), 0 6px 12px 0 rgba(65, 78, 101, 0.1),
		0 4px 4px 0 rgba(65, 78, 101, 0.1), 0 2px 2px 0 rgba(65, 78, 101, 0.1);
	border-color: #d0d3d9;
	border-top-color: #d9dce0;
	border-bottom-color: #aaafba;
}
.euiPopover__panel.euiPopover__panel-isOpen {
	opacity: 1;
	visibility: visible;
	pointer-events: auto;
}
.euiPopover__panel .euiPopover__panelArrow {
	position: absolute;
	width: 0;
	height: 0;
}
.euiPopover__panel .euiPopover__panelArrow:before {
	position: absolute;
	content: '';
	height: 0;
	width: 0;
}
.euiPopover__panel .euiPopover__panelArrow:after {
	position: absolute;
	content: '';
	height: 0;
	width: 0;
}
.euiPopover__panel .euiPopover__panelArrow.euiPopover__panelArrow--top:before {
	bottom: -10px;
	border-left: 12px solid transparent;
	border-right: 12px solid transparent;
	border-top: 12px solid #d3dae6;
}
.euiPopover__panel .euiPopover__panelArrow.euiPopover__panelArrow--top:after {
	bottom: -9px;
	border-left: 12px solid transparent;
	border-right: 12px solid transparent;
	border-top: 12px solid #fff;
}
.euiPopover__panel .euiPopover__panelArrow.euiPopover__panelArrow--right:before {
	left: -12px;
	top: 50%;
	border-top: 12px solid transparent;
	border-bottom: 12px solid transparent;
	border-right: 12px solid #d3dae6;
}
.euiPopover__panel .euiPopover__panelArrow.euiPopover__panelArrow--right:after {
	left: -11px;
	top: 50%;
	border-top: 12px solid transparent;
	border-bottom: 12px solid transparent;
	border-right: 12px solid #fff;
}
.euiPopover__panel .euiPopover__panelArrow.euiPopover__panelArrow--bottom:before {
	top: -12px;
	border-left: 12px solid transparent;
	border-right: 12px solid transparent;
	border-bottom: 12px solid #d3dae6;
}
.euiPopover__panel .euiPopover__panelArrow.euiPopover__panelArrow--bottom:after {
	top: -11px;
	border-left: 12px solid transparent;
	border-right: 12px solid transparent;
	border-bottom: 12px solid #fff;
}
.euiPopover__panel .euiPopover__panelArrow.euiPopover__panelArrow--left:before {
	right: -11px;
	top: 50%;
	border-top: 12px solid transparent;
	border-bottom: 12px solid transparent;
	border-left: 12px solid #d3dae6;
}
.euiPopover__panel .euiPopover__panelArrow.euiPopover__panelArrow--left:after {
	right: -10px;
	top: 50%;
	border-top: 12px solid transparent;
	border-bottom: 12px solid transparent;
	border-left: 12px solid #fff;
}
.euiPopover__panel.euiPopover__panel-noArrow .euiPopover__panelArrow {
	display: none;
}
.euiPopover__panel.euiPopover__panel-isAttached.euiPopover__panel--bottom {
	border-top-color: rgba(211, 218, 230, 0.8);
	border-top-right-radius: 0;
	border-top-left-radius: 0;
}
.euiPopover__panel.euiPopover__panel-isAttached.euiPopover__panel--top {
	-webkit-box-shadow: 0 0 12px -1px rgba(152, 162, 179, 0.2), 0 0 4px -1px rgba(152, 162, 179, 0.2),
		0 0 2px 0 rgba(152, 162, 179, 0.2);
	box-shadow: 0 0 12px -1px rgba(152, 162, 179, 0.2), 0 0 4px -1px rgba(152, 162, 179, 0.2),
		0 0 2px 0 rgba(152, 162, 179, 0.2);
	border-bottom-color: rgba(211, 218, 230, 0.8);
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

.euiPopover__panel.euiPopover__panel-isOpen.euiPopover__panel--top {
	-webkit-transform: translateY(8px) translateZ(0);
	transform: translateY(8px) translateZ(0);
}

.euiPopover__panel.euiPopover__panel-isOpen.euiPopover__panel--bottom {
	-webkit-transform: translateY(-8px) translateZ(0);
	transform: translateY(-8px) translateZ(0);
}

.euiPopover__panel.euiPopover__panel-isOpen.euiPopover__panel--left {
	-webkit-transform: translateX(8px) translateZ(0);
	transform: translateX(8px) translateZ(0);
}

.euiPopover__panel.euiPopover__panel-isOpen.euiPopover__panel--right {
	-webkit-transform: translateX(-8px) translateZ(0);
	transform: translateX(-8px) translateZ(0);
}

.euiPopoverTitle {
	color: #1a1c21;
	font-size: 14px;
	font-size: 0.875rem;
	line-height: 1.5rem;
	font-weight: 700;
	padding: 12px;
	text-transform: uppercase;
	border-bottom: 1px solid #d3dae6;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
}
.euiPopover__panel.euiPanel--paddingSmall .euiPopoverTitle {
	padding: 12px 8px;
	margin: -8px -8px 8px;
}
.euiPopover__panel.euiPanel--paddingMedium .euiPopoverTitle {
	padding: 12px 16px;
	margin: -16px -16px 16px;
}
.euiPopover__panel.euiPanel--paddingLarge .euiPopoverTitle {
	padding: 12px 24px;
	margin: -24px -24px 24px;
}

.euiPopoverFooter {
	font-size: 14px;
	font-size: 0.875rem;
	line-height: 1.5;
	padding: 12px;
	border-top: 1px solid #d3dae6;
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
}
.euiPopover__panel.euiPanel--paddingSmall .euiPopoverFooter {
	padding: 8px;
	margin: 8px -8px -8px;
}
.euiPopover__panel.euiPanel--paddingMedium .euiPopoverFooter {
	padding: 16px;
	margin: 16px -16px -16px;
}
.euiPopover__panel.euiPanel--paddingLarge .euiPopoverFooter {
	padding: 24px;
	margin: 24px -24px -24px;
}

.euiInputPopover {
	max-width: 400px;
}
.euiInputPopover.euiInputPopover--fullWidth {
	max-width: 100%;
}

/**
   * 1. Portal content is absolutely positioned (e.g. tooltips, popovers, flyouts).
   */
.euiBody-hasPortalContent {
	position: relative;
	/* 1 */
}

.euiProgress {
	position: relative;
	overflow: hidden;
	background-color: #d3dae6;
}

.euiProgress--xs {
	height: 2px;
}

.euiProgress--s {
	height: 4px;
}

.euiProgress--m {
	height: 8px;
}

.euiProgress--l {
	height: 16px;
}

.euiProgress--native {
	display: block;
	width: 100%;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border: none;
}
.euiProgress--native::-webkit-progress-bar {
	background-color: #d3dae6;
}
.euiProgress--native::-webkit-progress-value {
	-webkit-transition: width 250ms linear;
	transition: width 250ms linear;
}
.euiProgress--native::-moz-progress-bar {
	-webkit-transition: width 250ms linear;
	transition: width 250ms linear;
}

/**
   * An indeterminate bar has an unreliable end time. Because of a Firefox animation issue,
   * we apply this style to a <div> instead of a <progress> element.
   * See https://css-tricks.com/html5-progress-element/ for more info.
   */
.euiProgress--indeterminate:before {
	position: absolute;
	content: '';
	width: 100%;
	top: 0;
	bottom: 0;
	left: 0;
	-webkit-transform: scaleX(0) translateX(0%);
	transform: scaleX(0) translateX(0%);
	-webkit-animation: euiProgress 1s cubic-bezier(0.694, 0.0482, 0.335, 1) infinite;
	animation: euiProgress 1s cubic-bezier(0.694, 0.0482, 0.335, 1) infinite;
}

.euiProgress--fixed {
	position: fixed;
	z-index: 1001;
}

.euiProgress--absolute {
	position: absolute;
}

.euiProgress--fixed,
.euiProgress--absolute {
	top: 0;
	left: 0;
	right: 0;
	background-color: transparent;
}
.euiProgress--fixed.euiProgress--native::-webkit-progress-bar,
.euiProgress--absolute.euiProgress--native::-webkit-progress-bar {
	background-color: transparent;
}
.euiProgress--fixed.euiProgress--native::-moz-progress-bar,
.euiProgress--absolute.euiProgress--native::-moz-progress-bar {
	background-color: transparent;
}

.euiProgress--primary.euiProgress--native::-webkit-progress-value {
	background-color: #ef5742;
}

.euiProgress--primary.euiProgress--native::-moz-progress-bar {
	background-color: #ef5742;
}

.euiProgress--primary.euiProgress--indeterminate:before {
	background-color: #ef5742;
}

.euiProgress--secondary.euiProgress--native::-webkit-progress-value {
	background-color: #017d73;
}

.euiProgress--secondary.euiProgress--native::-moz-progress-bar {
	background-color: #017d73;
}

.euiProgress--secondary.euiProgress--indeterminate:before {
	background-color: #017d73;
}

.euiProgress--warning.euiProgress--native::-webkit-progress-value {
	background-color: #f5a700;
}

.euiProgress--warning.euiProgress--native::-moz-progress-bar {
	background-color: #f5a700;
}

.euiProgress--warning.euiProgress--indeterminate:before {
	background-color: #f5a700;
}

.euiProgress--danger.euiProgress--native::-webkit-progress-value {
	background-color: #bd271e;
}

.euiProgress--danger.euiProgress--native::-moz-progress-bar {
	background-color: #bd271e;
}

.euiProgress--danger.euiProgress--indeterminate:before {
	background-color: #bd271e;
}

.euiProgress--accent.euiProgress--native::-webkit-progress-value {
	background-color: #dd0a73;
}

.euiProgress--accent.euiProgress--native::-moz-progress-bar {
	background-color: #dd0a73;
}

.euiProgress--accent.euiProgress--indeterminate:before {
	background-color: #dd0a73;
}

.euiProgress--subdued.euiProgress--native::-webkit-progress-value {
	background-color: #6a717d;
}

.euiProgress--subdued.euiProgress--native::-moz-progress-bar {
	background-color: #6a717d;
}

.euiProgress--subdued.euiProgress--indeterminate:before {
	background-color: #6a717d;
}

@-webkit-keyframes euiProgress {
	0% {
		-webkit-transform: scaleX(1) translateX(-100%);
		transform: scaleX(1) translateX(-100%);
	}
	100% {
		-webkit-transform: scaleX(1) translateX(100%);
		transform: scaleX(1) translateX(100%);
	}
}

@keyframes euiProgress {
	0% {
		-webkit-transform: scaleX(1) translateX(-100%);
		transform: scaleX(1) translateX(-100%);
	}
	100% {
		-webkit-transform: scaleX(1) translateX(100%);
		transform: scaleX(1) translateX(100%);
	}
}

.euiTreeView__wrapper .euiTreeView {
	margin: 0;
	list-style-type: none;
}

.euiTreeView .euiTreeView {
	padding-left: 24px;
}

.euiTreeView__node {
	max-height: 32px;
	overflow: hidden;
	cursor: pointer;
	line-height: 32px;
}

.euiTreeView__node--expanded {
	max-height: 100vh;
	overflow: auto;
}

.euiTreeView__nodeInner {
	max-width: 100%;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
	white-space: nowrap !important;
	word-wrap: normal !important;
	padding-left: 8px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 32px;
	border-radius: 4px;
	width: 100%;
	-moz-text-align-last: left;
	text-align-last: left;
}
.euiTreeView__nodeInner:focus {
	-webkit-box-shadow: inset 0 0 0 1px rgba(0, 107, 180, 0.3);
	box-shadow: inset 0 0 0 1px rgba(0, 107, 180, 0.3);
}
.euiTreeView__nodeInner:hover,
.euiTreeView__nodeInner:active {
	background-color: #e9edf3;
}
.euiTreeView__nodeInner .euiTreeView__iconPlaceholder {
	width: 32px;
}

.euiTreeView__nodeLabel {
	max-width: 100%;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
	white-space: nowrap !important;
	word-wrap: normal !important;
}

.euiTreeView__iconWrapper {
	margin-top: -2px;
	margin-right: 8px;
}
.euiTreeView__iconWrapper .euiToken {
	margin-top: 2px;
}

.euiTreeView--compressed .euiTreeView__node {
	max-height: 24px;
	line-height: 24px;
}
.euiTreeView--compressed .euiTreeView__node .euiTreeView__nodeInner {
	height: 24px;
}
.euiTreeView--compressed .euiTreeView__node .euiTreeView__iconWrapper {
	margin: 0 6px 0 0;
}
.euiTreeView--compressed .euiTreeView__node .euiTreeView__nodeLabel {
	margin-top: -1px;
}
.euiTreeView--compressed .euiTreeView__node .euiTreeView__iconPlaceholder {
	width: 24px;
}

.euiTreeView--compressed .euiTreeView__node--expanded {
	max-height: 100vh;
	overflow: auto;
}

.euiTreeView--withArrows .euiTreeView__expansionArrow {
	margin-right: 4px;
}

.euiTreeView--withArrows.euiTreeView .euiTreeView__nodeInner--withArrows .euiTreeView__iconWrapper {
	margin-left: 0;
}

.euiTreeView--withArrows.euiTreeView .euiTreeView__iconWrapper {
	margin-left: 20px;
}

.euiTreeView--withArrows.euiTreeView--compressed
	.euiTreeView__nodeInner--withArrows
	.euiTreeView__iconWrapper {
	margin-left: 0;
}

.euiTreeView--withArrows.euiTreeView--compressed .euiTreeView__iconWrapper {
	margin-left: 16px;
}

.euiSideNav__mobileToggle {
	display: none;
	border-bottom: 1px solid #d3dae6;
	padding: 16px 24px;
	width: 100%;
	text-align: left;
	/**
     * 1. This toggle also works with EUI link, but we need the outline
     *    that comes with the focus state.
     */
}
.euiSideNav__mobileToggle:focus {
	outline: none;
}

.euiSideNav__mobileIcon {
	fill: #ef5742;
}

.euiSideNav__mobileWrap {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.euiSideNav__mobileTitle {
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	color: #ef5742;
}

.euiSideNav__hideButton {
	width: 24px;
	height: 24px;
	border-radius: 4px;
	border: 1px solid #d3dae6;
	background: #fff;
	position: absolute;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	right: -40px;
	top: 32px;
}

@media only screen and (max-width: 574px) {
	/**
     * 1. Animation doesn't work against height. Need max-height instead.
     *    We set a value larger than what is needed to fake "auto".
     */
	.euiSideNav-isOpenMobile .euiSideNav__content {
		visibility: visible;
		opacity: 1;
		margin: 24px;
		max-height: 5000px;
		/* 1 */
		-webkit-transition: all 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
		transition: all 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
	}
	.euiSideNav__mobileToggle {
		display: block;
	}
	.euiSideNav__content {
		visibility: hidden;
		opacity: 0;
		max-height: 0;
		margin: 0 24px;
	}
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
	/**
     * 1. Animation doesn't work against height. Need max-height instead.
     *    We set a value larger than what is needed to fake "auto".
     */
	.euiSideNav-isOpenMobile .euiSideNav__content {
		visibility: visible;
		opacity: 1;
		margin: 24px;
		max-height: 5000px;
		/* 1 */
		-webkit-transition: all 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
		transition: all 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
	}
	.euiSideNav__mobileToggle {
		display: block;
	}
	.euiSideNav__content {
		visibility: hidden;
		opacity: 0;
		max-height: 0;
		margin: 0 24px;
	}
}

/**
   * 1. Text-align defaults to center, so we have to override that.
   * 2. Need this on the button element to color the underline.
   */
.euiSideNavItemButton {
	font-size: 14px;
	font-size: 0.875rem;
	line-height: 1.5;
	text-align: left;
	/* 1 */
	display: block;
	width: 100%;
	padding: 2px 0;
	color: #000;
	/* 2 */
}
.euiSideNavItemButton.euiSideNavItemButton--isClickable:hover .euiSideNavItemButton__label {
	text-decoration: underline;
}
.euiSideNavItemButton.euiSideNavItemButton--isClickable:focus {
	background-color: #e6f0f8 !important;
}
.euiSideNavItemButton.euiSideNavItemButton-isSelected .euiSideNavItemButton__label {
	color: #ef5742;
	font-weight: 500;
	text-decoration: underline;
}

.euiSideNavItemButton__content {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.euiSideNavItemButton__icon {
	margin-right: 8px;
}

/**
    * 1. Enable ellipsis overflow to work (https://css-tricks.com/flexbox-truncated-text/)
    */
.euiSideNavItemButton__labelContainer {
	min-width: 0;
	/* 1 */
}

.euiSideNavItemButton__label {
	color: #69707d;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

/**
   * 1. Draw the vertical line to group an expanded item's child items together.
   */
.euiSideNavItem__items {
	position: relative;
}
.euiSideNavItem__items:after {
	/* 1 */
	position: absolute;
	content: '';
	top: 0;
	bottom: 12px;
	width: 1px;
	background: #d3dae6;
	left: 0;
}

.euiSideNavItem--root {
	/**
     * 1. Create padding around focus area without indenting the item itself.
     */
}
.euiSideNavItem--root.euiSideNavItem--rootIcon > .euiSideNavItem__items {
	margin-left: 24px;
}
.euiSideNavItem--root > .euiSideNavItemButton {
	margin-bottom: 8px;
	padding: 0;
	padding-left: 8px;
	/* 1 */
	padding-right: 8px;
	/* 1 */
	margin-left: -8px;
	/* 1 */
	width: calc(100% + 16px);
	/* 1 */
}
.euiSideNavItem--root > .euiSideNavItemButton .euiSideNavItemButton__label {
	color: #1a1c21;
	font-size: 16px;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 600;
	letter-spacing: -0.02em;
}
.euiSideNavItem--root > .euiSideNavItem__items {
	position: static;
	margin-left: 0;
}
.euiSideNavItem--root > .euiSideNavItem__items:after {
	display: none;
}
.euiSideNavItem--root + .euiSideNavItem--root {
	margin-top: 32px;
}

.euiSideNavItem--trunk {
	/**
     * 1. Create padding around focus area without indenting the item itself.
     */
}
.euiSideNavItem--trunk > .euiSideNavItemButton {
	padding-left: 8px;
	/* 1 */
	padding-right: 8px;
	/* 1 */
	margin-left: -8px;
	/* 1 */
	width: calc(100% + 16px);
	/* 1 */
}
.euiSideNavItem--trunk > .euiSideNavItem__items {
	margin-left: 8px;
	width: 100%;
}

.euiSideNavItem--branch {
	/**
     * 1. Absolutely position the horizontal tick connecting the item to the vertical line.
     */
}
.euiSideNavItem--branch > .euiSideNavItemButton {
	position: relative;
	/* 1 */
	padding-left: 8px;
	padding-right: 8px;
	/* 1 */
}
.euiSideNavItem--branch > .euiSideNavItemButton:after {
	position: absolute;
	/* 1 */
	content: '';
	top: 50%;
	left: 0;
	width: 4px;
	height: 1px;
	background: #d3dae6;
}
.euiSideNavItem--branch > .euiSideNavItem__items {
	margin-left: 16px;
}

.euiSideNavItem--hasChildItems > .euiSideNavItemButton-isOpen .euiSideNavItemButton__label {
	color: #000;
}

.euiSpacer--xs {
	height: 4px;
}

.euiSpacer--s {
	height: 8px;
}

.euiSpacer--m {
	height: 16px;
}

.euiSpacer--l {
	height: 24px;
}

.euiSpacer--xl {
	height: 32px;
}

.euiSpacer--xxl {
	height: 40px;
}

.euiSearchBar__searchHolder {
	min-width: 200px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.euiSearchBar__filtersHolder {
		max-width: calc(100% - 16px);
	}
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.euiSearchBar__filtersHolder {
		max-width: calc(100% - 16px);
	}
}

@media only screen and (min-width: 1200px) {
	.euiSearchBar__filtersHolder {
		max-width: calc(100% - 16px);
	}
}

.euiSelectable {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}

.euiSelectable-fullHeight {
	height: 100%;
}

.euiSelectableList-fullHeight {
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

.euiSelectableList-bordered {
	overflow: hidden;
	border: 1px solid #d3dae6;
	border-radius: 4px;
}

.euiSelectableList__list {
	-webkit-mask-image: linear-gradient(
		to bottom,
		rgba(255, 0, 0, 0.1) 0%,
		red 7.5px,
		red calc(100% - 7.5px),
		rgba(255, 0, 0, 0.1) 100%
	);
	mask-image: linear-gradient(
		to bottom,
		rgba(255, 0, 0, 0.1) 0%,
		red 7.5px,
		red calc(100% - 7.5px),
		rgba(255, 0, 0, 0.1) 100%
	);
	scrollbar-width: thin;
}
.euiSelectableList__list::-webkit-scrollbar {
	width: 16px;
	height: 16px;
}
.euiSelectableList__list::-webkit-scrollbar-thumb {
	background-color: rgba(105, 112, 125, 0.5);
	border: 6px solid transparent;
	background-clip: content-box;
}
.euiSelectableList__list::-webkit-scrollbar-corner,
.euiSelectableList__list::-webkit-scrollbar-track {
	background-color: transparent;
}

.euiSelectableList__groupLabel {
	color: #1a1c21;
	font-size: 12px;
	font-size: 0.75rem;
	line-height: 1.5rem;
	font-weight: 700;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom: 1px solid #eef2f7;
	padding: 4px 12px;
}

.euiSelectableListItem {
	font-size: 14px;
	font-size: 0.875rem;
	line-height: 1.5;
	padding: 4px 12px;
	display: block;
	width: 100%;
	text-align: left;
	color: #343741;
}
.euiSelectableListItem:not(:last-of-type) {
	border-bottom: 1px solid #eef2f7;
}
.euiSelectableListItem:hover .euiSelectableListItem__text,
.euiSelectableListItem:focus .euiSelectableListItem__text {
	text-decoration: underline;
}
.euiSelectableListItem:focus,
.euiSelectableListItem-isFocused {
	cursor: pointer;
	color: #ef5742;
	background-color: #e6f0f8;
}
.euiSelectableListItem[disabled] {
	color: #98a2b3;
	cursor: not-allowed;
}
.euiSelectableListItem[disabled]:hover {
	text-decoration: none;
}

.euiSelectableListItem__content {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.euiSelectableListItem__icon,
.euiSelectableListItem__prepend {
	margin-right: 12px;
}

.euiSelectableListItem__append {
	margin-left: 12px;
}

.euiSelectableListItem__text {
	max-width: 100%;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
	white-space: nowrap !important;
	word-wrap: normal !important;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

/**
    * 1. Prevent really long input from overflowing the container.
    */
.euiSelectableMessage {
	padding: 8px;
	text-align: center;
	word-wrap: break-word;
	/* 1 */
}

.euiStat .euiStat__title {
	color: #000;
}
.euiStat .euiStat__title--subdued {
	color: #6a717d;
}
.euiStat .euiStat__title--primary {
	color: #ef5742;
}
.euiStat .euiStat__title--secondary {
	color: #017d73;
}
.euiStat .euiStat__title--danger {
	color: #bd271e;
}
.euiStat .euiStat__title--accent {
	color: #dd0a73;
}

.euiStat .euiStat__title-isLoading {
	-webkit-animation: euiStatPulse 1.5s infinite ease-in-out;
	animation: euiStatPulse 1.5s infinite ease-in-out;
}

.euiStat.euiStat--leftAligned {
	text-align: left;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.euiStat.euiStat--centerAligned {
	text-align: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.euiStat.euiStat--rightAligned {
	text-align: right;
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
}

@-webkit-keyframes euiStatPulse {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0.25;
	}
	100% {
		opacity: 1;
	}
}

@keyframes euiStatPulse {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0.25;
	}
	100% {
		opacity: 1;
	}
}

.euiStepNumber {
	width: 32px;
	height: 32px;
	display: inline-block;
	line-height: 32px;
	border-radius: 32px;
	text-align: center;
	color: #fff;
	background-color: #ef5742;
	font-size: 16px;
	font-weight: 500;
}
.euiStepNumber .euiStepNumber__icon {
	position: relative;
	top: -2px;
}
.euiStepNumber--complete .euiStepNumber__icon {
	stroke: currentColor;
	stroke-width: 0.5px;
}
.euiStepNumber.euiStepNumber-isHollow {
	background-color: transparent;
	border: 2px solid #ef5742;
}
.euiStepNumber--warning {
	color: #936400;
	background-color: #fef6e6;
}
.euiStepNumber--warning.euiStepNumber-isHollow {
	border-color: #f5a700;
}
.euiStepNumber--danger {
	color: #bd271e;
	background-color: #f8e9e9;
}
.euiStepNumber--danger.euiStepNumber-isHollow {
	border-color: #bd271e;
}
.euiStepNumber--disabled {
	color: #646a77;
	background-color: #f0f1f2;
}
.euiStepNumber--disabled.euiStepNumber-isHollow {
	border-color: #69707d;
}
.euiStepNumber--incomplete {
	color: #646a77;
	background-color: #f0f1f2;
}
.euiStepNumber--incomplete.euiStepNumber-isHollow {
	border-color: #69707d;
}

/**
   * 1. Ensure that the step number vertically aligns with the title text
   */
.euiStep:not(:last-of-type) {
	background-image: linear-gradient(
		to right,
		transparent 0,
		transparent 15px,
		#d3dae6 15px,
		#d3dae6 17px,
		transparent 17px,
		transparent 100%
	);
	background-repeat: no-repeat;
	background-position: left 40px;
}

.euiStep__titleWrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.euiStep__circle {
	-ms-flex-negative: 0;
	flex-shrink: 0;
	margin-right: 16px;
	vertical-align: top;
	/* 1 */
}
.euiStep__circle[class*='complete'],
.euiStep__circle[class*='warning'],
.euiStep__circle[class*='danger'] {
	-webkit-animation: euiGrow 150ms cubic-bezier(0.34, 1.61, 0.7, 1);
	animation: euiGrow 150ms cubic-bezier(0.34, 1.61, 0.7, 1);
}
.euiStep__circle[class*='incomplete'] {
	border-color: #ef5742;
	-webkit-animation: none;
	animation: none;
}

.euiStep__title {
	font-weight: 500;
}

.euiStep__content {
	padding: 16px 16px 32px;
	margin: 8px 0;
	padding-left: 32px;
	margin-left: 16px;
}

.euiSubSteps {
	padding: 16px;
	background-color: #f5f7fa;
	margin-bottom: 16px;
}
.euiSubSteps > *:last-child {
	margin-bottom: 0;
}
.euiText .euiSubSteps ol,
.euiSubSteps ol {
	list-style-type: lower-alpha;
}

/**
   * 1. Ensure the connecting lines stays behind the number
   * 2. Make each step the same width
   * 3. Make the content of each step align to the top, even if the steps are of varying heights,
   *    e.g. due to some of their titles wrapping to multiple lines
   */
.euiStepsHorizontal {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	background: rgba(245, 247, 250, 0.5);
}

.euiStepHorizontal {
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	/* 2 */
	-ms-flex-preferred-size: 0%;
	flex-basis: 0%;
	/* 2 */
	padding: 24px 16px 16px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	/* 3 */
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	/* 3 */
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	/* 3 */
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	/* 3 */
	cursor: pointer;
	position: relative;
}
.euiStepHorizontal:focus:not(.euiStepHorizontal-isDisabled) .euiStepHorizontal__number,
.euiStepHorizontal:hover:not(.euiStepHorizontal-isDisabled) .euiStepHorizontal__number {
	-webkit-animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards
		focusRingAnimateLarge !important;
	animation: 350ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards focusRingAnimateLarge !important;
	-webkit-transition: background-color 250ms cubic-bezier(0.694, 0.0482, 0.335, 1),
		color 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
	transition: background-color 250ms cubic-bezier(0.694, 0.0482, 0.335, 1),
		color 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
}
.euiStepHorizontal:focus:not(.euiStepHorizontal-isDisabled) .euiStepHorizontal__title,
.euiStepHorizontal:hover:not(.euiStepHorizontal-isDisabled) .euiStepHorizontal__title {
	text-decoration: underline;
}
.euiStepHorizontal.euiStepHorizontal-isDisabled {
	cursor: not-allowed;
}
.euiStepHorizontal::before,
.euiStepHorizontal::after {
	content: '';
	position: absolute;
	width: 50%;
	height: 1px;
	top: 40px;
	background-color: #d3dae6;
	z-index: 0;
	/* 1 */
}
.euiStepHorizontal::before {
	left: 0;
}
.euiStepHorizontal::after {
	right: 0;
}
.euiStepHorizontal:first-of-type::before {
	display: none;
}
.euiStepHorizontal:last-of-type::after {
	display: none;
}

.euiStepHorizontal__number {
	position: relative;
	/* 1 */
	z-index: 1000;
	/* 1 */
	-webkit-transition: all 150ms ease-in-out;
	transition: all 150ms ease-in-out;
}

.euiStepHorizontal__title {
	color: #1a1c21;
	font-size: 16px;
	font-size: 1rem;
	line-height: 1.5rem;
	letter-spacing: -0.02em;
	margin-top: 8px;
	font-weight: 400;
	text-align: center;
	max-width: 100%;
}
.euiStepHorizontal-isDisabled .euiStepHorizontal__title {
	color: #69707d;
}

.euiStepHorizontal-isComplete::before,
.euiStepHorizontal-isComplete::after {
	height: 2px;
	background-color: #ef5742;
}

.euiStepHorizontal-isSelected
	.euiStepHorizontal__number:not([class*='danger']):not([class*='warning']) {
	-webkit-box-shadow: 0 2px 2px -1px rgba(18, 104, 162, 0.3);
	box-shadow: 0 2px 2px -1px rgba(18, 104, 162, 0.3);
}

.euiStepHorizontal-isSelected::before {
	height: 2px;
	background-color: #ef5742;
}

@media only screen and (max-width: 574px) {
	.euiStepHorizontal {
		padding-top: 16px;
	}
	.euiStepHorizontal:before,
	.euiStepHorizontal:after {
		top: 32px;
	}
	.euiStepHorizontal__title {
		display: none;
	}
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
	.euiStepHorizontal {
		padding-top: 16px;
	}
	.euiStepHorizontal:before,
	.euiStepHorizontal:after {
		top: 32px;
	}
	.euiStepHorizontal__title {
		display: none;
	}
}

.euiSuggestItem {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	font-size: 12px;
	white-space: nowrap;
}
.euiSuggestItem.euiSuggestItem-isClickable {
	width: 100%;
	text-align: left;
}
.euiSuggestItem.euiSuggestItem-isClickable:hover,
.euiSuggestItem.euiSuggestItem-isClickable:focus {
	cursor: pointer;
	background-color: #f5f7fa;
}
.euiSuggestItem.euiSuggestItem-isClickable:hover .euiSuggestItem__type,
.euiSuggestItem.euiSuggestItem-isClickable:focus .euiSuggestItem__type {
	color: #343741;
}
.euiSuggestItem .euiSuggestItem__type--tint0 {
	background-color: #e0f1ed;
	color: #357160;
}
.euiSuggestItem .euiSuggestItem__type--tint1 {
	background-color: #e2ebf4;
	color: #466b8d;
}
.euiSuggestItem .euiSuggestItem__type--tint2 {
	background-color: #f7e2e9;
	color: #a34a68;
}
.euiSuggestItem .euiSuggestItem__type--tint3 {
	background-color: #ebe5f2;
	color: #765b96;
}
.euiSuggestItem .euiSuggestItem__type--tint4 {
	background-color: #f5ebf0;
	color: #865f74;
}
.euiSuggestItem .euiSuggestItem__type--tint5 {
	background-color: #f8f3e1;
	color: #7a6c31;
}
.euiSuggestItem .euiSuggestItem__type--tint6 {
	background-color: #f2efea;
	color: #756a56;
}
.euiSuggestItem .euiSuggestItem__type--tint7 {
	background-color: #f8eade;
	color: #915c2e;
}
.euiSuggestItem .euiSuggestItem__type--tint8 {
	background-color: #f0e3e1;
	color: #92564a;
}
.euiSuggestItem .euiSuggestItem__type--tint9 {
	background-color: #fbe3df;
	color: #aa4b38;
}
.euiSuggestItem .euiSuggestItem__type--tint10 {
	background-color: #e4e5e8;
	color: #5f6571;
}
.euiSuggestItem .euiSuggestItem__label,
.euiSuggestItem .euiSuggestItem__type,
.euiSuggestItem .euiSuggestItem__description {
	-webkit-box-flex: 0;
	-ms-flex-positive: 0;
	flex-grow: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}
.euiSuggestItem .euiSuggestItem__type {
	position: relative;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	-ms-flex-preferred-size: auto;
	flex-basis: auto;
	width: 32px;
	height: 32px;
	text-align: center;
	overflow: hidden;
	padding: 4px;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.euiSuggestItem .euiSuggestItem__label {
	-ms-flex-preferred-size: 50%;
	flex-basis: 50%;
	min-width: 50%;
	font-family: var(--primary-font), 'Roboto Mono', Consolas, Menlo, Courier, monospace;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 4px 8px;
	color: #343741;
}
.euiSuggestItem .euiSuggestItem__label.euiSuggestItem__labelDisplay--expand {
	-ms-flex-preferred-size: auto;
	flex-basis: auto;
	-ms-flex-negative: 1;
	flex-shrink: 1;
}
.euiSuggestItem .euiSuggestItem__description,
.euiSuggestItem .euiSuggestItem__label {
	max-width: 100%;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
	white-space: nowrap !important;
	word-wrap: normal !important;
	display: block;
}
.euiSuggestItem .euiSuggestItem__description {
	color: #69707d;
	-ms-flex-preferred-size: auto;
	flex-basis: auto;
	padding-top: 2px;
}
.euiSuggestItem .euiSuggestItem__description:empty {
	-webkit-box-flex: 0;
	-ms-flex-positive: 0;
	flex-grow: 0;
	margin-left: 0;
}

.euiSuggestInput {
	font-size: 14px;
	color: #ef5742;
}
.euiSuggestInput .euiSuggestInput__statusIcon {
	background-color: transparent !important;
}

/**
   * NOTE: table-layout: fixed causes a bug in IE11 and Edge (see #9929). It also prevents us from
   * specifying a column width, e.g. the checkbox column.
   */
.euiTable {
	font-size: 14px;
	font-size: 0.875rem;
	line-height: 1.5;
	-webkit-font-feature-settings: 'calt' 1, 'kern' 1, 'liga' 1, 'tnum' 1;
	font-feature-settings: 'calt' 1, 'kern' 1, 'liga' 1, 'tnum' 1;
	width: 100%;
	table-layout: fixed;
	border: none;
	border-collapse: collapse;
	background-color: #fff;
}
.euiTable.euiTable--auto {
	table-layout: auto;
}

.euiTableCaption {
	position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.euiTable--compressed .euiTableCellContent {
		font-size: 12px;
		font-size: 0.75rem;
		line-height: 1.5;
		padding: 4px;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.euiTable--compressed .euiTableCellContent {
		font-size: 12px;
		font-size: 0.75rem;
		line-height: 1.5;
		padding: 4px;
	}
}

@media only screen and (min-width: 1200px) {
	.euiTable--compressed .euiTableCellContent {
		font-size: 12px;
		font-size: 0.75rem;
		line-height: 1.5;
		padding: 4px;
	}
}

.euiTableFooterCell,
.euiTableHeaderCell {
	vertical-align: middle;
	border-bottom: 1px solid #d3dae6;
	text-align: inherit;
	color: #1a1c21;
	font-size: 14px;
	font-size: 0.875rem;
	line-height: 1.5rem;
	font-weight: 500;
	border-top: none;
}
.euiTableFooterCell .euiTableHeaderButton,
.euiTableHeaderCell .euiTableHeaderButton {
	text-align: left;
	font-weight: 500;
}
.euiTableFooterCell .euiTableCellContent__text,
.euiTableHeaderCell .euiTableCellContent__text {
	font-size: 12px;
	font-size: 0.75rem;
	line-height: 1.5;
	font-weight: 600;
}

.euiTableHeaderButton {
	font-size: 14px;
	font-size: 0.875rem;
	line-height: 1.5;
	color: inherit;
	width: 100%;
}
.euiTableHeaderButton:hover .euiTableCellContent__text,
.euiTableHeaderButton:focus .euiTableCellContent__text {
	text-decoration: underline;
	color: #ef5742;
}
.euiTableHeaderButton:hover .euiTableSortIcon,
.euiTableHeaderButton:focus .euiTableSortIcon {
	fill: #ef5742;
}

.euiTableSortIcon {
	margin-left: 4px;
	-ms-flex-negative: 0;
	flex-shrink: 0;
}
.euiTableHeaderButton-isSorted .euiTableSortIcon {
	fill: #1a1c21;
}

.euiTableHeaderCellCheckbox {
	border-bottom: 1px solid #d3dae6;
	font-weight: inherit;
	text-align: inherit;
	width: 32px;
	vertical-align: middle;
	border-top: none;
}

.euiTableRow:hover {
	background-color: #fafbfd;
}

.euiTableRow.euiTableRow-isExpandedRow .euiTableRowCell {
	background-color: #fafbfd;
}

.euiTableRow.euiTableRow-isExpandedRow.euiTableRow-isSelectable .euiTableCellContent {
	padding-left: 40px;
}

.euiTableRow.euiTableRow-isSelected {
	background-color: #eef5fa;
}
.euiTableRow.euiTableRow-isSelected + .euiTableRow.euiTableRow-isExpandedRow .euiTableRowCell {
	background-color: #eef5fa;
}
.euiTableRow.euiTableRow-isSelected:hover,
.euiTableRow.euiTableRow-isSelected:hover
	+ .euiTableRow.euiTableRow-isExpandedRow
	.euiTableRowCell {
	background-color: #e6f0f8;
}

.euiTableRowCell {
	vertical-align: middle;
	border-top: 1px solid #d3dae6;
	border-bottom: 1px solid #d3dae6;
	font-weight: inherit;
	text-align: inherit;
	color: #343741;
}
.euiTableRowCell.euiTableRowCell--isMobileHeader {
	display: none;
}

.euiTableRowCellCheckbox {
	border-top: 1px solid #d3dae6;
	border-bottom: 1px solid #d3dae6;
	font-weight: inherit;
	text-align: inherit;
	width: 32px;
	vertical-align: middle;
}

.euiTableFooterCell {
	background-color: #f5f7fa;
	border-bottom: none;
}

/**
   * 1. Vertically align all children.
   * 2. The padding on this div allows the ellipsis to show if the content is truncated. If
   *    the padding was on the cell, the ellipsis would be cropped.
   * 3. Support wrapping.
   * 4. Prevent very long single words (e.g. the name of a field in a document) from overflowing
   *    the cell.
   */
.euiTableCellContent {
	overflow: hidden;
	/* 4 */
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	/* 1 */
	padding: 8px;
	/* 2 */
}

.euiTableCellContent__text {
	overflow-wrap: break-word !important;
	word-wrap: break-word !important;
	word-break: break-word;
	/* 4 */
	min-width: 0;
	text-overflow: ellipsis;
}

.euiTableCellContent--alignRight {
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	text-align: right;
}

.euiTableCellContent--alignCenter {
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	text-align: center;
}

.euiTableHeaderCell,
.euiTableFooterCell,
.euiTableCellContent--truncateText {
	white-space: nowrap;
	/* 3 */
}
.euiTableHeaderCell .euiTableCellContent__text,
.euiTableFooterCell .euiTableCellContent__text,
.euiTableCellContent--truncateText .euiTableCellContent__text {
	overflow: hidden;
}

.euiTableCellContent--overflowingContent {
	overflow: visible;
	white-space: normal;
	word-break: break-all;
	word-break: break-word;
}

.euiTableCellContent--showOnHover > *:not(:first-child) {
	margin-left: 8px;
}

.euiTableRow-hasActions .euiTableCellContent--showOnHover .euiTableCellContent__hoverItem {
	-ms-flex-negative: 0;
	flex-shrink: 0;
	opacity: 0.7;
	-webkit-filter: grayscale(100%);
	filter: grayscale(100%);
	-webkit-transition: opacity 250ms cubic-bezier(0.694, 0.0482, 0.335, 1),
		-webkit-filter 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
	transition: opacity 250ms cubic-bezier(0.694, 0.0482, 0.335, 1),
		filter 250ms cubic-bezier(0.694, 0.0482, 0.335, 1),
		-webkit-filter 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
}

.euiTableRow-hasActions .euiTableCellContent--showOnHover .expandedItemActions__completelyHide,
.euiTableRow-hasActions
	.euiTableCellContent--showOnHover
	.expandedItemActions__completelyHide:disabled,
.euiTableRow-hasActions
	.euiTableCellContent--showOnHover
	.expandedItemActions__completelyHide:disabled:hover,
.euiTableRow-hasActions
	.euiTableCellContent--showOnHover
	.expandedItemActions__completelyHide:disabled:focus,
.euiTableRow:hover
	.euiTableRow-hasActions
	.euiTableCellContent--showOnHover
	.expandedItemActions__completelyHide:disabled {
	-webkit-filter: grayscale(0%);
	filter: grayscale(0%);
	opacity: 0;
}

.euiTableRow-hasActions:hover
	.euiTableCellContent--showOnHover
	.euiTableCellContent__hoverItem:not(:disabled),
.euiTableRow-hasActions:hover
	.euiTableCellContent--showOnHover
	.euiTableCellContent__hoverItem:not(:disabled):hover,
.euiTableRow-hasActions:hover
	.euiTableCellContent--showOnHover
	.euiTableCellContent__hoverItem:not(:disabled):focus {
	opacity: 1;
	-webkit-filter: grayscale(0%);
	filter: grayscale(0%);
}

.euiTableRow-isExpandedRow .euiTableCellContent {
	overflow: hidden;
	-webkit-animation: 250ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards growExpandedRow;
	animation: 250ms cubic-bezier(0.694, 0.0482, 0.335, 1) 1 normal forwards growExpandedRow;
}

@-webkit-keyframes growExpandedRow {
	0% {
		max-height: 0;
	}
	100% {
		max-height: 1000px;
	}
}

@keyframes growExpandedRow {
	0% {
		max-height: 0;
	}
	100% {
		max-height: 1000px;
	}
}

.euiTableRow-isClickable:hover {
	background-color: rgba(0, 107, 180, 0.05);
	cursor: pointer;
}

.euiTableRow-isClickable:focus {
	background-color: rgba(0, 107, 180, 0.1);
}

.euiTableRowCell__mobileHeader {
	display: none;
}

@media only screen and (max-width: 574px) {
	.euiTableRowCell--hideForMobile {
		display: none !important;
	}
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
	.euiTableRowCell--hideForMobile {
		display: none !important;
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.euiTableRowCell--hideForDesktop {
		display: none !important;
	}
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.euiTableRowCell--hideForDesktop {
		display: none !important;
	}
}

@media only screen and (min-width: 1200px) {
	.euiTableRowCell--hideForDesktop {
		display: none !important;
	}
}

@media only screen and (max-width: 574px) {
	.euiTable.euiTable--responsive thead {
		display: none;
	}
	.euiTable.euiTable--responsive tfoot {
		display: none;
	}
	.euiTable.euiTable--responsive .euiTableRow.euiTableRow--flexGrowZero {
		-webkit-box-flex: 0;
		-ms-flex-positive: 0;
		flex-grow: 0;
	}
	.euiTable.euiTable--responsive .euiTableRow.euiTableRow--isClickable {
		display: block;
		width: 100%;
		text-align: left;
		-webkit-transition: all 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
		transition: all 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
	}
	.euiTable.euiTable--responsive .euiTableRow.euiTableRow--isClickable:hover,
	.euiTable.euiTable--responsive .euiTableRow.euiTableRow--isClickable:focus {
		-webkit-box-shadow: 0 4px 8px 0 rgba(152, 162, 179, 0.15),
			0 2px 2px -1px rgba(152, 162, 179, 0.3);
		box-shadow: 0 4px 8px 0 rgba(152, 162, 179, 0.15), 0 2px 2px -1px rgba(152, 162, 179, 0.3);
		-webkit-transform: translateY(-2px);
		transform: translateY(-2px);
		cursor: pointer;
	}
	.euiTable.euiTable--responsive .euiTableRow.euiTableRow--shadow {
		-webkit-box-shadow: 0 6px 12px -1px rgba(152, 162, 179, 0.2),
			0 4px 4px -1px rgba(152, 162, 179, 0.2), 0 2px 2px 0 rgba(152, 162, 179, 0.2);
		box-shadow: 0 6px 12px -1px rgba(152, 162, 179, 0.2), 0 4px 4px -1px rgba(152, 162, 179, 0.2),
			0 2px 2px 0 rgba(152, 162, 179, 0.2);
		border-bottom-color: rgba(152, 162, 179, 0.5);
	}
	.euiTable.euiTable--responsive .euiTableRow.euiTableRow--shadow.euiTableRow--isClickable:hover,
	.euiTable.euiTable--responsive .euiTableRow.euiTableRow--shadow.euiTableRow--isClickable:focus {
		-webkit-box-shadow: 0 12px 24px 0 rgba(152, 162, 179, 0.2),
			0 6px 12px 0 rgba(152, 162, 179, 0.2), 0 4px 4px 0 rgba(152, 162, 179, 0.2),
			0 2px 2px 0 rgba(152, 162, 179, 0.2);
		box-shadow: 0 12px 24px 0 rgba(152, 162, 179, 0.2), 0 6px 12px 0 rgba(152, 162, 179, 0.2),
			0 4px 4px 0 rgba(152, 162, 179, 0.2), 0 2px 2px 0 rgba(152, 162, 179, 0.2);
	}
	.euiTable.euiTable--responsive .euiTableRowCell__mobileHeader {
		max-width: 100%;
		overflow: hidden !important;
		text-overflow: ellipsis !important;
		white-space: nowrap !important;
		word-wrap: normal !important;
		font-size: 11px;
		font-size: 0.6875rem;
		display: block;
		color: #69707d;
		padding: 8px;
		padding-bottom: 0;
		margin-bottom: -8px;
		min-height: 24px;
	}
	.euiTableRowCell:only-child .euiTable.euiTable--responsive .euiTableRowCell__mobileHeader {
		min-height: 0;
	}
	.euiTable.euiTable--responsive .euiTableRowCell--enlargeForMobile {
		font-size: 16px;
		font-size: 1rem;
		line-height: 1.5;
	}
	.euiTable.euiTable--responsive .euiTableRowCell--isMobileFullWidth {
		width: 100%;
	}
	.euiTable.euiTable--responsive
		.euiTableRowCell--isMobileFullWidth
		.euiTableCellContent--alignCenter {
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		text-align: center;
	}
	.euiTable.euiTable--responsive .euiTableRow:hover {
		background-color: transparent;
	}
	.euiTable.euiTable--responsive .euiTableRow.euiTableRow-isExpandable,
	.euiTable.euiTable--responsive .euiTableRow.euiTableRow-hasActions {
		background-image: linear-gradient(
			to right,
			rgba(152, 162, 179, 0.1) 0,
			rgba(152, 162, 179, 0.1) 1px,
			transparent 1px,
			transparent 100%
		);
		background-size: 40px 100%;
		background-position-x: right;
		background-repeat: no-repeat;
		padding-right: 40px;
		position: relative;
	}
	.euiTable.euiTable--responsive .euiTableRow.euiTableRow-isExpandable .euiTableRowCell--isExpander,
	.euiTable.euiTable--responsive .euiTableRow.euiTableRow-hasActions .euiTableRowCell--hasActions {
		min-width: 0;
		width: 24px;
		position: absolute;
		top: 16px;
		right: 8px;
	}
	.euiTable.euiTable--responsive
		.euiTableRow.euiTableRow-isExpandable
		.euiTableRowCell--isExpander::before,
	.euiTable.euiTable--responsive
		.euiTableRow.euiTableRow-hasActions
		.euiTableRowCell--hasActions::before {
		display: none;
	}
	.euiTable.euiTable--responsive
		.euiTableRow.euiTableRow-isExpandable
		.euiTableRowCell--isExpander
		.euiTableCellContent,
	.euiTable.euiTable--responsive
		.euiTableRow.euiTableRow-hasActions
		.euiTableRowCell--hasActions
		.euiTableCellContent {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		padding: 0;
	}
	.euiTable.euiTable--responsive
		.euiTableRow.euiTableRow-isExpandable
		.euiTableRowCell--isExpander
		.euiTableCellContent
		.euiLink,
	.euiTable.euiTable--responsive
		.euiTableRow.euiTableRow-hasActions
		.euiTableRowCell--hasActions
		.euiTableCellContent
		.euiLink {
		padding: 4px;
	}
	.euiTable.euiTable--responsive
		.euiTableRow.euiTableRow-hasActions.euiTableRow-isExpandable
		.euiTableRowCell--isExpander {
		top: auto;
		bottom: 16px;
	}
	.euiTable.euiTable--responsive .euiTableRow.euiTableRow-isSelectable {
		padding-left: 36px;
		position: relative;
	}
	.euiTable.euiTable--responsive .euiTableRow.euiTableRow-isSelectable .euiTableRowCellCheckbox {
		position: absolute;
		left: 4px;
		top: 8px;
	}
	.euiTable.euiTable--responsive .euiTableRow.euiTableRow-isSelected,
	.euiTable.euiTable--responsive .euiTableRow.euiTableRow-isSelected:hover,
	.euiTable.euiTable--responsive
		.euiTableRow.euiTableRow-isSelected
		+ .euiTableRow.euiTableRow-isExpandedRow,
	.euiTable.euiTable--responsive
		.euiTableRow.euiTableRow-isSelected:hover
		+ .euiTableRow.euiTableRow-isExpandedRow
		.euiTableRowCell {
		background-color: #eef5fa;
	}
	.euiTable.euiTable--responsive .euiTableRow.euiTableRow-isExpandedRow {
		background-image: linear-gradient(
			to right,
			rgba(152, 162, 179, 0.1) 0,
			rgba(152, 162, 179, 0.1) 1px,
			transparent 1px,
			transparent 100%
		);
		background-size: 40px 100%;
		background-position-x: right;
		background-repeat: no-repeat;
		-webkit-box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3),
			0 1px 5px -2px rgba(152, 162, 179, 0.3);
		box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3), 0 1px 5px -2px rgba(152, 162, 179, 0.3);
		margin-top: -16px;
		position: relative;
		z-index: 2;
		border-top: none;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		padding-left: 8px;
	}
	.euiTable.euiTable--responsive .euiTableRow.euiTableRow-isExpandedRow:hover {
		background-color: #fff;
	}
	.euiTable.euiTable--responsive .euiTableRow.euiTableRow-isExpandedRow .euiTableRowCell {
		width: calc(100% - 40px);
	}
	.euiTable.euiTable--responsive .euiTableRow.euiTableRow-isExpandedRow .euiTableRowCell::before {
		display: none;
	}
	.euiTable.euiTable--responsive .euiTableRowCell {
		display: block;
		/* IE requires block to grow and wrap. */
		min-width: 50%;
		border: none;
	}
	.euiTable.euiTable--responsive .euiTableRowCellCheckbox {
		border: none;
	}
	.euiTable.euiTable--responsive .euiTableRow-hasActions .euiTableCellContent--showOnHover > * {
		margin-left: 0;
	}
	.euiTable.euiTable--responsive
		.euiTableRow-hasActions
		.euiTableCellContent--showOnHover
		.expandedItemActions__completelyHide {
		display: none;
	}
	.euiTable.euiTable--responsive
		.euiTableRow-hasActions
		.euiTableCellContent--showOnHover
		.euiTableCellContent__hoverItem {
		opacity: 1;
		-webkit-filter: none;
		filter: none;
		margin-left: 0;
		margin-bottom: 8px;
	}
	.euiTable.euiTable--responsive .euiTableCellContent--alignRight {
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}
	.euiTable.euiTable--responsive .euiTableCellContent--alignCenter {
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
	.euiTable.euiTable--responsive thead {
		display: none;
	}
	.euiTable.euiTable--responsive tfoot {
		display: none;
	}
	.euiTable.euiTable--responsive .euiTableRow.euiTableRow--flexGrowZero {
		-webkit-box-flex: 0;
		-ms-flex-positive: 0;
		flex-grow: 0;
	}
	.euiTable.euiTable--responsive .euiTableRow.euiTableRow--isClickable {
		display: block;
		width: 100%;
		text-align: left;
		-webkit-transition: all 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
		transition: all 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
	}
	.euiTable.euiTable--responsive .euiTableRow.euiTableRow--isClickable:hover,
	.euiTable.euiTable--responsive .euiTableRow.euiTableRow--isClickable:focus {
		-webkit-box-shadow: 0 4px 8px 0 rgba(152, 162, 179, 0.15),
			0 2px 2px -1px rgba(152, 162, 179, 0.3);
		box-shadow: 0 4px 8px 0 rgba(152, 162, 179, 0.15), 0 2px 2px -1px rgba(152, 162, 179, 0.3);
		-webkit-transform: translateY(-2px);
		transform: translateY(-2px);
		cursor: pointer;
	}
	.euiTable.euiTable--responsive .euiTableRow.euiTableRow--shadow {
		-webkit-box-shadow: 0 6px 12px -1px rgba(152, 162, 179, 0.2),
			0 4px 4px -1px rgba(152, 162, 179, 0.2), 0 2px 2px 0 rgba(152, 162, 179, 0.2);
		box-shadow: 0 6px 12px -1px rgba(152, 162, 179, 0.2), 0 4px 4px -1px rgba(152, 162, 179, 0.2),
			0 2px 2px 0 rgba(152, 162, 179, 0.2);
		border-bottom-color: rgba(152, 162, 179, 0.5);
	}
	.euiTable.euiTable--responsive .euiTableRow.euiTableRow--shadow.euiTableRow--isClickable:hover,
	.euiTable.euiTable--responsive .euiTableRow.euiTableRow--shadow.euiTableRow--isClickable:focus {
		-webkit-box-shadow: 0 12px 24px 0 rgba(152, 162, 179, 0.2),
			0 6px 12px 0 rgba(152, 162, 179, 0.2), 0 4px 4px 0 rgba(152, 162, 179, 0.2),
			0 2px 2px 0 rgba(152, 162, 179, 0.2);
		box-shadow: 0 12px 24px 0 rgba(152, 162, 179, 0.2), 0 6px 12px 0 rgba(152, 162, 179, 0.2),
			0 4px 4px 0 rgba(152, 162, 179, 0.2), 0 2px 2px 0 rgba(152, 162, 179, 0.2);
	}
	.euiTable.euiTable--responsive .euiTableRowCell__mobileHeader {
		max-width: 100%;
		overflow: hidden !important;
		text-overflow: ellipsis !important;
		white-space: nowrap !important;
		word-wrap: normal !important;
		font-size: 11px;
		font-size: 0.6875rem;
		display: block;
		color: #69707d;
		padding: 8px;
		padding-bottom: 0;
		margin-bottom: -8px;
		min-height: 24px;
	}
	.euiTableRowCell:only-child .euiTable.euiTable--responsive .euiTableRowCell__mobileHeader {
		min-height: 0;
	}
	.euiTable.euiTable--responsive .euiTableRowCell--enlargeForMobile {
		font-size: 16px;
		font-size: 1rem;
		line-height: 1.5;
	}
	.euiTable.euiTable--responsive .euiTableRowCell--isMobileFullWidth {
		width: 100%;
	}
	.euiTable.euiTable--responsive
		.euiTableRowCell--isMobileFullWidth
		.euiTableCellContent--alignCenter {
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		text-align: center;
	}
	.euiTable.euiTable--responsive .euiTableRow {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		padding: 8px;
		margin-bottom: 8px;
		-webkit-box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3),
			0 1px 5px -2px rgba(152, 162, 179, 0.3);
		box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3), 0 1px 5px -2px rgba(152, 162, 179, 0.3);
		background-color: #fff;
		border: 1px solid #d3dae6;
		border-radius: 4px;
		-webkit-box-flex: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
	}
	.euiTable.euiTable--responsive .euiTableRow:hover {
		background-color: transparent;
	}
	.euiTable.euiTable--responsive .euiTableRow.euiTableRow-isExpandable,
	.euiTable.euiTable--responsive .euiTableRow.euiTableRow-hasActions {
		background-image: linear-gradient(
			to right,
			rgba(152, 162, 179, 0.1) 0,
			rgba(152, 162, 179, 0.1) 1px,
			transparent 1px,
			transparent 100%
		);
		background-size: 40px 100%;
		background-position-x: right;
		background-repeat: no-repeat;
		padding-right: 40px;
		position: relative;
	}
	.euiTable.euiTable--responsive .euiTableRow.euiTableRow-isExpandable .euiTableRowCell--isExpander,
	.euiTable.euiTable--responsive .euiTableRow.euiTableRow-hasActions .euiTableRowCell--hasActions {
		min-width: 0;
		width: 24px;
		position: absolute;
		top: 16px;
		right: 8px;
	}
	.euiTable.euiTable--responsive
		.euiTableRow.euiTableRow-isExpandable
		.euiTableRowCell--isExpander::before,
	.euiTable.euiTable--responsive
		.euiTableRow.euiTableRow-hasActions
		.euiTableRowCell--hasActions::before {
		display: none;
	}
	.euiTable.euiTable--responsive
		.euiTableRow.euiTableRow-isExpandable
		.euiTableRowCell--isExpander
		.euiTableCellContent,
	.euiTable.euiTable--responsive
		.euiTableRow.euiTableRow-hasActions
		.euiTableRowCell--hasActions
		.euiTableCellContent {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		padding: 0;
	}
	.euiTable.euiTable--responsive
		.euiTableRow.euiTableRow-isExpandable
		.euiTableRowCell--isExpander
		.euiTableCellContent
		.euiLink,
	.euiTable.euiTable--responsive
		.euiTableRow.euiTableRow-hasActions
		.euiTableRowCell--hasActions
		.euiTableCellContent
		.euiLink {
		padding: 4px;
	}
	.euiTable.euiTable--responsive
		.euiTableRow.euiTableRow-hasActions.euiTableRow-isExpandable
		.euiTableRowCell--isExpander {
		top: auto;
		bottom: 16px;
	}
	.euiTable.euiTable--responsive .euiTableRow.euiTableRow-isSelectable {
		padding-left: 36px;
		position: relative;
	}
	.euiTable.euiTable--responsive .euiTableRow.euiTableRow-isSelectable .euiTableRowCellCheckbox {
		position: absolute;
		left: 4px;
		top: 8px;
	}
	.euiTable.euiTable--responsive .euiTableRow.euiTableRow-isSelected,
	.euiTable.euiTable--responsive .euiTableRow.euiTableRow-isSelected:hover,
	.euiTable.euiTable--responsive
		.euiTableRow.euiTableRow-isSelected
		+ .euiTableRow.euiTableRow-isExpandedRow,
	.euiTable.euiTable--responsive
		.euiTableRow.euiTableRow-isSelected:hover
		+ .euiTableRow.euiTableRow-isExpandedRow
		.euiTableRowCell {
		background-color: #eef5fa;
	}
	.euiTable.euiTable--responsive .euiTableRow.euiTableRow-isExpandedRow {
		background-image: linear-gradient(
			to right,
			rgba(152, 162, 179, 0.1) 0,
			rgba(152, 162, 179, 0.1) 1px,
			transparent 1px,
			transparent 100%
		);
		background-size: 40px 100%;
		background-position-x: right;
		background-repeat: no-repeat;
		-webkit-box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3),
			0 1px 5px -2px rgba(152, 162, 179, 0.3);
		box-shadow: 0 2px 2px -1px rgba(152, 162, 179, 0.3), 0 1px 5px -2px rgba(152, 162, 179, 0.3);
		margin-top: -16px;
		position: relative;
		z-index: 2;
		border-top: none;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		padding-left: 8px;
	}
	.euiTable.euiTable--responsive .euiTableRow.euiTableRow-isExpandedRow:hover {
		background-color: #fff;
	}
	.euiTable.euiTable--responsive .euiTableRow.euiTableRow-isExpandedRow .euiTableRowCell {
		width: calc(100% - 40px);
	}
	.euiTable.euiTable--responsive .euiTableRow.euiTableRow-isExpandedRow .euiTableRowCell::before {
		display: none;
	}
	.euiTable.euiTable--responsive .euiTableRowCell {
		display: block;
		/* IE requires block to grow and wrap. */
		min-width: 50%;
		border: none;
	}
	.euiTable.euiTable--responsive .euiTableRowCellCheckbox {
		border: none;
	}
	.euiTable.euiTable--responsive .euiTableRow-hasActions .euiTableCellContent--showOnHover > * {
		margin-left: 0;
	}
	.euiTable.euiTable--responsive
		.euiTableRow-hasActions
		.euiTableCellContent--showOnHover
		.expandedItemActions__completelyHide {
		display: none;
	}
	.euiTable.euiTable--responsive
		.euiTableRow-hasActions
		.euiTableCellContent--showOnHover
		.euiTableCellContent__hoverItem {
		opacity: 1;
		-webkit-filter: none;
		filter: none;
		margin-left: 0;
		margin-bottom: 8px;
	}
	.euiTable.euiTable--responsive .euiTableCellContent--alignRight {
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}
	.euiTable.euiTable--responsive .euiTableCellContent--alignCenter {
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}
}

.euiTableHeaderMobile,
.euiTableHeaderCell--hideForDesktop {
	display: none;
}

@media only screen and (max-width: 574px) {
	.euiTableHeaderMobile {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		padding: 8px 0;
	}
	.euiTableSortMobile {
		display: block;
	}
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
	.euiTableHeaderMobile {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		padding: 8px 0;
	}
	.euiTableSortMobile {
		display: block;
	}
}

.euiTabs {
	scrollbar-width: thin;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	max-width: 100%;
	overflow-x: auto;
	overflow-y: hidden;
	position: relative;
}
.euiTabs::-webkit-scrollbar-thumb {
	background-color: rgba(105, 112, 125, 0.5);
	border: 6px solid transparent;
	background-clip: content-box;
}
.euiTabs::-webkit-scrollbar-corner,
.euiTabs::-webkit-scrollbar-track {
	background-color: transparent;
}
.euiTabs::-webkit-scrollbar {
	height: 3px;
	width: 16px;
}
.euiTabs:not(.euiTabs--condensed)::before {
	background-color: #d3dae6;
	bottom: 0;
	content: '';
	height: 1px;
	left: 0;
	position: absolute;
	right: 0;
}

.euiTab {
	font-size: 16px;
	font-size: 1rem;
	color: #343741;
	background-color: transparent;
	cursor: pointer;
	line-height: 1.5;
	padding: 12px 16px;
	position: relative;
	-webkit-transition: color 250ms cubic-bezier(0.694, 0.0482, 0.335, 1),
		background-color 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
	transition: color 250ms cubic-bezier(0.694, 0.0482, 0.335, 1),
		background-color 250ms cubic-bezier(0.694, 0.0482, 0.335, 1);
}
.euiTab:hover:not(.euiTab-isSelected) {
	text-decoration: underline;
}
.euiTab:focus {
	background-color: #e6f0f8;
	text-decoration: underline;
}
.euiTab:focus::before {
	background-color: #d3dae6;
	bottom: 0;
	content: '';
	height: 1px;
	left: 0;
	position: absolute;
	right: 0;
}
.euiTab.euiTab-isDisabled {
	color: #98a2b3;
}
.euiTab.euiTab-isDisabled:hover {
	color: #98a2b3;
	cursor: not-allowed;
	text-decoration: none;
}
.euiTab.euiTab-isSelected {
	color: #ef5742;
	cursor: default;
}
.euiTab.euiTab-isSelected::after {
	-webkit-animation: euiTab 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
	animation: euiTab 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
	background-color: #ef5742;
	bottom: 0;
	content: ' ';
	height: 2px;
	left: 0;
	position: absolute;
	width: 100%;
}
.euiTabs--small .euiTab {
	font-size: 14px;
	font-size: 0.875rem;
	padding: 8px;
}
.euiTabs--condensed .euiTab {
	font-weight: 600;
	padding: 8px 0;
}
.euiTabs--condensed .euiTab:focus {
	background-color: transparent;
}
.euiTabs--condensed .euiTab:focus::before {
	display: none;
}
.euiTabs--condensed .euiTab + .euiTab {
	margin-left: 24px;
}
.euiTabs--expand .euiTab {
	-ms-flex-preferred-size: 0%;
	flex-basis: 0%;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

.euiTab__content {
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-transform: translateY(0);
	transform: translateY(0);
	-webkit-transition: -webkit-transform 150ms cubic-bezier(0.34, 1.61, 0.7, 1);
	transition: -webkit-transform 150ms cubic-bezier(0.34, 1.61, 0.7, 1);
	transition: transform 150ms cubic-bezier(0.34, 1.61, 0.7, 1);
	transition: transform 150ms cubic-bezier(0.34, 1.61, 0.7, 1),
		-webkit-transform 150ms cubic-bezier(0.34, 1.61, 0.7, 1);
	white-space: nowrap;
}

@-webkit-keyframes euiTab {
	0% {
		-webkit-transform: scaleX(0);
		transform: scaleX(0);
	}
	100% {
		-webkit-transform: scaleX(1);
		transform: scaleX(1);
	}
}

@keyframes euiTab {
	0% {
		-webkit-transform: scaleX(0);
		transform: scaleX(0);
	}
	100% {
		-webkit-transform: scaleX(1);
		transform: scaleX(1);
	}
}

.euiTitle + .euiTitle {
	margin-top: 24px;
}

.euiTitle--uppercase {
	text-transform: uppercase;
}

.euiTitle--xxxsmall {
	color: #1a1c21;
	font-size: 12px;
	font-size: 0.75rem;
	line-height: 1.5rem;
	font-weight: 700;
}

.euiTitle--xxsmall {
	color: #1a1c21;
	font-size: 14px;
	font-size: 0.875rem;
	line-height: 1.5rem;
	font-weight: 700;
}

.euiTitle--xsmall {
	color: #1a1c21;
	font-size: 16px;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 600;
	letter-spacing: -0.02em;
}

.euiTitle--small {
	color: #1a1c21;
	font-size: 20px;
	font-size: 1.25rem;
	line-height: 2rem;
	font-weight: 500;
	letter-spacing: -0.025em;
}

.euiTitle--medium {
	color: #1a1c21;
	font-size: 28px;
	font-size: 1.75rem;
	line-height: 2.5rem;
	font-weight: 300;
	letter-spacing: -0.04em;
}

.euiTitle--large {
	color: #1a1c21;
	font-size: 36px;
	font-size: 2.25rem;
	line-height: 3rem;
	font-weight: 300;
	letter-spacing: -0.03em;
}

/**
   * 1. Allow list to expand as items are added, but cap it at the screen height.
   * 2. Only show the scroll on hover. Generally, scrolling is bad for toasts.
   * 3. Allow some padding if a scroll shows up.
   */
.euiGlobalToastList {
	scrollbar-width: thin;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	position: fixed;
	z-index: 9000;
	bottom: 0;
	right: 0;
	width: 368px;
	/* 3 */
	padding-right: 16px;
	padding-left: 32px;
	max-height: 100vh;
	/* 1 */
}
.euiGlobalToastList::-webkit-scrollbar {
	width: 16px;
	height: 16px;
}
.euiGlobalToastList::-webkit-scrollbar-thumb {
	background-color: rgba(105, 112, 125, 0.5);
	border: 6px solid transparent;
	background-clip: content-box;
}
.euiGlobalToastList::-webkit-scrollbar-corner,
.euiGlobalToastList::-webkit-scrollbar-track {
	background-color: transparent;
}
.euiGlobalToastList:hover {
	overflow-y: auto;
	/* 2 */
}

.euiGlobalToastListItem {
	margin-bottom: 16px;
	margin-right: 16px;
	-webkit-animation: 250ms euiShowToast cubic-bezier(0.694, 0.0482, 0.335, 1);
	animation: 250ms euiShowToast cubic-bezier(0.694, 0.0482, 0.335, 1);
	opacity: 1;
	/**
     * 1. justify-content: flex-end interferes with overflowing content, so we'll use this to push
     *    items to the bottom instead.
     */
}
.euiGlobalToastListItem:first-child {
	margin-top: auto;
	/* 1 */
}
.euiGlobalToastListItem.euiGlobalToastListItem-isDismissed {
	-webkit-transition: opacity 250ms;
	transition: opacity 250ms;
	opacity: 0;
}

@-webkit-keyframes euiShowToast {
	from {
		-webkit-transform: translateY(24px) scale(0.9);
		transform: translateY(24px) scale(0.9);
		opacity: 0;
	}
	to {
		-webkit-transform: translateY(0) scale(1);
		transform: translateY(0) scale(1);
		opacity: 1;
	}
}

@keyframes euiShowToast {
	from {
		-webkit-transform: translateY(24px) scale(0.9);
		transform: translateY(24px) scale(0.9);
		opacity: 0;
	}
	to {
		-webkit-transform: translateY(0) scale(1);
		transform: translateY(0) scale(1);
		opacity: 1;
	}
}

@media only screen and (max-width: 574px) {
	/**
     * 1. Mobile we make these 100%. Matching change happens on the item as well.
     */
	.euiGlobalToastList {
		left: 0;
		padding-left: 16px;
		width: 100%;
		/* 1 */
	}
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
	/**
     * 1. Mobile we make these 100%. Matching change happens on the item as well.
     */
	.euiGlobalToastList {
		left: 0;
		padding-left: 16px;
		width: 100%;
		/* 1 */
	}
}

.euiToast {
	border: 1px solid #d3dae6;
	-webkit-box-shadow: 0 40px 64px 0 rgba(65, 78, 101, 0.1), 0 24px 32px 0 rgba(65, 78, 101, 0.1),
		0 16px 16px 0 rgba(65, 78, 101, 0.1), 0 8px 8px 0 rgba(65, 78, 101, 0.1),
		0 4px 4px 0 rgba(65, 78, 101, 0.1), 0 2px 2px 0 rgba(65, 78, 101, 0.1);
	box-shadow: 0 40px 64px 0 rgba(65, 78, 101, 0.1), 0 24px 32px 0 rgba(65, 78, 101, 0.1),
		0 16px 16px 0 rgba(65, 78, 101, 0.1), 0 8px 8px 0 rgba(65, 78, 101, 0.1),
		0 4px 4px 0 rgba(65, 78, 101, 0.1), 0 2px 2px 0 rgba(65, 78, 101, 0.1);
	border-color: #c6cad1;
	border-top-color: #e3e4e8;
	border-bottom-color: #aaafba;
	position: relative;
	padding: 16px;
	background-color: #fff;
	width: 100%;
}
.euiToast:hover .euiToast__closeButton,
.euiToast:focus .euiToast__closeButton {
	opacity: 1;
}

/**
    * 1. Fit button to icon.
    */
.euiToast__closeButton {
	position: absolute;
	top: 16px;
	right: 16px;
	line-height: 0;
	/* 1 */
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	opacity: 0;
	-webkit-transition: opacity 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
	transition: opacity 150ms cubic-bezier(0.694, 0.0482, 0.335, 1);
}
.euiToast__closeButton svg {
	fill: #8d8e90;
}
.euiToast__closeButton:hover svg {
	fill: #1a1c21;
}
.euiToast__closeButton:focus {
	background-color: #e6f0f8;
	opacity: 1;
}
.euiToast__closeButton:focus svg {
	fill: #ef5742;
}

.euiToast--primary {
	border-top: 2px solid #ef5742;
}

.euiToast--success {
	border-top: 2px solid #017d73;
}

.euiToast--warning {
	border-top: 2px solid #c88800;
}

.euiToast--danger {
	border-top: 2px solid #bd271e;
}

/**
   * 1. Align icon with first line of title text if it wraps.
   * 2. Apply margin to all but last item in the flex.
   * 3. Account for close button.
   */
.euiToastHeader {
	padding-right: 24px;
	/* 3 */
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: baseline;
	-ms-flex-align: baseline;
	align-items: baseline;
	/* 1 */
}
.euiToastHeader > * + * {
	margin-left: 8px;
	/* 2 */
}

/**
    * 1. Vertically center icon with first line of title.
    */
.euiToastHeader__icon {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	fill: #1a1c21;
	-webkit-transform: translateY(2px);
	transform: translateY(2px);
	/* 1 */
}

.euiToastHeader__title {
	color: #1a1c21;
	font-size: 16px;
	font-size: 1rem;
	line-height: 1.5rem;
	letter-spacing: -0.02em;
	overflow-wrap: break-word !important;
	word-wrap: break-word !important;
	word-break: break-word;
	font-weight: 300;
}

.euiToastHeader--withBody {
	margin-bottom: 12px;
}

/**
   * 1. Prevent long lines from overflowing.
   */
.euiToastBody {
	word-wrap: break-word;
	/* 1 */
}

.euiToken {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}
.euiToken svg {
	height: 100%;
	margin: auto;
}

.euiToken--circle {
	border-radius: 50%;
}

.euiToken--square {
	border-radius: 3px;
}

.euiToken--xsmall {
	width: 12px;
	height: 12px;
}
.euiToken--xsmall.euiToken--rectangle {
	padding: 0 4px;
}

.euiToken--small {
	width: 16px;
	height: 16px;
}
.euiToken--small.euiToken--rectangle {
	padding: 0 4px;
}

.euiToken--medium {
	width: 24px;
	height: 24px;
}
.euiToken--medium.euiToken--rectangle {
	padding: 0 8px;
}

.euiToken--large {
	width: 32px;
	height: 32px;
}
.euiToken--large.euiToken--rectangle {
	padding: 0 8px;
}

.euiToken--rectangle {
	-webkit-box-sizing: content-box;
	box-sizing: content-box;
}

.euiToken--euiColorVis0 {
	color: #54b399;
}
.euiToken--euiColorVis0.euiToken--light {
	color: #387765;
	background-color: #eef7f5;
	-webkit-box-shadow: inset 0 0 0 1px #cce8e0;
	box-shadow: inset 0 0 0 1px #cce8e0;
}
.euiToken--euiColorVis0.euiToken--dark {
	background-color: #6dccb1;
	color: #000;
}

.euiToken--euiColorVis1 {
	color: #6092c0;
}
.euiToken--euiColorVis1.euiToken--light {
	color: #4a7194;
	background-color: #eff4f9;
	-webkit-box-shadow: inset 0 0 0 1px #cfdeec;
	box-shadow: inset 0 0 0 1px #cfdeec;
}
.euiToken--euiColorVis1.euiToken--dark {
	background-color: #79aad9;
	color: #000;
}

.euiToken--euiColorVis2 {
	color: #d36086;
}
.euiToken--euiColorVis2.euiToken--light {
	color: #ac4e6d;
	background-color: #fbeff3;
	-webkit-box-shadow: inset 0 0 0 1px #f2cfdb;
	box-shadow: inset 0 0 0 1px #f2cfdb;
}
.euiToken--euiColorVis2.euiToken--dark {
	background-color: #ee789d;
	color: #000;
}

.euiToken--euiColorVis3 {
	color: #9170b8;
}
.euiToken--euiColorVis3.euiToken--light {
	color: #7c609e;
	background-color: #f4f1f8;
	-webkit-box-shadow: inset 0 0 0 1px #ded4ea;
	box-shadow: inset 0 0 0 1px #ded4ea;
}
.euiToken--euiColorVis3.euiToken--dark {
	background-color: #a987d1;
	color: #000;
}

.euiToken--euiColorVis4 {
	color: #ca8eae;
}
.euiToken--euiColorVis4.euiToken--light {
	color: #8d647a;
	background-color: #faf4f7;
	-webkit-box-shadow: inset 0 0 0 1px #efdde7;
	box-shadow: inset 0 0 0 1px #efdde7;
}
.euiToken--euiColorVis4.euiToken--dark {
	background-color: #e4a6c7;
	color: #000;
}

.euiToken--euiColorVis5 {
	color: #d6bf57;
}
.euiToken--euiColorVis5.euiToken--light {
	color: #807234;
	background-color: #fbf9ee;
	-webkit-box-shadow: inset 0 0 0 1px #f3eccd;
	box-shadow: inset 0 0 0 1px #f3eccd;
}
.euiToken--euiColorVis5.euiToken--dark {
	background-color: #f1d86f;
	color: #000;
}

.euiToken--euiColorVis6 {
	color: #b9a888;
}
.euiToken--euiColorVis6.euiToken--light {
	color: #7b705a;
	background-color: #f8f6f3;
	-webkit-box-shadow: inset 0 0 0 1px #eae5db;
	box-shadow: inset 0 0 0 1px #eae5db;
}
.euiToken--euiColorVis6.euiToken--dark {
	background-color: #d2c0a0;
	color: #000;
}

.euiToken--euiColorVis7 {
	color: #da8b45;
}
.euiToken--euiColorVis7.euiToken--light {
	color: #996130;
	background-color: #fbf3ec;
	-webkit-box-shadow: inset 0 0 0 1px #f4dcc7;
	box-shadow: inset 0 0 0 1px #f4dcc7;
}
.euiToken--euiColorVis7.euiToken--dark {
	background-color: #f5a35c;
	color: #000;
}

.euiToken--euiColorVis8 {
	color: #aa6556;
}
.euiToken--euiColorVis8.euiToken--light {
	color: #9a5b4e;
	background-color: #f7f0ee;
	-webkit-box-shadow: inset 0 0 0 1px #e6d1cc;
	box-shadow: inset 0 0 0 1px #e6d1cc;
}
.euiToken--euiColorVis8.euiToken--dark {
	background-color: #c47c6c;
	color: #000;
}

.euiToken--euiColorVis9 {
	color: #e7664c;
}
.euiToken--euiColorVis9.euiToken--light {
	color: #b34f3b;
	background-color: #fdf0ed;
	-webkit-box-shadow: inset 0 0 0 1px #f8d1c9;
	box-shadow: inset 0 0 0 1px #f8d1c9;
}
.euiToken--euiColorVis9.euiToken--dark {
	background-color: #ff7e62;
	color: #000;
}

.euiToken--gray {
	color: #69707d;
}
.euiToken--gray.euiToken--light {
	color: #646a77;
	background-color: #f0f1f2;
	-webkit-box-shadow: inset 0 0 0 1px #d2d4d8;
	box-shadow: inset 0 0 0 1px #d2d4d8;
}
.euiToken--gray.euiToken--dark {
	background-color: #69707d;
	color: #fff;
}

.euiToggle {
	position: relative;
}
.euiToggle .euiToggle__input {
	position: absolute;
	opacity: 0 !important;
	width: 100%;
	height: 100%;
	cursor: pointer;
	z-index: 1;
}
.euiToggle .euiToggle__input:disabled {
	cursor: not-allowed;
}

/*
   * 1. Shift arrow 1px more than half its size to account for border radius
   */
.euiToolTip {
	-webkit-box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.1), 0 6px 12px 0 rgba(0, 0, 0, 0.1),
		0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 2px 2px 0 rgba(0, 0, 0, 0.1);
	box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.1), 0 6px 12px 0 rgba(0, 0, 0, 0.1),
		0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 2px 2px 0 rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	background-color: #404040;
	color: #fff;
	z-index: 9000;
	max-width: 256px;
	overflow-wrap: break-word;
	font-size: 14px;
	font-size: 0.875rem;
	line-height: 1.5;
	padding: 12px;
	-webkit-animation: euiToolTipTop 350ms ease-out 250ms forwards;
	animation: euiToolTipTop 350ms ease-out 250ms forwards;
	position: absolute;
	opacity: 0;
	/* 1 */
	/* 1 */
}
.euiToolTip.euiToolTip--delayLong {
	-webkit-animation-delay: 1250ms;
	animation-delay: 1250ms;
}
.euiToolTip .euiToolTip__arrow {
	content: '';
	position: absolute;
	-webkit-transform-origin: center;
	transform-origin: center;
	border-radius: 2px;
	background-color: #404040;
	width: 12px;
	height: 12px;
	-webkit-transform: translateY(-7px) rotateZ(45deg);
	transform: translateY(-7px) rotateZ(45deg);
	/* 1 */
}
.euiToolTip.euiToolTip--right {
	-webkit-animation-name: euiToolTipRight;
	animation-name: euiToolTipRight;
}
.euiToolTip.euiToolTip--right .euiToolTip__arrow {
	-webkit-transform: translateX(-5px) rotateZ(45deg);
	transform: translateX(-5px) rotateZ(45deg);
	/* 1 */
}
.euiToolTip.euiToolTip--bottom {
	-webkit-animation-name: euiToolTipBottom;
	animation-name: euiToolTipBottom;
}
.euiToolTip.euiToolTip--bottom .euiToolTip__arrow {
	-webkit-transform: translateY(-5px) rotateZ(45deg);
	transform: translateY(-5px) rotateZ(45deg);
	/* 1 */
}
.euiToolTip.euiToolTip--left {
	-webkit-animation-name: euiToolTipLeft;
	animation-name: euiToolTipLeft;
}
.euiToolTip.euiToolTip--left .euiToolTip__arrow {
	-webkit-transform: translateX(-7px) rotateZ(45deg);
	transform: translateX(-7px) rotateZ(45deg);
	/* 1 */
}
.euiToolTip .euiToolTip__title {
	font-weight: 700;
	border-bottom: solid 1px #595959;
	padding-bottom: 4px;
	margin-bottom: 4px;
}

.euiToolTipAnchor {
	display: inline-block;
}
.euiToolTipAnchor *[disabled] {
	pointer-events: none;
}

@-webkit-keyframes euiToolTipTop {
	0% {
		opacity: 0;
		-webkit-transform: translateY(-16px);
		transform: translateY(-16px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}

@keyframes euiToolTipTop {
	0% {
		opacity: 0;
		-webkit-transform: translateY(-16px);
		transform: translateY(-16px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}

@-webkit-keyframes euiToolTipBottom {
	0% {
		opacity: 0;
		-webkit-transform: translateY(16px);
		transform: translateY(16px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}

@keyframes euiToolTipBottom {
	0% {
		opacity: 0;
		-webkit-transform: translateY(16px);
		transform: translateY(16px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}

@-webkit-keyframes euiToolTipLeft {
	0% {
		opacity: 0;
		-webkit-transform: translateX(-16px);
		transform: translateX(-16px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}

@keyframes euiToolTipLeft {
	0% {
		opacity: 0;
		-webkit-transform: translateX(-16px);
		transform: translateX(-16px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}

@-webkit-keyframes euiToolTipRight {
	0% {
		opacity: 0;
		-webkit-transform: translateX(16px);
		transform: translateX(16px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}

@keyframes euiToolTipRight {
	0% {
		opacity: 0;
		-webkit-transform: translateX(16px);
		transform: translateX(16px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}

.euiText {
	font-weight: 400;
	font-size: 16px;
	font-size: 1rem;
	color: inherit;
	line-height: 1.5rem;
}
.euiText a:not([class]) {
	color: #ef5742;
}
.euiText a:not([class]):hover {
	color: #004d81;
	text-decoration: underline;
}
.euiText a:not([class]):focus {
	text-decoration: underline;
	outline: solid 3px rgba(0, 107, 180, 0.1);
	background-color: rgba(0, 107, 180, 0.1);
}
.euiText img {
	display: block;
	width: 100%;
}
.euiText ul {
	list-style: disc;
}
.euiText ol {
	list-style: decimal;
}
.euiText blockquote p:last-child {
	margin-bottom: 0;
}
.euiText blockquote:before,
.euiText blockquote:after {
	position: absolute;
	content: '';
	height: 2px;
	width: 50%;
	right: 0;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	background: #69707d;
}
.euiText blockquote:before {
	top: 0;
}
.euiText blockquote:after {
	bottom: 0;
}
.euiText h4,
.euiText dt {
	color: #1a1c21;
	font-size: 16px;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 600;
	letter-spacing: -0.02em;
}
.euiText pre,
.euiText pre code {
	display: block;
}
.euiText p,
.euiText ul,
.euiText ol,
.euiText dl,
.euiText blockquote,
.euiText img,
.euiText pre {
	margin-bottom: 1.5rem;
	white-space: pre-wrap;
	background: #f5f7fa;
	color: #343741;
}
.euiText ul,
.euiText ol {
	margin-left: 1.5rem;
}
.euiText blockquote {
	padding: 1.5rem;
	font-size: 1.125rem;
	position: relative;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	font-family: var(--primary-font), Georgia, Times, Times New Roman, serif;
	font-style: italic;
	letter-spacing: normal;
}
.euiText h1,
.euiText h2,
.euiText h3,
.euiText h4,
.euiText h5,
.euiText h6 {
	margin-bottom: 0.5rem;
}
.euiText dd + dt {
	margin-top: 1rem;
}
.euiText * + h2,
.euiText * + h3,
.euiText * + h4,
.euiText * + h5,
.euiText * + h6 {
	margin-top: 2rem;
}
.euiText h1 {
	font-size: 2.25rem;
	line-height: 3rem;
	color: #1a1c21;
	font-weight: 300;
	letter-spacing: -0.03em;
}
.euiText h2 {
	font-size: 1.75rem;
	line-height: 2.5rem;
	color: #1a1c21;
	font-weight: 300;
	letter-spacing: -0.04em;
}
.euiText h3 {
	font-size: 1.25rem;
	line-height: 2rem;
	color: #1a1c21;
	font-weight: 500;
	letter-spacing: -0.025em;
}
.euiText h4,
.euiText dt,
.euiText .eui-definitionListReverse dd {
	font-size: 1rem;
	line-height: 1.5rem;
}
.euiText .eui-definitionListReverse dt {
	font-size: 0.75rem;
	color: #343741;
}
.euiText h5 {
	font-size: 0.875rem;
	line-height: 1rem;
	color: #1a1c21;
	font-weight: 700;
}
.euiText h6 {
	font-size: 0.75rem;
	line-height: 1rem;
	color: #1a1c21;
	font-weight: 700;
	text-transform: uppercase;
}
.euiText small {
	font-size: 0.875rem;
}
.euiText pre {
	padding: 16px;
}
.euiText code {
	font-size: 0.9rem;
	font-family: var(--primary-font), 'Roboto Mono', Consolas, Menlo, Courier, monospace;
	letter-spacing: normal;
	display: inline-block;
	font-weight: 400;
}
.euiText.euiText--constrainedWidth {
	max-width: 36em;
	min-width: 75%;
}
.euiText > :last-child,
.euiText .euiTextColor > :last-child {
	margin-bottom: 0 !important;
}

.euiText--small {
	font-size: 14px;
	font-size: 0.875rem;
	line-height: 1.3125rem;
}
.euiText--small p,
.euiText--small ul,
.euiText--small ol,
.euiText--small dl,
.euiText--small blockquote,
.euiText--small img,
.euiText--small pre {
	margin-bottom: 1.3125rem;
}
.euiText--small ul,
.euiText--small ol {
	margin-left: 1.3125rem;
}
.euiText--small blockquote {
	padding: 1.3125rem;
	font-size: 0.98438rem;
}
.euiText--small h1,
.euiText--small h2,
.euiText--small h3,
.euiText--small h4,
.euiText--small h5,
.euiText--small h6 {
	margin-bottom: 0.4375rem;
}
.euiText--small dd + dt {
	margin-top: 0.875rem;
}
.euiText--small * + h2,
.euiText--small * + h3,
.euiText--small * + h4,
.euiText--small * + h5,
.euiText--small * + h6 {
	margin-top: 1.75rem;
}
.euiText--small h1 {
	font-size: 1.96875rem;
	line-height: 2.625rem;
}
.euiText--small h2 {
	font-size: 1.53125rem;
	line-height: 2.1875rem;
}
.euiText--small h3 {
	font-size: 1.09375rem;
	line-height: 1.75rem;
}
.euiText--small h4,
.euiText--small dt,
.euiText--small .eui-definitionListReverse dd {
	font-size: 0.875rem;
	line-height: 1.3125rem;
}
.euiText--small .eui-definitionListReverse dt {
	font-size: 0.65625rem;
	color: #343741;
}
.euiText--small h5 {
	font-size: 0.76562rem;
	line-height: 0.875rem;
}
.euiText--small h6 {
	font-size: 0.65625rem;
	line-height: 0.875rem;
}
.euiText--small small {
	font-size: 0.76562rem;
}
.euiText--small pre {
	padding: 14px;
}
.euiText--small code {
	font-size: 0.7875rem;
}

.euiText--extraSmall {
	font-size: 12px;
	font-size: 0.75rem;
	line-height: 1.125rem;
}
.euiText--extraSmall p,
.euiText--extraSmall ul,
.euiText--extraSmall ol,
.euiText--extraSmall dl,
.euiText--extraSmall blockquote,
.euiText--extraSmall img,
.euiText--extraSmall pre {
	margin-bottom: 1.125rem;
}
.euiText--extraSmall ul,
.euiText--extraSmall ol {
	margin-left: 1.125rem;
}
.euiText--extraSmall blockquote {
	padding: 1.125rem;
	font-size: 0.84375rem;
}
.euiText--extraSmall h1,
.euiText--extraSmall h2,
.euiText--extraSmall h3,
.euiText--extraSmall h4,
.euiText--extraSmall h5,
.euiText--extraSmall h6 {
	margin-bottom: 0.375rem;
}
.euiText--extraSmall dd + dt {
	margin-top: 0.75rem;
}
.euiText--extraSmall * + h2,
.euiText--extraSmall * + h3,
.euiText--extraSmall * + h4,
.euiText--extraSmall * + h5,
.euiText--extraSmall * + h6 {
	margin-top: 1.5rem;
}
.euiText--extraSmall h1 {
	font-size: 1.6875rem;
	line-height: 2.25rem;
}
.euiText--extraSmall h2 {
	font-size: 1.3125rem;
	line-height: 1.875rem;
}
.euiText--extraSmall h3 {
	font-size: 0.9375rem;
	line-height: 1.5rem;
}
.euiText--extraSmall h4,
.euiText--extraSmall dt,
.euiText--extraSmall .eui-definitionListReverse dd {
	font-size: 0.75rem;
	line-height: 1.125rem;
}
.euiText--extraSmall .eui-definitionListReverse dt {
	font-size: 0.5625rem;
	color: #343741;
}
.euiText--extraSmall h5 {
	font-size: 0.65625rem;
	line-height: 0.75rem;
}
.euiText--extraSmall h6 {
	font-size: 0.5625rem;
	line-height: 0.75rem;
}
.euiText--extraSmall small {
	font-size: 0.65625rem;
}
.euiText--extraSmall pre {
	padding: 12px;
}
.euiText--extraSmall code {
	font-size: 0.675rem;
}

.euiTextColor--default {
	color: #343741;
}

.euiTextColor--subdued {
	color: #6a717d;
}

.euiTextColor--secondary {
	color: #017d73;
}

.euiTextColor--accent {
	color: #dd0a73;
}

.euiTextColor--warning {
	color: #9b6900;
}

.euiTextColor--danger {
	color: #bd271e;
}

.euiTextColor--ghost {
	color: #717171;
	color: #fff !important;
}

.euiTextAlign--left {
	text-align: left;
}

.euiTextAlign--right {
	text-align: right;
}

.euiTextAlign--center {
	text-align: center;
}

.invisible {
	visibility: hidden;
}
.echChartStatus {
	visibility: hidden;
	pointer-events: none;
	z-index: -100000;
	width: 0;
	height: 0;
	position: absolute;
}
.echChart {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	position: relative;
}
.echChart--column {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}
.echContainer {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	position: relative;
}
.echChartPointerContainer {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.echChartResizer {
	z-index: -10000000;
	position: absolute;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
#echAnnotationContainerPortal {
	position: absolute;
	pointer-events: none;
}
.echAnnotation {
	pointer-events: none;
	position: absolute;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	font-size: 12px;
	font-weight: 700;
}
.echAnnotation--hidden,
.echAnnotation__tooltip--hidden {
	opacity: 0;
}
.echAnnotation__tooltip {
	-webkit-box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.1), 0 6px 12px 0 rgba(0, 0, 0, 0.1),
		0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 2px 2px 0 rgba(0, 0, 0, 0.1);
	box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.1), 0 6px 12px 0 rgba(0, 0, 0, 0.1),
		0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 2px 2px 0 rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	background-color: #404040;
	color: #fff;
	z-index: 9000;
	max-width: 256px;
	overflow-wrap: break-word;
	font-size: 0.75rem;
	line-height: 1.5;
	position: absolute;
	padding: 0;
	-webkit-transition: opacity 250ms;
	transition: opacity 250ms;
	pointer-events: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.echAnnotation__header {
	font-weight: 700;
	border-bottom: solid 1px #595959;
	margin-bottom: 4px;
	padding: 4px 8px;
}
.echAnnotation__details {
	padding: 4px 8px;
}
#echTooltipContainerPortal {
	position: absolute;
	pointer-events: none;
	z-index: 10000000;
}
.echTooltip {
	position: absolute;
	-webkit-box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.1), 0 6px 12px 0 rgba(0, 0, 0, 0.1),
		0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 2px 2px 0 rgba(0, 0, 0, 0.1);
	box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.1), 0 6px 12px 0 rgba(0, 0, 0, 0.1),
		0 4px 4px 0 rgba(0, 0, 0, 0.1), 0 2px 2px 0 rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	background-color: #404040;
	color: #fff;
	z-index: 9000;
	max-width: 256px;
	overflow-wrap: break-word;
	font-size: 0.75rem;
	line-height: 1.5;
	padding: 0;
	-webkit-transition: opacity 250ms;
	transition: opacity 250ms;
	pointer-events: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	margin-bottom: 4px;
}
.echTooltip__list {
	padding: 4px;
}
.echTooltip__header {
	font-weight: 700;
	border-bottom: solid 1px #595959;
	margin-bottom: 4px;
	margin-bottom: 0;
	padding: 4px 8px;
}
.echTooltip__item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding: 3px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	border-left: 4px solid transparent;
	min-width: 1px;
}
.echTooltip__label {
	overflow-wrap: break-word;
	word-wrap: break-word;
	min-width: 1px;
	-webkit-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
}
.echTooltip__value {
	font-weight: 700;
	text-align: right;
	-webkit-font-feature-settings: 'tnum';
	font-feature-settings: 'tnum';
	margin-left: 8px;
}
.echTooltip__rowHighlighted {
	background-color: rgba(255, 255, 255, 0.1);
}
.echTooltip--hidden {
	opacity: 0;
}
.echIcon {
	-ms-flex-negative: 0;
	flex-shrink: 0;
	display: inline-block;
	vertical-align: middle;
	fill: currentColor;
}
.echIcon svg {
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0);
}
.echIcon:focus {
	opacity: 1;
	background: #e6f0f8;
}
.echLegend--top .echLegendList,
.echLegend--bottom .echLegendList {
	display: grid;
	grid-column-gap: 24px;
	grid-row-gap: 8px;
	margin-top: 8px;
	margin-bottom: 8px;
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
	.echLegend--top .echLegendList,
	.echLegend--bottom .echLegendList {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}
}
.echLegend--left .echLegendList,
.echLegend--right .echLegendList {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}
.echLegend--top,
.echLegend--left {
	-webkit-box-ordinal-group: 1;
	-ms-flex-order: 0;
	order: 0;
}
.echLegend--bottom,
.echLegend--right {
	-webkit-box-ordinal-group: 2;
	-ms-flex-order: 1;
	order: 1;
}
.echLegend--debug {
	background: red;
}
.echLegend .echLegendListContainer {
	scrollbar-width: thin;
	-webkit-mask-image: linear-gradient(
		to bottom,
		rgba(255, 0, 0, 0.1) 0%,
		red 7.5px,
		red calc(100% - 7.5px),
		rgba(255, 0, 0, 0.1) 100%
	);
	mask-image: linear-gradient(
		to bottom,
		rgba(255, 0, 0, 0.1) 0%,
		red 7.5px,
		red calc(100% - 7.5px),
		rgba(255, 0, 0, 0.1) 100%
	);
	height: 100%;
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;
}
.echLegend .echLegendListContainer::-webkit-scrollbar {
	width: 16px;
	height: 16px;
}
.echLegend .echLegendListContainer::-webkit-scrollbar-thumb {
	background-color: rgba(105, 112, 125, 0.5);
	border: 6px solid transparent;
	background-clip: content-box;
}
.echLegend .echLegendListContainer::-webkit-scrollbar-corner,
.echLegend .echLegendListContainer::-webkit-scrollbar-track {
	background-color: transparent;
}
.echLegendItem {
	color: #343741;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	width: 100%;
}
.echLegendItem:not(.echLegendItem--hidden) .echLegendItem__color--changable {
	cursor: pointer;
}
.echLegendItem__color {
	margin-right: 4px;
}
.echLegendItem__visibility {
	margin-right: 4px;
}
.echLegendItem__visibility:hover {
	cursor: pointer;
}
.echLegendItem__label {
	font-size: 0.75rem;
	line-height: 1.5;
	max-width: 100%;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
	white-space: nowrap !important;
	word-wrap: normal !important;
	-webkit-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
}
.echLegendItem__label--clickable:hover {
	cursor: pointer;
	text-decoration: underline;
}
.echLegendItem__extra {
	font-size: 0.75rem;
	line-height: 1.5;
	text-align: right;
	margin-left: 4px;
	-webkit-font-feature-settings: 'tnum';
	font-feature-settings: 'tnum';
}
.echLegendItem__extra--hidden {
	display: none;
}
.echLegendItem--right,
.echLegendItem--left {
	padding-top: 4px;
	padding-bottom: 4px;
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
	.echLegendItem {
		padding-top: 4px;
		padding-bottom: 4px;
	}
	.echLegendItem--bottom,
	.echLegendItem--top {
		width: 200px;
		margin-right: 24px;
	}
}
.echLegendItem--hidden {
	color: #69707d;
}
.echReactiveChart_unavailable {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	font-size: 0.75rem;
	line-height: 1.5;
	color: #69707d;
}
.echHighlighter {
	position: absolute;
	z-index: 1000;
	pointer-events: none;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
}
.echHighlighterOverlay__fill {
	fill: rgba(255, 255, 255, 0.2);
}
.echHighlighterOverlay__stroke {
	stroke: rgba(255, 255, 255, 0.2);
}
.echHighlighter__mask {
	fill: rgba(255, 255, 255, 0.5);
}
.echCrosshair,
.echCrosshair__band,
.echCrosshair__line {
	position: absolute;
	pointer-events: none;
}
.echCrosshair__line {
	z-index: 8000;
}
.echBrushTool {
	position: absolute;
	top: 0;
	left: 0;
	margin: 0;
	padding: 0;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	overflow: hidden;
	pointer-events: none;
}
.echCanvasRenderer {
	position: absolute;
	top: 0;
	left: 0;
	padding: 0;
	margin: 0;
	border: 0;
	background: transparent;
}
